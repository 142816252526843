import {
  useReducer, useMemo, useEffect,
} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ga4 from 'react-ga4';
import i18n from './translate/i18n';

import { isProductionVisible, reactAppPublicGA } from './config';
import { LocalContext } from './LocalContext';
import { LocalReducer, initialState } from './Reducer';
import { savedConstants } from 'constants/localStorage';
import { RoutesComponent } from 'routes/Router';
import { theme } from 'theme';

import { FirstEntranceModal } from 'components/FirstEntranceModal';
import { EntryPassword } from 'components/EntryPassword';

function App() {
  const [localState, localDispatch] = useReducer(LocalReducer, initialState);
  const isAuth = localStorage.getItem(savedConstants.IS_AUTH);
  const clientId = localStorage.getItem('client_id');

  useEffect(() => {
    ga4.initialize(reactAppPublicGA);
  });

  const providerState = useMemo(
    () => ({
      localState,
      localDispatch,
    }),
    [localState],
  );

  useEffect(() => {
    const lng = localStorage.getItem(savedConstants.LNG);
    if (lng === 'eng') {
      localStorage.setItem(savedConstants.LNG, 'en');
    }
    if (!isProductionVisible && !lng) {
      localStorage.setItem(savedConstants.LNG, 'pu');
      i18n.changeLanguage('pu');
    }
    i18n.changeLanguage(lng);
  }, []);

  return isAuth || isProductionVisible ? (
    <LocalContext.Provider value={providerState}>
      <ConfigProvider theme={theme}>
        <Router>
          <RoutesComponent />
          {!clientId && <FirstEntranceModal />}
        </Router>
      </ConfigProvider>
    </LocalContext.Provider>
  ) : (
    <EntryPassword />
  );
}

export default App;
