import { performAuthorizedRequest } from '../apiConfig';

const blackListProviderPath = '/client_actions';
const blackListVisitorPath = '/verified_visitor_actions';

export const sendReportProvider = (query) => performAuthorizedRequest(`${blackListProviderPath}/report_visitor`, query);

export const getBlackListProviderCollect = (query) => performAuthorizedRequest(`${blackListProviderPath}/collect`, query);

export const sendReportVisitor = (query) => performAuthorizedRequest(`${blackListVisitorPath}/report_client`, query);

export const getBlackListVisitorCollect = (query) => performAuthorizedRequest(`${blackListVisitorPath}/collect`, query);

export const deleteBlackListVisitorCollect = (query) => performAuthorizedRequest(`${blackListVisitorPath}/remove_client`, query);
