const reactAppPublicApiUrl = process.env.REACT_APP_PUBLIC_API_URL;
const reactAppPublicGA = process.env.REACT_APP_PUBLIC_GA;
const isProductionVisible = process.env.REACT_APP_PUBLIC_VISIBLE === 'true';

export const reactAppPublicV3 = process.env.REACT_APP_PUBLIC_RECAPTCHA_V3_SITE_KEY;
export const reactAppPublicV2 = process.env.REACT_APP_PUBLIC_RECAPTCHA_V2_SITE_KEY;

// eslint-disable-next-line max-len
const reactAppPublicApiUrlSecretPassword = process.env.REACT_APP_PUBLIC_REACT_APP_API_SECRET_PASSWORD;

const reactAppPublicReactAppApiSecretKey = process.env.REACT_APP_PUBLIC_REACT_APP_API_SECRET_KEY;

export {
  reactAppPublicApiUrl,
  reactAppPublicApiUrlSecretPassword,
  reactAppPublicReactAppApiSecretKey,
  isProductionVisible,
  reactAppPublicGA,
};
