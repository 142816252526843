import { initialFilters } from 'constants/initialFilters';

export const formatActualRangesData = (data) => ({
  age: [data.age_min, data.age_max],
  age_max: data.age_max,
  age_min: data.age_min,
  height__cm: [data.height_min, data.height_max],
  height__cm_min: data.height_min,
  height__cm_max: data.height_max,
  height__ft: [
    Math.round(data.height_min * 3.28084) / 100,
    Math.round(data.height_max * 3.28084) / 100,
  ],
  height__ft_min: Math.round(data.height_min * 3.28084) / 100,
  height__ft_max: Math.round(data.height_max * 3.28084) / 100,
  price: [data.rate_min, data.rate_max],
  price_max: data.rate_max,
  price_min: data.rate_min,
  weight__kg: [data.weight_min, data.weight_max],
  weight__kg_min: data.weight_min,
  weight__kg_max: data.weight_max,
  weight__lbs: [
    Math.round(data.weight_min * 2.20462),
    Math.round(data.weight_max * 2.20462),
  ],
  weight__lbs_min: Math.round(data.weight_min * 2.20462),
  weight__lbs_max: Math.round(data.weight_max * 2.20462),
});

export const getClearFilters = (filters) => ({
  ...initialFilters,
  price: [filters.price_min, filters.price_max],
  price_max: filters.price_max,
  price_min: filters.price_min,
  age: [filters.age_min, filters.age_max],
  age_max: filters.age_max,
  age_min: filters.age_min,
  height__cm: [filters.height__cm_min, filters.height__cm_max],
  height__cm_max: filters.height__cm_max,
  height__cm_min: filters.height__cm_min,
  height__ft: [filters.height__ft_min, filters.height__ft_max],
  height__ft_max: filters.height__ft_max,
  height__ft_min: filters.height__ft_min,
  weight__kg: [filters.weight__kg_min, filters.weight__kg_max],
  weight__kg_max: filters.weight__kg_max,
  weight__kg_min: filters.weight__kg_min,
  weight__lbs: [filters.weight__lbs_min, filters.weight__lbs_max],
  weight__lbs_max: filters.weight__lbs_max,
  weight__lbs_min: filters.weight__lbs_min,
});
