import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import i18n from 'translate/i18n';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { usePublicVersion } from 'hooks/usePublicVersion';

import { savedConstants } from 'constants';
import { translateLanguage } from 'constants/translateLanguage';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const clientId = localStorage.getItem('client_id');

const FirstEntranceModal = memo(() => {
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const { gaClickEvent } = useGoogleAnalytics();
  const lng = localStorage.getItem(savedConstants.LNG);
  const { isPublicVersion } = usePublicVersion();

  const [openText, setOpenText] = useState(false);
  const [language, setLanguage] = useState(lng || 'en');

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang.key);
    localStorage.setItem(savedConstants.LNG, lang.key);
    const matchedLngTitle = translateLanguage.find(
      (item) => item.key === lang.key,
    ).key;
    setLanguage(matchedLngTitle);
    setOpenText(false);
  };

  const onClose = () => {
    const savedItem = uuidv4();
    localStorage.setItem(savedConstants.CLIENT_ID, savedItem);
    gaClickEvent({
      action: 'Сlick_entry_modal',
      label: 'first visit to the site',
    });
    setOpen(false);
  };

  useEffect(() => {
    if (clientId) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [clientId]);

  return (
    <ModalWrapper
      open={open}
      noClose
      maxHeightModal="80dvh"
      footer={(
        <div className={styles.footer}>
          <Link to="https://www.google.com/">
            <Button secondary className={styles.footerButton}>
              {t('components.firstEntranceModal.leave')}
            </Button>
          </Link>
          <Button primary onClick={onClose} className={styles.footerButton}>
            {t('components.firstEntranceModal.enter')}
          </Button>
        </div>
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <img
            src={isPublicVersion ? publicLogo : logo}
            alt="logo"
            className={styles.logo}
          />
          <h2 className={styles.title}>{t('common.welcome')}</h2>
        </div>
        <p>{t('components.firstEntranceModal.FirstEntranceText.1')}</p>
        <p className={styles.listTitle}>
          {t('components.firstEntranceModal.FirstEntranceText.2')}
        </p>
        <ul className={styles.policyList}>
          <li>
            <p>
              <b>{t('components.firstEntranceModal.FirstEntranceText.3')}</b>
            </p>
          </li>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.4')}</p>
          </li>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.5')}</p>
          </li>
        </ul>
        <p className={styles.listTitle}>
          {t('components.firstEntranceModal.FirstEntranceText.6')}
        </p>
        <ul className={styles.policyList}>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.7')}</p>
          </li>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.8')}</p>
          </li>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.9')}</p>
          </li>
          <li>
            <p>{t('components.firstEntranceModal.FirstEntranceText.10')}</p>
          </li>
        </ul>
        <p>
          {openText
            ? t('components.firstEntranceModal.FirstEntranceText.11')
            : `${
              `${t(
                'components.firstEntranceModal.FirstEntranceText.11',
              )}`.split(',')[0]
            }...`}
          {' '}
          <Button
            type="link"
            className={styles.readMore}
            onClick={() => setOpenText(!openText)}
          >
            {openText ? t('common.hide') : t('common.read_more')}
          </Button>
        </p>
        <p>{t('components.firstEntranceModal.FirstEntranceText.12')}</p>
        <div className={styles.langButton}>
          {translateLanguage.map((item) => (
            <Button
              key={item.key}
              primary={language === item.key}
              secondary={language !== item.key}
              onClick={() => handleChangeLanguage(item)}
              className={styles.button}
            >
              {item.name}
            </Button>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
});

export { FirstEntranceModal };
