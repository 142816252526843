import React from 'react';
import PropTypes from 'prop-types';

const VerifiedIcon = ({ className }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="#633976"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.1667 8.99999L17.1333 6.68333L17.4167 3.61666L14.4083 2.93333L12.8333 0.283325L10 1.49999L7.16667 0.283325L5.59167 2.93333L2.58333 3.60833L2.86667 6.67499L0.833332 8.99999L2.86667 11.3167L2.58333 14.3917L5.59167 15.075L7.16667 17.725L10 16.5L12.8333 17.7167L14.4083 15.0667L17.4167 14.3833L17.1333 11.3167L19.1667 8.99999ZM8.33333 13.1667L5 9.83333L6.175 8.65833L8.33333 10.8083L13.825 5.31666L15 6.49999L8.33333 13.1667Z"
    />
  </svg>
);

VerifiedIcon.propTypes = {
  className: PropTypes.string,
};

export { VerifiedIcon };
