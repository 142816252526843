import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { CloseIcon } from 'components/ActiveIcon/CloseIcon';
import ok from 'assets/ok.svg';

import styles from '../../styled.module.css';

const StepTitle = ({
  setOpenMenu,
  setDisabled,
  name,
  step,
  сurrentStep,
  isFilled = false,
  isRequired,
  disabled,
  setOpenModal,
  setModalStep,
}) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
  } = useLocalContext();

  const handlerOnClick = () => {
    if (!userInfo && name !== t('common.bioInfo')) return;
    setDisabled(false);
    setOpenMenu(false);
    if (сurrentStep !== step) {
      setOpenModal(true);
      setModalStep(step);
    }
  };

  return (
    <div
      className={`${styles.stepTitle} ${disabled ? styles.disabled : ''}`}
      onClick={() => !disabled && handlerOnClick()}
    >
      <p className={styles.stepTitleName}>
        {name}
        {isRequired && (
        <span className={styles.required}>
          (
          {t('common.required')}
          )
        </span>
        )}
      </p>
      {isFilled ? <img src={ok} alt="ok" /> : <CloseIcon />}
    </div>
  );
};

StepTitle.propTypes = {
  step: PropTypes.number.isRequired,
  сurrentStep: PropTypes.number.isRequired,
  setOpenMenu: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isFilled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  isRequired: PropTypes.bool,
  setOpenModal: PropTypes.func,
  setModalStep: PropTypes.func,
};

export { StepTitle };
