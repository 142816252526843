import { useMemo, useState } from 'react';
import { Button, Form, Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';

import { sendContactUs } from 'api/requests/contactUs';
import { useLocalContext } from 'LocalContext';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { contactUsTags } from 'utils/filters';
import { scrollToErrorField } from 'utils/scrollToErrorField';

import { ProfileLayout } from '../components/ProfileLayout';

import styles from './styled.module.css';
import './styles.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const {
    localState: {
      userInfo: { user_type },
    },
  } = useLocalContext();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [error, setError] = useState(false);

  const actualContactUsTags = useMemo(() => {
    if (user_type === userProvider) {
      return contactUsTags;
    }
    return contactUsTags.filter(
      (item) => item.value !== 'verification_problem',
    );
  }, [user_type, userProvider, contactUsTags]);

  const requiredFields = ['tag', 'message'];
  const isAnyFieldTouched = () => requiredFields.every((field) => form.isFieldTouched(field));
  const hasErrors = () => form.getFieldsError(requiredFields).some(({ errors }) => errors.length);

  const onClickShowResult = async (values) => {
    const tag = contactUsTags.find((item) => item.value === values.tag);

    const query = {
      ...values,
      tag: {
        key: tag.value,
        value: tag.label,
      },
    };

    setLoading(true);
    try {
      await sendContactUs(query);
      setError(false);
      setIsSend(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return isSend ? (
    <ProfileLayout>
      <div
        className={`${styles.wrapper} ${
          user_type === userVisitor ? styles.visitor : ''
        }`}
      >
        <div className={styles.sendWrapper}>
          <div className={styles.sendTitle}>
            <p className={styles.title}>{t('pages.profile.contactUs.1')}</p>
            <p>{t('pages.profile.contactUs.2')}</p>
            <p>
              {t('pages.profile.contactUs.3')}
              <br />
              {t('pages.profile.contactUs.9')}
            </p>
          </div>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              setIsSend(false);
              form.resetFields();
            }}
          >
            {t('pages.profile.contactUs.4')}
          </Button>
        </div>
      </div>
    </ProfileLayout>
  ) : (
    <ProfileLayout>
      <div
        className={`${styles.wrapper} ${
          user_type === userVisitor ? styles.visitor : ''
        }`}
      >
        <div className={styles.reporTitle}>
          <p className={styles.title}>{t('pages.profile.contactUs.5')}</p>
          <p>{t('pages.profile.contactUs.6')}</p>
        </div>
        <div className={styles.depositBlock}>
          <Form
            onFinish={onClickShowResult}
            autoComplete="off"
            form={form}
            className={styles.form}
            onFinishFailed={(values) => scrollToErrorField(values)}
          >
            <div className={styles.formWrapper}>
              <div className={styles.info}>
                <Form.Item
                  name="message"
                  className="contactUs"
                  rules={[
                    {
                      required: true,
                      message: t('errors.enterMessage'),
                    },
                  ]}
                >
                  <TextArea
                    placeholder={t('pages.profile.contactUs.8')}
                    maxLength={2000}
                    showCount
                    style={{ minWidth: '100%' }}
                    allowClear
                  />
                </Form.Item>
              </div>
              <div className={styles.tags}>
                <p>
                  {t('common.issueTags')}
                  *
                </p>
                <Form.Item
                  name="tag"
                  className="contactUs"
                  rules={[
                    {
                      required: true,
                      message: t('errors.selectTag'),
                    },
                  ]}
                >
                  <Radio.Group className={styles.radioGroup}>
                    {actualContactUsTags.map((option) => (
                      <Radio key={option.value} value={option.value}>
                        <span className={styles.tagLabel}>
                          {t(option.label)}
                        </span>
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
                {error && (
                  <span className={styles.error}>
                    {t('errors.somethingWrong')}
                  </span>
                )}
              </div>
            </div>
            <Form.Item shouldUpdate className={styles.buttonBlock}>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.button}
                  disabled={!isAnyFieldTouched() || hasErrors()}
                >
                  {t('common.send')}
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </ProfileLayout>
  );
};

export { ContactUs };
