import { useEffect } from 'react';

const useScrollToError = (errors) => {
  useEffect(() => {
    if (Object.keys(errors).length) {
      const error = Object.keys(errors);
      const element = document.getElementById(error[0]);

      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetPosition = window.scrollY + rect.top - 85;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [!!Object.keys(errors).length]);
};

export { useScrollToError };
