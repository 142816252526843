import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Table } from 'antd';
import {
  languages,
  messengers as messengersData,
  socialMedia,
} from 'utils/filters';
import phone from 'assets/phone.svg';
import {
  catalogProfileRatesColums,
  catalogProfileRatesRows,
} from 'utils/catalogProfile';

import styles from './styled.module.css';
import './styles.css';

const actualMessengerOrSocila = (item, arr) => arr.find((el) => el.value === item.name);

const actualMessengerOrSocilasFormat = (item) => {
  const findMessengerOrSocial = actualMessengerOrSocila(item, messengersData);
  if (findMessengerOrSocial?.pattern) {
    return item.link.replace(findMessengerOrSocial.pattern, '');
  }
  return item.link;
};

const Contacts = ({
  profile,
  rates = [],
  myProfile = false,
  messengers = [],
  social_networks = [],
}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const isHaveRates = rates.some((item) => item.discount_amount > 0);
  const urlLink = window.location.href.split('/').slice(0, -1).join('/');

  const actualLanguages = useMemo(
    () => languages
      .filter((item) => profile.languages.includes(item.value))
      .map((item) => item.label)
      .join(' / '),
    [languages, profile],
  );

  const rows = catalogProfileRatesRows(rates);
  const showAllButton = rows.length > 3;

  return (
    <div
      className={`${styles.contacts} ${
        myProfile ? styles.contactsMyProfile : ''
      }`}
    >
      {!myProfile && (
        <div className={`${styles.block} ${styles.title}`}>
          <p>{profile.nickname}</p>
          <p>
            {profile.city}
            ,
            {' '}
            {t('common.age')}
            {' '}
            {profile.age}
          </p>
        </div>
      )}
      {/* <div className={styles.block}>
        <p>{t('components.aboutUser.contacts.1')}</p>
        <p>{t('components.aboutUser.contacts.2')}</p>
      </div> */}
      {profile.languages && (
        <div className={styles.block}>
          <p>{t('components.aboutUser.contacts.3')}</p>
          <p>{actualLanguages}</p>
        </div>
      )}
      {!!messengers.length && (
        <div className={styles.block}>
          <p>{t('common.messengers')}</p>
          <div className={styles.socials}>
            {messengers.map((item) => {
              const { url, text } = actualMessengerOrSocila(
                item,
                messengersData,
              );
              return (
                <Link
                  target="_blank"
                  to={`${url}${actualMessengerOrSocilasFormat(item)}${
                    text
                      ? `${text}${t('common.socialMessage')} ${urlLink}/${
                        profile.id_name
                      }`
                      : ''
                  }`}
                  key={item.name}
                >
                  <img
                    src={actualMessengerOrSocila(item, messengersData)?.image}
                    alt={item.name}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      )}
      {!!social_networks.length && profile.verified && (
        <div className={styles.block}>
          <p>{t('common.social_media')}</p>
          <div className={styles.socials}>
            {social_networks.map((item) => (
              <Link
                target="_blank"
                to={`${actualMessengerOrSocila(item, socialMedia)?.url}${
                  item.link
                }`}
                key={item.name}
              >
                <img
                  src={actualMessengerOrSocila(item, socialMedia)?.image}
                  alt={item.name}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
      {myProfile && profile.phone_number && (
        <div className={styles.block}>
          <p>{t('components.aboutUser.contacts.4')}</p>
          <p>{profile.phone_number}</p>
        </div>
      )}
      {!myProfile && rates && !!rates.length && (
        <div className={styles.table}>
          <p className={isHaveRates ? styles.rates : ''}>
            {isHaveRates && '🔥 '}
            {t('common.rates')}
          </p>
          <Table
            className="contacts"
            dataSource={showAll ? rows : rows.slice(0, 3)}
            columns={catalogProfileRatesColums}
            pagination={false}
          />
          {showAllButton && (
            <Button
              className={`${styles.showMoreButton} ${
                showAll ? styles.showInfo : ''
              }`}
              onClick={() => setShowAll(!showAll)}
              type="link"
            >
              {showAll
                ? t('common.hide')
                : `${t('common.show')} ${t('common.more')}`}
            </Button>
          )}
        </div>
      )}
      {!myProfile && profile.phone_number && (
        <Link to={`tel:${profile.phone_number}`}>
          <Button type="primary" className={styles.button}>
            {profile.phone_number}
            <img src={phone} alt="phone" />
          </Button>
        </Link>
      )}
    </div>
  );
};

Contacts.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    age: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
    verified: PropTypes.bool.isRequired,
    id_name: PropTypes.string.isRequired,
  }).isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      type_of_service: PropTypes.string.isRequired,
      currency: PropTypes.string,
      discount_type: PropTypes.string,
      amount_after_discount: PropTypes.number.isRequired,
      discount_amount: PropTypes.number,
    }),
  ),
  messengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  social_networks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  myProfile: PropTypes.bool,
};

export { Contacts };
