import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePrivateNavigate } from 'hooks/usePrivateNavigate';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { profileStatus } from 'constants/profileStatus';
import { verificationStatus } from 'constants/verificationStatus';

import styles from './styled.module.css';

const ProfileNotifiaction = () => {
  const privateNavigate = usePrivateNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: {
      userInfo: { status, verification_status },
    },
  } = useLocalContext();

  let notificationMessage;

  if (
    status === profileStatus.published
    && verification_status !== verificationStatus.verified
    && verification_status !== verificationStatus.verification
    && verification_status !== verificationStatus.verification_failure
  ) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.1')}</p>,
      text: <p>{t('pages.profile.components.profileNotification.2')}</p>,
      description: (
        <p>
          {t('pages.profile.components.profileNotification.3')}
          <br />
          <span>{t('pages.profile.components.profileNotification.4')}</span>
        </p>
      ),
      button: (
        <Button
          type="primary"
          className={styles.button}
          onClick={() => {
            privateNavigate({ paths: paths.verification });
          }}
        >
          {t('pages.profile.components.profileNotification.10')}
        </Button>
      ),
    };
  }

  if (status === profileStatus.moderation) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.27')}</p>,
      text: (
        <p>
          {t('pages.profile.components.profileNotification.5')}
          <br />
          {t('pages.profile.components.profileNotification.6')}
        </p>
      ),
    };
  }

  if (
    status === profileStatus.published
    && verification_status === verificationStatus.verification
  ) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.1')}</p>,
      text: (
        <p>
          {t('pages.profile.components.profileNotification.25')}
          <br />
          {t('pages.profile.components.profileNotification.26')}
        </p>
      ),
    };
  }

  if (
    status === profileStatus.published
    && verification_status === verificationStatus.verification_failure
  ) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.7')}</p>,
      text: <p>{t('pages.profile.components.profileNotification.8')}</p>,
      description: <p>{t('pages.profile.components.profileNotification.9')}</p>,
      button: (
        <Button
          type="primary"
          className={styles.button}
          onClick={() => {
            privateNavigate({ paths: paths.verification });
          }}
        >
          {t('pages.profile.components.profileNotification.34')}
        </Button>
      ),
    };
  }

  if (status === profileStatus.new) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.19')}</p>,
      text: <p>{t('pages.profile.components.profileNotification.20')}</p>,
      description: (
        <p>{t('pages.profile.components.profileNotification.21')}</p>
      ),
      button: (
        <Button
          type="primary"
          className={styles.button}
          onClick={() => navigate(paths.profileCreation)}
        >
          {t('pages.profile.components.profileNotification.33')}
        </Button>
      ),
    };
  }

  if (status === profileStatus.draft) {
    notificationMessage = {
      title: <p>{t('pages.profile.components.profileNotification.29')}</p>,
      text: (
        <p style={{ whiteSpace: 'pre-line' }}>
          {t('pages.profile.components.profileNotification.30')}
          {' '}
          <Link to={paths.terms}>{t('common.terms')}</Link>
          {' '}
          &
          {' '}
          <Link to={paths.codeOfConduct}>{t('common.codeOfConduct')}</Link>
          {' '}
          {t('pages.profile.components.profileNotification.31')}
        </p>
      ),
      description: (
        <p>{t('pages.profile.components.profileNotification.32')}</p>
      ),
      button: (
        <Button
          type="primary"
          className={styles.button}
          onClick={() => navigate(paths.profileCreation)}
        >
          {t('pages.profile.components.profileNotification.22')}
        </Button>
      ),
    };
  }

  if (!notificationMessage) {
    return null;
  }

  return (
    <div
      className={`
    ${styles.profileNotifiaction} 
    ${
      status === profileStatus.new
      || status === profileStatus.banned
      || status === profileStatus.draft
      || (status === profileStatus.published
        && verification_status !== verificationStatus.verified
        && verification_status !== verificationStatus.verification)
        ? styles.warning
        : ''
    }
    `}
    >
      {notificationMessage?.title}
      {notificationMessage?.text}
      {notificationMessage?.description}
      {notificationMessage?.button}
    </div>
  );
};

export { ProfileNotifiaction };
