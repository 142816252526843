export const initialFilters = {
  duration: undefined,
  color: [undefined],
  length: [undefined],
  pubic_hair: [undefined],
  cup_size: [undefined],
  type: [undefined],
  travel: undefined,
  weight_type: 'kg',
  height_type: 'cm',
  eye_color: [undefined],
  tattoo: undefined,
  piercing: [undefined],
  type_of_service: undefined,
  smoking: undefined,
  ethnicity: undefined,
  nationality: undefined,
  languages: undefined,
  services: undefined,
  available_for: undefined,
  zodiac: undefined,
};
