import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import { useIsMobile } from 'hooks/useIsMobile';

import { useLocalContext } from 'LocalContext';
import { reportTags, reportTagsVisitor } from 'utils/filters';
import { cities } from 'constants';
import { userVisitor } from 'constants/registrationUserType';

import { Button } from 'components/Button';
import { PhoneCode } from 'components/PhoneCode';
import { OpenArrow } from 'components/ActiveIcon/OpenArrow';
import { SelectItem } from '../SelectItem';

import styles from './styled.module.css';

const SearchBlock = ({
  values,
  setValues,
  onSearch,
  oldValues,
  setOldValues,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile({ maxWidth: 730 });
  const {
    localState: {
      userInfo: { user_type },
    },
  } = useLocalContext();

  const [render, setRender] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    setOldValues(
      `${values.city.join('')}${values.tags.join('')}${values.phone}`,
    );
  }, [render]);

  return (
    <div className={`${styles.wrapper} ${isMobile ? styles.mobile : ''}`}>
      {isMobile && (
        <div
          onClick={() => setShowSearch(!showSearch)}
          className={styles.title}
        >
          {t('common.search_block')}
          {showSearch && (
            <OpenArrow
              className={`${styles.arrow} ${showSearch ? styles.show : ''}`}
            />
          )}
        </div>
      )}
      {(!isMobile || (isMobile && showSearch)) && (
        <div className={styles.searchBlock}>
          <div
            className={`
        ${styles.searchItems}
        ${user_type === userVisitor ? styles.visitor : ''}
        `}
          >
            <PhoneCode
              initialValue={values.phone}
              onChange={(e) => {
                if (values.phoneCode !== `+${e.country.dialCode}`) {
                  setValues({ ...values, phoneCode: `+${e.country.dialCode}` });
                }
                setValues({
                  ...values,
                  phone: e.inputValue.replace(/[\s-]+/g, ''),
                });
              }}
            />
            {user_type === userVisitor && (
              <Input
                placeholder={t('common.modelName')}
                maxLength={32}
                minLength={1}
                value={values.client_link}
                onChange={(e) => {
                  setValues({ ...values, client_link: e.target.value });
                }}
              />
            )}
            <SelectItem
              placeholder={t('common.chooseCity')}
              value={values.city}
              setValue={(e) => setValues({ ...values, city: e })}
              options={cities}
            />
            <SelectItem
              placeholder={t('common.chooseTag')}
              value={values.tags}
              setValue={(e) => setValues({ ...values, tags: e })}
              options={
                user_type === userVisitor ? reportTagsVisitor : reportTags
              }
            />
          </div>
          <div className={styles.buttons}>
            <Button
              primary
              className={styles.clear}
              disabled={
                oldValues
                === `${values.city.join('')}${values.tags.join('')}${values.phone}${
                  values.client_link
                }`
              }
              onClick={() => {
                setRender(!render);
                setValues({ ...values, page: 1 });
                if (values.page === 1) {
                  onSearch();
                }
                setShowSearch(false);
              }}
            >
              {t('common.search_input')}
            </Button>
            <Button
              secondary
              className={styles.clear}
              disabled={
                values.city.length === 0
                && values.tags.length === 0
                && values.phone === values.phoneCode
                && values.client_link === ''
              }
              onClick={() => {
                setValues({
                  ...values,
                  city: [],
                  tags: [],
                  phone: values.phoneCode,
                  page: 1,
                  client_link: '',
                });
                setRender(!render);
                setShowSearch(false);
                onSearch({ empty: true });
              }}
            >
              {t('common.clear')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SearchBlock.propTypes = {
  values: PropTypes.shape({
    city: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    phone: PropTypes.string,
    phoneCode: PropTypes.string,
    page: PropTypes.number,
    client_link: PropTypes.string,
  }),
  oldValues: PropTypes.string,
  setOldValues: PropTypes.func,
  setValues: PropTypes.func,
  onSearch: PropTypes.func,
};

export { SearchBlock };
