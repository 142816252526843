import { Button, Form, Input } from 'antd';
import React, { useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getSocialMediaType } from 'utils/profileCreationValues';

import {
  latinLettersPattern,
  patternForSocialNickname,
} from 'constants/patterns';

import { PhoneCode } from 'components/PhoneCode';
import { Select } from 'components/Select';

import styles from '../../../styled.module.css';

const SocialMediaItem = memo(
  ({
    data = [],
    setData,
    options,
    form,
    formItemName,
    isMobile,
  }) => {
    const { t } = useTranslation();

    const [activeSocial, setActiveSocial] = useState(null);
    const [activeSocialType, setActiveSocialType] = useState(null);

    const actualMessenger = useMemo(
      () => (item) => options.find((el) => el.value === item.name),
      [options],
    );

    const filteredMessengers = useMemo(
      () => options.filter((item) => data.every((el) => el.name !== item.value)),
      [options, data],
    );

    const addSocialMedia = useMemo(
      () => () => {
        const values = form.getFieldsValue([
          formItemName,
          `${formItemName}_link`,
        ]);
        if (values[formItemName] && values[`${formItemName}_link`]) {
          setData((prevData) => [
            ...prevData,
            {
              name: values[formItemName],
              link: values[`${formItemName}_link`],
            },
          ]);
          form.resetFields([formItemName, `${formItemName}_link`]);
          setActiveSocial(null);
          setActiveSocialType(null);
        }
      },
      [form, formItemName],
    );

    return (
      <div className={styles.formWrapper}>
        {data.map((item) => {
          const findItem = actualMessenger(item);
          const findItemType = getSocialMediaType(item.link);
          if (findItem) {
            return (
              <div className={styles.formItem} key={findItem.value}>
                <div className={styles.oneLineItem}>
                  <Form.Item
                    name={`${findItem.value}_select`}
                    className={styles.selectLanguage}
                    initialValue={findItem.value}
                  >
                    <span>
                      <img src={findItem.image} alt="icon" />
                      {' '}
                      {findItem.label}
                    </span>
                  </Form.Item>
                  <Button
                    className={`${styles.button} ${styles.secondary}`}
                    onClick={() => {
                      form.setFieldValue(`${findItem.value}`, undefined);
                      setData((prev) => prev.filter((el) => el.name !== findItem.value));
                    }}
                  >
                    {t('common.delete')}
                  </Button>
                </div>
                {findItemType === 'phone' ? (
                  <PhoneCode
                    form={form}
                    name={findItem.value}
                    className={styles.select}
                    initialValue={item.link}
                    optional
                  />
                ) : (
                  <Form.Item
                    name={findItem.value}
                    initialValue={item.link}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: t('pages.profileCreation.socialMedia.4'),
                      },
                      findItemType === 'email' && {
                        type: 'email',
                        message: t('errors.emailFormat'),
                      },
                      findItemType === 'nickname' && {
                        pattern: patternForSocialNickname,
                        message: t('errors.onlyLatinLong'),
                      },
                      findItemType === 'email' && {
                        pattern: latinLettersPattern,
                        message: t('errors.onlyLatin'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t(
                        `common.${findItem.placeholder[findItemType]}`,
                      )}
                    />
                  </Form.Item>
                )}
              </div>
            );
          }
          return null;
        })}
        <div className={styles.newItem}>
          {data && data.length < 4 && (
            <div className={styles.formItem}>
              <Select
                title={formItemName}
                options={filteredMessengers}
                scrollHeight={isMobile ? 70 : 0}
                noLabel
                form={form}
                className={styles.select}
                onSelect={(e) => {
                  const findEl = options.find((el) => el.value === e);
                  form.resetFields([`${formItemName}_link`]);
                  setActiveSocial(findEl);
                  setActiveSocialType(findEl.type[0]);
                }}
              />
              {activeSocial?.type?.length > 1 && (
                <div className={styles.socialType}>
                  {activeSocial?.type.map((el) => (
                    <Button
                      className={el === activeSocialType ? styles.active : ''}
                      key={el}
                      onClick={() => setActiveSocialType(el)}
                    >
                      {el}
                    </Button>
                  ))}
                </div>
              )}
              <Form.Item dependencies={[formItemName]}>
                {() => {
                  const dependentValue = form.getFieldValue(formItemName);
                  let findItem;
                  if (dependentValue) {
                    findItem = actualMessenger({ name: dependentValue });
                  }
                  if (activeSocialType === 'phone') {
                    return (
                      <PhoneCode
                        form={form}
                        name={`${formItemName}_link`}
                        className={styles.select}
                        optional
                      />
                    );
                  }
                  return (
                    <Form.Item
                      name={`${formItemName}_link`}
                      className={styles.select}
                      rules={[
                        activeSocialType === 'email' && {
                          type: 'email',
                          message: t('errors.emailFormat'),
                        },
                        activeSocialType === 'nickname' && {
                          pattern: patternForSocialNickname,
                          message: t('errors.onlyLatinLong'),
                        },
                        activeSocialType === 'email' && {
                          pattern: latinLettersPattern,
                          message: t('errors.onlyLatin'),
                        },
                      ]}
                    >
                      <Input
                        placeholder={
                          findItem?.placeholder
                            ? t(
                              `common.${findItem?.placeholder[activeSocialType]}`,
                            )
                            : 'link'
                        }
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          )}
          {!!(4 - data.length) && (
            <div className={`${styles.buttonBlock}`} style={{ zIndex: '0' }}>
              <Form.Item
                shouldUpdate
                style={{ marginBottom: '0', width: '100%' }}
              >
                {() => {
                  const isDisabled = !form.getFieldValue(`${formItemName}_link`)
                    || !form.getFieldValue(formItemName)
                    || !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length;
                  return (
                    <Button
                      onClick={addSocialMedia}
                      className={`${styles.button} ${styles.secondary} ${
                        styles.addDescription
                      } ${isDisabled ? styles.disabled : ''}`}
                      style={{ fontSize: '19px' }}
                    >
                      {data.length === 3 && t('common.save')}
                      {data.length < 3
                        && (formItemName === 'messenger'
                          ? t('pages.profileCreation.socialMedia.5')
                          : t('pages.profileCreation.socialMedia.6'))}
                    </Button>
                  );
                }}
              </Form.Item>
              <p>
                {formItemName === 'messenger'
                  ? t('pages.profileCreation.socialMedia.7')
                  : t('pages.profileCreation.socialMedia.8')}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  },
);

SocialMediaItem.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ),
  setData: PropTypes.func,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }),
  formItemName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export { SocialMediaItem };
