import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import styles from '../../styled.module.css';

const SelectWrapper = ({
  multi = false,
  form,
  title,
  uniquelabel,
  noLabel = false,
  initialValue,
  limitMessage,
  optional = false,
  children,
}) => {
  const formatTitle = title?.replace(/_/g, ' ') || '';
  const { t } = useTranslation();
  return form ? (
    <div className={styles.selectBlock}>
      {!noLabel && (
        <p className={styles.title}>
          {uniquelabel || formatTitle}
          :
        </p>
      )}
      <Form.Item
        name={title.toLowerCase()}
        className={styles.select}
        initialValue={initialValue || undefined}
        rules={[
          ...(multi ? [{ type: 'array' }] : []),
          {
            required: !optional,
            message: t('errors.selectYour', { text: formatTitle }),
          },
        ]}
        extra={
          limitMessage && <p style={{ color: '#633976' }}>{limitMessage}</p>
        }
        style={{ marginBottom: limitMessage && '0' }}
      >
        {children}
      </Form.Item>
    </div>
  ) : (
    children
  );
};

SelectWrapper.propTypes = {
  multi: PropTypes.bool,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }),
  uniquelabel: PropTypes.string,
  title: PropTypes.string,
  noLabel: PropTypes.bool,
  limitMessage: PropTypes.string,
  initialValue: PropTypes.string,
  optional: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export { SelectWrapper };
