import { performConfirmedRequest, performNonAuthorizedRequest } from '../apiConfig';

const registrationsPath = '/registrations';

export const onRegistrationVisitor = (query) => performNonAuthorizedRequest(`${registrationsPath}/verified_visitor_signup`, query);

export const onRegistrationProvider = (query) => performNonAuthorizedRequest(`${registrationsPath}/client_signup`, query);

export const resendConfirmationEmail = (query) => performNonAuthorizedRequest(`${registrationsPath}/resend_confirmation_email`, query);

export const confirmEmail = (key) => performConfirmedRequest(`${registrationsPath}/confirm_email`, key);
