import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ className, onClick }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#757575"
    className={className}
    onClick={onClick}
  >
    <path
      d="M12.5 2.9997L15.5 5.9997M10.5 16.9997H18.5M2.5 12.9997L1.5 16.9997L5.5 15.9997L17.086 4.4137C17.4609 4.03864 17.6716 3.53003 17.6716 2.9997C17.6716 2.46937 17.4609 1.96075 17.086 1.5857L16.914 1.4137C16.5389 1.03876 16.0303 0.828125 15.5 0.828125C14.9697 0.828125 14.4611 1.03876 14.086 1.4137L2.5 12.9997Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

EditIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { EditIcon };
