import { axiosInstance } from './axiosInstance';
import { setHeader, setAuthHeader, setConfirmHeader } from './setHeaders';

export const createBaseRequestBody = (query) => ({
  query: {
    ...query,
  },
  config: {
    auth: {
      client: 'client_test',
    },
  },
});

const apiUrl = 'api/v1';

export const performAuthorizedRequest = (endpoint, query) => {
  setAuthHeader();
  const body = createBaseRequestBody(query);
  return axiosInstance.post(`${apiUrl}${endpoint}`, body)
    .then((response) => ({
      data: response.data,
      status: response.status,
    }))
    .catch((error) => {
      // console.error('Error in performAuthorizedRequest:', error);
      throw error;
    });
};

export const performNonAuthorizedRequest = (endpoint, query) => {
  setHeader();
  const body = createBaseRequestBody(query);
  return axiosInstance.post(`${apiUrl}${endpoint}`, body)
    .then((response) => ({
      data: response.data,
      status: response.status,
    }))
    .catch((error) => {
      // console.error('Error in performNonAuthorizedRequest:', error);
      throw error;
    });
};

export const performConfirmedRequest = (endpoint, key, query) => {
  setConfirmHeader(key);
  const body = createBaseRequestBody(query);
  return axiosInstance.post(`${apiUrl}${endpoint}`, body);
};
