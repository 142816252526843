import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Form, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useFlipper } from 'hooks/useFlipper';

import { reactAppPublicV3 } from 'config';
import { paths } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { RegistrationPolicy } from './components/RegistrationPolicy';
import { RegistrationForm } from './components/ RegistrationForm';
import { Button } from 'components/Button';
import { Spinner } from 'components/Spinner';

import styles from './styled.module.css';
import './styles.css';

const Registration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const {
    flipperLoading: loading,
    isFlipperFeatureEnabled: isRecaptchaEnabled,
  } = useFlipper('recaptcha');

  if (!locationState) {
    return <Navigate to="/" replace />;
  }

  const [form] = Form.useForm();

  const onChangeProvider = () => {
    navigate(paths.registration, {
      state: {
        userType:
          locationState.userType === userProvider ? userVisitor : userProvider,
      },
    });
    form.resetFields();
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className={`${styles.wrapper} registration`}>
      <div>
        <div className={styles.title}>
          <h1>{t('common.welcome')}</h1>
          <p>{t('pages.registration.joy')}</p>
        </div>
        <div className={styles.content}>
          <RegistrationPolicy
            isProvider={locationState.userType === userProvider}
          />
          {isRecaptchaEnabled ? (
            <GoogleReCaptchaProvider reCaptchaKey={reactAppPublicV3}>
              <div className={styles.formWrapper}>
                <RegistrationForm
                  form={form}
                  isRecaptchaEnabled={isRecaptchaEnabled}
                />
              </div>
            </GoogleReCaptchaProvider>
          ) : (
            <div className={styles.formWrapper}>
              <RegistrationForm
                form={form}
                isRecaptchaEnabled={isRecaptchaEnabled}
              />
            </div>
          )}
        </div>
        <Divider
          style={{
            color: 'rgba(255, 26, 122, 1)',
            margin: '40px 0 20px',
          }}
        >
          {locationState.userType === userProvider
            ? t('pages.registration.8')
            : t('pages.registration.14')}
        </Divider>
        <div className={styles.providerButton}>
          <Button secondary onClick={onChangeProvider}>
            {locationState.userType === userProvider
              ? t('pages.registration.13')
              : t('pages.registration.7')}
          </Button>
          {locationState.userType === userProvider ? (
            <p>{t('pages.registration.9')}</p>
          ) : (
            <p>{t('pages.registration.10')}</p>
          )}
        </div>
      </div>
      <p>{t('pages.registration.11')}</p>
    </div>
  );
};
export { Registration };
