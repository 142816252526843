import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { QRCode } from 'antd';

import { ModalWrapper } from 'components/ModalWrapper';

import styles from './styled.module.css';
import './styles.css';

const PaymentPageModal = memo(({ open = false, setOpen, value }) => (
  <ModalWrapper open={open} setOpen={setOpen}>
    <div className={styles.wrapper}>
      <QRCode
        type="canvas"
        value={value || ''}
        rootClassName="paymentPageModalContent"
      />
    </div>
  </ModalWrapper>
));

PaymentPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { PaymentPageModal };
