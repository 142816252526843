import {
  memo, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, Form, Input, InputNumber, Tooltip,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';

import search from 'assets/search.svg';
import { editProfile } from 'api/requests/profile';
import { useLocalContext } from 'LocalContext';
import { services } from 'utils/filters';
import {
  formatBackToServices,
  formatServicesToBack,
} from 'utils/profileCreationValues';
import { onlyNumberInInput } from 'utils/onlyNumberInInput';

import { useIsMobile } from 'hooks/useIsMobile';

import info from 'assets/info.svg';

import styles from './styled.module.css';
import './styles.css';

const Services = memo(({ nextStep, setLoading, setDisabled }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();

  const backData = userInfo && !!userInfo.services
    ? userInfo.services.map((item) => item.service_name.toLowerCase())
    : [];

  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(false);
  const [activeServices, setActiveServices] = useState(backData);

  const initialValue = useMemo(
    () => formatBackToServices(services, userInfo && userInfo?.services),
    [],
  );

  useEffect(() => {
    setDisabled(!backData.length);
  }, []);

  const idDisabled = !activeServices.length;

  const changeCheckBox = (e, service) => {
    if (e.target.checked) {
      form.setFieldsValue({ [e.target.id]: service.value });
      setActiveServices((prev) => [...prev, service.label.toLowerCase()]);
    } else {
      form.setFieldsValue({ [e.target.id]: undefined });
      setActiveServices((prev) => [
        ...prev.filter((item) => item !== service.label.toLowerCase()),
      ]);
    }
  };

  const filteredServices = useMemo(
    () => services
      .filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()))
      .sort(
        (a, b) => backData.includes(b.label.toLowerCase())
            - backData.includes(a.label.toLowerCase()),
      ),
    [searchValue],
  );

  const onFinish = async (values) => {
    const sendData = formatServicesToBack(values);

    const query = {
      services: sendData,
    };

    if (
      JSON.stringify(sendData)
      === JSON.stringify(formatServicesToBack(initialValue))
    ) {
      nextStep();
    } else {
      setLoading(true);
      try {
        const {
          data: { card },
        } = await editProfile(query);
        localDispatch({ type: 'SET_USER_INFO', payload: card });
        setError(false);
        nextStep();
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
  };

  if (error) {
    return <span>{t('common.error')}</span>;
  }

  return (
    <Form
      className={styles.wrapper}
      form={form}
      onFinish={onFinish}
      initialValues={initialValue}
    >
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={idDisabled}
            className={`${styles.button} ${styles.primary}`}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
      <div className={styles.searchCurrencyBlock}>
        <Input
          placeholder={t('common.search_input')}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={styles.search}
          value={searchValue}
          maxLength={30}
          prefix={<img alt="search" src={search} />}
          allowClear
        />
        <div className={styles.currency}>
          <p>{t('common.yourCurrency')}</p>
          <p>PLN</p>
        </div>
      </div>
      {filteredServices.map((service) => (
        <div
          key={service.value}
          className={`${styles.serviceItem} serviceItem ${
            activeServices.includes(service.value)
              ? `${styles.active} active`
              : ''
          }`}
        >
          <div>
            <div className={styles.labelAndTooltip}>
              <Form.Item name={`${service.value}_checkbox`}>
                <Checkbox
                  className={styles.checkbox}
                  checked={activeServices.includes(service.label.toLowerCase())}
                  onChange={(e) => changeCheckBox(e, service)}
                >
                  {service.label}
                </Checkbox>
              </Form.Item>
              {service.description && (
                <Tooltip
                  placement="top"
                  onClick={(e) => e.stopPropagation()}
                  title={(
                    <div className={styles.tooltipTitle}>
                      <p>
                        {t(
                          `pages.profileCreation.services.description.${service?.description}`,
                        )}
                      </p>
                    </div>
                  )}
                  overlayClassName="serviceItem"
                >
                  <img src={info} alt="info" />
                </Tooltip>
              )}
            </div>
            <div className={styles.extraInputNumber}>
              <p>{t('pages.profileCreation.services.1')}</p>
              <Form.Item
                className={styles.extraInputNumber}
                name={`${service.value}_extra`}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  onKeyDown={(e) => onlyNumberInInput(e)}
                  maxLength={9}
                  placeholder={t('pages.profileCreation.services.2')}
                  disabled={
                    !activeServices.includes(service.label.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name={`${service.value}_text`}
          >
            <TextArea
              placeholder={t('pages.profileCreation.services.3')}
              maxLength={70}
              showCount
              className={styles.textArea}
              style={{ minWidth: '100%' }}
              allowClear
              disabled={!activeServices.includes(service.label.toLowerCase())}
            />
          </Form.Item>
        </div>
      ))}
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={idDisabled}
            className={`${styles.button} ${styles.primary}`}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
    </Form>
  );
});

Services.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
  setDisabled: PropTypes.func,
};

export { Services };
