import ga4 from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { genders } from 'utils/genders';

const useGoogleAnalytics = () => {
  const { pathname } = useLocation();

  const gaClickEvent = ({ action, label }) => {
    ga4.event({
      category: 'Click',
      action,
      label,
    });
  };

  const gaClickSearch = ({
    gender,
    orientation,
    city,
    verified,
    service,
    district,
  }) => {
    const genderTitle = genders.find(
      (item) => item.gender === gender && item.orientation === orientation,
    ).title;
    const label = `${genderTitle} ${city ? `, ${city}` : ''}${
      verified ? ', verified' : ''
    } ${service ? ', outcall' : ''} ${
      district?.length ? `${district.join(',')}` : ''
    }`;

    ga4.event({
      category: 'Click',
      action: 'Click_search',
      label,
    });
  };

  const gaPageView = () => {
    ga4.send({
      hitType: 'pageview',
      page: pathname,
      title: document.title,
    });
  };

  return {
    gaClickEvent,
    gaClickSearch,
    gaPageView,
  };
};

export { useGoogleAnalytics };
