import CryptoJS from 'crypto-js';
import { reactAppPublicReactAppApiSecretKey } from '../config';

function base64url(source) {
  return CryptoJS.enc.Base64.stringify(source)
    .replace(/=+$/, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

const createToken = (token, fieldName) => {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
  };

  const payload = {
    date: new Date(),
    [fieldName]: token,
  };

  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);

  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
  const encodedPayload = base64url(stringifiedData);

  const signature = CryptoJS.HmacSHA256(`${encodedHeader}.${encodedPayload}`, reactAppPublicReactAppApiSecretKey);
  const signed = base64url(signature);

  return `${encodedHeader}.${encodedPayload}.${signed}`;
};

export { createToken };
