import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { reportTags, reportTagsVisitor } from 'utils/filters';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { quantityPerBlackList } from 'constants/quantityPerPage';
import {
  deleteBlackListVisitorCollect,
  getBlackListProviderCollect,
  getBlackListVisitorCollect,
} from 'api/requests/blackList';

import errorLogo from 'assets/error.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { CloseIcon } from 'components/ActiveIcon/CloseIcon';
import { CommentItem } from './components/CommentItem';
import { Spinner } from 'components/Spinner';
import { Pagination } from 'components/Pagination';
import { SearchBlock } from './components/SearchBlock';

import styles from './styled.module.css';

const BlackListData = ({ isSend }) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
  } = useLocalContext();
  const { isPublicVersion } = usePublicVersion();

  const [isShowInfo, setIsShowInfo] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [values, setValues] = useState({
    city: [],
    tags: [],
    phone: '+48',
    phoneCode: '+48',
    page: 1,
    client_link: '',
  });
  const [oldValues, setOldValues] = useState(
    `${values.city.join('')}${values.tags.join('')}${values.phone}${
      values.client_link
    }`,
  );
  const [total, setTotal] = useState(0);
  const [listDataCollect, setListDataCollect] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const showInfo = (key) => {
    if (isShowInfo.includes(key)) {
      setIsShowInfo(isShowInfo.filter((rowKey) => rowKey !== key));
    } else {
      setIsShowInfo([...isShowInfo, key]);
    }
  };

  const getBlackListData = async ({ empty = false } = {}) => {
    setLoading(true);
    const query = {
      limit: quantityPerBlackList,
      offset: (empty ? 0 : values.page - 1) * quantityPerBlackList,
      ...(values.city.length && !empty && { city: values.city }),
      ...(values.phone !== values.phoneCode
        && !empty && { phone_number: values.phone }),
      ...(values.tags.length && !empty && { tags: values.tags }),
      ...(values.client_link && !empty && { client_link: values.client_link }),
    };
    try {
      if (userInfo.user_type === userProvider) {
        const res = await getBlackListProviderCollect(query);
        setListDataCollect(res.data.blacklists.reverse());
        setTotal(res.data.total);
      }
      if (userInfo.user_type === userVisitor) {
        const res = await getBlackListVisitorCollect(query);
        setListDataCollect(res.data.blacklists.reverse());
        setTotal(res.data.total);
      }
      setError(false);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstRender) {
      getBlackListData();
      setFirstRender(false);
    }

    if (isSend) {
      getBlackListData();
    }
  }, [isSend, firstRender]);

  useEffect(() => {
    if (!firstRender && !isSend) {
      getBlackListData();
    }
  }, [values.page]);

  const deleteUser = async (item) => {
    if (item?.id) {
      const query = {
        id: item.id,
      };
      try {
        await deleteBlackListVisitorCollect(query);
        setListDataCollect([
          ...listDataCollect.filter(
            (el) => el.contact_info.profile_id !== item.contact_info.profile_id,
          ),
        ]);
        setError(false);
      } catch (e) {
        setError(true);
      }
    }
  };

  const rows = listDataCollect.map((item) => ({
    key: `${item.created_at}${item.contact_info.visitor_name}${item.city}`,
    provider_info: (
      <div className={styles.info}>
        {item.contact_info.visitor_name && (
          <p>{item.contact_info.visitor_name}</p>
        )}
        {(item.contact_info.phone_number || item.contact_info.email) && (
          <div>
            {item.contact_info.client_link && (
              <p>{item.contact_info.client_link}</p>
            )}
            {item.contact_info.phone_number && (
              <p>{item.contact_info.phone_number}</p>
            )}
            {item.contact_info.email && <p>{item.contact_info.email}</p>}
          </div>
        )}
      </div>
    ),
    city: (
      <div className={styles.city}>
        <p>{item.city}</p>
        <p>{item.created_at.slice(0, 10)}</p>
        <p>{item.created_at.slice(11, 19)}</p>
      </div>
    ),
    tags: (
      <div className={styles.tags}>
        {item.tags.map((el) => (
          <div className={styles.tag} key={el}>
            {(userInfo?.user_type === userProvider
              ? reportTags
              : reportTagsVisitor
            ).find((tag) => tag.value === el)?.label || el}
          </div>
        ))}
      </div>
    ),
    additional_info: (
      <CommentItem
        text={item.text || ''}
        isShowInfo={isShowInfo.includes(
          `${item.created_at}${item.contact_info.visitor_name}${item.city}`,
        )}
      />
    ),
    ...(userInfo?.user_type === userVisitor && {
      delete: (
        <div className={styles.delete}>
          <Button
            className={styles.deleteButton}
            type="link"
            onClick={() => deleteUser(item)}
          >
            <CloseIcon className={styles.close} />
            <p>{t('common.delete')}</p>
          </Button>
        </div>
      ),
    }),
  }));

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.listDataTitle}>
          <p className={styles.title}>
            {t('pages.profile.blackList.blackListData.3')}
          </p>
        </div>
        <SearchBlock
          values={values}
          setValues={setValues}
          oldValues={oldValues}
          setOldValues={setOldValues}
          onSearch={getBlackListData}
        />
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles.wrapper} ${error ? styles.error : ''}`}>
        <img src={isPublicVersion ? publicLogo : errorLogo} alt="errorLogo" />
        <p className={styles.message}>
          {t('pages.profile.blackList.blackListData.1')}
          <br />
          {t('errors.weAreWorking')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.listDataTitle}>
        <p className={styles.title}>
          {t('pages.profile.blackList.blackListData.3')}
        </p>
      </div>
      <SearchBlock
        values={values}
        setValues={setValues}
        oldValues={oldValues}
        setOldValues={setOldValues}
        onSearch={getBlackListData}
      />
      {listDataCollect.length ? (
        <div className={styles.listDataBlock}>
          {total > quantityPerBlackList && !loading && (
            <Pagination
              disabled={
                oldValues
                !== `${values.city.join('')}${values.tags.join('')}${values.phone}`
              }
              total={total}
              onChange={(e) => setValues({ ...values, page: e })}
              current={values.page}
              quantityPerPage={quantityPerBlackList}
              className={styles.pagination}
            />
          )}
          <div className={styles.table}>
            <div className={styles.tableNav}>
              <p>{t('common.providerInfo')}</p>
              <p>{t('common.city')}</p>
              <p>{t('common.reportTags')}</p>
              <p>{t('common.additionalInfo')}</p>
              {userInfo?.user_type === userVisitor && (
                <p>{t('common.delete')}</p>
              )}
            </div>
            {rows.map((item) => (
              <div key={item.key} className={styles.row}>
                <div className={styles.infoCity}>
                  {item.provider_info}
                  {item.city}
                </div>
                <p
                  className={`${styles.showInfoButton} ${
                    isShowInfo.includes(item.key) ? styles.showInfo : ''
                  }`}
                  onClick={() => showInfo(item.key)}
                >
                  {isShowInfo.includes(item.key)
                    ? t('common.hide')
                    : t('common.show')}
                  {' '}
                  {t('common.blockedInfo')}
                </p>
                <div
                  className={`${styles.tagsText} ${
                    isShowInfo.includes(item.key) ? styles.showInfo : ''
                  }`}
                >
                  {item.tags}
                  {item.additional_info}
                  {userInfo?.user_type === userVisitor && item.delete}
                </div>
              </div>
            ))}
          </div>
          {total > quantityPerBlackList && !loading && (
            <Pagination
              disabled={
                oldValues
                !== `${values.city.join('')}${values.tags.join('')}${values.phone}`
              }
              total={total}
              onChange={(e) => setValues({ ...values, page: e })}
              current={values.page}
              quantityPerPage={quantityPerBlackList}
              className={styles.pagination}
            />
          )}
        </div>
      ) : (
        <div className={styles.emptyBlock}>
          <img src={isPublicVersion ? publicLogo : errorLogo} alt="errorLogo" />
          <p className={styles.empty}>{t('common.blackListEmpty')}</p>
        </div>
      )}
    </div>
  );
};

BlackListData.propTypes = {
  isSend: PropTypes.bool.isRequired,
};

export { BlackListData };
