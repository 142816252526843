import PropTypes from 'prop-types';

import { catalogProfileInfoFields } from 'utils/catalogProfile';

import styles from './styled.module.css';

const UserInfo = ({ profile }) => (
  <div className={styles.info}>
    {catalogProfileInfoFields(profile).map((item) => (
      <div key={item.label} className={styles.infoLine}>
        <p>
          {item.label}
          :
        </p>
        <p>{item.content}</p>
      </div>
    ))}
  </div>
);

UserInfo.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    weight: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    ethnicity: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    hair_color: PropTypes.string.isRequired,
    hair_length: PropTypes.string.isRequired,
    pubic_hair: PropTypes.string.isRequired,
    breast_size: PropTypes.string.isRequired,
    breast_type: PropTypes.string.isRequired,
    travel: PropTypes.string.isRequired,
    promoted_at: PropTypes.string,
    tattoo: PropTypes.bool.isRequired,
    eye_color: PropTypes.string.isRequired,
    piercing: PropTypes.arrayOf(PropTypes.string).isRequired,
    smoker: PropTypes.string.isRequired,
    zodiac_sign: PropTypes.string.isRequired,
  }).isRequired,
};

export { UserInfo };
