import React from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

import { usePublicVersion } from 'hooks/usePublicVersion';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import './styles.css';

const DividerWithLogo = ({ className }) => {
  const { isPublicVersion } = usePublicVersion();
  return (
    <div className={className}>
      <Divider>
        <img src={isPublicVersion ? publicLogo : logo} alt="logo" />
      </Divider>
    </div>
  );
};

DividerWithLogo.propTypes = {
  className: PropTypes.string,
};

export { DividerWithLogo };
