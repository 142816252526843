import { duration } from './filters';

export const catalogProfileInfoFields = (profile) => [
  {
    label: 'Eye color',
    content: profile?.eye_color,
  },
  {
    label: 'Travel',
    content: profile?.travel,
  },
  {
    label: 'Weight',
    content: profile?.weight
      ? `${profile.weight}${profile.weight > 151 ? '+' : ''} kg / ${Math.ceil(
        profile.weight * 2.205,
      )}${profile.weight > 151 ? '+' : ''} lbs`
      : undefined,
  },
  {
    label: 'Height',
    content: profile?.height
      ? `${profile.height}
      ${profile.height > 201 ? '+' : ''} cm / ${Math.ceil(
  profile.height / 2.54,
)}
      ${profile.height > 201 ? '+' : ''} in`
      : undefined,
  },
  {
    label: 'Ethnicity',
    content: profile?.ethnicity,
  },
  {
    label: 'Nationality',
    content: profile?.nationality,
  },
  ...(profile?.gender !== 'male'
    ? [
      {
        label: 'Breast',
        content: profile?.breast_type,
      },
    ]
    : []),
  ...(profile?.gender !== 'male'
    ? [
      {
        label: 'Breast size',
        content: profile?.breast_size,
      },
    ]
    : []),
  {
    label: 'Pubic hair',
    content: profile?.pubic_hair,
  },
  {
    label: 'Hair color',
    content: profile?.hair_color,
  },
  {
    label: 'Hair length',
    content: profile?.hair_length,
  },
  {
    label: 'Tattoo',
    content: profile.tattoo ? 'Yes' : 'No',
  },
  {
    label: 'Smoker',
    content: profile?.smoker,
  },
  {
    label: 'Zodiac',
    content: profile?.zodiac_sign,
  },
  {
    label: 'Gender',
    content: profile?.gender,
  },
  {
    label: 'Orientation',
    content: profile?.orientation,
  },
  {
    label: 'Piercing',
    content: profile.piercing
      ? profile.piercing.map((item) => item).join(' / ')
      : 'No',
  },
];

export const catalogProfileRatesColums = [
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Incall',
    dataIndex: 'incall',
    key: 'incall',
  },
  {
    title: 'Outcall',
    dataIndex: 'outcall',
    key: 'outcall',
  },
];

export const catalogProfileRatesRows = (data) => data.reduce((formatData, item) => {
  const actualItem = formatData.find((entry) => entry.key === item.duration);
  if (actualItem) {
    actualItem[item.type_of_service] = (
      <div>
        {!!item.discount_amount && item.discount_amount !== 0 && (
        <>
          <p
            style={{
              fontWeight: '600',
              fontSize: '12px',
              height: '14px',
              color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
            }}
          >
            {item.discount_amount}
            % OFF
          </p>
          <p
            style={{
              fontWeight: '700',
              fontSize: '14px',
              height: '18px',
              color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
            }}
          >
            {item.amount_after_discount}
            {' '}
            PLN
          </p>
        </>
        )}
        <p
          style={{
            color:
                !!item.discount_amount && item.discount_amount !== 0
                  ? '#757575'
                  : '#633976',
            textDecoration:
                !!item.discount_amount && item.discount_amount !== 0
                  ? 'line-through'
                  : 'none',
            fontSize:
                !!item.discount_amount && item.discount_amount !== 0
                  ? '10px'
                  : '16px',
          }}
        >
          {item.amount}
          {' '}
          PLN
        </p>
      </div>
    );
  } else {
    formatData.push({
      key: item.duration,
      duration: duration.find((el) => el.value === item.duration).label,
      sortKey: duration.find((el) => el.value === item.duration).id,
      incall:
          item.type_of_service === 'incall' ? (
            <div>
              {!!item.discount_amount && item.discount_amount !== 0 && (
                <>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '12px',
                      height: '14px',
                      color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
                    }}
                  >
                    {item.discount_amount}
                    % OFF
                  </p>
                  <p
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      height: '18px',
                      color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
                    }}
                  >
                    {item.amount_after_discount}
                    {' '}
                    PLN
                  </p>
                </>
              )}
              <p
                style={{
                  color:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? '#757575'
                      : '#633976',
                  textDecoration:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? 'line-through'
                      : 'none',
                  fontSize:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? '10px'
                      : '16px',
                }}
              >
                {item.amount}
                {' '}
                PLN
              </p>
            </div>
          ) : null,
      outcall:
          item.type_of_service === 'outcall' ? (
            <div>
              {!!item.discount_amount && item.discount_amount !== 0 && (
              <>
                <p
                  style={{
                    fontWeight: '600',
                    fontSize: '12px',
                    height: '14px',
                    color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
                  }}
                >
                  {item.discount_amount}
                  % OFF
                </p>
                <p
                  style={{
                    fontWeight: '700',
                    fontSize: '14px',
                    height: '18px',
                    color: item.discount_amount >= 50 ? '#FF1A7A' : '#633976',
                  }}
                >
                  {item.amount_after_discount}
                  {' '}
                  PLN
                </p>
              </>
              )}
              <p
                style={{
                  color:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? '#757575'
                      : '#633976',
                  textDecoration:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? 'line-through'
                      : 'none',
                  fontSize:
                    !!item.discount_amount && item.discount_amount !== 0
                      ? '10px'
                      : '16px',
                }}
              >
                {item.amount}
                {' '}
                PLN
              </p>
            </div>
          ) : null,
    });
  }

  return formatData.sort((a, b) => a.sortKey - b.sortKey);
}, []);

export const catalogProfileFormatServices = (data) => {
  const trueArray = data.filter((item) => item.included_free);
  const falseArray = data.filter((item) => !item.included_free);

  if (trueArray.length > 0 && falseArray.length > 0) {
    return [trueArray, falseArray];
  }
  if (trueArray.length > 0) {
    return [trueArray];
  }
  if (falseArray.length > 0) {
    return [falseArray];
  }
  return [];
};
