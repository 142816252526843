import { Table, Tabs } from 'antd';
import PropTypes from 'prop-types';

import { DividerWithLogo } from 'components/DividerWithLogo';
import {
  catalogProfileRatesColums,
  catalogProfileRatesRows,
} from 'utils/catalogProfile';
import { UserInfo } from '../UserInfo';
import { Services } from '../Services';

import styles from './styled.module.css';
import './styles.css';
import { useTranslation } from 'react-i18next';

const formatTabMobile = (profilePart, servicesPart, ratesPart) => {
  const { t } = useTranslation();
  const result = [];
  if (profilePart) {
    result.push({
      key: 'bio',
      label: t('common.bio'),
      children: <UserInfo profile={profilePart} />,
    });
  }
  if (servicesPart && !!servicesPart.length) {
    result.push({
      key: 'services',
      label: t('common.services'),
      children: <Services services={servicesPart} />,
    });
  }
  if (ratesPart && !!ratesPart.length) {
    const rows = catalogProfileRatesRows(ratesPart);
    result.push({
      key: 'rates',
      label: t('common.rates'),
      children: (
        <Table
          className="contacts"
          dataSource={rows}
          columns={catalogProfileRatesColums}
          pagination={false}
        />
      ),
    });
  }
  return result;
};

const TabsMobile = ({ profile, services, rates }) => (
  <div className={styles.tabsMobile}>
    <Tabs
      defaultActiveKey="bio"
      items={formatTabMobile(profile, services, rates)}
      className="tabsMobile"
    />
    <DividerWithLogo />
  </div>
);

TabsMobile.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    weight: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    ethnicity: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    hair_color: PropTypes.string.isRequired,
    hair_length: PropTypes.string.isRequired,
    pubic_hair: PropTypes.string.isRequired,
    breast_size: PropTypes.string.isRequired,
    breast_type: PropTypes.string.isRequired,
    travel: PropTypes.string.isRequired,
    promoted_at: PropTypes.string,
    tattoo: PropTypes.bool.isRequired,
    eye_color: PropTypes.string.isRequired,
    piercing: PropTypes.arrayOf(PropTypes.string).isRequired,
    smoker: PropTypes.string.isRequired,
    zodiac_sign: PropTypes.string.isRequired,
  }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      service_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      included_free: PropTypes.bool.isRequired,
      extra_amount: PropTypes.number.isRequired,
      comment: PropTypes.string.isRequired,
    }),
  ),
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      type_of_service: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      discount_type: PropTypes.string,
      amount_after_discount: PropTypes.number.isRequired,
      discount_amount: PropTypes.number,
    }),
  ),
};

export { TabsMobile };
