import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useLocalContext } from 'LocalContext';
import { DividerWithLogo } from 'components/DividerWithLogo';
import { Select } from 'components/Select';
import {
  available,
  breastCupSize,
  breastType,
  duration,
  ethnicity,
  eyeColor,
  hairColor,
  hairLength,
  hairPubic,
  heightType,
  horoscrope,
  languages,
  nationality,
  piercing,
  services,
  smoking,
  tattoo,
  travel,
  weightType,
} from 'utils/filters';
import { Checkbox } from '../Checkbox';
import { RadioButton } from '../RadioButton';
import { Slider } from '../Slider';

import styles from './styled.module.css';
import './styles.css';

const FiltersContent = ({
  form, open, setOpen, getActualModalRanges,
}) => {
  const { t } = useTranslation();

  const { localDispatch, localState } = useLocalContext();
  const [searchParams] = useSearchParams();
  const gender = searchParams.get('gender');

  useEffect(() => {
    getActualModalRanges({ total: true });
  }, []);

  useEffect(() => {
    form.setFieldsValue(localState.filters);
  }, [JSON.stringify(localState.filters)]);

  const onClickShowResult = () => {
    setOpen(false);
    localDispatch({ type: 'SET_ACTIVE_CAROUSEL_ITEM', payload: null });
    localDispatch({
      type: 'SET_FILTERS',
      payload: { ...localState.filters, ...form.getFieldsValue() },
    });
    localDispatch({ type: 'SET_RELOAD_DATA', payload: true });
  };

  return (
    <Form
      onFinish={onClickShowResult}
      autoComplete="off"
      form={form}
      className={styles.form}
      initialValues={localState.filters}
      id="myForm"
    >
      <h3 className={styles.modalTitle}>{t('common.advancedFilters')}</h3>
      <div className={styles.formItem}>
        <Form.Item
          dependencies={['duration', 'price']}
          className={styles.formItemSlider}
        >
          {() => {
            const dependentValue = form.getFieldValue('duration');
            const valuePrice = form.getFieldValue('price');
            return (
              <Slider
                title="price"
                postfix="PLN"
                form={form}
                uniquelabel={t('common.price')}
                onChange={() => getActualModalRanges({ total: true })}
                dependencyValue={dependentValue}
                disabled="duration"
                reset={open}
                value={valuePrice}
              />
            );
          }}
        </Form.Item>
        <div style={{ width: '100%' }}>
          <RadioButton
            withDefaultValue
            title="duration"
            uniquelabel={t('common.duration')}
            options={duration}
            onChange={() => getActualModalRanges({ total: true })}
            form={form}
            width="66px"
          />
        </div>
      </div>
      <div className={styles.formItem}>
        <Form.Item dependencies={['age']} className={styles.formItemSlider}>
          {() => {
            const valueAge = form.getFieldValue('age');
            return (
              <Slider
                title="age"
                uniquelabel={t('common.age')}
                form={form}
                reset={open}
                value={valueAge}
                onChange={() => getActualModalRanges({ total: true })}
              />
            );
          }}
        </Form.Item>
      </div>
      <DividerWithLogo />
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.services')}</p>
        <RadioButton
          title="type_of_service"
          uniquelabel={t('common.available_for')}
          options={available}
          onChange={() => getActualModalRanges({ total: true })}
          withDefaultValue
        />
        <Select
          multi
          form={form}
          title="services"
          uniquelabel={t('common.services')}
          options={services}
          optional
          onSelect={() => {
            getActualModalRanges({ total: true });
          }}
        />
      </div>
      <DividerWithLogo />
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.hair')}</p>
        <Checkbox
          title="color"
          uniquelabel={t('common.color')}
          options={hairColor}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
          form={form}
        />
        <Checkbox
          title="length"
          uniquelabel={t('common.length')}
          options={hairLength}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
          form={form}
        />
        <Checkbox
          title="pubic_hair"
          uniquelabel={t('common.pubic_hair')}
          options={hairPubic}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
          form={form}
        />
      </div>
      <DividerWithLogo />
      {gender !== 'male' && (
        <>
          <div className={styles.formItemColumn}>
            <p className={styles.title}>{t('common.breast')}</p>
            <Checkbox
              title="cup_size"
              uniquelabel={t('common.cup_size')}
              options={breastCupSize}
              small
              withDefaultValue
              onChange={() => getActualModalRanges({ total: true })}
              form={form}
            />
            <Checkbox
              title="type"
              uniquelabel={t('common.breast_type')}
              options={breastType}
              withDefaultValue
              onChange={() => getActualModalRanges({ total: true })}
              form={form}
            />
          </div>
          <DividerWithLogo />
        </>
      )}
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.travel')}</p>
        <RadioButton
          title="travel"
          options={travel}
          noLabel
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
        />
      </div>
      <DividerWithLogo />
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.body')}</p>
        <div className={styles.formItem}>
          <Form.Item
            dependencies={['weight_type', 'weight__kg', 'weight__lbs']}
            className={styles.formItemSlider}
          >
            {() => {
              const dependentValue = form.getFieldValue('weight_type');
              const weightValueKg = form.getFieldValue('weight__kg');
              const weightValueLbs = form.getFieldValue('weight__lbs');
              return (
                <div
                  className={
                    dependentValue === 'kg' ? styles.weightKg : styles.weightLbs
                  }
                >
                  <Slider
                    title="weight__kg"
                    uniquelabel={t('common.weight')}
                    label
                    form={form}
                    onChange={(value) => {
                      form.setFieldValue('weight__lbs', [
                        Math.round(value[0] * 2.20462),
                        Math.round(value[1] * 2.20462),
                      ]);
                      getActualModalRanges({ total: true });
                    }}
                    dependencyType={dependentValue}
                    value={weightValueKg}
                  />
                  <Slider
                    title="weight__lbs"
                    uniquelabel={t('common.weight')}
                    label
                    form={form}
                    onChange={(value) => {
                      form.setFieldValue('weight__kg', [
                        Math.round(value[0] / 2.20462),
                        Math.round(value[1] / 2.20462),
                      ]);
                      getActualModalRanges({ total: true });
                    }}
                    dependencyType={dependentValue}
                    value={weightValueLbs}
                  />
                </div>
              );
            }}
          </Form.Item>
          <Form.Item name="weight_type">
            <Radio.Group buttonStyle="solid" className={styles.radioGroup}>
              {weightType.map((option) => (
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  className={styles.radioItem}
                >
                  {option.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={styles.formItem}>
          <Form.Item
            dependencies={['height_type', 'height__cm', 'height__ft']}
            className={styles.formItemSlider}
          >
            {() => {
              const dependentValue = form.getFieldValue('height_type');
              const heightValueCm = form.getFieldValue('height__cm');
              const heightValueFt = form.getFieldValue('height__ft');
              return (
                <div
                  className={
                    dependentValue === 'cm' ? styles.heightCm : styles.heightFt
                  }
                >
                  <Slider
                    title="height__cm"
                    uniquelabel={t('common.height')}
                    label
                    form={form}
                    dependencyType={dependentValue}
                    onChange={(value) => {
                      form.setFieldValue('height__ft', [
                        Math.round((value[0] / 30.48) * 100) / 100,
                        Math.round((value[1] / 30.48) * 100) / 100,
                      ]);
                      getActualModalRanges({ total: true });
                    }}
                    value={heightValueCm}
                  />
                  <Slider
                    title="height__ft"
                    uniquelabel={t('common.height')}
                    label
                    form={form}
                    step={0.01}
                    dependencyType={dependentValue}
                    onChange={(value) => {
                      form.setFieldValue('height__cm', [
                        Math.round(value[0] * 30.48),
                        Math.round(value[1] * 30.38),
                      ]);
                      getActualModalRanges({ total: true });
                    }}
                    value={heightValueFt}
                  />
                </div>
              );
            }}
          </Form.Item>
          <Form.Item name="height_type">
            <Radio.Group buttonStyle="solid" className={styles.radioGroup}>
              {heightType.map((option) => (
                <Radio.Button
                  key={option.value}
                  value={option.value}
                  className={styles.radioItem}
                >
                  {option.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
        <Checkbox
          title="eye_color"
          uniquelabel={t('common.eye_color')}
          options={eyeColor}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
          form={form}
        />
        <RadioButton
          title="tattoo"
          uniquelabel={t('common.tattoo')}
          options={tattoo}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
        />
        <Checkbox
          title="piercing"
          uniquelabel={t('common.piercing')}
          options={piercing}
          withDefaultValue
          form={form}
          onChange={() => getActualModalRanges({ total: true })}
        />
      </div>
      <DividerWithLogo />
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.ethnicity')}</p>
        <Select
          multi
          form={form}
          title="ethnicity"
          uniquelabel={t('common.ethnicity')}
          options={ethnicity}
          optional
          onSelect={() => {
            getActualModalRanges({ total: true });
          }}
        />
        <Select
          multi
          form={form}
          title="nationality"
          uniquelabel={t('common.nationality')}
          options={nationality}
          optional
          onSelect={() => {
            getActualModalRanges({ total: true });
          }}
        />
        <Select
          multi
          form={form}
          title="languages"
          uniquelabel={t('common.languages')}
          options={languages}
          optional
          onSelect={() => {
            getActualModalRanges({ total: true });
          }}
        />
      </div>
      <DividerWithLogo />
      <div className={styles.formItemColumn}>
        <p className={styles.title}>{t('common.other')}</p>
        <Checkbox
          title="zodiac"
          uniquelabel={t('common.zodiac')}
          options={horoscrope}
          onChange={() => getActualModalRanges({ total: true })}
        />
        <RadioButton
          title="smoking"
          uniquelabel={t('common.smoking')}
          options={smoking}
          withDefaultValue
          onChange={() => getActualModalRanges({ total: true })}
        />
      </div>
    </Form>
  );
};

FiltersContent.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  getActualModalRanges: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    setFieldValue: PropTypes.func,
  }),
};

export { FiltersContent };
