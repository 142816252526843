import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './styled.module.css';

const TimePicker = ({
  className,
  value = '',
  setValue = () => {},
  disabled = false,
  error = false,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <input
      className={`
      ${styles.wrapper}
      ${className || ''}
      ${(error && !inputValue) || inputError ? styles.error : ''}
    `}
      type="time"
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onBlur={(e) => {
        if (e.target.value) {
          setValue(e.target.value);
          setInputError(false);
        } else {
          setInputError(true);
        }
      }}
      disabled={disabled}
    />
  );
};

TimePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export { TimePicker };
