import { Navigate } from 'react-router-dom';
import {
  arrayOf, node, oneOfType, bool,
} from 'prop-types';

const ProtectedRoute = ({ isLogged, children }) => {
  if (!isLogged) {
    return <Navigate to="/" replace />;
  }
  return children;
};

ProtectedRoute.propTypes = {
  isLogged: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export { ProtectedRoute };
