import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { scrollToErrorField } from 'utils/scrollToErrorField';
import { updateUrlName } from 'api/requests/settings';
import { savedConstants, paths } from 'constants';
import { useLocalContext } from 'LocalContext';
import info from 'assets/info.svg';

import styles from './styled.module.css';
import './styles.css';

const UpdateUrlName = ({ activeKey }) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(!!location.state);
  const [error, setError] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [activeKey]);

  const onFinish = async (values) => {
    const { new_nickname } = values;
    const query = {
      new_nickname,
    };
    setLoading(true);
    try {
      const res = await updateUrlName(query);
      localStorage.setItem(savedConstants.AUTH_TOKEN, res.data.token);
      localDispatch({ type: 'SET_USER' });
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          profile: {
            ...userInfo.profile,
            id_name: values.new_nickname,
          },
        },
      });
      setIsUpdate(true);
      navigate(paths.profileSettings, { state: true });
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const confirmValidator = (_, value) => new Promise((resolve, reject) => {
    const { getFieldValue } = form;
    if (value && value === getFieldValue('old_nickname')) {
      reject(new Error(t('pages.profile.settings.updateUrlName.1')));
    }
    resolve();
  });

  return isUpdate ? (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        {t('pages.profile.settings.updateUrlName.2')}
      </p>
      <p className={styles.label}>
        {t('pages.profile.settings.updateUrlName.3')}
        <br />
        <b>{userInfo.profile.id_name}</b>
      </p>
      <p className={styles.label}>
        {t('pages.profile.settings.updateUrlName.4')}
        <br />
        <b>
          <a
            href={`https://tolips.link/
            ${userInfo.profile.id_name}`}
            target="_blank"
            rel="noreferrer"
          >
            https://tolips.link/
            {userInfo.profile.id_name}
          </a>
        </b>
      </p>
    </div>
  ) : (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      className={styles.wrapper}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      <div>
        <p className={styles.label}>{t('common.currentUrlName')}</p>
        <Form.Item
          initialValue={userInfo.profile.id_name}
          name="old_nickname"
          rootClassName="disabledInput"
        >
          <Input
            placeholder={t('common.nickname')}
            disabled
            suffix={<Tooltip overlayClassName="registration" />}
          />
        </Form.Item>
        <p className={styles.example}>
          https://tolips.link/
          {userInfo.profile.id_name}
        </p>
      </div>
      <div>
        <p className={styles.label}>{t('common.newNickname')}</p>
        <Form.Item
          name="new_nickname"
          rules={[
            {
              required: true,
              message: t('errors.enterNickname'),
            },
            {
              min: 5,
              message: t('errors.nicknameAtLeast', { number: 5 }),
            },
            {
              max: 32,
              message: t('errors.nicknameLess', { number: 32 }),
            },
            {
              pattern: '^[A-Za-z0-9_-]*$',
              message: t('errors.onlyLatinLong'),
            },
            {
              validator: confirmValidator,
            },
          ]}
        >
          <Input
            placeholder={t('common.newNickname')}
            suffix={(
              <Tooltip
                placement="topRight"
                onClick={(e) => e.stopPropagation()}
                title={(
                  <div className={styles.tooltipTitle}>
                    <p>
                      {t('pages.profile.settings.updateUrlName.5')}
                    </p>
                    <p>{t('pages.profile.settings.updateUrlName.6')}</p>
                  </div>
                )}
                overlayClassName="registration"
              >
                <img src={info} alt="info" />
              </Tooltip>
            )}
          />
        </Form.Item>
        <Form.Item dependencies={['new_nickname']}>
          {() => {
            const dependentValue = form.getFieldValue('new_nickname');
            return (
              <p className={styles.example}>
                https://tolips.link/
                {dependentValue || '...'}
              </p>
            );
          }}
        </Form.Item>
        {error && <span className={styles.error}>{t('errors.somethingWrong')}</span>}
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className={styles.button}
            disabled={
              !form.isFieldTouched('new_nickname')
                || !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
          >
            {t('common.update')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

UpdateUrlName.propTypes = {
  activeKey: PropTypes.string.isRequired,
};

export { UpdateUrlName };
