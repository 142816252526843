import { initialFilters } from 'constants/initialFilters';
import { savedConstants } from 'constants/localStorage';

export const initialState = {
  user: null,
  userId: null,
  userInfo: null,
  notificationsCount: 0,
  filters: initialFilters,
  reloadData: false,
  isFetchedUser: false,
  isCreatedProfile: false,
  isShowNotification: { isShow: false, status: null },
  isPrivatePage: false,
  activeCarouselItem: null,
  isFirstCatalogRequest: true,
  isBlocking: false,
};

// eslint-disable-next-line default-param-last
export const LocalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: true };
    case 'LOGOUT':
      localStorage.removeItem(savedConstants.AUTH_TOKEN);
      localStorage.removeItem(savedConstants.USER_TYPE);
      return {
        ...state,
        userInfo: null,
        user: null,
        userId: null,
        notificationsCount: 0,
        isCreatedProfile: false,
        isFetchedUser: false,
        isPrivatePage: false,
        activeCarouselItem: null,
        isFirstCatalogRequest: true,
      };
    case 'SET_USER_INFO':
      return { ...state, userInfo: action.payload };
    case 'SET_USER_ID':
      return { ...state, userId: action.payload };
    case 'SET_NOTIFICATIONS_COUNT':
      return { ...state, notificationsCount: action.payload };
    case 'SET_FILTERS':
      return { ...state, filters: action.payload };
    case 'SET_ACTIVE_CAROUSEL_ITEM':
      return { ...state, activeCarouselItem: action.payload };
    case 'SET_RELOAD_DATA':
      return { ...state, reloadData: action.payload };
    case 'SET_IS_CREATED_PROFILE':
      return { ...state, isCreatedProfile: action.payload };
    case 'SET_IS_PRIVATE_PAGE':
      return { ...state, isPrivatePage: action.payload };
    case 'SET_IS_SHOW_NOTIFICATION':
      return { ...state, isShowNotification: action.payload };
    case 'SET_IS_FIRST_CATALOG_REQUEST':
      return { ...state, isFirstCatalogRequest: action.payload };
    case 'SET_IS_FETCHED_USER':
      // TODO: set it to true when update user
      return { ...state, isFetchedUser: action.payload };
    case 'SET_IS_BLOCKING':
      return { ...state, isBlocking: action.payload };
    default:
      return state;
  }
};
