import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, onClick, id }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#757575"
    className={className}
    onClick={onClick}
    id={id}
  >
    <path
      d="M1 1L11 11M1 11L11 1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

export { CloseIcon };
