import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { getMainData, getMainDataForVisitor } from 'api/requests';
import { confirmEmail } from 'api/requests/auth';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { cities, paths, savedConstants } from 'constants';
import { status } from 'constants/status';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { country_districts } from 'constants/country_districts';
import { genders } from 'utils/genders';
import { getClearFilters } from 'utils/setInitialFilters';

import { CatalogList } from 'components/CatalogList';
import { NotificationModal } from 'components/NotificationModal';
import { Spinner } from 'components/Spinner';
import { Select } from 'components/Select';
import { Button } from 'components/Button';

import styles from './styled.module.css';
import './styles.css';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    localState: { isShowNotification, filters, userInfo },
    localDispatch,
  } = useLocalContext();
  const { isPublicVersion } = usePublicVersion();

  const { gaClickSearch } = useGoogleAnalytics();

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [searchParams] = useSearchParams();
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState([]);
  const [orientation, setOrientation] = useState('hetero');
  const [gender, setGender] = useState('female');
  const [verified, setVerified] = useState(false);
  const [service, setService] = useState(false);
  const [loadingRandomProfiles, setLoadingRandomProfiles] = useState(false);
  const [randomProfiles, setRandomProfiles] = useState(null);
  const userType = localStorage.getItem('user_type');

  const getTotalCount = async () => {
    const query = {
      gender,
      orientation,
      ...(city && { city }),
      ...(verified && { verified: true }),
      ...(service && {
        type_of_service: ['outcall'],
      }),
      ...(district.length && { district }),
    };
    setLoading(true);
    try {
      if (userInfo && userInfo.user_type === userVisitor) {
        const { data } = await getMainDataForVisitor({
          ...query,
          count_total: true,
        });
        setTotalCount(data.total);
      } else {
        const { data } = await getMainData({ ...query, count_total: true });
        setTotalCount(data.total);
      }
    } catch {
      console.log('catalog has problem');
    } finally {
      setLoading(false);
    }
  };

  const getRandomProfiles = async () => {
    const query = {
      sample_catalog: true,
      limit: 4,
    };
    setLoadingRandomProfiles(true);
    try {
      if (userInfo && userInfo.user_type === userVisitor) {
        const {
          data: { sample_catalog },
        } = await getMainDataForVisitor(query);
        setRandomProfiles(sample_catalog);
      } else {
        const {
          data: { sample_catalog },
        } = await getMainData(query);
        setRandomProfiles(sample_catalog);
      }
    } catch {
      console.log('catalog has problem');
    } finally {
      setLoadingRandomProfiles(false);
    }
  };

  const confirmEmailRequest = async () => {
    try {
      const token = searchParams.get('access_token');

      const { data } = await confirmEmail(token);
      if (data.user_type === userVisitor) {
        localDispatch({
          type: 'SET_IS_SHOW_NOTIFICATION',
          payload: { isShow: true, status: status.success },
        });
      }
      localStorage.setItem(savedConstants.AUTH_TOKEN, data.token);
      localStorage.setItem(savedConstants.USER_TYPE, data.user_type);
      navigate(
        data.user_type === userProvider ? paths.notCreatedProfile : paths.main,
        {
          replace: true,
          state:
            data.user_type === userProvider
              ? { afterEmailComfirm: true }
              : null,
        },
      );
    } catch {
      localDispatch({
        type: 'SET_IS_SHOW_NOTIFICATION',
        payload: { isShow: true, status: status.error },
      });
      navigate(paths.main, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    getRandomProfiles();
  }, []);

  useEffect(() => {
    getTotalCount();
  }, [city, orientation, gender, verified, service, district.length]);

  useEffect(() => {
    if (
      location.pathname === '/confirm_email'
      && location.search.includes('access_token')
    ) {
      confirmEmailRequest();
    }
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>{t('pages.home.title')}</h1>
        <p>{t('pages.home.subtitle')}</p>
      </div>
      <div className={styles.genders}>
        {genders.map((item) => (
          <div
            key={item.title}
            className={`
                ${styles.gender} 
                ${
                  gender === item.gender && orientation === item.orientation
                    ? styles.active
                    : ''
                }
              `}
            onClick={() => {
              setGender(item.gender);
              setOrientation(item.orientation);
            }}
          >
            {!isPublicVersion && <img src={item.image} alt={item.gender} />}
            <p>{t(`pages.home.genders.${item.title}`)}</p>
          </div>
        ))}
      </div>
      <div className={styles.searchBlock}>
        <div className={styles.inputs}>
          <Select
            options={cities}
            placeholder={t('common.chooseCity')}
            defaultValue={city}
            onSelect={(e) => {
              setDistrict([]);
              setCity(e);
            }}
            className={styles.input}
          />
          {city && country_districts[city.toLowerCase()] && (
            <Select
              options={country_districts[city.toLowerCase()].slice(1)}
              placeholder={t('pages.home.district')}
              defaultValue={district}
              multi
              onSelect={(e) => {
                setDistrict(e);
              }}
            />
          )}
        </div>
        <div className={styles.checkboxButton}>
          <div>
            <Checkbox
              rootClassName="home"
              checked={verified}
              onChange={(e) => setVerified(e.target.checked)}
            >
              {t('pages.home.profiles')}
            </Checkbox>
            <Checkbox
              checked={service}
              onChange={(e) => {
                setService(e.target.checked);
                localDispatch({
                  type: 'SET_FILTERS',
                  payload: {
                    ...filters,
                    type_of_service: e.target.checked ? 'outcall' : undefined,
                  },
                });
              }}
            >
              {t('common.outcall')}
            </Checkbox>
          </div>
          <Link
            className={styles.buttonLink}
            to={`catalog/?page=1&city=${
              city || ''
            }&gender=${gender}&orientation=${orientation}${
              verified ? `&verified=${verified}` : ''
            }${service ? '&service=outcall' : ''}${
              district.length ? `&district=${district.join('__')}` : ''
            }`}
            onClick={() => {
              gaClickSearch({
                gender,
                orientation,
                city,
                verified,
                service,
                district,
              });
              const clearFilters = getClearFilters(filters);
              localDispatch({
                type: 'SET_FILTERS',
                payload: clearFilters,
              });
              localDispatch({
                type: 'SET_ACTIVE_CAROUSEL_ITEM',
                payload: null,
              });
            }}
          >
            <Button loading={loading} primary className={styles.button}>
              {!loading && <span>{t('common.search')}</span>}
              {!loading && <span>{totalCount || 0}</span>}
            </Button>
          </Link>
        </div>
      </div>
      {loadingRandomProfiles && <Spinner />}
      {randomProfiles && <CatalogList items={randomProfiles} />}
      {isShowNotification.isShow && (
        <NotificationModal
          logo
          buttonName={
            isShowNotification.status === status.success
            && userType === userProvider
            && t('pages.notCreatedProfile.9')
          }
          buttonDescription={
            isShowNotification.status === status.success
            && userType === userProvider
            && t('pages.notCreatedProfile.2')
          }
          onButtonClick={() => {
            navigate(paths.notCreatedProfile, {
              state: { afterEmailComfirm: true },
            });
          }}
          messageSuccess={t('pages.home.email')}
          messageError={t('pages.home.notConfirmed')}
        />
      )}
    </div>
  );
};

export { Home };
