import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const RegistrationBlockingModal = ({
  open, setOpen, onClick, loading,
}) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('common.ohNo')}</h2>
        <div className={styles.description}>
          <p>{t('pages.registration.blockingModal.1')}</p>
          <p>{t('pages.registration.blockingModal.2')}</p>
        </div>
        <div className={styles.buttonBlock}>
          <Button primary loading={loading} onClick={onClick}>
            {t('pages.profileCreation.photos.photosModal.12')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

RegistrationBlockingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { RegistrationBlockingModal };
