import { Checkbox as AntdCheckbox, Form } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styled.module.css';
import './styles.css';

const Checkbox = ({
  title,
  noLabel = false,
  uniquelabel,
  options,
  small,
  onChange,
  withDefaultValue = false,
  form,
  monoValue = false,
}) => {
  const { t } = useTranslation();
  const formatTitle = title.replace(/_/g, ' ');
  const [defaultValueSelected, setDefaultValueSelected] = useState(true);
  const defaultValue = {
    name: t('common.doesNotMatter'),
    value: undefined,
  };

  const handleChange = (values) => {
    if (monoValue) {
      form.setFieldsValue({ [title]: values.slice(-1) });
    }
    if (defaultValueSelected && values.includes(undefined)) {
      setDefaultValueSelected(false);
      const filteredValues = values.filter((item) => item);
      form.setFieldsValue({ [title]: filteredValues });
    }
    if (!defaultValueSelected && !values.includes(undefined)) {
      form.setFieldsValue({ [title]: values });
    }
    if (
      (!defaultValueSelected && values.includes(undefined))
      || !values.length
    ) {
      form.setFieldsValue({ [title]: [defaultValue.value] });
      setDefaultValueSelected(true);
    }
  };

  return (
    <div className={styles.checkboxBlock}>
      {!noLabel && (
      <p>
        {uniquelabel || formatTitle}
        :
      </p>
      )}
      <Form.Item name={title.toLowerCase()}>
        <AntdCheckbox.Group
          className={`${styles.checkboxGroup} custom-checkbox-group`}
          onChange={(values) => {
            if (form) {
              handleChange(values);
            }
            onChange();
          }}
        >
          {withDefaultValue && (
            <AntdCheckbox
              key={defaultValue}
              className={styles.checkboxItem}
              value={defaultValue.value}
            >
              {defaultValue.name}
            </AntdCheckbox>
          )}
          {options.map((option) => (
            <AntdCheckbox
              key={option.value}
              value={option.value}
              className={`${styles.checkboxItem} ${
                (option.image || option.color) && styles.checkboxItemLeft
              } ${small && styles.checkboxItemSmall}`}
            >
              <div className={styles.optionBlock}>
                {option.color && (
                  <div
                    className={styles.colorItem}
                    style={{
                      background: option.color,
                    }}
                  />
                )}
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.value}
                    style={{ height: '23px', width: '23px' }}
                  />
                )}
                <span>{option.label}</span>
              </div>
            </AntdCheckbox>
          ))}
        </AntdCheckbox.Group>
      </Form.Item>
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  uniquelabel: PropTypes.string,
  monoValue: PropTypes.bool,
  small: PropTypes.bool,
  withDefaultValue: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
  }),
};

export { Checkbox };
