export const paths = {
  main: '/',
  catalog: '/catalog',
  registration: '/registration',
  emailConfirmation: '/email-confirmation',
  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  deletePolicy: '/delete-policy',
  dmcaPage: '/about-dmca-notice',
  advertisingPolicy: '/advertising-policy',
  antiExploitationPolicy: '/anti-exploitation-policy',
  codeOfConduct: '/code-of-conduct',
  about: '/about',
  profileCreation: '/profile-creation',
  notCreatedProfile: '/profile-not-created',
  forgotPassword: '/reset_password',
  myProfile: '/profile/my-profile',
  profileSettings: '/profile/settings',
  profileWallet: '/profile/wallet',
  profileContactUs: '/profile/contact-us',
  profileBlackList: '/profile/black-list',
  profileNotifications: '/profile/notifications',
  errorPage: '/404',
  paymentPage: '/payment-page',
  hotDealPage: '/hot-deal',
  verification: '/verification',
  blockingPage: '/blocking-page',
  catalogProfile: (id = ':id') => `/${id}`,
};

export const pageTitle = {
  [`${paths.main}`]: 'ToLips',
  [`${paths.catalog}/`]: 'Catalog',
  [`${paths.registration}`]: 'Registration',
  [`${paths.emailConfirmation}`]: 'Email Confirmation',
  [`${paths.terms}`]: 'Terms',
  [`${paths.privacyPolicy}`]: 'Privacy Policy',
  [`${paths.deletePolicy}`]: 'Delete Policy',
  [`${paths.dmcaPage}`]: 'About DMCA',
  [`${paths.advertisingPolicy}`]: 'Advertising Policy',
  [`${paths.antiExploitationPolicy}`]: 'Anti-Exploitation Policy',
  [`${paths.codeOfConduct}`]: 'Code Of Conduct',
  [`${paths.about}`]: 'About',
  [`${paths.profileCreation}`]: 'Profile Creation',
  [`${paths.notCreatedProfile}`]: 'Profile Not Created',
  [`${paths.forgotPassword}`]: 'Reset Password',
  [`${paths.myProfile}`]: 'My Profile',
  [`${paths.profileSettings}`]: 'Settings',
  [`${paths.profileWallet}`]: 'Wallet',
  [`${paths.profileContactUs}`]: 'Contact Us',
  [`${paths.profileBlackList}`]: 'Black List',
  [`${paths.profileNotifications}`]: 'Notifications',
  [`${paths.errorPage}`]: '404',
  [`${paths.paymentPage}`]: 'Payment Page',
  [`${paths.hotDealPage}`]: 'Hot Deal',
  [`${paths.verification}`]: 'Verification',
  [`${paths.blockingPage}`]: 'Blocking Page',
  [`${paths.catalogProfile}`]: paths.catalogProfile,
};
