import { Form, Radio } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styled.module.css';
import './styles.css';

const RadioButton = ({
  title, label, noLabel = false, options, width, message,
}) => {
  const { t } = useTranslation();
  const formatTitle = title.replace(/_/g, ' ');
  return (
    <div className={styles.radioButtonBlock}>
      {!noLabel && (
      <p>
        {label || formatTitle}
        :
      </p>
      )}
      <Form.Item
        name={title}
        className="radio-button-mod"
        rules={[
          {
            required: true,
            message: message || t('errors.selectYour', { text: formatTitle }),
          },
        ]}
      >
        <Radio.Group buttonStyle="solid" className={styles.radioGroup}>
          {options.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              className={`${styles.radioItem} ${
                (option.image || option.color) && styles.radioItemLeft
              }`}
              style={{ width: width && width }}
            >
              <div className={styles.optionBlock}>
                {option.color && (
                  <div
                    className={styles.colorItem}
                    style={{
                      background: option.color,
                    }}
                  />
                )}
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.value}
                    style={{ height: '23px', width: '23px' }}
                  />
                )}
                <span>{option.label}</span>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

RadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  width: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { RadioButton };
