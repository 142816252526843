export const scrollToErrorField = (values) => {
  const { errorFields } = values;
  if (errorFields && errorFields.length > 0) {
    const firstErrorField = errorFields[0].name[0];
    const element = document.getElementById(firstErrorField);

    if (element) {
      const rect = element.getBoundingClientRect();
      const offsetPosition = window.scrollY + rect.top - 85;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }
};
