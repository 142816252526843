import { useState } from 'react';
import checkPropTypes from 'prop-types';

import { OpenArrow } from 'components/ActiveIcon/OpenArrow';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const RegistrationPolicy = ({ isProvider }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.policy}>
      <div
        onClick={() => setOpen(!open)}
        className={`${styles.title} ${open ? '' : styles.hide}`}
      >
        <h2>{t('pages.registration.registrationPolicy.1')}</h2>
        <OpenArrow className={`${styles.arrow} ${open ? styles.show : ''}`} />
      </div>
      <div className={`${styles.text} ${open ? styles.open : styles.hide}`}>
        <p>{t('pages.registration.registrationPolicy.2')}</p>
        {isProvider ? (
          <ul className={styles.list}>
            <li>
              <p>{t('pages.registration.registrationPolicy.3')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.4')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.5')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.6')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.7')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.8')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.9')}</p>
            </li>
          </ul>
        ) : (
          <ul className={styles.list}>
            <li>
              <p>{t('pages.registration.registrationPolicy.10')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.11')}</p>
            </li>
            <li>
              <p>{t('pages.registration.registrationPolicy.12')}</p>
            </li>
          </ul>
        )}
        {isProvider ? (
          <p style={{ fontSize: '18px', fontWeight: 600 }}>
            {t('pages.registration.registrationPolicy.13')}
          </p>
        ) : (
          <p>{t('pages.registration.registrationPolicy.14')}</p>
        )}
      </div>
    </div>
  );
};

RegistrationPolicy.propTypes = {
  isProvider: checkPropTypes.bool.isRequired,
};

export { RegistrationPolicy };
