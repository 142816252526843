import {
  Select as AntdSelect, Form, Input, InputNumber,
} from 'antd';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';
import { useScrollToError } from 'hooks/useScrollToError';

import { useLocalContext } from 'LocalContext';
import { createProfile, editProfile } from 'api/requests/profile';
import { country_districts } from 'constants/country_districts';
import { cities } from 'constants';
import { latinLettersPattern } from 'constants/patterns';
import {
  breastCupSize,
  breastType,
  ethnicity,
  eyeColor,
  hairColor,
  hairLength,
  hairPubic,
  horoscrope,
  languages,
  nationality,
  orientation,
  piercing,
  sex,
  smoking,
  tattoo,
  travel,
} from 'utils/filters';
import { onlyNumberInInput } from 'utils/onlyNumberInInput';
import {
  formatBackToBioInfoValues,
  formatBioInfoValuesToBack,
} from 'utils/profileCreationValues';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import { getErrorInForm } from 'utils/error';

import { DividerWithLogo } from 'components/DividerWithLogo';
import { PhoneCode } from 'components/PhoneCode';
import { Checkbox } from '../../components/Checkbox';
import { RadioButton } from '../../components/RadioButton';
import { Button } from 'components/Button';
import { Select } from 'components/Select';

import styles from '../../styled.module.css';

const BioInformation = ({
  nextStep, setLoading, errors, setErrors,
}) => {
  const { t } = useTranslation();
  const {
    localState: { isCreatedProfile, userInfo, userId },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();
  const [form] = Form.useForm();
  useScrollToError(errors);

  const [sexType, setSexType] = useState(userInfo?.profile?.gender || '');
  const initialValue = formatBackToBioInfoValues(
    userInfo ? userInfo.profile : { nickname: userId },
  );

  const filteredCities = useMemo(
    () => cities.filter((item) => item.label !== 'Anywhere'),
    [cities],
  );

  const onFinish = async (values) => {
    const profileValies = formatBioInfoValuesToBack(values);
    const query = {
      profile: profileValies,
    };

    if (JSON.stringify(values) === JSON.stringify(initialValue)) {
      nextStep();
    } else {
      setLoading(true);
      try {
        if (isCreatedProfile) {
          const {
            data: { card },
          } = await editProfile(query);
          localDispatch({ type: 'SET_USER_INFO', payload: card });
        } else {
          const {
            data: { card },
          } = await createProfile(query);
          localDispatch({ type: 'SET_USER_INFO', payload: card });
        }
        setErrors([]);
        nextStep();
      } catch (e) {
        localDispatch({
          type: 'SET_USER_INFO',
          payload: isCreatedProfile ? {
            ...userInfo,
            profile: { ...userInfo.profile, ...profileValies },
          } : {
            profile: { ...profileValies },
          },
        });
        const formatErrors = getErrorInForm(e, values);
        setErrors(formatErrors);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      className={`${styles.form} ${styles.bioInformation}`}
      initialValues={initialValue}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Button
            primary
            submit
            className={styles.button}
            disabled={!!Object.keys(errors).length}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
      <div className={styles.formWrapper}>
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.basic')}</p>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('errors.enterModelName'),
              },
              {
                min: 2,
                message: t('errors.modelNameAtLeast', { number: 2 }),
              },
              {
                max: 31,
                message: t('errors.modelNameLess', { number: 31 }),
              },
              {
                pattern: latinLettersPattern,
                message: t('errors.onlyLatin'),
              },
            ]}
          >
            <Input placeholder={t('common.modelName')} maxLength={32} />
          </Form.Item>
          <Select
            title="city"
            options={filteredCities}
            form={form}
            scrollHeight={isMobile ? 70 : 0}
            defaultValue={initialValue?.city}
            noLabel
            onSelect={() => {
              form.setFieldsValue({ district: undefined });
            }}
          />
          <Form.Item
            shouldUpdate
            style={{ margin: 0 }}
          >
            {() => {
              const dependentValue = form.getFieldValue('city');
              const district = dependentValue?.replace(/-/g, '_').toLowerCase();
              const isHaveDistrict = Object.keys(country_districts).includes(district);
              const value = form.getFieldValue('district');
              const options = value === undefined
                ? country_districts[district]?.filter(
                  (item) => item.value !== 'all',
                ) || []
                : country_districts[district] || [];
              return (
                <Select
                  title="district"
                  form={form}
                  options={options}
                  optional
                  scrollHeight={isMobile ? 70 : 0}
                  noLabel
                  dependency={dependentValue}
                  defaultValue={initialValue?.district}
                  disabled={!isHaveDistrict}
                  limitMessage={!isHaveDistrict ? t('errors.noDistricts') : ''}
                />
              );
            }}
          </Form.Item>
          <PhoneCode
            form={form}
            name="phone"
            status={Object.keys(errors)?.includes('phone_number') && 'error'}
            help={
              Object.keys(errors)?.includes('phone_number')
                ? 'Number already binded to another profile'
                : null
            }
            setErrors={setErrors}
            errors={errors}
          />
          <Form.Item
            name="age"
            rules={[
              {
                required: true,
                message: t('errors.enterAge'),
              },
              {
                type: 'number',
                min: 18,
                max: 120,
                message: t('errors.ageLimits', { min: 18, max: 120 }),
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t('common.age')}
              onKeyDown={(e) => onlyNumberInInput(e)}
              maxLength={3}
            />
          </Form.Item>
          <div className={styles.oneLineItem}>
            <Select
              title="sex"
              options={sex}
              translate
              noLabel
              readOnly
              scrollHeight={isMobile ? 70 : 0}
              form={form}
              defaultValue={initialValue?.sex}
              onSelect={(e) => {
                if (e === 'trans') {
                  form.setFieldValue('orientation', 'bi');
                }
                setSexType(e);
              }}
            />
            <Select
              title="orientation"
              options={
                sexType === 'trans'
                  ? orientation.filter((item) => item.value === 'bi')
                  : orientation
              }
              noLabel
              readOnly
              form={form}
              scrollHeight={isMobile && sexType !== 'trans' ? 70 : 0}
              noSelect={sexType === 'trans'}
              defaultValue={sexType === 'trans' ? 'bi' : initialValue?.orientation}
            />
          </div>
          <div className={styles.oneLineItem}>
            <Form.Item
              dependencies={['heightType']}
              style={{ width: '50%', margin: 0 }}
            >
              {() => {
                const dependentValue = form.getFieldValue('heightType');
                const min = dependentValue !== 'ft' ? 100 : 3.28;
                const max = dependentValue !== 'ft' ? 220 : 7.22;
                return (
                  <Form.Item
                    name={dependentValue !== 'ft' ? 'height_cm' : 'height_ft'}
                    rules={[
                      { required: true, message: t('errors.enterHeight') },
                      {
                        type: 'number',
                        min,
                        max,
                        message: t('errors.heightLimit', { min, max }),
                      },
                    ]}
                  >
                    <InputNumber
                      addonAfter={(
                        <Form.Item name="heightType" noStyle>
                          <AntdSelect
                            className="extraSelect"
                            rootClassName="aaa"
                            style={{
                              width: 70,
                            }}
                            onChange={() => form.resetFields(['height'])}
                          >
                            <AntdSelect.Option value="cm">
                              {t('cm')}
                            </AntdSelect.Option>
                            <AntdSelect.Option value="ft">
                              {t('ft')}
                            </AntdSelect.Option>
                          </AntdSelect>
                        </Form.Item>
                      )}
                      style={{ width: '100%' }}
                      placeholder={t('common.height')}
                      onKeyDown={(e) => onlyNumberInInput(e, dependentValue === 'ft')}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              dependencies={['weightType']}
              style={{ width: '50%', margin: 0 }}
            >
              {() => {
                const dependentValue = form.getFieldValue('weightType');
                const min = dependentValue === 'kg' ? 40 : 88;
                const max = dependentValue === 'kg' ? 150 : 330;
                return (
                  <Form.Item
                    name={dependentValue === 'kg' ? 'weight_kg' : 'weight_lbs'}
                    rules={[
                      { required: true, message: t('errors.enterWeight') },
                      {
                        type: 'number',
                        min,
                        max,
                        message: t('errors.weightLimits', { min, max }),
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <InputNumber
                      addonAfter={(
                        <Form.Item name="weightType" noStyle>
                          <AntdSelect
                            className="extraSelect"
                            style={{
                              width: 70,
                            }}
                            onChange={() => form.resetFields(['weight'])}
                          >
                            <AntdSelect.Option value="kg">
                              {t('kg')}
                            </AntdSelect.Option>
                            <AntdSelect.Option value="lbs">
                              {t('lbs')}
                            </AntdSelect.Option>
                          </AntdSelect>
                        </Form.Item>
                      )}
                      style={{
                        width: 'calc(100%)',
                      }}
                      placeholder={t('common.weight')}
                      onKeyDown={(e) => onlyNumberInInput(e)}
                      maxLength={3}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </div>
        <DividerWithLogo className={styles.divider} />
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.ethnicity')}</p>
          <Select
            title="ethnicity"
            options={ethnicity}
            scrollHeight={isMobile ? 70 : 0}
            noLabel
            form={form}
            defaultValue={initialValue?.ethnicity}
          />

          <Select
            title="nationality"
            options={nationality}
            scrollHeight={isMobile ? 70 : 0}
            noLabel
            form={form}
            defaultValue={initialValue?.nationality}
          />
          <Select
            title="languages"
            options={languages}
            scrollHeight={isMobile ? 70 : 0}
            noLabel
            form={form}
            limitMessage={t('errors.limitLanguages', { number: 7 })}
            limit={7}
            multi
            defaultValue={initialValue?.languages}
          />
        </div>
        <DividerWithLogo className={styles.divider} />
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.hair')}</p>
          <RadioButton
            title="hair_color"
            label={t('common.hair_color')}
            options={hairColor}
          />
          <RadioButton
            title="hair_length"
            label={t('common.hair_length')}
            options={hairLength}
          />
          <RadioButton
            title="pubic_hair"
            label={t('common.pubic_hair')}
            options={hairPubic}
          />
        </div>
        <DividerWithLogo className={styles.divider} />
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.body')}</p>
          <RadioButton
            title="eye_color"
            label={t('common.eye_color')}
            options={eyeColor}
          />
          <RadioButton
            title="tattoo"
            label={t('common.tattoo')}
            options={tattoo}
          />
          <Checkbox
            title="piercing"
            label={t('common.piercing')}
            options={piercing}
            form={form}
          />
        </div>
        <DividerWithLogo className={styles.divider} />
        {sexType !== 'male' && (
          <>
            <div className={styles.formItem}>
              <p className={styles.title}>{t('common.breast')}</p>
              <RadioButton
                title="cup_size"
                label={t('common.cup_size')}
                options={breastCupSize}
                width="60px"
              />
              <RadioButton
                title="breast_type"
                label={t('common.breast_type')}
                options={breastType}
              />
            </div>
            <DividerWithLogo className={styles.divider} />
          </>
        )}
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.travel')}</p>
          <RadioButton title="travel" options={travel} noLabel />
        </div>
        <DividerWithLogo className={styles.divider} />
        <div className={styles.formItem}>
          <p className={styles.title}>{t('common.other')}</p>
          <RadioButton
            title="zodiac"
            label={t('common.zodiac')}
            options={horoscrope}
          />
          <RadioButton
            title="smoker"
            label={t('common.smoker')}
            options={smoking}
            message={t('pages.profileCreation.bioInfo.1')}
          />
        </div>
      </div>
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Button
            primary
            submit
            className={styles.button}
            disabled={!!Object.keys(errors).length}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
    </Form>
  );
};

BioInformation.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
  errors: PropTypes.shape([]),
  setErrors: PropTypes.func,
};

export { BioInformation };
