import { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { scrollToErrorField } from 'utils/scrollToErrorField';
import { updatePassword } from 'api/requests/settings';

import styles from './styled.module.css';

const UpdatePassword = ({ activeKey }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [activeKey]);

  const onFinish = async (values) => {
    const query = {
      ...values,
    };

    setLoading(true);
    try {
      await updatePassword(query);
      setIsUpdate(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const confirmOldNewValidator = (_, value) => new Promise((resolve, reject) => {
    const { getFieldValue } = form;
    if (!!value && value === getFieldValue('old_password')) {
      reject(new Error(t('errors.passwordEqual')));
    } else {
      resolve();
    }
  });

  const confirmValidator = (_, value) => new Promise((resolve, reject) => {
    const { getFieldValue } = form;
    if (!value || value !== getFieldValue('new_password')) {
      reject(new Error(t('errors.passwords')));
    } else {
      resolve();
    }
  });

  return isUpdate ? (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        {t('pages.profile.settings.updatePassword.1')}
      </p>
      <p className={styles.label}>
        {t('pages.profile.settings.updatePassword.2')}
      </p>
    </div>
  ) : (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      className={styles.wrapper}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      <div>
        <p className={styles.label}>{t('common.oldPassword')}</p>
        <Form.Item
          name="old_password"
          rules={[
            {
              required: true,
              message: t('errors.inputPassword'),
            },
            {
              min: 8,
              message: t('errors.passwordAtLeast', { number: 8 }),
            },
            {
              max: 32,
              message: t('errors.password.less', { number: 32 }),
            },
            {
              pattern: /\d/,
              message: t('errors.passwordDigit'),
            },
            {
              pattern: /[A-Z]/,
              message: t('errors.passwordCapital'),
            },
          ]}
        >
          <Input.Password
            placeholder={t('common.oldPassword')}
            autoComplete="off"
          />
        </Form.Item>
      </div>
      <div>
        <p className={styles.label}>{t('common.newPassword')}</p>
        <Form.Item
          name="new_password"
          dependencies={['old_password']}
          rules={[
            {
              required: true,
              message: t('errors.inputPassword'),
            },
            {
              min: 8,
              message: t('errors.passwordAtLeast', { number: 8 }),
            },
            {
              max: 32,
              message: t('errors.password.less', { number: 32 }),
            },
            {
              pattern: /\d/,
              message: t('errors.passwordDigit'),
            },
            {
              pattern: /[A-Z]/,
              message: t('errors.passwordCapital'),
            },
            {
              validator: confirmOldNewValidator,
            },
          ]}
        >
          <Input.Password
            placeholder={t('common.newPassword')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="confirmed_password"
          dependencies={['new_password']}
          rules={[
            {
              validator: confirmValidator,
            },
          ]}
        >
          <Input.Password
            placeholder={t('common.confirmPassword')}
            autoComplete="off"
          />
        </Form.Item>
        {error && (
          <span className={styles.error}>{t('errors.somethingWrong')}</span>
        )}
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className={styles.button}
            disabled={
              !form.isFieldsTouched(true)
              || !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {t('common.update')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

UpdatePassword.propTypes = {
  activeKey: PropTypes.string.isRequired,
};

export { UpdatePassword };
