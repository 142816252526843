import React from 'react';
import PropTypes from 'prop-types';

const PauseIcon = ({ className, onClick }) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="#633976"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
  >
    <path d="M2.375 0C1.87772 0 1.40081 0.197544 1.04917 0.549175C0.697544 0.900805 0.5 1.37772 0.5 1.875V16.125C0.5 16.6223 0.697544 17.0992 1.04917 17.4508C1.40081 17.8025 1.87772 18 2.375 18H5.625C6.12228 18 6.5992 17.8025 6.95083 17.4508C7.30246 17.0992 7.5 16.6223 7.5 16.125V1.875C7.5 1.37772 7.30246 0.900805 6.95083 0.549175C6.5992 0.197544 6.12228 0 5.625 0H2.375ZM1.75 1.875C1.75 1.53 2.03 1.25 2.375 1.25H5.625C5.97 1.25 6.25 1.53 6.25 1.875V16.125C6.25 16.47 5.97 16.75 5.625 16.75H2.375C2.03 16.75 1.75 16.47 1.75 16.125V1.875ZM11.375 0C10.8777 0 10.4008 0.197544 10.0492 0.549175C9.69754 0.900805 9.5 1.37772 9.5 1.875V16.125C9.5 16.6223 9.69754 17.0992 10.0492 17.4508C10.4008 17.8025 10.8777 18 11.375 18H14.625C15.1223 18 15.5992 17.8025 15.9508 17.4508C16.3025 17.0992 16.5 16.6223 16.5 16.125V1.875C16.5 1.37772 16.3025 0.900805 15.9508 0.549175C15.5992 0.197544 15.1223 0 14.625 0H11.375ZM10.75 1.875C10.75 1.53 11.03 1.25 11.375 1.25H14.625C14.97 1.25 15.25 1.53 15.25 1.875V16.125C15.25 16.47 14.97 16.75 14.625 16.75H11.375C11.03 16.75 10.75 16.47 10.75 16.125V1.875Z" />
  </svg>
);

PauseIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { PauseIcon };
