import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { Image } from 'antd';

import { reactAppPublicApiUrl } from '../../config';

import { Spinner } from 'components/Spinner';
import { ImagePreviewWrapper } from 'components/ImagePreviewWrapper';

import styles from './styled.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';

const PhotoCarousel = ({ images }) => {
  const [scroll, setScroll] = useState(true);
  const [currentImage, setCurrentImage] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onLoad, setOnLoad] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const container = useRef(null);

  const isAllImagesLoaded = new Set(onLoad).size === loadedImages.length;

  useEffect(() => {
    const imageElements = document.querySelectorAll(
      '.photoCarouselItem .ant-image-img',
    );

    const imagesLoaded = [];

    const handleImageLoad = (event) => {
      imagesLoaded.push(event.target);
      if (imagesLoaded.length === imageElements.length) {
        setLoadedImages(imagesLoaded);
        setLoading(false);
      }
    };

    if (container.current) {
      setContainerWidth(container.current.clientWidth);
    }

    imageElements.forEach((image) => {
      image.addEventListener('load', handleImageLoad);
    });

    return () => {
      imageElements.forEach((image) => {
        image.removeEventListener('load', handleImageLoad);
      });
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      loadedImages.forEach((image) => {
        const width = image.offsetWidth;
        setTotalWidth((prev) => prev + +width);
      });
    }
  }, [loading]);

  useEffect(() => {
    if (container.current) {
      setContainerWidth(container.current.clientWidth);
    }
  }, [window.innerWidth]);

  const settings = {
    className: `${styles.photoCarousel} photoCarousel`,
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  if (!images) {
    return (
      <div className={`${styles.container} ${styles.backWithoutSlider}`}>
        <div className={styles.back} />
      </div>
    );
  }

  return (
    <div className={styles.container} ref={container}>
      <div className={styles.back} />
      <Spinner
        className={`${styles.loader} ${
          loading
          || (containerWidth <= totalWidth && !isAllImagesLoaded
            ? ''
            : styles.hide)
        }`}
      />
      <div
        className={`${!loading ? '' : styles.hide}`}
        style={{
          visibility:
            containerWidth <= totalWidth && !isAllImagesLoaded && 'hidden',
        }}
      >
        <ImagePreviewWrapper images={images} initialImage={currentImage}>
          {containerWidth >= totalWidth ? (
            <div className={`${styles.blockWithoutSlider} photoCarousel`}>
              {images.map((item, index) => (
                <div
                  className={`${styles.block} photoCarouselItem`}
                  key={item.id}
                  onClick={() => setCurrentImage(index)}
                >
                  <Image
                    rootClassName={`${styles.photo} photoCarouselItem`}
                    src={`${reactAppPublicApiUrl}${item.image_url}`}
                    alt={item.position}
                    preview={scroll}
                    loading="eager"
                  />
                </div>
              ))}
            </div>
          ) : (
            <Slider
              {...settings}
              beforeChange={() => setScroll(false)}
              afterChange={(e) => {
                setCurrentImage(e);
                setScroll(true);
              }}
            >
              {images.map((item, index) => (
                <div
                  className={`${styles.block} photoCarouselItem`}
                  key={item.id}
                  onClick={() => setCurrentImage(index)}
                >
                  <Image
                    rootClassName={`${styles.photo} photoCarouselItem`}
                    src={`${reactAppPublicApiUrl}${item.image_url}`}
                    alt={item.position}
                    preview={scroll}
                    loading="eager"
                    onLoad={(e) => {
                      setOnLoad((prev) => [...prev, e.target.src]);
                    }}
                  />
                </div>
              ))}
            </Slider>
          )}
        </ImagePreviewWrapper>
      </div>
    </div>
  );
};

PhotoCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
    }),
  ),
};

export { PhotoCarousel };
