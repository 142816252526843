import { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useLocalContext } from 'LocalContext';
import { userProvider } from 'constants/registrationUserType';

import { UpdatePassword } from './components/UpdatePassword';
import { UpdateUrlName } from './components/UpdateUrlName';
import { ProfileLayout } from '../components/ProfileLayout';

import styles from './styled.module.css';
import './styles.css';

const Settings = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [activeKey, setActiveKey] = useState(location.state ? '2' : '1');

  const {
    localState: { userInfo },
  } = useLocalContext();

  const onChange = (key) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: '1',
      label: <p>{t('common.changePassword')}</p>,
      children: <UpdatePassword activeKey={activeKey} />,
    },
    userInfo?.user_type === userProvider && {
      key: '2',
      label: <p className={styles.tabLabel}>{t('common.nickname')}</p>,
      children: <UpdateUrlName activeKey={activeKey} />,
    },
  ];
  return (
    <ProfileLayout>
      <div className={styles.wrapper}>
        <Tabs
          defaultActiveKey={location.state ? '2' : '1'}
          items={items}
          onChange={onChange}
          rootClassName="settings"
        />
      </div>
    </ProfileLayout>
  );
};

export { Settings };
