export const onlyNumberInInput = (event, option = false) => {
  if (
    !/\d/.test(event.key)
    && (!option || event.key !== '.')
    && event.key !== 'Backspace'
    && event.key !== 'Delete'
    && event.key !== 'Enter'
    && event.key !== 'ArrowLeft'
    && event.key !== 'ArrowRight'
  ) {
    event.preventDefault();
  }
};
