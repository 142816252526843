import { messengers as messengersData } from './filters';

export const formatBackToBioInfoValues = (profile) => ({
  name: profile ? profile.nickname || undefined : undefined,
  city: profile ? profile.city || undefined : undefined,
  district:
    profile && profile.district && profile.district !== 'all'
      ? profile.district
      : undefined,
  phone: profile ? profile.phone_number || '+48' : '+48',
  age: profile ? profile.age || undefined : undefined,
  sex: profile ? profile.gender || undefined : undefined,
  orientation: profile ? profile.orientation || undefined : undefined,
  heightType: 'cm',
  height_cm: profile ? profile.height || undefined : undefined,
  height_ft: profile
    ? Math.round((profile.height / 30.48) * 100) / 100 || undefined
    : undefined,
  weightType: 'kg',
  weight_kg: profile ? profile.weight || undefined : undefined,
  weight_lbs: profile
    ? Math.round(profile.weight / 0.453592) || undefined
    : undefined,
  ethnicity: profile ? profile.ethnicity || undefined : undefined,
  nationality: profile ? profile.nationality || undefined : undefined,
  languages: profile ? profile.languages || [] : [],
  hair_color: profile ? profile.hair_color || undefined : undefined,
  hair_length: profile ? profile.hair_length || undefined : undefined,
  pubic_hair: profile ? profile.pubic_hair || undefined : undefined,
  eye_color: profile ? profile.eye_color || undefined : undefined,
  tattoo: profile && profile.tattoo !== undefined ? profile.tattoo : undefined,
  piercing: profile ? profile.piercing || undefined : undefined,
  cup_size: profile ? profile.breast_size || undefined : undefined,
  breast_type: profile ? profile.breast_type || undefined : undefined,
  travel: profile ? profile.travel || undefined : undefined,
  zodiac: profile ? profile.zodiac_sign || undefined : undefined,
  smoker: profile ? profile.smoker || undefined : undefined,
});

export const formatBioInfoValuesToBack = (values) => ({
  nickname: values.name,
  work_email: 'aaa@gmail.com',
  phone_number: `${values.phone}`.includes('+')
    ? values.phone
    : `+${values.phone}`,
  gender: values.sex,
  orientation: values.orientation,
  city: values.city,
  ...(values.district
    && values.district !== 'all' && { district: values.district }),
  breast_size: values.sex !== 'male' ? values.cup_size : 'a',
  breast_type: values.sex !== 'male' ? values.breast_type : 'natural',
  hair_color: values.hair_color,
  hair_length: values.hair_length,
  pubic_hair: values.pubic_hair,
  age: values.age,
  travel: values.travel,
  weight:
    values.weightType === 'kg'
      ? values.weight_kg
      : Math.round(values.weight_lbs * 0.453592),
  height:
    values.heightType === 'cm'
      ? values.height_cm
      : Math.round(values.height_ft * 30.48),
  ethnicity: values.ethnicity,
  languages: values.languages,
  nationality: `${values.nationality
    .slice(0, 1)
    .toUpperCase()}${values.nationality.slice(1)}`,
  tattoo: values.tattoo,
  piercing: values.piercing,
  smoker: values.smoker,
  eye_color: values.eye_color,
  zodiac_sign: values.zodiac,
});

export const formatAboutToBack = (values) => {
  const formatValues = [];
  Object.keys(values).forEach((key) => {
    if (key.endsWith('_description')) {
      const language = values[key.replace('_description', '')];
      const description = values[key];
      if (language !== undefined && description !== undefined) {
        formatValues.push({ language, description });
      }
    }
  });
  return formatValues;
};

export const formatAboutDataToSendFormat = (backData = [], sendData = []) => {
  const result = [];
  const backDataKeys = backData.map((item) => item.language);
  const sendDataKeys = sendData.map((item) => item.language);

  backData.forEach((item) => {
    if (!sendDataKeys.includes(item.language)) {
      result.push({ ...item, description: '' });
    } else {
      const itemInSendData = sendData.find(
        (el) => el.language === item.language && el.description !== item.description,
      );
      if (itemInSendData) {
        result.push(itemInSendData);
      }
    }
  });

  sendData.forEach((item) => {
    if (!backDataKeys.includes(item.language)) {
      result.push(item);
    }
  });
  return result;
};

export const formatBackToAvailability = (data) => {
  const activeDays = [];
  const available = {};

  if (data) {
    data.forEach((item) => {
      if (item.available) {
        activeDays.push(item.day);
        if (item.start_time && item.end_time) {
          available[item.day] = [item.start_time, item.end_time];
        }
      }
    });
  }
  return { activeDays, available };
};

export const formatAvailabilityToBack = (days, time) => {
  const initialValue = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  return initialValue.map((item) => ({
    day: item,
    ...(time[item] && {
      end_time: time[item][1],
    }),
    available: days.includes(item) && !!time[item],
    ...(time[item] && {
      start_time: time[item][0],
    }),
  }));
};

const basicTypeOfService = {
  outcall_half_hour: null,
  incall_half_hour: null,
  outcall_hour: null,
  incall_hour: null,
  outcall_two_hours: null,
  incall_two_hours: null,
  outcall_three_hours: null,
  incall_three_hours: null,
  outcall_six_hours: null,
  incall_six_hours: null,
  outcall_half_day: null,
  incall_half_day: null,
  outcall_day: null,
  incall_day: null,
  outcall_two_days: null,
  incall_two_days: null,
};

export const formatBackToRates = (data) => {
  const hour = data ? [...new Set(data.map((item) => item.duration))] : [];

  if (data) {
    data.forEach((item) => {
      basicTypeOfService[`${item.type_of_service}_${item.duration}`] = item.amount;
    });
  }

  return {
    hour,
    typeOfService: basicTypeOfService,
  };
};

export const formatRatesToBack = (hour, typeOfService) => {
  const result = [];
  hour.forEach((duration) => {
    const outcall = `outcall_${duration}`;
    const incall = `incall_${duration}`;

    if (typeOfService[outcall] !== null) {
      result.push({
        duration,
        amount: typeOfService[outcall],
        type_of_service: 'outcall',
      });
    }

    if (typeOfService[incall] !== null) {
      result.push({
        duration,
        amount: typeOfService[incall],
        type_of_service: 'incall',
      });
    }
  });

  return result;
};

export const formatRatesDataToSendFormat = (backData = [], sendData = []) => {
  const result = [];
  const key = (item) => `${item.duration}_${item.type_of_service}`;
  const backDataKeys = backData.map((item) => key(item));
  const sendDataKeys = sendData.map((item) => key(item));

  backData.forEach((item) => {
    if (!sendDataKeys.includes(key(item))) {
      result.push({ ...item, amount: null });
    } else {
      const itemInSendData = sendData.find(
        (el) => key(el) === key(item) && el.amount !== item.amount,
      );
      if (itemInSendData) {
        result.push(itemInSendData);
      }
    }
  });

  sendData.forEach((item) => {
    if (!backDataKeys.includes(key(item))) {
      result.push(item);
    }
  });
  return result;
};

export const formatBackToServices = (data, backData) => {
  if (backData) {
    const result = data.reduce((acc, item) => {
      const findService = backData.find(
        (el) => el.service_name.toLowerCase() === item.label.toLowerCase(),
      );

      if (
        backData
          .map((el) => el.service_name.toLowerCase())
          .includes(item.label.toLowerCase())
      ) {
        acc[`${item.value}_extra`] = findService?.extra_amount === 0
          ? undefined
          : findService?.extra_amount;
        acc[`${item.value}_text`] = findService?.comment;
        acc[`${item.value}_checkbox`] = item.value;
      }

      return acc;
    }, {});

    return result;
  }
  return undefined;
};

export const formatServicesToBack = (values) => {
  if (values) {
    const filteredKeys = Object.keys(values).filter(
      (item) => item.endsWith('_checkbox') && values[item] !== undefined,
    );

    const result = filteredKeys.map((item) => {
      const id = item.split('_')[0];
      return {
        favor_id: +id,
        included_free: !values[`${id}_extra`],
        extra_amount: values[`${id}_extra`] || 0,
        comment: values[`${id}_text`] || '',
      };
    });
    return result;
  }
  return [];
};

export const formatServicesToLocalStore = (values, services) => {
  const filteredKeys = Object.keys(values).filter(
    (item) => item.endsWith('_checkbox') && values[item] !== undefined,
  );

  const result = filteredKeys.map((item) => {
    const id = item.split('_')[0];
    return {
      service_name: services.find((el) => el.value === +id).label,
      included_free: !values[`${id}_extra`],
      extra_amount:
        (values[`${id}_extra`] === 0 ? undefined : values[`${id}_extra`])
        || null,
      comment: values[`${id}_text`] || '',
    };
  });
  return result;
};

export const formatSocialMediaToBack = (data) => {
  const total = [];
  const messengers = [];
  const social_networks = [];

  const findMessengerOrSocial = (item) => messengersData.map((el) => el.value).includes(item);

  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined && key.endsWith('_select')) {
      const name = key.replace('_select', '');
      const link = data[name];
      if (findMessengerOrSocial(name)) {
        messengers.push({ name, link });
      } else {
        social_networks.push({ name, link });
      }
      total.push({ name, link });
    }
  });

  return {
    messengers,
    social_networks,
  };
};

export const getSocialMediaType = (el) => {
  if (el.includes('+') && !el.includes('.') && !el.includes('@')) {
    return 'phone';
  }
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(el)) {
    return 'email';
  }
  return 'nickname';
};

export const formatSocialMediaDataToSendFormat = (
  messengers,
  social_networks,
) => [
  ...(messengers?.map((item) => ({
    name: item.name,
    link: item.link,
  })) || []),
  ...(social_networks?.map((item) => ({
    name: item.name,
    link: item.link,
  })) || []),
];
