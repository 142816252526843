import { isProductionVisible } from 'config';

export const translateLanguage = [
  ...(!isProductionVisible ? [{
    key: 'pu',
    label: 'Public',
    name: 'PU',
  }] : []),
  {
    key: 'pl',
    label: 'PL - Polish',
    name: 'PL',
  },
  {
    key: 'en',
    label: 'EN - English',
    name: 'EN',
  },
  {
    key: 'ru',
    label: 'RU - Russian',
    name: 'RU',
  },
  // {
  //   key: 'de',
  //   label: 'DE - Deutsch',
  //   name: 'DE',
  // },
  // {
  //   key: 'es',
  //   label: 'ES - Spanish',
  //   name: 'ES',
  // },
  // {
  //   key: 'fr',
  //   label: 'FR - French',
  //   name: 'FR',
  // },
  // {
  //   key: 'uk',
  //   label: 'UK - Ukrainian',
  //   name: 'UK',
  // },
];
