import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';

import styles from './styled.module.css';
import './styles.css';

const CommentItem = ({ text, isShowInfo }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile({ maxWidth: 768 });
  const [showAllText, setShowAllText] = useState(false);
  const [showAllTextButton, setShowAllTextButton] = useState(false);

  const childrenRef = useRef(null);
  const parentRef = useRef(null);

  useEffect(() => {
    if (isShowInfo && childrenRef.current?.offsetHeight) {
      setShowAllTextButton(childrenRef.current.offsetHeight > 200);
    }
  }, [isShowInfo]);

  return isMobile ? (
    <div
      ref={parentRef}
      className={`${styles.mobileText} ${
        showAllTextButton ? styles.showButton : ''
      } ${showAllText ? styles.showText : ''}`}
    >
      <p
        ref={childrenRef}
        className={`${styles.textBlock} ${
          showAllTextButton ? styles.hiddenText : ''
        } ${showAllText ? styles.showText : ''}`}
      >
        {text || ''}
      </p>
      {showAllTextButton && (
        <p
          className={`${styles.show} ${showAllText ? styles.hide : ''}`}
          onClick={() => {
            if (showAllText) {
              parentRef.current.scrollIntoView({
                block: 'start',
              });
            }
            setShowAllText(!showAllText);
          }}
        >
          {`${showAllText ? t('common.hide') : t('common.show')} ${t('common.allText')}`}
        </p>
      )}
    </div>
  ) : (
    <TextArea
      placeholder={t('common.textForAdditionInfo')}
      value={text || ''}
      disabled
      className={`${styles.text} listData`}
    />
  );
};

CommentItem.propTypes = {
  text: PropTypes.string.isRequired,
  isShowInfo: PropTypes.bool.isRequired,
};

export { CommentItem };
