import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';
import { useLocalContext } from 'LocalContext';
import { editProfile } from 'api/requests/profile';
import {
  formatAboutDataToSendFormat,
  formatAboutToBack,
} from 'utils/profileCreationValues';
import { languages } from 'utils/filters';
import { scrollToErrorField } from 'utils/scrollToErrorField';

import { Select } from 'components/Select';

import styles from '../../styled.module.css';
import './styles.css';

const About = ({ nextStep, setLoading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const addDescriptionButton = useRef(null);

  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();

  const backData = (userInfo && userInfo.profile_descriptions) || [];
  const [data, setData] = useState(backData);

  useEffect(() => {
    if (!data?.length) {
      form.setFieldValue('language', 'pol');
    }
  }, [data]);

  const sendAbout = async () => {
    const sendData = formatAboutToBack(form.getFieldsValue());
    const profile_descriptions = formatAboutDataToSendFormat(
      backData,
      sendData,
    );

    const query = {
      profile_descriptions,
    };

    if (JSON.stringify(sendData) === JSON.stringify(backData)) {
      nextStep();
    } else {
      setLoading(true);
      try {
        const {
          data: { card },
        } = await editProfile(query);
        localDispatch({ type: 'SET_USER_INFO', payload: card });
        setError(false);
        nextStep();
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
  };

  const filteredLanguages = useMemo(
    () => languages.filter((language) => data
      .every((description) => description.language !== language.value)),
    [languages, data],
  );

  const languageLabel = useMemo(
    () => (item) => languages.find((language) => language.value === item.language)?.label
      || item.language,
    [languages],
  );

  const addDescription = () => {
    const values = form.getFieldsValue(['language', 'description']);
    if (values.language && values.description) {
      setData([
        ...data,
        { language: values.language, description: values.description },
      ]);
      form.resetFields(['language', 'description']);
    }
  };

  if (error) {
    return <span>{t('common.error')}</span>;
  }

  return (
    <Form
      form={form}
      onFinish={addDescription}
      autoComplete="off"
      className={`${styles.form} ${styles.about}`}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Form.Item
            dependencies={['description']}
            style={{
              marginBottom: '0',
              maxWidth: '410px',
              width: '100%',
              textAlign: 'end',
            }}
          >
            {() => {
              const dependentValue = form.getFieldValue('description');
              return (
                <Button
                  type="primary"
                  className={`${styles.button} ${styles.primary}`}
                  onClick={sendAbout}
                  disabled={dependentValue}
                >
                  {t('common.next')}
                </Button>
              );
            }}
          </Form.Item>
        </div>
      )}
      <div className={styles.formWrapper}>
        {data.map((item) => (
          <div className={styles.formItem} key={item.language}>
            <div className={styles.oneLineItem}>
              <Form.Item
                name={item.language}
                className={styles.selectLanguage}
                initialValue={item.language}
              >
                <span>{languageLabel(item)}</span>
              </Form.Item>
              <Button
                className={`${styles.button} ${styles.secondary}`}
                onClick={() => {
                  setData((prev) => prev.filter((el) => el.language !== item.language));
                }}
              >
                {t('common.delete')}
              </Button>
            </div>
            <Form.Item
              name={`${item.language}_description`}
              rules={[
                {
                  required: true,
                  message: t('errors.enterMessage'),
                },
              ]}
              initialValue={item.description}
              className="about"
            >
              <TextArea
                placeholder={t('pages.profileCreation.about.1')}
                maxLength={2000}
                showCount
                className={styles.textArea}
                style={{ minWidth: '100%' }}
                allowClear
              />
            </Form.Item>
          </div>
        ))}
        {data && data.length < 5 && filteredLanguages.length > 0 && (
          <div className={styles.formItem} ref={addDescriptionButton}>
            <Select
              form={form}
              title="language"
              options={filteredLanguages}
              scrollHeight={isMobile ? 70 : 0}
              noLabel
              placeholder={t('pages.profileCreation.about.4')}
              onSelect={() => form.resetFields(['description'])}
            />
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: t('errors.enterMessage'),
                },
              ]}
              className="about"
            >
              <TextArea
                placeholder={t('pages.profileCreation.about.1')}
                maxLength={2000}
                showCount
                className={styles.textArea}
                style={{ minWidth: '100%' }}
                allowClear
              />
            </Form.Item>
          </div>
        )}
        {!!filteredLanguages.length && !!(5 - data.length) && (
          <div className={`${styles.buttonBlock}`}>
            <Form.Item
              shouldUpdate
              style={{ marginBottom: '0', width: '100%' }}
            >
              {() => {
                const isDisabled = !form.getFieldValue('description')
                  || !form.getFieldValue('language')
                  || !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length;
                return (
                  <Button
                    htmlType="submit"
                    className={`${styles.button} ${styles.secondary} ${
                      styles.addDescription
                    } ${isDisabled ? styles.disabled : ''}`}
                    onClick={() => {
                      if (isDisabled) {
                        const addDescriptionButtonElement = addDescriptionButton.current;
                        const scrollOffset = addDescriptionButtonElement.offsetTop + 300;
                        window.scrollTo({
                          top: scrollOffset,
                          behavior: 'smooth',
                        });
                      }
                    }}
                    style={{ fontSize: '19px' }}
                  >
                    {data.length === 4
                      ? t('common.save')
                      : t('pages.profileCreation.about.2')}
                  </Button>
                );
              }}
            </Form.Item>
            <p>{t('pages.profileCreation.about.3')}</p>
          </div>
        )}
      </div>
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Form.Item
            dependencies={['description']}
            style={{
              marginBottom: '0',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {() => {
              const dependentValue = form.getFieldValue('description');
              return (
                <Button
                  type="primary"
                  className={`${styles.button} ${styles.primary}`}
                  onClick={sendAbout}
                  disabled={dependentValue}
                >
                  {t('common.next')}
                </Button>
              );
            }}
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

About.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
};

export { About };
