import React, { forwardRef, useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { createProfileAlbum } from 'api/requests/profileAlbums';
import { reactAppPublicApiUrl } from 'config';

import { usePublicVersion } from 'hooks/usePublicVersion';
import { useConvertToJpeg } from 'hooks/useConvertToJpeg';

import { PhotoAlbumModal } from './components/PhotoAlbumModal';
import { DeleteIcon } from 'components/ActiveIcon/DeleteIcon';
import { Button } from 'components/Button';

import plus from 'assets/plus.svg';
import logo from 'assets/logo-white.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import styles from './styled.module.css';

const PhotoAlbum = forwardRef(
  (
    {
      photoAlbumId,
      photoAlbum,
      setPhotoAlbumId,
      setPhotoAlbum,
      setBackDataAlbum,
    },
    ref,
  ) => {
    const maxItem = 15;

    const { isPublicVersion } = usePublicVersion();
    const { convertToJPEG } = useConvertToJpeg();
    const { t } = useTranslation();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [deleteAlbumModalOpen, setDeleteAlbumModalOpen] = useState(false);

    const handleImageChange = async (e) => {
      const files = Array.from(e.target.files).slice(
        0,
        maxItem - photoAlbum.length,
      );
      const newImages = await Promise.all(
        files.map(async (file) => {
          const formatFile = await convertToJPEG(file);
          return URL.createObjectURL(formatFile);
        }),
      );
      setPhotoAlbum((prevImages) => [...prevImages, ...newImages]);
    };

    const handlePreview = async (e) => {
      setPreviewImage(
        typeof e === 'string' ? e : `${reactAppPublicApiUrl}${e.image_url}`,
      );
      setPreviewOpen(true);
    };

    const setCreateAlbum = async () => {
      try {
        const {
          data: { id },
        } = await createProfileAlbum({ title: 'album' });
        setPhotoAlbumId(id);
      } catch {
        console.log('create albums has problem');
      }
    };

    return (
      <>
        {photoAlbumId ? (
          <>
            <div className={styles.row} ref={ref}>
              <span>{t('components.photoAlbum.5', { value: maxItem })}</span>
              <Button
                primary
                className={styles.button}
                onClick={() => setDeleteAlbumModalOpen(true)}
              >
                {t('components.photoAlbum.4')}
              </Button>
            </div>

            <div className={styles.wrapper}>
              {photoAlbumId && photoAlbum?.length < maxItem && (
                <div className={styles.inputBlock}>
                  <input
                    type="file"
                    name=""
                    id="photoInput"
                    multiple
                    accept="image/png, image/jpeg, image/heic, image/webp, image/heif, image/avif"
                    onChange={handleImageChange}
                    className={styles.input}
                  />
                  <div className={styles.inputContent}>
                    <img
                      className={styles.noAvatar}
                      src={isPublicVersion ? publicLogo : logo}
                      alt="Add"
                    />
                    {t('components.photoAlbum.6')}
                  </div>
                </div>
              )}
              {photoAlbum?.map((image) => (
                <div
                  className={styles.image}
                  key={typeof image === 'string' ? image : image.id}
                  onClick={() => handlePreview(image)}
                >
                  <DeleteIcon
                    className={styles.close}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (typeof image === 'string') {
                        setPhotoAlbum((prevImages) => prevImages.filter((item) => item !== image));
                      } else {
                        setPhotoAlbum((prevImages) => prevImages
                          .filter((item) => item.id !== image.id));
                      }
                    }}
                  />
                  <img
                    src={
                      typeof image === 'string'
                        ? image
                        : `${reactAppPublicApiUrl}${image.image_url}`
                    }
                    alt="aaa"
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <p className={styles.row} ref={ref}>
              {t('pages.profileCreation.photos.4')}
            </p>
            <div className={styles.emptyBlockWrapper}>
              <div className={styles.emptyBlock1} onClick={setCreateAlbum}>
                <img className={styles.noAvatar} src={plus} alt="Add" />
                {t('components.photoAlbum.1')}
              </div>
              <div className={styles.emptyBlock2} />
              <div className={styles.emptyBlock3} />
            </div>
          </>
        )}
        <Modal
          open={previewOpen}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>

        <PhotoAlbumModal
          open={deleteAlbumModalOpen}
          setOpen={setDeleteAlbumModalOpen}
          photoAlbumId={photoAlbumId}
          setPhotoAlbum={setPhotoAlbum}
          setPhotoAlbumId={setPhotoAlbumId}
          setBackDataAlbum={setBackDataAlbum}
        />
      </>
    );
  },
);

PhotoAlbum.propTypes = {
  photoAlbumId: PropTypes.number,
  photoAlbum: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      position: PropTypes.number,
      file: PropTypes.shape({
        filename: PropTypes.string,
        io: PropTypes.string,
      }),
    }),
  ),
  setPhotoAlbum: PropTypes.func.isRequired,
  setPhotoAlbumId: PropTypes.func.isRequired,
  setBackDataAlbum: PropTypes.func.isRequired,
};

export { PhotoAlbum };
