import { isProductionVisible } from 'config';
import { useState, useEffect } from 'react';

const usePublicVersion = () => {
  const lng = localStorage.getItem('lng');
  const [isPublicVersion, setIsPublicVersion] = useState(false);

  useEffect(() => {
    if (!isProductionVisible && lng === 'pu') {
      setIsPublicVersion(true);
    } else {
      setIsPublicVersion(false);
    }
  }, [lng]);

  return { isPublicVersion };
};

export { usePublicVersion };
