import { Card } from 'antd';
import {
  bool, number, shape, string,
} from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { duration } from 'utils/filters';
import { paths } from 'constants';
import { profileStatus } from 'constants/profileStatus';
import { useActualDistrict } from 'hooks/useActualDictrict';
import { useLocalContext } from 'LocalContext';
import { setVisitorLike } from 'api/requests/visitor';
import { reactAppPublicApiUrl } from '../../config';

import { Spinner } from 'components/Spinner';
import { LikeIcon } from 'components/ActiveIcon/LikeIcon';
import { VerifiedIcon } from 'components/ActiveIcon/VerifiedIcon';
import albumCountImage from 'assets/albumCount.svg';
import top from 'assets/top.svg';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import styles from './styled.module.css';
import './styles.css';

const InnerCard = ({
  data,
  scroll = true,
  albumCount = 0,
  weeklyOffer = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { userInfo, user },
    localDispatch,
  } = useLocalContext();

  const [imageLoading, setImageLoading] = useState(false);

  const actualDistrict = useActualDistrict(data.city, data.district);
  const isLike = (userInfo?.favorites?.map((item) => item.id) || []).includes(
    data.id,
  );
  const actualDuration = duration.find((item) => item.value === data.duration)?.label || '';

  const [like, setLike] = useState(isLike);

  useEffect(() => {
    if (!user) {
      setLike(false);
    }
  }, [user]);

  const setLikeForProfile = async () => {
    const query = {
      favorite: {
        id: data.id,
      },
    };
    try {
      const res = await setVisitorLike(query);
      setLike(!like);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: { ...userInfo, favorites: res.data.favorites },
      });
    } catch (error) {
      console.log('error');
    }
  };

  return (
    <Link
      onClick={(e) => {
        if (!scroll) {
          e.preventDefault();
        }
      }}
      to={
        !scroll || (data.status && data.status !== profileStatus.published)
          ? '.'
          : `/${data.id_name}`
      }
    >
      <Card
        hoverable
        className={`${styles.cardWrapper} ${
          data.status && data?.status !== profileStatus.published
            ? styles.disabled
            : ''
        }`}
        cover={(
          <>
            {data.status && data.status !== profileStatus.published && (
              <div className={styles.styledimgDark} />
            )}

            <div
              className={styles.styledimg}
              style={{ display: imageLoading && 'none' }}
            >
              <Spinner className={styles.spinner} />
            </div>

            <img
              alt="avatar"
              src={`${reactAppPublicApiUrl}${data.avatar}`}
              className={styles.styledimg}
              style={{ display: !imageLoading && 'none' }}
              loading="eager"
              onLoad={() => setImageLoading(true)}
            />
          </>
        )}
        rootClassName="innerCard"
      >
        {data?.verified && imageLoading && (
          <VerifiedIcon className={styles.verified} />
        )}
        {weeklyOffer && <img src={top} alt="top" className={styles.top} />}
        {userInfo?.user_type !== userProvider && imageLoading && (
          <LikeIcon
            className={`${styles.like} ${like ? styles.active : ''}`}
            onClick={async (e) => {
              e.preventDefault();
              if (userInfo?.user_type === userVisitor) {
                await setLikeForProfile();
              } else {
                navigate(paths.registration, {
                  state: { userType: userVisitor },
                });
              }
            }}
          />
        )}
        {!!albumCount && imageLoading && (
          <div
            className={`${styles.albumCount} ${
              data?.discount ? styles.withDiscount : ''
            }`}
          >
            <img src={albumCountImage} alt="albumCountImage" />
            <p>{albumCount}</p>
          </div>
        )}
        {data?.discount && imageLoading && (
          <div
            className={`${styles.discountLine} ${
              data?.discount >= 50 ? styles.high : ''
            }`}
          >
            -
            {data?.discount}
            % per
            {' '}
            {actualDuration}
          </div>
        )}
        {data.status && data.status !== profileStatus.published ? (
          <div className={styles.description}>
            <span className={styles.nickname}>{data.nickname}</span>
            <p className={styles.notAvailable}>{t('common.notAvailable')}</p>
          </div>
        ) : (
          <>
            <div className={styles.description}>
              <span className={styles.nickname}>{data.nickname}</span>
              <p className={styles.city}>{data.city}</p>
              {actualDistrict && (
                <p className={styles.district}>{actualDistrict}</p>
              )}
              <p className={styles.age}>{`${t('common.age')}: ${data.age}`}</p>
            </div>
            <div
              className={`${styles.price} ${
                data?.discount ? styles.discount : ''
              }`}
            >
              <span>{`${data.amount} ${data.currency}`}</span>
              {data?.discount && (
                <span>{`${data?.amount_after_discount} ${data.currency}`}</span>
              )}
              <span className={styles.time}>
                {'/ '}
                {actualDuration}
              </span>
            </div>
          </>
        )}
      </Card>
    </Link>
  );
};

InnerCard.propTypes = {
  data: shape({
    id_name: string.isRequired,
    avatar: string.isRequired,
    nickname: string.isRequired,
    amount: number.isRequired,
    currency: string.isRequired,
    city: string.isRequired,
    district: string,
    age: number.isRequired,
  }).isRequired,
  scroll: bool,
  albumCount: number,
  weeklyOffer: bool,
};

export { InnerCard };
