import aquarius from 'assets/zodiaс/aquarius.svg';
import aries from 'assets/zodiaс/aries.svg';
import cancer from 'assets/zodiaс/cancer.svg';
import capricorn from 'assets/zodiaс/capricorn.svg';
import gemini from 'assets/zodiaс/gemini.svg';
import leo from 'assets/zodiaс/leo.svg';
import libra from 'assets/zodiaс/libra.svg';
import pisces from 'assets/zodiaс/pisces.svg';
import sagittarius from 'assets/zodiaс/sagittarius.svg';
import scorpio from 'assets/zodiaс/scorpio.svg';
import taurus from 'assets/zodiaс/taurus.svg';
import virgo from 'assets/zodiaс/virgo.svg';
import bitcoin from 'assets/virtualCurrencies/bitcoin.svg';
import ethereum from 'assets/virtualCurrencies/ethereum.svg';
import tron from 'assets/virtualCurrencies/tron.svg';
import tether from 'assets/virtualCurrencies/tether.svg';
import usdttrc20 from 'assets/virtualCurrencies/usdttrc20.svg';
import xrp from 'assets/virtualCurrencies/xrp.svg';
import litcoin from 'assets/virtualCurrencies/litcoin.svg';
import usdc from 'assets/virtualCurrencies/ethereumerc20.svg';
import cardano from 'assets/virtualCurrencies/cardano.svg';
import daierc20 from 'assets/virtualCurrencies/daierc20.svg';

import female from 'assets/female.svg';
import male from 'assets/male.svg';
import trans from 'assets/trans.svg';

import whatsapp from 'assets/messengers/whatsapp.svg';
import telegram from 'assets/messengers/telegram.svg';
import signal from 'assets/messengers/signal.svg';
import facetime from 'assets/messengers/facetime.svg';
import viber from 'assets/messengers/viber.svg';
import instagram from 'assets/messengers/instagram.svg';
import tiktok from 'assets/messengers/tiktok.svg';
import snapchat from 'assets/messengers/snapchat.svg';
import onlyFans from 'assets/messengers/onlyfans.svg';
import fansly from 'assets/messengers/fansly.svg';
import twitter from 'assets/messengers/twitter.svg';

export const duration = [
  { value: 'half_hour', label: `${30} min`, id: 0.5 },
  { value: 'hour', label: `${1} hr`, id: 1 },
  { value: 'two_hours', label: `${2} hr`, id: 2 },
  { value: 'three_hours', label: `${3} hr`, id: 3 },
  { value: 'six_hours', label: `${6} hr`, id: 6 },
  { value: 'half_day', label: `${12} hr`, id: 12 },
  { value: 'day', label: `${24} hr`, id: 24 },
  { value: 'two_days', label: `${48} hr`, id: 48 },
];

export const ethnicity = [
  { value: 'arabian', label: 'Arabian' },
  { value: 'asian', label: 'Asian' },
  { value: 'ebony', label: 'Ebony' },
  { value: 'european', label: 'European' },
  { value: 'indian', label: 'Indian' },
  { value: 'latin', label: 'Latin' },
  { value: 'mongolia', label: 'Mongolia' },
  { value: 'mixed', label: 'Mixed' },
];

export const nationality = [
  { value: 'Albanian', label: 'Albanian' },
  { value: 'American', label: 'American' },
  { value: 'Argentinian', label: 'Argentinian' },
  { value: 'Austrian', label: 'Austrian' },
  { value: 'Belarussian', label: 'Belarussian' },
  { value: 'Belgain', label: 'Belgain' },
  { value: 'Brazilian', label: 'Brazilian' },
  { value: 'British', label: 'British' },
  { value: 'Bulgarian', label: 'Bulgarian' },
  { value: 'Canadian', label: 'Canadian' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Colombian', label: 'Colombian' },
  { value: 'Croatian', label: 'Croatian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'English', label: 'English' },
  { value: 'Esonian', label: 'Esonian' },
  { value: 'Filipino', label: 'Filipino' },
  { value: 'French', label: 'French' },
  { value: 'German', label: 'German' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Indonesian', label: 'Indonesian' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Latvian', label: 'Latvian' },
  { value: 'Lebanese', label: 'Lebanese' },
  { value: 'Lithuanian', label: 'Lithuanian' },
  { value: 'Malaysian', label: 'Malaysian' },
  { value: 'Maltese', label: 'Maltese' },
  { value: 'Mexican', label: 'Mexican' },
  { value: 'Moldovan', label: 'Moldovan' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Portuguese', label: 'Portuguese' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Singaporean', label: 'Singaporean' },
  { value: 'Slovak', label: 'Slovak' },
  { value: 'South Аfrican', label: 'South Аfrican' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Venezuelan', label: 'Venezuelan' },
  { value: 'Vietnamese', label: 'Vietnamese' },
];

export const languages = [
  { value: 'afr', label: 'Afrikaans' },
  { value: 'alb', label: 'Albanian' },
  { value: 'amh', label: 'Amharic' },
  { value: 'anp', label: 'Angika' },
  { value: 'ara', label: 'Arabic' },
  { value: 'arm', label: 'Armenian' },
  { value: 'asm', label: 'Assamese' },
  { value: 'awa', label: 'Awadhi' },
  { value: 'aym', label: 'Aymara' },
  { value: 'aze', label: 'Azerbaijani' },
  { value: 'bak', label: 'Bashkir' },
  { value: 'bam', label: 'Bambara' },
  { value: 'bel', label: 'Belarusian' },
  { value: 'bem', label: 'Bemba' },
  { value: 'ben', label: 'Bengali' },
  { value: 'bos', label: 'Bosnian' },
  { value: 'bul', label: 'Bulgarian' },
  { value: 'bur', label: 'Burmese' },
  { value: 'cat', label: 'Catalan; Valencian' },
  { value: 'ceb', label: 'Cebuano' },
  { value: 'che', label: 'Chechen' },
  { value: 'chi', label: 'Chinese' },
  { value: 'cze', label: 'Czech' },
  { value: 'dan', label: 'Danish' },
  { value: 'dut', label: 'Dutch; Flemish' },
  { value: 'eng', label: 'English' },
  { value: 'est', label: 'Estonian' },
  { value: 'ewe', label: 'Ewe' },
  { value: 'fij', label: 'Fijian' },
  { value: 'fil', label: 'Filipino; Pilipino' },
  { value: 'fin', label: 'Finnish' },
  { value: 'fre', label: 'French' },
  { value: 'ful', label: 'Fulah' },
  { value: 'geo', label: 'Georgian' },
  { value: 'ger', label: 'German' },
  { value: 'gle', label: 'Irish' },
  { value: 'gre', label: 'Greek, Modern (1453-)' },
  { value: 'grn', label: 'Guarani' },
  { value: 'guj', label: 'Gujarati' },
  { value: 'hat', label: 'Haitian; Haitian Creole' },
  { value: 'hau', label: 'Hausa' },
  { value: 'heb', label: 'Hebrew' },
  { value: 'hin', label: 'Hindi' },
  { value: 'hrv', label: 'Croatian' },
  { value: 'hun', label: 'Hungarian' },
  { value: 'ibo', label: 'Igbo' },
  { value: 'ind', label: 'Indonesian' },
  { value: 'ita', label: 'Italian' },
  { value: 'jav', label: 'Javanese' },
  { value: 'jpn', label: 'Japanese' },
  { value: 'kan', label: 'Kannada' },
  { value: 'kas', label: 'Kashmiri' },
  { value: 'kaz', label: 'Kazakh' },
  { value: 'khm', label: 'Central Khmer' },
  { value: 'kin', label: 'Kinyarwanda' },
  { value: 'kir', label: 'Kirghiz; Kyrgyz' },
  { value: 'kon', label: 'Kongo' },
  { value: 'kor', label: 'Korean' },
  { value: 'kur', label: 'Kurdish' },
  { value: 'lao', label: 'Lao' },
  { value: 'lav', label: 'Latvian' },
  { value: 'lim', label: 'Limburgan; Limburger; Limburgish' },
  { value: 'lin', label: 'Lingala' },
  { value: 'lit', label: 'Lithuanian' },
  { value: 'ltz', label: 'Luxembourgish; Letzeburgesch' },
  { value: 'mac', label: 'Macedonian' },
  { value: 'mad', label: 'Madurese' },
  { value: 'mag', label: 'Magahi' },
  { value: 'mai', label: 'Maithili' },
  { value: 'mal', label: 'Malayalam' },
  { value: 'man', label: 'Mandingo' },
  { value: 'mar', label: 'Marathi' },
  { value: 'may', label: 'Malay' },
  { value: 'mdr', label: 'Mandar' },
  { value: 'mlg', label: 'Malagasy' },
  { value: 'mon', label: 'Mongolian' },
  { value: 'mos', label: 'Mossi' },
  { value: 'nor', label: 'Norwegian' },
  { value: 'nso', label: 'Pedi; Sepedi; Northern Sotho' },
  { value: 'ori', label: 'Oriya' },
  { value: 'orm', label: 'Oromo' },
  { value: 'pan', label: 'Panjabi; Punjabi' },
  { value: 'per', label: 'Persian' },
  { value: 'pol', label: 'Polish' },
  { value: 'por', label: 'Portuguese' },
  { value: 'pus', label: 'Pushto; Pashto' },
  { value: 'rom', label: 'Romany' },
  { value: 'rum', label: 'Romanian; Moldavian; Moldovan' },
  { value: 'run', label: 'Rundi' },
  { value: 'rus', label: 'Russian' },
  { value: 'sat', label: 'Santali' },
  { value: 'sin', label: 'Sinhala; Sinhalese' },
  { value: 'slo', label: 'Slovak' },
  { value: 'slv', label: 'Slovenian' },
  { value: 'sna', label: 'Shona' },
  { value: 'som', label: 'Somali' },
  { value: 'sot', label: 'Sotho, Southern' },
  { value: 'spa', label: 'Spanish; Castilian' },
  { value: 'srp', label: 'Serbian' },
  { value: 'sun', label: 'Sundanese' },
  { value: 'swa', label: 'Swahili' },
  { value: 'swe', label: 'Swedish' },
  { value: 'tam', label: 'Tamil' },
  { value: 'tel', label: 'Telugu' },
  { value: 'tgk', label: 'Tajik' },
  { value: 'tha', label: 'Thai' },
  { value: 'tpi', label: 'Tok Pisin' },
  { value: 'tsn', label: 'Tswana' },
  { value: 'tuk', label: 'Turkmen' },
  { value: 'tum', label: 'Tumbuka' },
  { value: 'tur', label: 'Turkish' },
  { value: 'ukr', label: 'Ukrainian' },
  { value: 'umb', label: 'Umbundu' },
  { value: 'urd', label: 'Urdu' },
  { value: 'uzb', label: 'Uzbek' },
  { value: 'vie', label: 'Vietnamese' },
  { value: 'xho', label: 'Xhosa' },
  { value: 'yor', label: 'Yoruba' },
  { value: 'zul', label: 'Zulu' },
];

export const hairColor = [
  {
    value: 'blonde',
    color: '#F5F4E4',
    label: 'Blonde',
  },
  {
    value: 'black',
    color: '#1B191C',
    label: 'Black',
  },
  {
    value: 'brown',
    color: '#79350F',
    label: 'Brown',
  },
  {
    value: 'ginger',
    color: '#D46424',
    label: 'Ginger',
  },
  {
    value: 'red',
    color: '#C00A0A',
    label: 'Red',
  },
  {
    value: 'rare',
    color:
      'linear-gradient(131.19deg, #FF1A7A 18.47%, #11790F 47.6%, rgba(53, 169, 235, 0.83) 79.37%)',
    label: 'Rare',
  },
];

export const eyeColor = [
  {
    value: 'blue',
    color: '#5DC9EB',
    label: 'Blue',
  },
  {
    value: 'blue_green',
    color: '#14BBBB',
    label: 'Blue-green',
  },
  {
    value: 'brown',
    color: '#79350F',
    label: 'Brown',
  },
  {
    value: 'black',
    color: '#1B191C',
    label: 'Black',
  },
  {
    value: 'hazel',
    color: '#D9914F',
    label: 'Hazel',
  },
  {
    value: 'red',
    color: '#ed0707',
    label: 'Red',
  },
  {
    value: 'green',
    color: '#216012',
    label: 'Green',
  },
  {
    value: 'grey',
    color: '#909090',
    label: 'Grey',
  },
  {
    value: 'other',
    color:
      'linear-gradient(131.19deg, #FF1A7A 18.47%, #11790F 47.6%, rgba(53, 169, 235, 0.83) 79.37%)',
    label: 'Other',
  },
];

export const hairLength = [
  {
    value: 'long',
    label: 'Long hair',
  },
  {
    value: 'medium_long',
    label: 'Medium long',
  },
  {
    value: 'short',
    label: 'Short hair',
  },
  {
    value: 'shaved',
    label: 'Shaved',
  },
];

export const hairPubic = [
  {
    value: 'shaved',
    label: 'Shaved',
  },
  {
    value: 'trimmed',
    label: 'Trimmed',
  },
  {
    value: 'natural',
    label: 'Natural',
  },
];

export const breastCupSize = [
  {
    value: 'a',
    label: 'A/1',
  },
  {
    value: 'b',
    label: 'B/2',
  },
  {
    value: 'c',
    label: 'C/3',
  },
  {
    value: 'd',
    label: 'D/4',
  },
  {
    value: 'e',
    label: 'E/5',
  },
  {
    value: 'f',
    label: 'F/6+',
  },
];

export const breastType = [
  {
    value: 'natural',
    label: 'Natural',
  },
  {
    value: 'silicon',
    label: 'Silicon',
  },
];

export const travel = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'countrywide',
    label: 'Countrywide',
  },
  {
    value: 'europe',
    label: 'Europe',
  },
  {
    value: 'worldwide',
    label: 'Worldwide',
  },
];

export const weightType = [
  {
    value: 'kg',
    label: 'Kg',
  },
  {
    value: 'lbs',
    label: 'Lbs',
  },
];

export const heightType = [
  {
    value: 'cm',
    label: 'Cm',
  },
  {
    value: 'ft',
    label: 'Ft',
  },
];

export const smoking = [
  {
    value: 'no',
    label: 'No',
  },
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'occasionally',
    label: 'Occasionally',
  },
];

export const tattoo = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const available = [
  {
    value: 'outcall',
    label: 'Outcall',
  },
  {
    value: 'incall',
    label: 'Incall',
  },
];

export const piercing = [
  { value: 'none', label: 'No' },
  { value: 'navel', label: 'Belly' },
  { value: 'eyebrow', label: 'Eyebrow' },
  { value: 'genitals', label: 'Genitals' },
  { value: 'nose', label: 'Nose' },
  { value: 'nipple', label: 'Nipple' },
  { value: 'tongue', label: 'Tongue' },
  { value: 'lip', label: 'Lip' },
  { value: 'ears', label: 'Ears' },
  { value: 'other', label: 'Other' },
];

export const services = [
  {
    value: 1,
    label: 'Unlim Sexual Intercourses per Hr',
    description: 1,
  },
  {
    value: 2,
    label: '1 Sexual Intercourse per Hr',
  },
  {
    value: 3,
    label: '2 Sexual Intercourse per Hr',
  },
  {
    value: 4,
    label: '2M+1W',
  },
  {
    value: 5,
    label: '2W+1M',
  },
  {
    value: 6,
    label: '69 Position',
  },
  {
    value: 7,
    label: 'Anal Sex',
  },
  {
    value: 8,
    label: 'Back Massage',
    description: 8,
  },
  {
    value: 9,
    label: 'Ball Sucking',
  },
  {
    value: 10,
    label: 'Ball Busting',
    description: 10,
  },
  {
    value: 11,
    label: 'Bath Together',
  },
  {
    value: 12,
    label: 'Blowjob with Condom',
  },
  {
    value: 13,
    label: 'Blowjob without Condom',
  },
  {
    value: 14,
    label: 'Bondage',
    description: 14,
  },
  {
    value: 15,
    label: 'Body to Body Massage',
    description: 15,
  },
  {
    value: 16,
    label: 'Classic Massage',
    description: 16,
  },
  {
    value: 17,
    label: 'Classic Sex',
  },
  {
    value: 18,
    label: 'Costumes',
    description: 18,
  },
  {
    value: 19,
    label: 'Couples',
  },
  {
    value: 20,
    label: 'Couple Massage',
    description: 20,
  },
  {
    value: 21,
    label: 'Cowgirl',
  },
  {
    value: 22,
    label: 'Cuckold',
    description: 22,
  },
  {
    value: 23,
    label: 'Cuddling',
    description: 23,
  },
  {
    value: 24,
    label: 'Cum in Mouth',
  },
  {
    value: 25,
    label: 'Cum on Body',
  },
  {
    value: 26,
    label: 'Cum on Face',
  },
  {
    value: 27,
    label: 'Cunnilingus-Fingering',
  },
  {
    value: 28,
    label: 'Dating',
  },
  {
    value: 29,
    label: 'Deepthroat',
  },
  {
    value: 30,
    label: 'Dirty Talk',
    description: 30,
  },
  {
    value: 31,
    label: 'Doggystyle',
  },
  {
    value: 32,
    label: 'Domination',
    description: 32,
  },
  {
    value: 33,
    label: 'Duo Massage',
    description: 33,
  },
  {
    value: 34,
    label: 'Duo with Girl',
  },
  {
    value: 35,
    label: 'Erotic Asphyxiation',
    description: 35,
  },
  {
    value: 36,
    label: 'Erotic Massage For Men',
  },
  {
    value: 37,
    label: 'Erotic Massage for Women',
  },
  {
    value: 38,
    label: 'Erotic Massage For Couples',
  },
  {
    value: 39,
    label: 'Erotic Photos',
  },
  {
    value: 40,
    label: 'Face Fucking',
  },
  {
    value: 41,
    label: 'Facesitting',
  },
  {
    value: 42,
    label: 'Fisting',
  },
  {
    value: 43,
    label: 'Foot Fetish',
    description: 43,
  },
  {
    value: 44,
    label: 'Foot Job',
  },
  {
    value: 45,
    label: 'Foreplay',
    description: 45,
  },
  {
    value: 46,
    label: 'Femdom',
    description: 46,
  },
  {
    value: 47,
    label: 'French Kissing',
  },
  {
    value: 48,
    label: 'Gangbang',
  },
  {
    value: 49,
    label: 'GFE',
    description: 49,
  },
  {
    value: 50,
    label: 'GFE Massage',
  },
  {
    value: 51,
    label: 'Golden Shower give',
  },
  {
    value: 52,
    label: 'Golden Shower recive',
  },
  {
    value: 53,
    label: 'Group Sex',
  },
  {
    value: 54,
    label: 'Hair Pulling',
  },
  {
    value: 55,
    label: 'Handjob',
  },
  {
    value: 56,
    label: 'Hard Sex',
  },
  {
    value: 57,
    label: 'Headscissors',
    description: 57,
  },
  {
    value: 58,
    label: 'Heels',
  },
  {
    value: 59,
    label: 'Kamasutra',
  },
  {
    value: 60,
    label: 'Latex',
  },
  {
    value: 61,
    label: 'Leather',
  },
  {
    value: 62,
    label: 'Lingam Massage',
    description: 62,
  },
  {
    value: 63,
    label: 'Lomi Lomi Massage',
    description: 63,
  },
  {
    value: 64,
    label: 'Massage',
  },
  {
    value: 65,
    label: 'Masturbation',
  },
  {
    value: 66,
    label: 'Mistress',
    description: 66,
  },
  {
    value: 67,
    label: 'Mutual Masturbation',
    description: 67,
  },
  {
    value: 68,
    label: 'Nuru Massage',
    description: 68,
  },
  {
    value: 69,
    label: 'Passionate Kissing',
    description: 69,
  },
  {
    value: 70,
    label: 'Pet training',
    description: 70,
  },
  {
    value: 71,
    label: 'Porn Star Experience',
  },
  {
    value: 72,
    label: 'Pregnancy Massage',
    description: 72,
  },
  {
    value: 73,
    label: 'Professional Massage',
  },
  {
    value: 74,
    label: 'Prostate Massage',
  },
  {
    value: 75,
    label: 'Relax Massage',
  },
  {
    value: 76,
    label: 'Rimming active',
    description: 76,
  },
  {
    value: 77,
    label: 'Rimming passive',
    description: 77,
  },
  {
    value: 78,
    label: 'Role-play',
    description: 78,
  },
  {
    value: 79,
    label: 'Sex between Breasts',
  },
  {
    value: 80,
    label: 'Sex Lingerie',
    description: 80,
  },
  {
    value: 81,
    label: 'Sex Toys',
  },
  {
    value: 82,
    label: 'Sex with Couples',
  },
  {
    value: 83,
    label: 'Shoe Fetish',
    description: 83,
  },
  {
    value: 84,
    label: 'Skirts',
  },
  {
    value: 85,
    label: 'Slapping',
  },
  {
    value: 86,
    label: 'Spitting',
    description: 86,
  },
  {
    value: 87,
    label: 'Squirting',
  },
  {
    value: 88,
    label: 'Stockings',
  },
  {
    value: 89,
    label: 'Strapon',
  },
  {
    value: 90,
    label: 'Striptease',
  },
  {
    value: 91,
    label: 'Submission',
    description: 91,
  },
  {
    value: 92,
    label: 'Swallowing',
  },
  {
    value: 93,
    label: 'Swearing',
    description: 93,
  },
  {
    value: 94,
    label: 'Taking Photos',
  },
  {
    value: 95,
    label: 'Tantric Massage',
    description: 95,
  },
  {
    value: 96,
    label: 'Trampling',
    description: 96,
  },
  {
    value: 97,
    label: 'Uniforms',
  },
  {
    value: 98,
    label: 'Various Positions',
  },
  {
    value: 99,
    label: 'Video with Sex',
  },
  {
    value: 100,
    label: 'Whipping',
    description: 100,
  },
  {
    value: 101,
    label: 'With 2 Men',
  },
  {
    value: 102,
    label: 'Yoni Massage',
    description: 102,
  },
];

export const horoscrope = [
  {
    label: 'Aquarius',
    value: 'aquarius',
    image: aquarius,
  },
  {
    label: 'Pisces',
    value: 'pisces',
    image: pisces,
  },
  {
    label: 'Aries',
    value: 'aries',
    image: aries,
  },
  {
    label: 'Taurus',
    value: 'taurus',
    image: taurus,
  },
  {
    label: 'Gemini',
    value: 'gemini',
    image: gemini,
  },
  {
    label: 'Cancer',
    value: 'cancer',
    image: cancer,
  },
  {
    label: 'Leo',
    value: 'leo',
    image: leo,
  },
  {
    label: 'Virgo',
    value: 'virgo',
    image: virgo,
  },
  {
    label: 'Libra',
    value: 'libra',
    image: libra,
  },
  {
    label: 'Scorpio',
    value: 'scorpio',
    image: scorpio,
  },
  {
    label: 'Sagittarius',
    value: 'sagittarius',
    image: sagittarius,
  },
  {
    label: 'Capricorn',
    value: 'capricorn',
    image: capricorn,
  },
];

export const sex = [
  {
    value: 'female',
    label: 'Female',
    image: female,
  },
  {
    value: 'male',
    label: 'Male',
    image: male,
  },
  {
    value: 'trans',
    label: 'Trans',
    image: trans,
  },
];

export const orientation = [
  {
    value: 'hetero',
    label: 'Hetero',
  },
  {
    value: 'homo',
    label: 'Homo',
  },
  {
    value: 'bi',
    label: 'Bi',
  },
];

export const languageType = [
  {
    value: 'pol',
    label: 'Polish (POL)',
  },
  {
    value: 'eng',
    label: 'English (ENG)',
  },
  {
    value: 'rus',
    label: 'Russian (RUS)',
  },
  {
    value: 'lat',
    label: 'Latin (LAT)',
  },
  {
    value: 'lav',
    label: 'Latvian (LAV)',
  },
  {
    value: 'lit',
    label: 'Lithuanian (LIT)',
  },
];

export const days = [
  {
    value: 'monday',
    label: 'Monday',
  },
  {
    value: 'tuesday',
    label: 'Tuesday',
  },
  {
    value: 'wednesday',
    label: 'Wednesday',
  },
  {
    value: 'thursday',
    label: 'Thursday',
  },
  {
    value: 'friday',
    label: 'Friday',
  },
  {
    value: 'saturday',
    label: 'Saturday',
  },
  {
    value: 'sunday',
    label: 'Sunday',
  },
];

export const currency = [
  {
    value: 'pln',
    label: 'PLN',
  },
  {
    value: 'usd',
    label: 'USD',
  },
  {
    value: 'eur',
    label: 'EUR',
  },
];

export const virtualCurrencies = [
  {
    value: 'btc',
    image: bitcoin,
    label: 'Bitcoin',
    qrPrefix: 'bitcoin',
  },
  {
    value: 'eth',
    image: ethereum,
    label: 'Ethereum',
    qrPrefix: 'ethereum',
  },
  {
    value: 'trx',
    image: tron,
    label: 'Tron',
    qrPrefix: 'tron',
  },
  {
    value: 'usdterc20',
    image: tether,
    label: 'Tether',
  },
  {
    value: 'usdttrc20',
    image: usdttrc20,
    label: 'USDT Tron',
  },
  {
    value: 'xrp',
    image: xrp,
    label: 'XRP',
  },
  {
    value: 'ltc',
    image: litcoin,
    label: 'Litcoin',
    qrPrefix: 'litecoin',
  },
  {
    value: 'ada',
    image: cardano,
    label: 'Cardano',
    qrPrefix: 'cardano',
  },
  {
    value: 'dai',
    image: daierc20,
    label: 'DAI Ethereum',
  },
  {
    value: 'usdc',
    image: usdc,
    label: 'USDC Ethereum',
  },
];

export const offerSortDays = [
  {
    value: 8,
    label: 'Last week',
  },
  {
    value: 31,
    label: 'Last 30 days',
  },
  {
    value: 61,
    label: 'Last 60 days',
  },
  {
    value: 91,
    label: 'Last 90 days',
  },
];

export const reportTags = [
  {
    value: 'unpaid',
    label: 'Unpaid',
  },
  {
    value: 'fake_money',
    label: 'Fake money',
  },
  {
    value: 'crazy',
    label: 'Crazy',
  },
  {
    value: 'drunk',
    label: 'Drunk',
  },
  {
    value: 'junkie',
    label: 'Junkie',
  },
  {
    value: 'venereal',
    label: 'Venereal',
  },
  {
    value: 'racist',
    label: 'Racist',
  },
  {
    value: 'sexist',
    label: 'Sexist',
  },
  {
    value: 'rude',
    label: 'Rude',
  },
  {
    value: 'threatens',
    label: 'Threatens',
  },
  {
    value: 'harassment',
    label: 'Harassment',
  },
  {
    value: 'beat',
    label: 'Beat',
  },
  {
    value: 'blackmail',
    label: 'Blackmail',
  },
  {
    value: 'police',
    label: 'Police',
  },
  {
    value: 'client_under_18',
    label: 'Client under 18',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'asks_for_personal_info',
    label: 'Asks for Personal Info',
  },
];

export const reportTagsVisitor = [
  {
    value: 'brothel',
    label: 'Brothel',
  },
  {
    value: 'fake',
    label: 'Fake',
  },
  {
    value: 'robbery',
    label: 'Robbery',
  },
  {
    value: 'roofie',
    label: 'Roofie',
  },
  {
    value: 'crazy',
    label: 'Crazy',
  },
  {
    value: 'drunk',
    label: 'Drunk',
  },
  {
    value: 'junkie',
    label: 'Junkie',
  },
  {
    value: 'venereal',
    label: 'Venereal',
  },
  {
    value: 'racist',
    label: 'Racist',
  },
  {
    value: 'rude',
    label: 'Rude',
  },
  {
    value: 'threatens',
    label: 'Threatens',
  },
  {
    value: 'beat',
    label: 'Beat',
  },
  {
    value: 'blackmail',
    label: 'Blackmail',
  },
  {
    value: 'police',
    label: 'Police',
  },
  {
    value: 'client_under_18',
    label: 'Client under 18',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'asks_for_personal_info',
    label: 'Asks for Personal Info',
  },
];

export const contactUsTags = [
  {
    value: 'crypto_help',
    label: 'I did not understand the purchase of cryptocurrency',
  },
  {
    value: 'payment_problem',
    label: 'Problem with payments',
  },
  {
    value: 'credit_problem',
    label: 'Game currency not credited',
  },
  {
    value: 'identity_stolen',
    label: 'Someone is pretending to be me',
  },
  {
    value: 'copyright',
    label: 'Copyright infringement',
  },
  {
    value: 'verification_problem',
    label: "Can't get verified",
  },
  {
    value: 'blacklist',
    label: "Why I'm in blacklist",
  },
  {
    value: 'blocked',
    label: "Why I'm blocked",
  },
  {
    value: 'change_email',
    label: 'I want to change my email',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const messengers = [
  {
    label: 'WhatsApp',
    value: 'whatsapp',
    image: whatsapp,
    url: 'https://wa.me/',
    type: ['phone'],
    placeholder: { phone: 'phone' },
    text: '?text=',
  },
  {
    label: 'Telegram',
    value: 'telegram',
    image: telegram,
    type: ['phone', 'nickname'],
    url: 'https://t.me/',
    placeholder: { phone: 'phone', nickname: 'nickname' },
    pattern: /[^\w\d_\-+]/g,
    text: '?text=',
  },
  {
    label: 'Signal',
    value: 'signal',
    image: signal,
    url: 'https://signal.me/#p/',
    type: ['phone'],
    placeholder: { phone: 'phone' },
  },
  {
    label: 'FaceTime',
    value: 'facetime',
    image: facetime,
    url: 'facetime://',
    type: ['phone', 'email'],
    placeholder: { phone: 'phone', email: 'email' },
  },
  {
    label: 'Viber',
    value: 'viber',
    image: viber,
    url: 'viber://chat?number=%2B',
    type: ['phone'],
    placeholder: { phone: 'phone' },
    pattern: /\+/g,
  },
];

export const socialMedia = [
  {
    label: 'Instagram',
    value: 'instagram',
    image: instagram,
    url: 'https://www.instagram.com/',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
  {
    label: 'TikTok',
    value: 'tiktok',
    image: tiktok,
    url: 'https://www.tiktok.com/@',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
  {
    label: 'Snapchat',
    value: 'snapchat',
    image: snapchat,
    url: 'https://www.snapchat.com/',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
  {
    label: 'OnlyFans',
    value: 'onlyFans',
    image: onlyFans,
    url: 'https://onlyfans.com/',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
  {
    label: 'Fansly',
    value: 'fansly',
    image: fansly,
    url: 'https://fansly.com/',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
  {
    label: 'Twitter',
    value: 'twitter',
    image: twitter,
    url: 'https://twitter.com/',
    type: ['nickname'],
    placeholder: { nickname: 'nickname' },
  },
];
