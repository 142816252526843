export const cities = [
  { value: '', label: 'Anywhere' },
  { value: 'Warsaw', label: 'Warszawa' },
  { value: 'Krakow', label: 'Kraków' },
  { value: 'Lodz', label: 'Łódź' },
  { value: 'Wroclaw', label: 'Wrocław' },
  { value: 'Poznan', label: 'Poznań' },
  { value: 'Gdansk', label: 'Gdańsk' },
  { value: 'Szczecin', label: 'Szczecin' },
  { value: 'Bydgoszcz', label: 'Bydgoszcz' },
  { value: 'Lublin', label: 'Lublin' },
  { value: 'Bialystok', label: 'Białystok' },
  { value: 'Katowice', label: 'Katowice' },
  { value: 'Gdynia', label: 'Gdynia' },
  { value: 'Czestochowa', label: 'Częstochowa' },
  { value: 'Radom', label: 'Radom' },
  { value: 'Torun', label: 'Toruń' },
  { value: 'Rzeszow', label: 'Rzeszów' },
  { value: 'Sosnowiec', label: 'Sosnowiec' },
  { value: 'Kielce', label: 'Kielce' },
  { value: 'Gliwice', label: 'Gliwice' },
  { value: 'Olsztyn', label: 'Olsztyn' },
  { value: 'Zabrze', label: 'Zabrze' },
  { value: 'Bielsko-Biala', label: 'Bielsko-Biała' },
  { value: 'Bytom', label: 'Bytom' },
  { value: 'Zielona-Gora', label: 'Zielona Góra' },
  { value: 'Rybnik', label: 'Rybnik' },
  { value: 'Ruda-Slaska', label: 'Ruda Śląska' },
  { value: 'Opole', label: 'Opole' },
  { value: 'Tychy', label: 'Tychy' },
  { value: 'Gorzow-Wielkopolski', label: 'Gorzów Wielkopolski' },
  { value: 'Elblag', label: 'Elbląg' },
  { value: 'Dabrowa-Gornicza', label: 'Dąbrowa Górnicza' },
  { value: 'Plock', label: 'Płock' },
  { value: 'Walbrzych', label: 'Wałbrzych' },
  { value: 'Wloclawek', label: 'Włocławek' },
  { value: 'Tarnow', label: 'Tarnów' },
  { value: 'Chorzow', label: 'Chorzów' },
  { value: 'Koszalin', label: 'Koszalin' },
  { value: 'Kalisz', label: 'Kalisz' },
  { value: 'Legnica', label: 'Legnica' },
  { value: 'Grudziadz', label: 'Grudziądz' },
  { value: 'Jaworzno', label: 'Jaworzno' },
  { value: 'Slupsk', label: 'Słupsk' },
  { value: 'Jastrzebie-Zdroj', label: 'Jastrzębie Zdrój' },
  { value: 'Nowy-Sacz', label: 'Nowy Sącz' },
  { value: 'Jelenia-Gora', label: 'Jelenia Góra' },
  { value: 'Siedlce', label: 'Siedlce' },
  { value: 'Myslowice', label: 'Mysłowice' },
  { value: 'Konin', label: 'Konin' },
  { value: 'Piła', label: 'Piła' },
  { value: 'Piotrkow-Trybunalski', label: 'Piotrków Trybunalski' },
  { value: 'Lubin', label: 'Lubin' },
  { value: 'Inowroclaw', label: 'Inowrocław' },
  { value: 'Ostrow-Wielkopolski', label: 'Ostrów Wielkopolski' },
  { value: 'Suwalki', label: 'Suwałki' },
  { value: 'Stargard', label: 'Stargard' },
  { value: 'Gniezno', label: 'Gniezno' },
  { value: 'Ostrowiec-Swietokrzyski', label: 'Ostrowiec Świętokrzyski' },
  { value: 'Glogow', label: 'Głogów' },
  { value: 'Pabianice', label: 'Pabianice' },
  { value: 'Leszno', label: 'Leszno' },
  { value: 'Zory', label: 'Żory' },
  { value: 'Zamosc', label: 'Zamość' },
  { value: 'Pruszkow', label: 'Pruszków' },
  { value: 'Lomza', label: 'Łomża' },
  { value: 'Elk', label: 'Ełk' },
  { value: 'Tarnowskie-Gory', label: 'Tarnowskie Góry' },
  { value: 'Tomaszow-Mazowiecki', label: 'Tomaszów Mazowiecki' },
  { value: 'Chelm', label: 'Chełm' },
  { value: 'Mielec', label: 'Mielec' },
  { value: 'Kedzierzyn-Kozle', label: 'Kędzierzyn-Koźle' },
  { value: 'Przemysl', label: 'Przemyśl' },
  { value: 'Stalowa-Wola', label: 'Stalowa Wola' },
  { value: 'Tczew', label: 'Tczew' },
  { value: 'Biala-Podlaska', label: 'Biała Podlaska' },
  { value: 'Belchatow', label: 'Bełchatów' },
  { value: 'Swidnica', label: 'Świdnica' },
  { value: 'Bedzin', label: 'Będzin' },
  { value: 'Zgierz', label: 'Zgierz' },
  { value: 'Piekary-Slaskie', label: 'Piekary Śląskie' },
  { value: 'Raciborz', label: 'Racibórz' },
  { value: 'Legionowo', label: 'Legionowo' },
  { value: 'Ostroleka', label: 'Ostrołęka' },
  { value: 'Swietochlowice', label: 'Świętochłowice' },
  { value: 'Starachowice', label: 'Starachowice' },
  { value: 'Wejherowo', label: 'Wejherowo' },
  { value: 'Zawiercie', label: 'Zawiercie' },
  { value: 'Rumia', label: 'Rumia' },
  { value: 'Skierniewice', label: 'Skierniewice' },
  { value: 'Starogard-Gdanski', label: 'Starogard Gdańsk' },
  { value: 'Wodzislaw-Slaski', label: 'Wodzisław Śląski' },
  { value: 'Skarzysko-Kamienna', label: 'Skarżysko-Kamienna' },
  { value: 'Piaseczno', label: 'Piaseczno' },
  { value: 'Tarnobrzeg', label: 'Tarnobrzeg' },
  { value: 'Pulawy', label: 'Puławy' },
  { value: 'Debica', label: 'Dębica' },
  { value: 'Kolobrzeg', label: 'Kołobrzeg' },
  { value: 'Krosno', label: 'Krosno' },
  { value: 'Radomsko', label: 'Radomsko' },
  { value: 'Kutno', label: 'Kutno' },
  { value: 'Otwock', label: 'Otwock' },
  { value: 'Ciechanow', label: 'Ciechanów' },
  { value: 'Nysa', label: 'Nysa' },
  { value: 'Nowy-Dwor-Mazowiecki', label: 'Nowy Dwór Mazowiecki' },
  { value: 'Police', label: 'Police' },
  { value: 'Sieradz', label: 'Sieradz' },
  { value: 'Zdunska-Wola', label: 'Zduńska Wola' },
  { value: 'Zyrardow', label: 'Żyrardów' },
  { value: 'Swinoujscie', label: 'Świnoujście' },
  { value: 'Mikolow', label: 'Mikołów' },
  { value: 'Chojnice', label: 'Chojnice' },
  { value: 'Minsk-Mazowiecki', label: 'Mińsk Mazowiecki' },
  { value: 'Szczecinek', label: 'Szczecinek' },
  { value: 'Swidnik', label: 'Świdnik' },
  { value: 'Chrzanow', label: 'Chrzanów' },
  { value: 'Oswiecim', label: 'Oświęcim' },
  { value: 'Jaroslaw', label: 'Jarosław' },
  { value: 'Boleslawiec', label: 'Bolesławiec' },
  { value: 'Nowa-Sol', label: 'Nowa Sól' },
  { value: 'Malbork', label: 'Malbork' },
  { value: 'Kwidzyn', label: 'Kwidzyn' },
  { value: 'Sanok', label: 'Sanok' },
  { value: 'Knurów', label: 'Knurów' },
  { value: 'Zary', label: 'Żary' },
  { value: 'Sochaczew', label: 'Sochaczew' },
  { value: 'Olesnica', label: 'Oleśnica' },
  { value: 'Sopot', label: 'Sopot' },
  { value: 'Jaslo', label: 'Jasło' },
  { value: 'Olkusz', label: 'Olkusz' },
  { value: 'Wolomin', label: 'Wołomin' },
  { value: 'Krasnik', label: 'Kraśnik' },
  { value: 'Czechowice-Dziedzice', label: 'Czechowice-Dziedzice' },
  { value: 'Brzeg', label: 'Brzeg' },
  { value: 'Lebork', label: 'Lębork' },
  { value: 'Cieszyn', label: 'Cieszyn' },
  { value: 'Nowy-Targ', label: 'Nowy Targ' },
  { value: 'Dzierzoniow', label: 'Dzierżoniów' },
  { value: 'Ilawa', label: 'Iława' },
  { value: 'Ostróda', label: 'Ostróda' },
  { value: 'Olawa', label: 'Oława' },
  { value: 'Zabki', label: 'Ząbki' },
  { value: 'Myszkow', label: 'Myszków' },
  { value: 'Czeladz', label: 'Czeladź' },
  { value: 'Turek', label: 'Turek' },
  { value: 'Żywiec', label: 'Żywiec' },
  { value: 'Mlawa', label: 'Mława' },
  { value: 'Lukow', label: 'Łuków' },
  { value: 'Zgorzelec', label: 'Zgorzelec' },
  { value: 'Śrem', label: 'Śrem' },
  { value: 'Augustów', label: 'Augustów' },
  { value: 'Bochnia', label: 'Bochnia' },
  { value: 'Bielawa', label: 'Bielawa' },
  { value: 'Giżycko', label: 'Giżycko' },
  { value: 'Gorlice', label: 'Gorlice' },
  { value: 'Krotoszyn', label: 'Krotoszyn' },
  { value: 'Lubon', label: 'Luboń' },
  { value: 'Marki', label: 'Marki' },
  { value: 'Swarzedz', label: 'Swarzędz' },
  { value: 'Łowicz', label: 'Łowicz' },
  { value: 'Wrzesnia', label: 'Września' },
  { value: 'Brodnica', label: 'Brodnica' },
  { value: 'Czerwionka-Leszczyny', label: 'Czerwionka-Leszczyny' },
  { value: 'Skawina', label: 'Skawina' },
  { value: 'Ketrzyn', label: 'Kętrzyn' },
  { value: 'Zakopane', label: 'Zakopane' },
  { value: 'Bilgoraj', label: 'Biłgoraj' },
  { value: 'Grodzisk-Mazowiecki', label: 'Grodzisk Mazowiecki' },
  { value: 'Szczytno', label: 'Szczytno' },
  { value: 'Klodzko', label: 'Kłodzko' },
  { value: 'Pruszcz-Gdanski', label: 'Pruszcz Gdański' },
  { value: 'Wyszkow', label: 'Wyszków' },
  { value: 'Walcz', label: 'Wałcz' },
  { value: 'Ropczyce', label: 'Ropczyce' },
  { value: 'Reda', label: 'Reda' },
  { value: 'Jarocin', label: 'Jarocin' },
  { value: 'Pszczyna', label: 'Pszczyna' },
  { value: 'Zagan', label: 'Żagań' },
  { value: 'Swiecie', label: 'Świecie' },
  { value: 'Bielsk-Podlaski', label: 'Bielsk Podlaski' },
];
