import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  getMainData,
  getMainDataForVisitor,
  getModalRanges,
} from 'api/requests';
import { useLocalContext } from 'LocalContext';
import { useIsMobile } from 'hooks/useIsMobile';
import { getClearFilters } from 'utils/setInitialFilters';
import { prepareBody } from 'utils/prepareBody';
import { userVisitor } from 'constants/registrationUserType';

import { FiltersContent } from './components/FiltersContent';
import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const FiltersModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    localState: { filters, userInfo },
    localDispatch,
  } = useLocalContext();
  const showSmallModal = useIsMobile({ maxWidth: 900 });
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);

  const getActualModalRanges = async ({ total }) => {
    const query = {
      ...prepareBody(
        searchParams.get('city'),
        searchParams.get('district')?.split('__'),
        searchParams.get('gender'),
        searchParams.get('orientation'),
        searchParams.get('verified'),
        null,
        searchParams.get('page'),
        {
          ...filters,
          ...form.getFieldsValue(),
        },
      ),
    };

    setLoading(true);
    try {
      if (total) {
        if (userInfo && userInfo.user_type === userVisitor) {
          const { data } = await getMainDataForVisitor({
            ...query,
            count_total: true,
          });
          setTotalCount(data.total);
        } else {
          const { data } = await getMainData({ ...query, count_total: true });
          setTotalCount(data.total);
        }
      } else {
        const { data } = await getModalRanges(query);
        setTotalCount(data.total);
      }
    } catch {
      throw new Error('modal_ranges or catalog - total has problem');
    }
    setLoading(false);
  };

  const handleClearAll = async () => {
    const clearFilters = getClearFilters(filters);
    await localDispatch({
      type: 'SET_FILTERS',
      payload: clearFilters,
    });
    form.resetFields();
    getActualModalRanges({ total: true });
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      onClose={() => form.resetFields()}
      maxHeightModal="70dvh"
      rootClassName="filtersModal"
      widthModal={showSmallModal ? '510px' : '810px'}
      footer={(
        <div className={styles.footer}>
          <Button secondary onClick={handleClearAll}>
            {t('common.clearAll')}
          </Button>
          <Button primary form="myForm" submit>
            {!loading && <span>{t('common.showResult')}</span>}
            {!loading && <span>{totalCount}</span>}
          </Button>
        </div>
      )}
    >
      <FiltersContent
        form={form}
        open={open}
        setOpen={setOpen}
        setLoading={setLoading}
        getActualModalRanges={getActualModalRanges}
      />
    </ModalWrapper>
  );
};

FiltersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export { FiltersModal };
