import { Button, Popover, QRCode } from 'antd';
import { useMemo, useState } from 'react';
import {
  Link, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useResetPrivatePage } from 'hooks/useResetPrivatePage';

import { useLocalContext } from 'LocalContext';
import { virtualCurrencies } from 'utils/filters';
import copy from 'assets/copy.svg';
import { paths } from 'constants';

import { Timer } from 'components/Timer';
import { PaymentPageModal } from './components/PaymentPageModal';

import styles from './styled.module.css';
import './styles.css';

/*
locationState example:
{
  pay_address: string, (required)
  pay_currency: string, (required)
  price_currency: string, (required)
  price_amount: string, (required)
  pay_amount: string, (required)
  payment_id: string, (required)
  purchase_id: string, (required)
  order_id: string, (required)
  order_description: string, (required)
  expiration_estimate_date: string, (required)
}
*/

const PaymentPage = () => {
  useResetPrivatePage(paths.profileWallet);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { isPrivatePage },
  } = useLocalContext();
  const { state: locationState } = useLocation();

  const [isPriceCopy, setIsisPriceCopy] = useState(false);
  const [isTokenCopy, setIsisTokenCopy] = useState(false);
  const [isTimerEnd, setIsTimerEnd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalValue, setModalValue] = useState('');

  if (!locationState || !isPrivatePage) {
    return <Navigate to={paths.profileWallet} replace state={null} />;
  }

  const onTimerEnd = () => setIsTimerEnd(true);

  const actualCurrency = useMemo(
    () => virtualCurrencies.find(
      (item) => item.value === locationState.pay_currency,
    ),
    [],
  );

  const handleOpenChange = (isOpen, setValue) => {
    if (isOpen) {
      setTimeout(() => {
        setValue(false);
      }, 1500);
    } else {
      clearTimeout();
    }
    setValue(isOpen);
  };

  return isTimerEnd ? (
    <div className={`${styles.wrapper} ${styles.noTime}`}>
      <p>{t('pages.paymentPage.1')}</p>
      <p>{t('pages.paymentPage.2')}</p>
      <Button
        onClick={() => navigate(paths.profileWallet, { replace: true })}
        className={styles.cancelButton}
        type="link"
      >
        {t('common.goBack')}
      </Button>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <p>{t('pages.paymentPage.3')}</p>
      <p>{t('pages.paymentPage.4')}</p>
      <div className={styles.content}>
        <div className={styles.qrInfoBlock}>
          <div className={styles.qrBlock}>
            <div>
              <p className={styles.title}>{t('pages.paymentPage.5')}</p>
              <div className={styles.lineItem}>
                <div className={styles.currencyItem}>
                  <img src={actualCurrency.image} alt={actualCurrency.value} />
                  <div className={styles.currency}>
                    <span>{actualCurrency.label}</span>
                    <span>{actualCurrency.value}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.qrItem}>
              <QRCode
                type="canvas"
                value={
                  locationState
                    ? `${
                      actualCurrency.qrPrefix && `${actualCurrency.qrPrefix}:`
                    }${locationState.pay_address}?amount=${
                      locationState.pay_amount
                    }`
                    : ''
                }
                rootClassName="payment"
              />
              <Link to="/" target="_blank" className={styles.helpLink}>
                {t('pages.paymentPage.6')}
              </Link>
              <Timer onTimerEnd={onTimerEnd} />
            </div>
            <div>
              <p className={styles.title}>{t('pages.paymentPage.7')}</p>
              <div className={styles.line}>
                <div className={styles.lineItem}>
                  <div className={styles.currency}>
                    <span>
                      {locationState.pay_amount}
                      {' '}
                      {locationState.pay_currency.toUpperCase()}
                    </span>
                    <span>
                      {locationState.price_amount}
                      {' '}
                      {locationState.price_currency}
                    </span>
                  </div>
                  <Popover
                    content={`Copied: ${locationState.pay_amount}`}
                    trigger="click"
                    placement="topRight"
                    rootClassName="payment"
                    open={isPriceCopy}
                    onOpenChange={(e) => handleOpenChange(e, setIsisPriceCopy)}
                  >
                    <div
                      className={styles.copy}
                      onClick={() => navigator.clipboard.writeText(locationState.pay_amount)}
                    >
                      <img src={copy} alt="copy" />
                      <p>{t('common.copy')}</p>
                    </div>
                  </Popover>
                </div>
                <div
                  className={styles.lineQr}
                  onClick={() => {
                    setOpenModal(true);
                    setModalValue(locationState.pay_amount);
                  }}
                >
                  <QRCode
                    type="canvas"
                    value={locationState?.pay_amount || ''}
                    rootClassName="liltePayment"
                    color="#757575"
                  />
                </div>
              </div>
            </div>
            <div>
              <p className={styles.title}>{t('common.address')}</p>
              <div className={styles.line}>
                <div className={styles.lineItem}>
                  <div className={`${styles.currency} ${styles.token}`}>
                    <span>{locationState.pay_address}</span>
                  </div>
                  <Popover
                    content={(
                      <div className={styles.popover}>
                        <span>Copied:</span>
                        <span>{locationState.pay_address}</span>
                      </div>
                    )}
                    trigger="click"
                    placement="topRight"
                    rootClassName="payment"
                    open={isTokenCopy}
                    onOpenChange={(e) => handleOpenChange(e, setIsisTokenCopy)}
                  >
                    <div
                      className={styles.copy}
                      onClick={() => navigator.clipboard.writeText(locationState.pay_address)}
                    >
                      <img src={copy} alt="copy" />
                      <p>{t('common.copy')}</p>
                    </div>
                  </Popover>
                </div>
                <div
                  className={styles.lineQr}
                  onClick={() => {
                    setOpenModal(true);
                    setModalValue(locationState.pay_address);
                  }}
                >
                  <QRCode
                    type="canvas"
                    value={`${locationState?.pay_address}`}
                    rootClassName="liltePayment"
                    color="#757575"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.infoBlock}>
            <p className={styles.infoTitle}>Additional information</p>
            <div className={styles.infoDetails}>
              <p className={styles.infoTitle}>Payment details</p>
              <div className={styles.infoLine}>
                <p>{t('common.price')}</p>
                <p>
                  {locationState.price_amount}
                  {' '}
                  {locationState.price_currency.toUpperCase()}
                </p>
              </div>
              <div className={styles.infoLine}>
                <p>{t('common.total')}</p>
                <p>
                  {locationState.pay_amount}
                  {' '}
                  {locationState.pay_currency.toUpperCase()}
                </p>
              </div>
            </div>
            <div className={styles.infoDetails}>
              <p className={styles.infoTitle}>{t('common.orderSummary')}</p>
              <div className={styles.infoLine}>
                <p>{t('common.order')}</p>
                <p>ToLips.link</p>
              </div>
              <div className={styles.infoLine}>
                <p>{t('pages.paymentPage.8')}</p>
                <p>{locationState.payment_id}</p>
              </div>
              <div className={styles.infoLine}>
                <p>{t('common.description')}</p>
                <p>{locationState.order_description}</p>
              </div>
            </div>
            <div className={`${styles.infoLine} ${styles.infoPrice}`}>
              <p>{t('common.price')}</p>
              <p>
                {locationState.price_amount}
                {' '}
                {locationState.price_currency.toUpperCase()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Button
        onClick={() => navigate(paths.profileWallet, { replace: true })}
        className={styles.cancelButton}
        type="link"
      >
        {t('common.cancelPayment')}
      </Button>
      <PaymentPageModal
        open={openModal}
        setOpen={setOpenModal}
        value={modalValue}
      />
    </div>
  );
};

export { PaymentPage };
