import React from 'react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import PropTypes from 'prop-types';

import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import './styles.css';

const TimerFlipClock = ({
  time, setTimerState, up, hotDeal, weeklyOffer,
}) => (
  <FlipClockCountdown
    className="timerFilpClock"
    to={time}
    dividerStyle={{ color: 'rgba(99, 57, 118, 1)' }}
    onComplete={() => {
      if (up) {
        setTimerState((prev) => ({ ...prev, up: true }));
      }
      if (hotDeal) {
        setTimerState((prev) => ({ ...prev, hotDeal: true }));
      }
      if (weeklyOffer) {
        setTimerState((prev) => ({ ...prev, weeklyOffer: true }));
      }
    }}
  />
);

TimerFlipClock.propTypes = {
  setTimerState: PropTypes.func,
  up: PropTypes.bool,
  hotDeal: PropTypes.bool,
  weeklyOffer: PropTypes.bool,
  time: PropTypes.number,
};

export { TimerFlipClock };
