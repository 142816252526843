import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';

import { usePublicVersion } from 'hooks/usePublicVersion';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { LoginModal } from 'components/LoginModal';
import { RegistrationModal } from 'components/RegistrationModal';
import { LanguagesDropDown } from './components/LanguagesDropDown';
import { UserDropDown } from './components/UserDropDown';
import { InfoModal } from 'pages/ProfileCreation/components/InfoModal';

import styles from './styled.module.css';

const Header = () => {
  const { t } = useTranslation();
  const { localState } = useLocalContext();
  const { isPublicVersion } = usePublicVersion();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerContainer}>
        {pathname !== paths.profileCreation ? (
          <Link to={paths.main} className={styles.logo}>
            <img
              src={isPublicVersion ? publicLogo : logo}
              alt="logo"
              style={{ width: '38px' }}
            />
            <h3 className={styles.logoTitle}>
              {isPublicVersion ? 'YourFlowers' : 'ToLips'}
            </h3>
          </Link>
        ) : (
          <div
            className={styles.logo}
            onClick={() => {
              if (pathname === paths.profileCreation) {
                setOpenInfoModal(true);
              }
            }}
          >
            <img
              src={isPublicVersion ? publicLogo : logo}
              alt="logo"
              style={{ width: '38px' }}
            />
            <h3 className={styles.logoTitle}>
              {isPublicVersion ? 'YourFlowers' : 'ToLips'}
            </h3>
          </div>
        )}
        <div className={styles.category}>
          <LanguagesDropDown />
          {localState.user ? (
            <UserDropDown />
          ) : (
            <>
              <h3
                className={styles.userBlock}
                onClick={() => setOpenLoginModal(true)}
              >
                {t('common.login')}
              </h3>
              <h3
                className={styles.userBlock}
                onClick={() => setOpenRegistrationModal(true)}
              >
                {t('common.join')}
              </h3>
            </>
          )}
        </div>
      </div>
      <LoginModal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        setOpenRegistrationModal={setOpenRegistrationModal}
      />
      <RegistrationModal
        open={openRegistrationModal}
        setOpen={setOpenRegistrationModal}
        setOpenLoginModal={setOpenLoginModal}
      />
      <InfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        onClick={() => navigate(paths.main)}
      />
    </div>
  );
};
export { Header };
