import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setLiftingUp } from 'api/requests/lifting';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { priceForUp } from 'constants/prices';
import { verificationStatus } from 'constants/verificationStatus';
import { isProductionVisible } from 'config';

import { TimerFlipClock } from 'components/TimerFlipClock';
import { Button } from 'components/Button';
import { ModalWrapper } from 'components/ModalWrapper';

import styles from './styled.module.css';

const UpModal = memo(({ open = false, setOpen, setTimerState }) => {
  const [isPushed, setIsPushed] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();

  const {
    promo: {
      up: { unblock_at },
    },
    balance,
    verification_status,
  } = userInfo;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [localTimerState, setLocalTimerState] = useState({
    up: new Date().getTime() >= +unblock_at * 1000,
  });

  const isHaveMoney = +balance >= priceForUp;

  const onPushedUp = async () => {
    setLoading(true);

    try {
      const res = await setLiftingUp();
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          balance: res.data.charge_details.client_balance,
          promo: {
            ...userInfo.promo,
            up: {
              unblock_at: res.data.charge_details.lock,
            },
          },
        },
      });
      if (setTimerState) {
        setTimerState((prev) => ({ ...prev, up: false }));
      }
      setLocalTimerState((prev) => ({ ...prev, up: false }));

      setIsPushed(true);
      setError(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  let modalText = {
    title: <p className={styles.title}>One click, one UP.</p>,
    firstText: (
      <p>
        {verification_status === verificationStatus.verified
          ? ''
          : t('pages.profile.components.liftingBlock.4')}
      </p>
    ),
    secondText:
      verification_status === verificationStatus.verified ? (
        <p>{t('pages.profile.components.liftingBlock.5')}</p>
      ) : (
        <p>
          {t('pages.profile.components.liftingBlock.6')}
          <br />
          <span>{t('pages.profile.components.liftingBlock.19')}</span>
          {' '}
          {t('pages.profile.components.liftingBlock.20')}
        </p>
      ),
    lastType: verification_status === verificationStatus.verified && (
      <p className={styles.lastType}>
        {t('pages.profile.components.liftingBlock.7')}
      </p>
    ),
    buttonText: 'UP/ 5 GTL',
    buttonFunc: () => onPushedUp(),
  };

  if (!isHaveMoney) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.7')}
          <br />
          {t('pages.profile.components.liftingBlock.components.hotDealModal.8')}
        </p>
      ),
      firstText: (
        <p>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.9')}
        </p>
      ),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.hotDealModal.10',
      ),
      buttonFunc: () => navigate(paths.profileWallet),
    };
  }

  if (error) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.7')}
          <br />
          {t('errors.somethingWrong')}
        </p>
      ),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.upModal.1',
      ),
      buttonFunc: () => onPushedUp,
    };
  }

  if (isPushed) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('pages.profile.components.liftingBlock.components.upModal.2')}
          <br />
          {t('pages.profile.components.liftingBlock.components.upModal.3')}
        </p>
      ),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.upModal.4',
      ),
      buttonFunc: () => {
        navigate(
          `${paths.catalog}/?page=1&city=&gender=female&orientation=hetero`,
        );
        setOpen(false);
      },
    };
  }

  const onCloseModal = () => {
    setOpen(false);
    setIsPushed(false);
  };

  return (
    <ModalWrapper open={open} setOpen={onCloseModal}>
      <div className={styles.wrapper}>
        {modalText.title}
        {!isPushed && (
          <TimerFlipClock
            up
            setTimerState={setLocalTimerState}
            time={+unblock_at * 1000}
          />
        )}
        <div className={styles.description}>
          {modalText.firstText}
          {modalText.secondText}
          {modalText?.lastType}
        </div>
        <div className={styles.buttonBlock}>
          <Button
            primary
            loading={loading}
            disabled={
              (!localTimerState.up && !isPushed)
              || (isProductionVisible && !isHaveMoney)
            }
            onClick={() => {
              modalText.buttonFunc();
            }}
          >
            {modalText.buttonText}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
});

UpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setTimerState: PropTypes.func,
};

export { UpModal };
