import { useLocalContext } from 'LocalContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useResetPrivatePage = (paths) => {
  const navigate = useNavigate();
  const { localDispatch } = useLocalContext();

  useEffect(
    () => () => {
      localDispatch({ type: 'SET_IS_PRIVATE_PAGE', payload: false });
      if (paths) {
        navigate(paths, { state: null });
      }
    },
    [localDispatch, navigate],
  );

  return null;
};

export { useResetPrivatePage };
