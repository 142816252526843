import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setVerificationImages } from 'api/requests';

import { useFlipper } from 'hooks/useFlipper';

import { useLocalContext } from 'LocalContext';
import verificationExample1 from 'assets/verificationExample1.svg';
import verificationExample2 from 'assets/verificationExample2.svg';
import verificationExample3 from 'assets/verificationExample3.svg';
import { paths } from 'constants';
import { formatFrontImagesVerificationToBack } from 'utils/images';

import { PhotosItem } from 'components/PhotosItem';

import styles from './styled.module.css';

const VerificationPage = ({ setShowVerificationPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const { isFlipperFeatureEnabled: isImageConverterEnabled } = useFlipper('image_converter');

  const [photoCollection, setPhotoCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const sendPhotos = async () => {
    const images = await formatFrontImagesVerificationToBack(
      photoCollection,
      isImageConverterEnabled,
    );
    const query = {
      images,
    };

    setError(false);
    setLoading(true);
    try {
      const {
        data: { status, verification_status },
      } = await setVerificationImages(query);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: { ...userInfo, status, verification_status },
      });
      setError(false);
      navigate(paths.myProfile);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => setShowVerificationPage(false)}
      >
        {t('common.goBack')}
      </div>
      <div className={styles.title}>
        <h1>{t('pages.verification.verificationPage.1')}</h1>
      </div>
      <div className={styles.content}>
        <h3>{t('pages.verification.verificationPage.2')}</h3>
        <p>{t('pages.verification.verificationPage.3')}</p>
        <ul>
          <li>
            <p>{t('pages.verification.verificationPage.4')}</p>
          </li>
          <li>
            <p>{t('pages.verification.verificationPage.5')}</p>
          </li>
          <li>
            <p>{t('pages.verification.verificationPage.6')}</p>
          </li>
          <li>
            <p>{t('pages.verification.verificationPage.7')}</p>
          </li>
        </ul>
        <p>{t('pages.verification.verificationPage.8')}</p>
        <ul>
          <li>
            <p>{t('pages.verification.verificationPage.9')}</p>
          </li>
          <li>
            <p>{t('pages.verification.verificationPage.10')}</p>
          </li>
          <li>
            <p>{t('pages.verification.verificationPage.11')}</p>
          </li>
        </ul>
        <h3>{t('pages.verification.verificationPage.12')}</h3>
        <div className={styles.exampleBlock}>
          <div className={styles.goodExample}>
            <img src={verificationExample1} alt="example1" />
            <div>
              <h4>{t('pages.verification.verificationPage.13')}</h4>
              <ul>
                <li>
                  <p>{t('pages.verification.verificationPage.14')}</p>
                </li>
                <span>{t('pages.verification.verificationPage.15')}</span>
                <li>
                  <p>{t('pages.verification.verificationPage.16')}</p>
                </li>
                <li>
                  <p>{t('pages.verification.verificationPage.17')}</p>
                </li>
                <li>
                  <p>{t('pages.verification.verificationPage.18')}</p>
                </li>
                <li>
                  <p>{t('pages.verification.verificationPage.19')}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.badExamples}>
            <div className={styles.badExample}>
              <img alt="exapmle2" src={verificationExample2} />
              <div>
                <h4>{t('pages.verification.verificationPage.20')}</h4>
                <ul>
                  <li>
                    <p>{t('pages.verification.verificationPage.21')}</p>
                  </li>
                  <span>{t('pages.verification.verificationPage.22')}</span>
                  <li>
                    <p>{t('pages.verification.verificationPage.23')}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.badExample}>
              <img alt="exapmle3" src={verificationExample3} />
              <div>
                <h4>{t('pages.verification.verificationPage.24')}</h4>
                <ul>
                  <li>
                    <p>{t('pages.verification.verificationPage.25')}</p>
                  </li>
                  <span>{t('pages.verification.verificationPage.26')}</span>
                </ul>
              </div>
            </div>
            <div className={styles.mobileBadExample}>
              <div className={styles.imagesBlock}>
                <img alt="exapmle2" src={verificationExample2} />
                <img alt="exapmle3" src={verificationExample3} />
              </div>
              <div className={styles.content}>
                <h4>{t('pages.verification.verificationPage.27')}</h4>
                <ul>
                  <li>
                    <p>{t('pages.verification.verificationPage.28')}</p>
                  </li>
                  <span>{t('pages.verification.verificationPage.29')}</span>
                  <li>
                    <p>{t('pages.verification.verificationPage.30')}</p>
                  </li>
                  <li>
                    <p>{t('pages.verification.verificationPage.31')}</p>
                  </li>
                  <span>{t('pages.verification.verificationPage.32')}</span>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <h3>{t('pages.verification.verificationPage.33')}</h3>
        <PhotosItem
          maxItem={3}
          imagesList={photoCollection}
          setImagesList={setPhotoCollection}
        />
      </div>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={!photoCollection.length}
        className={styles.button}
        onClick={sendPhotos}
      >
        {t('pages.verification.verificationPage.34')}
      </Button>
      {error && <p>{t('pages.verification.verificationPage.35')}</p>}
    </div>
  );
};

VerificationPage.propTypes = {
  setShowVerificationPage: PropTypes.func.isRequired,
};

export { VerificationPage };
