import { useState, useEffect } from 'react';
import { Input, Form, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { onLogin, resendConfirmationEmail } from 'api/requests';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import { paths, savedConstants } from 'constants';
import { latinLettersPattern } from 'constants/patterns';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import styles from './styled.module.css';

const LoginModal = ({ open = false, setOpen, setOpenRegistrationModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isPublicVersion } = usePublicVersion();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { localDispatch } = useLocalContext();
  const { gaClickEvent } = useGoogleAnalytics();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    return () => setError(false);
  }, [open]);

  const onClickSignUp = () => {
    setOpenRegistrationModal(true);
    setOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password } = values;
    const query = {
      email: email.toLowerCase(),
      password,
    };
    try {
      const res = await onLogin(query);
      if (res.status === 200) {
        const { token, user_type, id_name } = res.data;

        gaClickEvent({ action: 'Login', label: `${id_name}, ${user_type}` });

        localStorage.setItem(savedConstants.AUTH_TOKEN, token);
        localStorage.setItem(savedConstants.USER_TYPE, user_type);
        navigate(paths.main);
        setOpen(false);
        localDispatch({ type: 'SET_USER' });
      }
      if (res.status === 206) {
        const { user_type, email: resendEmail } = res.data;

        const resendBody = {
          email: resendEmail,
          user_type,
        };
        try {
          await resendConfirmationEmail(resendBody);
          navigate(paths.emailConfirmation, {
            state: { userEmail: email, userStatus: 206, userType: user_type },
          });
          setOpen(false);
        } catch (e) {
          setError(true);
        }
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          <img
            src={isPublicVersion ? publicLogo : logo}
            alt="logo"
            className={styles.logo}
          />
          <h2 className={styles.title}>{t('components.loginModal.1')}</h2>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          onFinishFailed={(values) => scrollToErrorField(values)}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('errors.inputEmail'),
              },
              {
                type: 'email',
                message: t('errors.emailFormat'),
              },
              {
                pattern: latinLettersPattern,
                message: t('errors.onlyLatin'),
              },
            ]}
          >
            <Input placeholder={t('common.email')} autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('errors.inputYourPassword'),
              },
            ]}
            style={{ margin: error ? '0' : '' }}
          >
            <Input.Password
              placeholder={t('common.password')}
              autoComplete="off"
            />
          </Form.Item>
          {error && (
          <span className={styles.error}>
            {t('errors.invalidPasswordOrEmail')}
          </span>
          )}
          <div className={styles.optionsWrapper}>
            {/* <Form.Item */}
            {/*  name="remember" */}
            {/*  valuePropName="checked" */}
            {/*  style={{ margin: '0px' }} */}
            {/* > */}
            {/*  <Checkbox> */}
            {/*    {t('modals.Remember me')} */}
            {/*  </Checkbox> */}
            {/* </Form.Item> */}
            <p
              className={styles.forgot}
              onClick={() => {
                setOpen(false);
                navigate(paths.forgotPassword);
              }}
            >
              {t('common.forgotPassword')}
            </p>
          </div>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                primary
                submit
                loading={loading}
                fullWidth
                disabled={
                    !form.isFieldsTouched(['password'])
                    || !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
              >
                {!loading && t('common.login')}
              </Button>
            )}
          </Form.Item>

          <Divider
            style={{
              color: '#633976',
              margin: '40px 0 20px',
            }}
          >
            {t('components.loginModal.2')}
          </Divider>
          <Button
            primary
            onClick={onClickSignUp}
            fullWidth
          >
            {t('common.joinTolips')}
          </Button>
        </Form>
      </div>
    </ModalWrapper>
  );
};

LoginModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOpenRegistrationModal: PropTypes.func.isRequired,
};

export { LoginModal };
