import { useEffect, useMemo, useState } from 'react';
import { Menu, Steps } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';

import burger from 'assets/burger.svg';
import { Photos } from './ProfileCreationSteps/Photos';
import { BioInformation } from 'pages/ProfileCreation/ProfileCreationSteps/BioInformation';
import { Rates } from 'pages/ProfileCreation/ProfileCreationSteps/Rates';
import { SocialMedia } from './ProfileCreationSteps/SocialMedia';
import { Services } from 'pages/ProfileCreation/ProfileCreationSteps/Services';
import { About } from './ProfileCreationSteps/About';
import { Availability } from './ProfileCreationSteps/Availability';
import { StepDescription } from './components/StepDescription';
import { StepTitle } from './components/StepTitle';
import { Spinner } from 'components/Spinner';
import { InfoModal } from './components/InfoModal';

import styles from './styled.module.css';
import './styles.css';

const ProfileCreation = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [currentStep, setCurrentStep] = useState(state || 0);
  const [render, setRender] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const {
    localState: { userInfo },
  } = useLocalContext();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalStep, setModalStep] = useState(null);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // if (userInfo) {
  //   const timerStatus = userInfo?.user_type === userProvider
  //     ? [
  //       new Date().getTime() >= +userInfo.weekly_offer_unblock_at * 1000,
  //       new Date().getTime() >= +userInfo.hot_deal_unblock_at * 1000,
  //     ].some((item) => !item)
  //     : true;

  //   if (
  //     userInfo?.user_type !== userProvider
  //     || userInfo?.status === profileStatus.moderation
  //     || userInfo?.verification_status === verificationStatus.verification
  //     || timerStatus
  //   ) {
  //     return <Navigate to={paths.main} replace />;
  //   }
  // }

  useEffect(() => {
    if (state) {
      setRender(state);
    }
  }, []);

  useEffect(() => {
    const el = document.querySelector('.profile-creation').getBoundingClientRect().top
      + window.scrollY;
    window.scrollTo({
      top: el - 140,
      behavior: 'smooth',
    });
  }, [currentStep]);

  const hasAvailableDay = useMemo(
    () => !!userInfo
      && !!userInfo.availability_calendar
      && userInfo.availability_calendar.some((day) => day.available === true),
    [userInfo && userInfo.availability_calendar],
  );

  const steps = [
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.5')}
          step={0}
          сurrentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          isFilled={!!userInfo?.profile && !!userInfo?.profile?.phone_number}
          disabled={currentStep >= 0 ? false : disabled}
          isRequired
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      content: (
        <BioInformation
          nextStep={nextStep}
          setLoading={setLoading}
          errors={errors}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.11')}
          step={1}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          disabled={
            (currentStep >= 1 ? false : disabled)
            || !userInfo?.profile?.phone_number
          }
          isFilled={
            !!userInfo?.social_networks?.length
            || !!userInfo?.messengers?.length
          }
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      content: (
        <SocialMedia
          nextStep={nextStep}
          setLoading={setLoading}
          render={render}
          setRender={setRender}
        />
      ),
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.6')}
          step={2}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          disabled={
            (currentStep >= 2 ? false : disabled)
            || !userInfo?.profile?.phone_number
          }
          isFilled={!!userInfo?.profile_descriptions?.length}
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      description: <StepDescription name="About" />,
      content: <About nextStep={nextStep} setLoading={setLoading} />,
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.7')}
          step={3}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          isFilled={hasAvailableDay}
          disabled={
            (currentStep >= 3 ? false : disabled)
            || !userInfo?.profile?.phone_number
          }
          isRequired
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      content: (
        <Availability
          nextStep={nextStep}
          setLoading={setLoading}
          setDisabled={setDisabled}
        />
      ),
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.8')}
          step={4}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          isFilled={!!userInfo?.services?.length}
          disabled={
            (currentStep >= 4 ? false : disabled)
            || !hasAvailableDay
            || !userInfo?.profile?.phone_number
          }
          isRequired
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      description: <StepDescription name="Services" />,
      content: (
        <Services
          nextStep={nextStep}
          setLoading={setLoading}
          setDisabled={setDisabled}
        />
      ),
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.9')}
          step={5}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          isFilled={!!userInfo?.rates?.length}
          disabled={
            (currentStep >= 5 ? false : disabled)
            || !hasAvailableDay
            || !userInfo?.services?.length
            || !userInfo?.profile?.phone_number
          }
          isRequired
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      content: (
        <Rates
          setLoading={setLoading}
          next
          nextStep={nextStep}
          setDisabled={setDisabled}
        />
      ),
    },
    {
      title: (
        <StepTitle
          name={t('pages.profileCreation.10')}
          step={6}
          сurrentStep={currentStep}
          setOpenMenu={setOpenMenu}
          setDisabled={setDisabled}
          isFilled={!!userInfo?.avatar}
          disabled={
            (currentStep >= 5 ? false : disabled)
            || !hasAvailableDay
            || !userInfo?.services?.length
            || !userInfo?.rates?.length
            || !userInfo?.profile?.phone_number
          }
          isRequired
          setOpenModal={setOpenModal}
          setModalStep={setModalStep}
        />
      ),
      description: <StepDescription name="Photos" />,
      content: <Photos />,
    },
  ];

  const menuChildren = useMemo(
    () => steps.map((step) => ({
      key: step.title.props.name,
      label: step.title,
      className:
          currentStep === step.title.props.step ? 'active_menu_item' : '',
      disabled: !userInfo && step.title.props.name !== 'Bio Information',
    })),
    [steps, currentStep, userInfo],
  );

  const subTitle = () => {
    if (currentStep === 0) {
      return t('pages.profileCreation.1');
    }
    if (currentStep === 1) {
      return t('pages.profileCreation.18');
    }
    if (currentStep === 2) {
      return t('pages.profileCreation.13');
    }
    if (currentStep === 3) {
      return t('pages.profileCreation.14');
    }
    if (currentStep === 4) {
      return t('pages.profileCreation.15');
    }
    if (currentStep === 5) {
      return t('pages.profileCreation.16');
    }
    if (currentStep === 6) {
      return t('pages.profileCreation.17');
    }
    return null;
  };

  return (
    <div className={`${styles.wrapper} profile-creation`}>
      <div className={styles.title}>
        <h2>
          {userInfo
            ? t('pages.profileCreation.12')
            : t('pages.profileCreation.0')}
        </h2>
        <p>{subTitle()}</p>
      </div>
      <Menu
        mode="inline"
        openKeys={openMenu ? ['title'] : []}
        className={`${styles.menu} menu`}
        defaultSelectedKeys="Bio Information"
        items={[
          {
            key: 'title',
            label: (
              <div className={styles.menuLabel}>
                <div className={styles.menuTitle}>
                  {steps[currentStep].title}
                  <img src={burger} alt="burger" />
                </div>
                {steps[currentStep].description}
              </div>
            ),
            onTitleClick: () => setOpenMenu(!openMenu),
            children: menuChildren,
          },
        ]}
      />
      <div className={styles.contentBlock}>
        <Steps
          current={currentStep}
          items={steps}
          direction="vertical"
          className={`${styles.steps} steps`}
        />
        <div className={styles.content}>
          {loading ? <Spinner /> : steps[currentStep].content}
        </div>
      </div>
      <InfoModal
        open={openModal}
        setOpen={setOpenModal}
        modalStep={modalStep}
        setCurrentStep={setCurrentStep}
      />
    </div>
  );
};

export { ProfileCreation };
