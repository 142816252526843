import React, { useState, forwardRef } from 'react';
import { Image } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import { setVisitorLike } from 'api/requests/visitor';
import { reactAppPublicApiUrl } from 'config';
import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { usePublicVersion } from 'hooks/usePublicVersion';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';
import albumCountImage from 'assets/albumCount.svg';

import { Spinner } from 'components/Spinner';
import { ImagePreviewWrapper } from 'components/ImagePreviewWrapper';
import { VerifiedIcon } from 'components/ActiveIcon/VerifiedIcon';
import { LikeIcon } from 'components/ActiveIcon/LikeIcon';

import styles from './styled.module.css';
import './styles.css';

const Avatar = forwardRef(
  ({
    images, isVerified = false, id, albumCount = 0,
  }, ref) => {
    const navigate = useNavigate();
    const {
      localState: { userInfo },
      localDispatch,
    } = useLocalContext();
    const { isPublicVersion } = usePublicVersion();

    const isLike = (userInfo?.favorites?.map((item) => item.id) || []).includes(
      id,
    );

    const [scroll, setScroll] = useState(true);
    const [like, setLike] = useState(isLike);
    const [currentImage, setCurrentImage] = useState(0);
    const location = useLocation();
    const [onImagesLoad, setOnImagesLoad] = useState([]);

    const isAllImageLoaded = images
      ? new Set(onImagesLoad).size === images.length
      : true;

    const setLikeForProfile = async () => {
      const query = {
        favorite: {
          id,
        },
      };
      try {
        const res = await setVisitorLike(query);
        setLike(!like);
        localDispatch({
          type: 'SET_USER_INFO',
          payload: { ...userInfo, favorites: res.data.favorites },
        });
      } catch (error) {
        console.log('error');
      }
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div
        className={`${styles.wrapper} ${
          images?.length < 2 ? styles.withoutSlider : ''
        }`}
      >
        <div
          className={`${styles.avatarBlock} avatarSlider ${
            !isAllImageLoaded ? '' : styles.hide
          }`}
        >
          <Spinner />
        </div>
        {images?.length > 1 ? (
          <div
            className={`${styles.avatarBlock} avatarSlider ${
              isAllImageLoaded ? '' : styles.hide
            }`}
          >
            <ImagePreviewWrapper images={images} initialImage={currentImage}>
              <Slider
                {...settings}
                beforeChange={() => setScroll(false)}
                afterChange={(e) => {
                  setCurrentImage(e);
                  setScroll(true);
                }}
                className="avatarSlider"
              >
                {images.map((item) => (
                  <Image
                    key={item.id}
                    alt="image"
                    src={`${reactAppPublicApiUrl}${item.image_url}`}
                    preview={scroll}
                    loading="eager"
                    onLoad={(e) => {
                      setOnImagesLoad((prev) => [...prev, e.target.src]);
                    }}
                  />
                ))}
              </Slider>
            </ImagePreviewWrapper>
          </div>
        ) : (
          <div
            className={`${styles.avatarBlock} avatarSlider  ${
              isAllImageLoaded ? '' : styles.hide
            }`}
          >
            {images ? (
              <ImagePreviewWrapper images={images} initialImage={currentImage}>
                <Image
                  className={styles.avatar}
                  src={`${reactAppPublicApiUrl}${images[0].image_url}`}
                  alt="avatar"
                  loading="eager"
                  onLoad={(e) => {
                    setOnImagesLoad((prev) => [...prev, e.target.src]);
                  }}
                />
              </ImagePreviewWrapper>
            ) : (
              <img alt="no avatar" src={isPublicVersion ? publicLogo : logo} className={styles.noAvatar} />
            )}
          </div>
        )}
        {location.pathname !== paths.myProfile && (
          <>
            {isVerified && <VerifiedIcon className={styles.verified} />}
            {userInfo?.user_type !== userProvider && (
              <LikeIcon
                className={`${styles.like} ${like ? styles.active : ''}`}
                onClick={async (e) => {
                  e.preventDefault();
                  if (userInfo?.user_type === userVisitor) {
                    await setLikeForProfile();
                  } else {
                    navigate(paths.registration, {
                      state: { userType: userVisitor },
                    });
                  }
                }}
              />
            )}
            {!!albumCount && (
              <div
                className={styles.albumCount}
                onClick={() => {
                  if (ref && ref.current) {
                    ref.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                <img src={albumCountImage} alt="albumCountImage" />
                <p>{albumCount}</p>
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);

Avatar.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
    }),
  ),
  isVerified: PropTypes.bool,
  id: PropTypes.number,
  albumCount: PropTypes.number,
};

export { Avatar };
