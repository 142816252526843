import { Route } from 'react-router-dom';

import { paths } from 'constants';
import { useLocalContext } from 'LocalContext';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { isProductionVisible } from 'config';

import {
  BlackList,
  ContactUs,
  MyProfile,
  Notifications,
  ProfileCreation,
  Settings,
  Wallet,
  NotCreatedProfile,
  PaymentPage,
  HotDeal,
  Verification,
} from 'pages';

const useProfileSettingsRoutes = () => {
  const { localState } = useLocalContext();

  return (
    <>
      {!isProductionVisible && (
        <Route
          path={paths.profileWallet}
          element={(
            <ProtectedRoute isLogged={!!localState.user}>
              <Wallet />
            </ProtectedRoute>
          )}
        />
      )}
      <Route
        path={paths.profileBlackList}
        element={(
          <ProtectedRoute isLogged={!!localState.user}>
            <BlackList />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.profileContactUs}
        element={(
          <ProtectedRoute isLogged={!!localState.user}>
            <ContactUs />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.myProfile}
        element={(
          <ProtectedRoute isLogged={!!localState.user}>
            <MyProfile />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.profileNotifications}
        element={(
          <ProtectedRoute isLogged={!!localState.user}>
            <Notifications />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.profileSettings}
        element={(
          <ProtectedRoute isLogged={!!localState.user}>
            <Settings />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.profileCreation}
        element={(
          <ProtectedRoute isLogged>
            <ProfileCreation />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.notCreatedProfile}
        element={(
          <ProtectedRoute isLogged>
            <NotCreatedProfile />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.paymentPage}
        element={(
          <ProtectedRoute isLogged>
            <PaymentPage />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.hotDealPage}
        element={(
          <ProtectedRoute isLogged>
            <HotDeal />
          </ProtectedRoute>
        )}
      />
      <Route
        path={paths.verification}
        element={(
          <ProtectedRoute isLogged>
            <Verification />
          </ProtectedRoute>
        )}
      />
    </>
  );
};

export { useProfileSettingsRoutes };
