import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Modal, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { usePublicVersion } from 'hooks/usePublicVersion';
import { useConvertToJpeg } from 'hooks/useConvertToJpeg';

import { getFileBase64 } from 'utils/images';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import styles from './styled.module.css';
import './styles.css';

const PhotosItem = ({
  maxItem,
  imagesList,
  setImagesList,
  className,
  isImageConverterEnabled,
}) => {
  const { t } = useTranslation();
  const { isPublicVersion } = usePublicVersion();
  const { convertToJPEG } = useConvertToJpeg();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // old feature
  const [isCheck, setIsCheck] = useState(true);
  const [error, setError] = useState('');

  const rulesCheck = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg'
      || file.type === 'image/png'
      || file.type === 'image/heic'
      || file.type === 'image/webp';
    if (!isJpgOrPng) {
      setError(t('components.photosItem.1'));
      return false;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      setError(`${t('components.photosItem.2')}`);
      return false;
    }

    setError('');
    return true;
  };

  const onCustomRequest = ({ file, onSuccess }) => {
    const editedFile = new File([file.originFileObj], 'edited-image.png', {
      type: 'image/png',
    });

    setImagesList((prevFileList) => prevFileList
      .map((item) => (item.uid === file.uid ? editedFile : item)));

    onSuccess();
  };
  // ...

  const handlePreview = async (e) => {
    if (!e.url && !e.preview) {
      e.preview = await getFileBase64(e.originFileObj);
    }
    setPreviewImage(e.url || e.preview);
    setPreviewOpen(true);
  };

  return (
    <>
      <ImgCrop
        // old feature
        quality={isImageConverterEnabled ? 1 : 0.5}
        // ...
        aspect={1 / 1.3}
        beforeCrop={async (e) => {
          // new feature
          if (isImageConverterEnabled) {
            const file = await convertToJPEG(e);
            return file;
          }
          // ...
          // old feature
          const isActualCheck = rulesCheck(e);
          setIsCheck(!!isActualCheck);
          return !!isActualCheck;
          // ...
        }}
      >
        <Upload
          listType="picture-card"
          fileList={imagesList}
          accept="image/png, image/jpeg, image/heic, image/webp, image/heif, image/avif"
          onPreview={handlePreview}
          onChange={({ fileList }) => {
            // new feature
            if (isImageConverterEnabled) {
              setImagesList(fileList);
              // ...
              // old feature
            } else if (imagesList.length > fileList.length || isCheck) {
              setImagesList(fileList);
            }
            // ...
          }}
          customRequest={({ file, onSuccess }) => {
            // new feature
            if (isImageConverterEnabled) {
              onSuccess();
              // ...
              // old feature
            } else {
              onCustomRequest({ file, onSuccess });
            }
            // ...
          }}
          rootClassName={
            isImageConverterEnabled
            // new feature
              ? `${styles.imagesBlock} upload ${className || ''}`
            // ...
            // old feature
              : `${styles.imagesBlock} upload ${error ? 'error' : ''} ${
                className || ''
              }`
            // ...
          }
        >
          {error
            || (imagesList.length >= maxItem ? null : (
              <div className={styles.emptyBlock}>
                <img
                  className={styles.noAvatar}
                  src={isPublicVersion ? publicLogo : logo}
                  alt="no-avatar"
                />
                {t('components.photosItem.3')}
              </div>
            ))}
        </Upload>
      </ImgCrop>
      <Modal
        open={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

PhotosItem.propTypes = {
  maxItem: PropTypes.number.isRequired,
  imagesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      position: PropTypes.number,
      file: PropTypes.string,
    }),
  ),
  setImagesList: PropTypes.func.isRequired,
  className: PropTypes.string,
  isImageConverterEnabled: PropTypes.bool,
};

export { PhotosItem };
