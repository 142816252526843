import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';
import { useLocalContext } from 'LocalContext';
import { editProfile } from 'api/requests/profile';
import { verificationStatus } from 'constants/verificationStatus';

import { messengers, socialMedia } from 'utils/filters';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import {
  formatSocialMediaDataToSendFormat,
  formatSocialMediaToBack,
} from 'utils/profileCreationValues';

import { SocialMediaItem } from './SocialMediaItem';

import styles from '../../styled.module.css';

const SocialMedia = ({
  nextStep, setLoading, render, setRender,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const messengersRef = useRef(null);
  const socialMediaRef = useRef(null);

  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();

  const [messengersData, setMessengersData] = useState(
    userInfo?.messengers || [],
  );

  const [socialMediaData, setSocialMediaData] = useState(
    userInfo?.social_networks || [],
  );

  const sendSocialMedia = async () => {
    const sendData = formatSocialMediaToBack(form.getFieldsValue());
    const formatBack = formatSocialMediaDataToSendFormat(
      userInfo?.messengers,
      userInfo?.social_networks,
    );

    const query = {
      ...((!!userInfo?.messengers?.length || !!sendData.messengers.length) && {
        messengers: sendData.messengers,
      }),
      ...((!!userInfo?.social_networks?.length
        || !!sendData.social_networks.length) && {
        social_networks: sendData.social_networks,
      }),
    };

    if (
      JSON.stringify(formatBack)
        === JSON.stringify([...sendData.messengers, ...sendData.social_networks])
      || !Object.keys(query).length
    ) {
      nextStep();
    } else {
      setLoading(true);
      try {
        const { data: { card } } = await editProfile(query);
        localDispatch({ type: 'SET_USER_INFO', payload: card });
        setError(false);
        nextStep();
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const onScroll = (ref) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 140,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (render === 1) {
      onScroll(socialMediaRef);
      setRender(2);
    }
  }, [render]);

  if (error) {
    return <span>{t('common.error')}</span>;
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      className={`${styles.form} ${styles.about} ${styles.media}`}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Form.Item
            shouldUpdate
            style={{
              marginBottom: '0',
              maxWidth: '410px',
              width: '100%',
              textAlign: 'end',
            }}
          >
            {() => {
              const dependentValue = form.getFieldsValue([
                'messenger_link',
                'social_media_link',
              ]);
              const isDisabled = Object.values(dependentValue).some(
                (el) => !!el,
              );
              return (
                <Button
                  type="primary"
                  className={`${styles.button} ${styles.primary}`}
                  onClick={sendSocialMedia}
                  disabled={
                    isDisabled
                    || !!form.getFieldsError().some(({ errors }) => errors.length)
                  }
                >
                  {t('common.next')}
                </Button>
              );
            }}
          </Form.Item>
        </div>
      )}
      <div className={styles.allButtonsCategory}>
        <Button
          type="primary"
          className={`${styles.buttonCategory} ${styles.primary}`}
          onClick={() => onScroll(messengersRef)}
        >
          {t('common.messengers')}
        </Button>
        <Button
          type="primary"
          className={`${styles.buttonCategory} ${styles.primary}`}
          onClick={() => onScroll(socialMediaRef)}
        >
          {t('common.social_media')}
        </Button>
      </div>
      <div className={`${styles.formItem} ${styles.socialMediaMessengers}`}>
        <p className={styles.title} ref={messengersRef}>
          {t('pages.profileCreation.socialMedia.1')}
        </p>
        <SocialMediaItem
          data={messengersData}
          setData={setMessengersData}
          options={messengers}
          form={form}
          formItemName="messenger"
          isMobile={isMobile}
        />
      </div>
      <div
        className={`${styles.formItem} ${styles.socialMedia} ${
          userInfo.verification_status !== verificationStatus.verified
            ? styles.disabled
            : ''
        }`}
      >
        <p className={styles.title} ref={socialMediaRef}>
          {userInfo.verification_status === verificationStatus.verified
            ? t('pages.profileCreation.socialMedia.2')
            : t('pages.profileCreation.socialMedia.3')}
        </p>
        {userInfo.verification_status === verificationStatus.verified ? (
          <SocialMediaItem
            data={socialMediaData}
            setData={setSocialMediaData}
            options={socialMedia}
            form={form}
            formItemName="social_media"
            isMobile={isMobile}
          />
        ) : (
          <div className={styles.socialExample}>
            {socialMedia.map((item) => (
              <img src={item.image} alt={item.value} key={item.value} />
            ))}
          </div>
        )}
      </div>
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Form.Item
            shouldUpdate
            style={{
              marginBottom: '0',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {() => {
              const dependentValue = form.getFieldValue('messenger_link');
              return (
                <Button
                  type="primary"
                  className={`${styles.button} ${styles.primary}`}
                  onClick={sendSocialMedia}
                  disabled={
                    dependentValue
                    || !!form.getFieldsError().some(({ errors }) => errors.length)
                  }
                >
                  {t('common.next')}
                </Button>
              );
            }}
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

SocialMedia.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
  setRender: PropTypes.func,
  render: PropTypes.number,
};

export { SocialMedia };
