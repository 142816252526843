import {
  Button, Form, InputNumber, Table,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { onlyNumberInInput } from 'utils/onlyNumberInInput';
import { useIsMobile } from 'hooks/useIsMobile';
import { duration } from 'utils/filters';
import { editProfile } from 'api/requests/profile';
import { useLocalContext } from 'LocalContext';
import {
  formatBackToRates,
  formatRatesDataToSendFormat,
  formatRatesToBack,
} from 'utils/profileCreationValues';
import outcall from 'assets/catalogCarouselImages/outcall.svg';

import styles from './styled.module.css';
import './styles.css';

const Rates = ({ nextStep, setLoading, setDisabled }) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();

  const ratesData = formatBackToRates(userInfo && userInfo.rates);
  const getSelectedRows = (values) => (values
    ? Object.keys(values)
      .filter((key) => values[key] !== null)
      .map((item) => item.split('_').slice(1).join('_'))
    : []);

  const [selectedRowKeys, setSelectedRowKeys] = useState(ratesData.hour);
  const [selectedRow, setSelectedRow] = useState(
    getSelectedRows(ratesData.typeOfService),
  );
  const [error, setError] = useState(false);
  const [form] = Form.useForm();

  const isDisabled = !(
    !!selectedRowKeys?.length
    && !!selectedRow?.length
    && selectedRowKeys.some((item) => selectedRow.includes(item))
  );

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  const onFinish = async (values) => {
    const rates = formatRatesToBack(selectedRowKeys, values);
    const ratesBack = userInfo && userInfo.rates
      ? userInfo.rates.map((item) => ({
        duration: item.duration,
        amount: item.amount,
        type_of_service: item.type_of_service,
      }))
      : [];

    const query = {
      rates: formatRatesDataToSendFormat(ratesBack, rates),
    };

    if (JSON.stringify(ratesBack) === JSON.stringify(rates)) {
      nextStep();
    } else {
      setLoading(true);
      try {
        const {
          data: { card },
        } = await editProfile(query);
        localDispatch({ type: 'SET_USER_INFO', payload: card });
        setError(false);
        nextStep();
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: (
        <p>
          <img src={outcall} alt="outcall" style={{ width: '22px' }} />
          {' '}
          {t('common.outcall')}
        </p>
      ),
      dataIndex: 'outcall',
      key: 'outcall',
    },
    {
      title: t('common.incall'),
      dataIndex: 'incall',
      key: 'incall',
    },
  ];

  const data = duration.map((item) => ({
    key: item.value,
    duration: item.label,
    outcall: (
      <Form.Item name={`outcall_${item.value}`}>
        <InputNumber
          className={styles.inputNumber}
          min={1}
          onKeyDown={(e) => onlyNumberInInput(e)}
          maxLength={9}
          placeholder={t('common.setRate')}
          disabled={!selectedRowKeys.includes(item.value)}
        />
      </Form.Item>
    ),
    incall: (
      <Form.Item name={`incall_${item.value}`}>
        <InputNumber
          className={styles.inputNumber}
          min={1}
          onKeyDown={(e) => onlyNumberInInput(e)}
          maxLength={9}
          placeholder={t('common.setRate')}
          disabled={!selectedRowKeys.includes(item.value)}
        />
      </Form.Item>
    ),
  }));

  if (error) {
    return <span>{t('common.error')}</span>;
  }

  return (
    <Form
      className={`${styles.wrapper} rates`}
      form={form}
      onFinish={onFinish}
      initialValues={ratesData.typeOfService}
      onChange={() => {
        const values = form.getFieldsValue();
        setSelectedRow(
          Object.keys(values)
            .filter((key) => values[key] !== null)
            .map((item) => item.split('_').slice(1).join('_')),
        );
      }}
    >
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            className={`${styles.button} ${styles.primary}`}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
      <div className={styles.formBlock}>
        <div className={styles.currency}>
          <p>{t('common.yourCurrency')}</p>
          <p>PLN</p>
        </div>
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ width: '100%' }}
        />
      </div>
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            className={`${styles.button} ${styles.primary}`}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
    </Form>
  );
};

Rates.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
  setDisabled: PropTypes.func,
};

export { Rates };
