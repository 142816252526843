import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { paths, savedConstants } from 'constants';
import { globalEmail } from 'constants/globalEmail';

import { LoginModal } from 'components/LoginModal';
import { RegistrationModal } from 'components/RegistrationModal';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const DMCAPage = () => {
  const authToken = localStorage.getItem(savedConstants.AUTH_TOKEN);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);

  return (
    <div className={`${styles.policies} ${styles.wrapper}`}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>How do I issue a useful DMCA notice?</h1>
      <p>
        You must be the content owner or authorized agent to issue a takedown
        request. If you are not the copyright owner but believe content is
        stolen, you can bring it to our attention via a
        {' '}
        <Link
          to={authToken && paths.profileContactUs}
          onClick={() => !authToken && setOpenLoginModal(true)}
        >
          new ticket
        </Link>
        .
      </p>
      <p>
        If your copyrighted material has been used on ToLips and you want to
        submit a request to have it removed, please ensure you include the
        following information in an email to
        {' '}
        <b>{globalEmail}</b>
        .
      </p>
      <p>
        Your information, or the information of the copyright holder if you are
        an authorized representative acting on their behalf. This must include
        the full name of the person or entity the copyright belongs to. A
        description, including the type, of each infringing work or a link to
        the specific piece of media on ToLips.
      </p>
      <p>
        Your assertion of copyright (why you are the copyright holder and that
        you have not transferred that ownership to another party).
      </p>
      <p>
        A statement that your submission is in good faith and that you have the
        authority to act on this claim. Takedown requests that do not include
        all of the above information may not be processed at all or will require
        you to provide additional information to proceed. An email stating that
        you are the copyright owner with no proof is not sufficient to have
        content removed.
      </p>
      <p>
        Abuse of DMCA takedown notices will result in your being banned from all
        current and future Assembly Four platforms, including ToLips.
      </p>
      <LoginModal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        setOpenRegistrationModal={setOpenRegistrationModal}
      />
      <RegistrationModal
        open={openRegistrationModal}
        setOpen={setOpenRegistrationModal}
        setOpenLoginModal={setOpenLoginModal}
      />
    </div>
  );
};
export { DMCAPage };
