import { receiveAllNotifications } from 'api/requests';

export const getNotifications = async ({
  setNotifications,
  localDispatch,
  setLoading,
  setError,
}) => {
  const query = {
    limit: 100,
    offset: 0,
  };
  if (setLoading) {
    setLoading(true);
  }
  try {
    const { data } = await receiveAllNotifications(query);
    if (setNotifications) {
      setNotifications(data.notifications);
    }
    if (localDispatch) {
      localDispatch({
        type: 'SET_NOTIFICATIONS_COUNT',
        payload: data.unread,
      });
    }
    if (setError) {
      setError(false);
    }
  } catch (e) {
    if (setError) {
      setError(true);
    }
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};
