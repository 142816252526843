import { paths, savedConstants } from 'constants';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { globalEmail } from 'constants/globalEmail';

import { LoginModal } from 'components/LoginModal';
import { RegistrationModal } from 'components/RegistrationModal';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const CodeOfConduct = () => {
  const authToken = localStorage.getItem(savedConstants.AUTH_TOKEN);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);

  return (
    <div className={`${styles.policies} ${styles.wrapper}`}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>Code of Conduct</h1>
      <p>
        All users of ToLips.link agree to be bound by the ToLips.link Code of
        Conduct.
      </p>
      <p>
        ToLips.link aims to create welcoming environments, regardless of age,
        race, gender identity or expression, background, disability, appearance,
        sexuality, walk of life, profession, or religion.
      </p>
      <p>
        In collaboration with sex workers across the globe, Tryst.link creates
        products and services that help them thrive; not just survive, and we
        ask all of our users to adopt these values.
      </p>
      <p>
        We may need to change the Code of Conduct from time to time and reserve
        the right to do so. Failure to adhere to the standards outlined may
        result in your access to our services being limited or blocked.
      </p>
      <p>
        <b>TL;DR short version</b>
      </p>
      <p>
        This is the &apos;too long, didn&apos;t read&apos; version of our code
        of conduct. Please note that the full version below takes precedence
        over this short quick reference version.
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            Treat everyone with respect, including other users and members of
            the team.
          </p>
        </li>
        <li>
          <p>Mark adult content appropriately.</p>
        </li>
        <li>
          <p>
            Do not abuse the platform or cause the platform to come into
            disrepute.
          </p>
        </li>
      </ul>

      <h2 className={styles.subtitle}>Using our platforms</h2>
      <p>
        ToLips.link is focused on, and dedicated to, diversity and inclusion. We
        welcome all users over the age of 18 (and the age of majority in your
        jurisdiction) to use our platforms. You must be using our platforms of
        your own free will, free of any outside pressure.
      </p>
      <p>
        You may not use our platforms to offer or request services that are
        against your local laws, including references to rape or other
        criminalised sexual acts. This includes offering bareback services or
        using any terminology to refer to available bareback services,
        including, but not limited to, bbfs, creampie, and cip.
      </p>
      <p>
        As our platforms are intended for adult users and for the advertising of
        adult services we do not allow use by, or presentation as, an underage
        user regardless of your true age. This includes using phrasing and
        photos that would lead a reasonable person to assume you were under the
        age of 18 (and the age of majority in your jurisdiction).
      </p>

      <h2 className={styles.subtitle}>Treat everyone with respect</h2>
      <p>
        Everyone deserves the right to be treated with respect and we expect all
        users of our platforms to treat each other with respect and courtesy.
        Systemic racism is a huge contributor to the oppression of sex workers
        and we have a zero-tolerance policy toward this behavior. Bullying,
        harassment, threatening behavior, and discriminatory behavior are not
        welcome and are actively moderated.
      </p>
      <p>
        Any discriminatory statements used on our platforms will be removed. We
        understand that some of our users need to filter who they interact with
        for legitimate personal reasons, but we will not allow the use of
        discriminatory language. We want to be a part of the change to
        destigmatise sex work and that means we as a community should not
        stigmatise others.
      </p>
      <p>
        You will not submit or transmit any content that is forbidden, libelous,
        defamatory, graphic violence, threats, obscene, abusive, offensive, hate
        speech, incitements of violence, profane, or that infringes on any
        copyright or other rights of any other person.
      </p>
      <p>
        We firmly stand behind all of our users and their ability to present in
        the way appropriate to them and we support members of the community
        taking back language once used against them.
      </p>

      <p>
        We specifically do not allow the use of the following terms or
        variations on them. This isn&apos;t a complete list; any discriminatory
        language is covered:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>No AA, No Black, or variations on those terms.</p>
        </li>
        <li>
          <p>Biological [sex] only, or any variation on this term.</p>
        </li>
      </ul>
      <p>
        Derogatory language against any group and any person will not be
        tolerated on our platforms.
      </p>

      <h2 className={styles.subtitle}>We are people too</h2>
      <p>
        Respecting users also extends to our support team. We understand it can
        be frustrating when things aren&apos;t working correctly, but please
        remember we are people too and we want to help out. We will not tolerate
        abusive language or actions towards our team and will not respond to
        abusive requests.
      </p>
      <p>
        If you continue to send abusive messages, we will remove your account
        from the platform.
      </p>

      <h2 className={styles.subtitle}>Media content</h2>
      <p>
        We provide platforms for adult industries which means we welcome adult
        content. We think it is important to allow our users to express
        themselves in an appropriate way so we allow media containing nudity to
        be shared, provided that media is not explicit and is of the person
        sharing the media.
      </p>
      <p>
        We explicitly do not allow certain types of content and sharing
        something of this type will result in your access to our platforms or
        services being restricted or blocked.
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>Any depiction of minor, regardless of context.</p>
        </li>
        <li>
          <p>
            Content illegal in Germany, Switzerland and/or France, such as
            holocaust denial or Nazi symbolism.
          </p>
        </li>
        <li>
          <p>Any hate symbols, regardless of context.</p>
        </li>
      </ul>

      <p>
        For our platforms that provide a content warning filter, we ask that you
        always flag any adult or not safe for work (NSFW) content with it.
      </p>
      <h2 className={styles.subtitle}>Malicious use of our platforms</h2>
      <p>
        We do not tolerate the malicious use of our services or platforms for
        spam or to harass another person, even if they are not a user of one of
        our products. The following are some examples of what we consider
        malicious use of our platforms:
      </p>

      <ul className={styles.policyList}>
        <li>
          <p>
            <b>Spam</b>
            {' '}
            - Bulk or aggressive activity that attempts to
            manipulate or disrupt our platform or the experience of our users on
            the platform. This also includes attempts to drive traffic or
            attention to unrelated account, products, services, or initiatives.
          </p>
        </li>
        <li>
          <p>
            <b>Harassment</b>
            {' '}
            - Any continued and targeted harassment of another
            user of the platform.
          </p>
        </li>
        <li>
          <p>
            <b>Doxing</b>
            {' '}
            - Revealing someone&apos;s personal information
            without their permission, including sensitive personally
            identifiable information such as addresses, social security numbers,
            passport numbers, phone numbers or bank account numbers.
          </p>
        </li>
        <li>
          <p>
            <b>Misleading content or metadata</b>
            {' '}
            - Do not create misleading
            descriptions, tags, titles, or thumbnails in order to increase views
            or otherwise trick users.
          </p>
        </li>
        <li>
          <p>
            <b>Scamming</b>
            {' '}
            - Any attempt to scam others either on or off
            platform that uses the platform as a tool in that scamming.
          </p>
        </li>
      </ul>

      <p>
        Attempts to use any of our platforms to harass or intimidate another
        person will result in permanent suspension. This includes attempts to
        create fake or harassing accounts, profiles, posts, or accounts created
        with the intention to attempt to scam others or are used at any point to
        scam others.
      </p>

      <h2 className={styles.subtitle}>Safety</h2>
      <p>
        We take the safety of our users seriously and will never ask you to
        share sensitive information, such as your password or social security
        number, with us or anyone else. If someone is asking you for this
        information, please do not comply and immediately let the support team
        know.
      </p>
      <p>
        ToLips.link will only contact you via the site dashboard and a ticket
        sent to and/or received from our official email addresses. If you are
        unsure about the legitimacy of an email, please contact us directly at
        {' '}
        <b>{globalEmail}</b>
        {' '}
        and we will be happy to assist. We will never request information via
        other channels. We similarly ask that you do not share information
        outside of these channels to ensure the security of your account.
      </p>

      <h2 className={styles.subtitle}>Questions?</h2>
      <p>
        If you are unsure of anything you have read, please go on your profile
        and
        {' '}
        <Link
          to={authToken && paths.profileContactUs}
          onClick={() => !authToken && setOpenLoginModal(true)}
        >
          contact us
        </Link>
        .
      </p>
      <LoginModal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        setOpenRegistrationModal={setOpenRegistrationModal}
      />
      <RegistrationModal
        open={openRegistrationModal}
        setOpen={setOpenRegistrationModal}
        setOpenLoginModal={setOpenLoginModal}
      />
    </div>
  );
};
export { CodeOfConduct };
