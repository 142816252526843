import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';

import { usePageTitle } from 'hooks/usePageTitle';

import { getVisitorData } from 'api/requests/visitor';
import { getNotifications } from 'api/requestFunctions/getNotifications';
import { getUserData } from 'api/requests';
import { useLocalContext } from 'LocalContext';
import { savedConstants } from 'constants/localStorage';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { paths } from 'constants/paths';
import { useProfileSettingsRoutes } from 'routes/ProfileSettingsRoutes';
import { useStaticFooterRoutes } from 'routes/StaticFooterRoutes';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useBlocking } from 'hooks/useBlocking';

import { GlobalWrapper } from 'components/GlobalWrapper/GlobalWrapper';
import {
  BlockingPage,
  Catalog,
  CatalogProfile,
  EmailConfirmation,
  ErrorPage,
  ForgotPasswordPage,
  Home,
  Registration,
} from '../pages';

const RoutesComponent = () => {
  const userToken = localStorage.getItem(savedConstants.AUTH_TOKEN);
  const userType = localStorage.getItem(savedConstants.USER_TYPE);
  const {
    localState: { notificationsCount, isBlocking },
    localDispatch,
  } = useLocalContext();

  const { isBlocking: actualIsBlocking } = useBlocking();

  usePageTitle();
  const { gaPageView } = useGoogleAnalytics();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const fetchUserInfo = async () => {
    setLoading(true);
    if (userToken && userType) {
      try {
        const res = userType === userProvider
          ? await getUserData()
          : await getVisitorData();
        localDispatch({ type: 'SET_USER' });
        let data;
        if (res.status === 200) {
          if (userType === userProvider) {
            data = res.data.card;
          } else {
            data = {
              ...res.data.card,
              favorites: [...res.data.favorites || []],
              user_type: userVisitor,
            };
          }
          localDispatch({ type: 'SET_IS_CREATED_PROFILE', payload: true });
          localDispatch({ type: 'SET_USER_INFO', payload: data });
        }
        if (res.status === 203) {
          localDispatch({ type: 'SET_USER_ID', payload: res.data.user_id });
        }
        setLoading(false);
      } catch (e) {
        localDispatch({ type: 'LOGOUT' });
      }
      localDispatch({ type: 'SET_IS_FETCHED_USER', payload: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    localDispatch({ type: 'SET_IS_BLOCKING', payload: actualIsBlocking });
  }, []);

  useEffect(() => {
    gaPageView();
    if (isBlocking) {
      navigate(paths.main, { replace: true });
    }
  }, [pathname, isBlocking]);

  useEffect(() => {
    if (
      pathname !== paths.profileNotifications
      && !notificationsCount
      && userToken
    ) {
      getNotifications({ localDispatch });
    }
    fetchUserInfo();
  }, [userToken, userType]);

  const footerRoutes = useStaticFooterRoutes();
  const profileSettingsRoutes = useProfileSettingsRoutes();

  return loading ? (
    <div
      style={{
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <GlobalWrapper>
      {isBlocking ? (
        <Routes>
          <Route path="/" element={<BlockingPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={paths.main} element={<Home />} />
          <Route path={paths.catalog} element={<Catalog />} />
          <Route path={paths.catalogProfile()} element={<CatalogProfile />} />

          {footerRoutes}
          {profileSettingsRoutes}

          <Route path={paths.registration} element={<Registration />} />
          <Route
            path={paths.emailConfirmation}
            element={<EmailConfirmation />}
          />
          <Route path={paths.forgotPassword} element={<ForgotPasswordPage />} />
          <Route path={paths.errorPage} element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/confirm_email" element={<Home />} />
        </Routes>
      )}
    </GlobalWrapper>
  );
};

export { RoutesComponent };
