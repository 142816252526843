import { Button } from 'antd';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { resendConfirmationEmail } from 'api/requests/auth';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { paths } from 'constants';
import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { OpenArrow } from 'components/ActiveIcon/OpenArrow';
import { Timer } from 'components/Timer';

import styles from './styled.module.css';

/*
locationState example:
{
 userEmail: string, (required)
 userStatus: number,
 userType: string, (required)
}
*/

const EmailConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { isPublicVersion } = usePublicVersion();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('Letter was sent');
  const [countOfResends, setCountOfResends] = useState(0);
  const [isResend, setIsResend] = useState(true);

  const onTimerEnd = () => {
    setIsResend(false);
    if (countOfResends === 3) setMessage(false);
  };

  if (!locationState.userEmail || !locationState.userType) {
    return <Navigate to="/" />;
  }

  const onResend = async () => {
    const query = {
      email: locationState.userEmail,
      user_type: locationState.userType,
    };
    setLoading(true);
    try {
      await resendConfirmationEmail(query);
      setMessage(t('common.letterResent'));
      setIsResend(true);
      setCountOfResends(countOfResends + 1);
    } catch (e) {
      setMessage(t('errors.somethingWrong'));
    }
    setLoading(false);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>
          <h1>
            {locationState.userStatus === 206
              ? t('pages.emailConfirmation.1')
              : t('pages.emailConfirmation.2')}
          </h1>
          <p>
            {locationState.userStatus === 206
              ? t('pages.emailConfirmation.3')
              : t('pages.emailConfirmation.4')}
          </p>
        </div>
        <div className={styles.content}>
          <img
            src={isPublicVersion ? publicLogo : logo}
            alt="logo"
            className={styles.logo}
          />
          <div className={styles.emailContent}>
            <p className={styles.emailTitle}>
              {t('pages.emailConfirmation.5')}
              {' '}
              <span>{locationState.userEmail}</span>
            </p>
            <p className={styles.link}>{t('pages.emailConfirmation.6')}</p>
            <div
              onClick={() => setOpen(!open)}
              className={`${styles.listTitle} ${open ? '' : styles.open}`}
            >
              <p>{t('pages.emailConfirmation.65')}</p>
              <OpenArrow
                className={`${styles.arrow} ${open ? styles.show : ''}`}
              />
            </div>
            <ul className={`${styles.list} ${open ? styles.show : ''}`}>
              {locationState.userStatus !== 206 && (
                <li>
                  <p>{t('pages.emailConfirmation.7')}</p>
                </li>
              )}
              <li>
                <p>{t('pages.emailConfirmation.8')}</p>
              </li>
              <li>
                <p>{t('pages.emailConfirmation.9')}</p>
              </li>
            </ul>
            {isResend && (
              <div className={styles.centerBlock}>
                <Timer minutes={1} onTimerEnd={onTimerEnd} />
              </div>
            )}
            {!isResend && countOfResends < 3 && (
              <Button
                className={styles.button}
                onClick={onResend}
                loading={loading}
              >
                {t('pages.emailConfirmation.10')}
              </Button>
            )}
            {!isResend && countOfResends === 3 && (
              <p className={styles.message}>
                {t('pages.emailConfirmation.11')}
                <br />
                {t('pages.emailConfirmation.12')}
              </p>
            )}
            {!!message && (
              <div className={styles.centerBlock}>
                <p className={styles.message}>{message}</p>
              </div>
            )}
          </div>
        </div>
        <Button
          className={`${styles.button} ${styles.mainButton}`}
          onClick={() => navigate(paths.main, { replace: true })}
        >
          {t('pages.emailConfirmation.13')}
        </Button>
      </div>
      <p>{t('pages.emailConfirmation.14')}</p>
    </div>
  );
};

export { EmailConfirmation };
