import { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { userVisitor } from 'constants/registrationUserType';

import { ProfileLayout } from '../components/ProfileLayout';
import { ReportToBlackList } from './components/ReportToBlackList';
import { BlackListData } from './components/BlackListData';

import styles from './styled.module.css';
import './styles.css';

const BlackList = () => {
  const {
    localState: { userInfo },
  } = useLocalContext();
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState(
    userInfo?.user_type === userVisitor ? '2' : '1',
  );
  const [isSend, setIsSend] = useState(false);

  const items = [
    {
      key: '1',
      label: <p>{t('pages.profile.blackList.1')}</p>,
      children: (
        <ReportToBlackList
          activeKey={activeKey}
          isSend={isSend}
          setIsSend={setIsSend}
        />
      ),
    },
    {
      key: '2',
      label: <p>{t('pages.profile.blackList.2')}</p>,
      children: <BlackListData isSend={isSend} />,
    },
  ];

  return (
    <ProfileLayout>
      <div
        className={`${styles.wrapper} ${
          userInfo?.user_type === userVisitor ? styles.hideNav : ''
        }`}
      >
        <Tabs
          defaultActiveKey={activeKey}
          items={items}
          rootClassName={`blackList ${
            userInfo?.user_type === userVisitor ? 'hideNav' : ''
          }`}
          onChange={(e) => setActiveKey(e)}
        />
      </div>
    </ProfileLayout>
  );
};

export { BlackList };
