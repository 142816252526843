import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import filter from 'assets/filter.svg';
import { genders } from 'utils/genders';
import { cities } from 'constants';

import { FiltersModal } from '../FiltersModal';
import { GendersModal } from '../GendersModal';

import styles from './styled.module.css';

const GlobalOverview = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [openGenders, setOpenGenders] = useState(false);
  const city = searchParams.get('city');
  const gender = searchParams.get('gender');
  const orientation = searchParams.get('orientation');

  const cityName = useMemo(
    () => cities.find((item) => {
      if (city === null) {
        return item.value === '';
      }
      return item.value === city;
    })?.label,
    [cities, city],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.options} onClick={() => setOpenGenders(true)}>
        <h4 className={styles.option}>{t(cityName)}</h4>
        <h4 className={styles.option}>
          {t(
            `pages.home.genders.${
              genders.find(
                (item) => item.gender === gender && item.orientation === orientation,
              )?.title
            }`,
          )}
        </h4>
      </div>
      <div className={styles.search} onClick={() => setOpenFilters(true)}>
        <img src={filter} alt="filter" />
        <p>{t('common.filters')}</p>
      </div>
      <FiltersModal open={openFilters} setOpen={setOpenFilters} />
      <GendersModal open={openGenders} setOpen={setOpenGenders} />
    </div>
  );
};

export { GlobalOverview };
