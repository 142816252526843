import { duration } from './filters';

export const formatBackToHotDealFront = (data) => {
  const incall = [];
  const outcall = [];
  data
    .map((item) => {
      const findItem = duration.find((el) => el.value === item.duration);
      return {
        ...item,
        label: findItem.label,
        labelId: findItem.id,
      };
    })
    .sort((a, b) => a.labelId - b.labelId)
    .forEach((item) => (item.type_of_service === 'incall' ? incall : outcall).push(item));

  return {
    incall,
    outcall,
  };
};

export const formatFrontHotDealToBack = (applyOnAll, formValues, backData) => {
  const { promotion, ...items } = formValues;
  if (applyOnAll && promotion) {
    return backData.map((item) => ({
      rate_id: +item.id,
      discount_type: 'percentage',
      price: +item.amount,
      percentage: +promotion,
    }));
  }

  const result = Object.entries(items)
    .filter((item) => !!item[1])
    .map(([key, percentage]) => {
      const [serviceType, dur] = key.split('__');
      const findItem = backData.find(
        (item) => item.type_of_service === serviceType && item.duration === dur,
      );

      return (
        findItem && {
          rate_id: +findItem.id,
          discount_type: 'percentage',
          price: +findItem.amount,
          percentage: +percentage,
        }
      );
    });

  return result;
};
