import { performAuthorizedRequest } from '../apiConfig';

const imagesPath = '/profile_albums';

export const createProfileAlbum = (query) => performAuthorizedRequest(`${imagesPath}/create`, query);

export const updateProfileAlbum = (query) => performAuthorizedRequest(`${imagesPath}/update`, query);

export const deleteProfileAlbum = (query) => performAuthorizedRequest(`${imagesPath}/destroy`, query);

export const uploadProfileAlbumImages = (query) => performAuthorizedRequest(`${imagesPath}/upload_images`, query);
