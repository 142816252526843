import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styls.module.css';

function Timer({ onTimerEnd, minutes = 15, seconds = 0 }) {
  const [time, setTime] = useState({ min: minutes, sec: seconds });
  const workerRef = useRef(null);

  useEffect(() => {
    if (!workerRef.current) {
      const workerCode = `
        let countdownInterval;
        self.onmessage = function (e) {
          if (e.data === 'start') {
            countdownInterval = setInterval(() => {
              self.postMessage('tick');
            }, 1000);
          } else if (e.data === 'stop') {
            clearInterval(countdownInterval);
          }
        };
      `;
      const blob = new Blob([workerCode], { type: 'application/javascript' });
      const blobUrl = URL.createObjectURL(blob);
      workerRef.current = new Worker(blobUrl);
    }

    const { min, sec } = time;

    if (min === 0 && sec === 0) {
      workerRef.current.postMessage('stop');
      onTimerEnd();
    } else {
      workerRef.current.postMessage('start');
      workerRef.current.onmessage = (e) => {
        if (e.data === 'tick') {
          if (sec > 0) {
            setTime({ min, sec: sec - 1 });
          } else {
            setTime({ min: min - 1, sec: 59 });
          }
        }
      };
    }

    return () => {
      workerRef.current.postMessage('stop');
    };
  }, [time]);

  return (
    <div className={styles.timer}>
      <p>
        {time.min < 10 ? `0${time.min}` : time.min}
        :
        {time.sec < 10 ? `0${time.sec}` : time.sec}
      </p>
    </div>
  );
}

Timer.propTypes = {
  onTimerEnd: PropTypes.func.isRequired,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
};

export { Timer };
