import { Spin } from 'antd';
import PropTypes from 'prop-types';

import styled from './styled.module.css';

const Spinner = ({ className }) => (
  <div className={`${styled.loaderWrapper} ${className || ''}`}>
    <Spin size="large" />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};

export { Spinner };
