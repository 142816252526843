import { reactAppPublicApiUrl } from '../config';

export const getFileBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

// old feature
const readFileAsArrayBuffer = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
  reader.readAsBinaryString(file);
});
// ...

export const formatAndCompressImage = async (imageFile) => {
  if (!imageFile) {
    return null;
  }

  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = async (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxDimension = 2048;
        let { width, height } = img;

        if (width > height) {
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const compressImage = (quality) => new Promise((res) => {
          canvas.toBlob(
            (blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                res(reader.result);
              };
              reader.readAsArrayBuffer(blob);
            },
            'image/jpeg',
            quality,
          );
        });

        const compressToTargetSize = async () => {
          let quality = 1;
          let result = await compressImage(quality);

          while (result.byteLength > 250 * 1024 && quality > 0.1) {
            quality -= 0.1;
            // eslint-disable-next-line no-await-in-loop
            result = await compressImage(quality);
          }

          const byteArray = new Uint8Array(result);
          let binaryString = '';
          for (let i = 0; i < byteArray.byteLength; i += 1) {
            binaryString += String.fromCharCode(byteArray[i]);
          }

          resolve(binaryString);
        };

        await compressToTargetSize();
      };

      img.onerror = () => {
        console.log('Ошибка загрузки изображения');
      };
    };

    fileReader.onerror = (error) => {
      reject(error);
    };

    fileReader.readAsDataURL(imageFile);
  });
};

export const formatAndCompressImageForAlbum = async (imageBlobUrl) => {
  if (!imageBlobUrl) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageBlobUrl;

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const maxDimension = 2048;
      let { width, height } = img;

      if (width > height) {
        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }
      } else if (height > maxDimension) {
        width *= maxDimension / height;
        height = maxDimension;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      const compressImage = (quality) => new Promise((res) => {
        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              res(reader.result);
            };
            reader.readAsArrayBuffer(blob);
          },
          'image/jpeg',
          quality,
        );
      });

      const compressToTargetSize = async () => {
        let quality = 1;
        let result = await compressImage(quality);

        while (result.byteLength > 250 * 1024 && quality > 0.1) {
          quality -= 0.1;
          // eslint-disable-next-line no-await-in-loop
          result = await compressImage(quality);
        }

        const byteArray = new Uint8Array(result);
        let binaryString = '';
        for (let i = 0; i < byteArray.byteLength; i += 1) {
          binaryString += String.fromCharCode(byteArray[i]);
        }

        resolve(binaryString);
      };

      await compressToTargetSize();
    };

    img.onerror = () => {
      console.log('Ошибка загрузки изображения');
      reject(new Error('Ошибка загрузки изображения'));
    };
  });
};

export const formatBackImagesToFront = (data) => {
  const avatarImages = [];
  const photoCollectionImages = [];
  if (data) {
    data.forEach((item) => {
      const formatEl = {
        id: item.id,
        position: item.position,
        uid: item.uid,
        name: item.image_url,
        url: `${reactAppPublicApiUrl}${item.image_url}`,
        status: 'done',
      };
      if (formatEl.position === 0) {
        avatarImages.push(formatEl);
      } else {
        photoCollectionImages.push(formatEl);
      }
    });
  }

  return {
    avatarImages,
    photoCollectionImages,
  };
};

export const formatFrontImagesToBack = async (
  avatar,
  photoCollection,
  backData,
  isFlipperFeatureEnabled = true,
) => {
  const result = [];
  const emptyPositionInBackImages = [];
  const occupiedPositionInBackImages = [];

  [1, 2, 3, 4].forEach((item) => {
    if (backData && backData.some((el) => el.position === item)) {
      occupiedPositionInBackImages.push(item);
    } else {
      emptyPositionInBackImages.push(item);
    }
  });

  const processAvatar = async () => {
    if (!avatar[0]) {
      result.push({
        ...(backData && {
          id: backData?.[0]?.id,
        }),
        position: 0,
        file: null,
      });
    } else if (!avatar[0].id) {
      const binaryString = isFlipperFeatureEnabled
        ? await formatAndCompressImage(avatar[0].originFileObj)
        : await readFileAsArrayBuffer(avatar[0].originFileObj);
      result.push({
        ...(backData && {
          id: backData.find((item) => item.position === 0)?.id,
        }),
        position: 0,
        file: {
          filename: avatar[0].name,
          io: binaryString,
        },
      });
    }
  };

  const processPhotoCollection = async () => {
    if (photoCollection) {
      const deletedImages = occupiedPositionInBackImages.filter(
        (item) => !photoCollection.some((el) => el.position === item),
      );

      deletedImages.forEach((item) => {
        emptyPositionInBackImages.push(item);
        emptyPositionInBackImages.sort();
        result.push({
          id: backData.find((el) => el.position === item)?.id,
          position: item,
          file: null,
        });
      });

      const promises = photoCollection.map(async (element) => {
        if (element.originFileObj) {
          const binaryString = isFlipperFeatureEnabled
            ? await formatAndCompressImage(element.originFileObj)
            : await readFileAsArrayBuffer(element.originFileObj);
          result.push({
            position: emptyPositionInBackImages[0],
            file: {
              filename: element.name,
              io: binaryString,
            },
          });
          emptyPositionInBackImages.sort().shift();
        }
      });

      await Promise.all(promises);
    }
  };

  await processAvatar();
  await processPhotoCollection();

  const finallyArray = result.reduce((acc, item) => {
    const existingItem = acc.find(
      (mergedItem) => mergedItem.position === item.position,
    );

    if (existingItem) {
      existingItem.file = existingItem.file || item.file;
    } else {
      acc.push({ ...item });
    }

    return acc;
  }, []);

  return finallyArray;
};

export const formatFrontImagesVerificationToBack = async (
  data,
  isFlipperFeatureEnabled = true,
) => {
  const result = [];
  const promises = data.map(async (element) => {
    if (element.originFileObj) {
      const binaryString = isFlipperFeatureEnabled
        ? await formatAndCompressImage(element.originFileObj)
        : await readFileAsArrayBuffer(element.originFileObj);
      result.push({
        file: {
          filename: element.name,
          io: binaryString,
        },
      });
    }
  });
  await Promise.all(promises);
  return result;
};

export const formatAlbumForBackend = async (
  data,
  back,
) => {
  const dataId = data.filter((item) => item.id).map((item) => item.id);
  const backId = back.filter((item) => item.id).map((item) => item.id);
  const result = [];

  if (dataId.length !== backId.length) {
    const deletedItems = backId.filter((item) => !dataId.includes(item));
    deletedItems.map((item) => result.push({
      id: item,
      file: null,
    }));
  }

  const promises = data.map(async (element, index) => {
    if (typeof element === 'string') {
      const binaryString = await formatAndCompressImageForAlbum(element);
      const formattedImages = {
        filename: element.split('/')[element.split('/').length - 1],
        io: binaryString,
      };
      result.push({
        id: index,
        file: formattedImages,
      });
    }
  });

  await Promise.all(promises);
  return result;
};
