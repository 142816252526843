import { useTranslation } from 'react-i18next';

import { useBlocking } from 'hooks/useBlocking';

import { TimerFlipClock } from 'components/TimerFlipClock';

import logo from 'assets/logo.svg';

import styles from './styled.module.css';

const BlockingPage = () => {
  const { blockingTime } = useBlocking();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h1>{t('pages.blockingPage.1')}</h1>
      <img src={logo} alt="logo" />
      <p>{t('pages.blockingPage.2')}</p>
      <p>{t('pages.blockingPage.3')}</p>
      <TimerFlipClock time={blockingTime} />
    </div>
  );
};
export { BlockingPage };
