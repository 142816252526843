import { useLocalContext } from 'LocalContext';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { Spinner } from 'components/Spinner';
import { ProfileLayout } from '../components/ProfileLayout';
import ProviderProfile from './components/ProviderProfile';
import VisitorProfile from './components/VisitorProfile';

const MyProfile = () => {
  const {
    localState: { userInfo },
  } = useLocalContext();

  if (userInfo) {
    return (
      <ProfileLayout>
        {userInfo.user_type === userProvider && <ProviderProfile />}
        {userInfo.user_type === userVisitor && <VisitorProfile />}
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <Spinner />
    </ProfileLayout>
  );
};

export { MyProfile };
