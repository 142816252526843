import { quantityPerCatalogPage } from 'constants/quantityPerPage';

const isOnlUndefinedValue = (arr) => !arr?.length || (arr.length === 1 && arr[0] === undefined);

const filterObject = (obj) => {
  const filtered = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      filtered[key] = obj[key];
    }
  });

  return filtered;
};

export const prepareBody = (
  city,
  district,
  gender,
  orientation,
  verified,
  service,
  page,
  filters,
) => {
  let body = {
    gender,
    orientation,
    limit: quantityPerCatalogPage,
    offset: (page - 1) * quantityPerCatalogPage,
  };

  if (district && !!district.length) {
    body.district = district;
  }

  if (city) {
    body.city = city;
  }
  if (verified) {
    body.verified = !!verified;
  }
  if (service) {
    body.service = 'outcall';
  }

  if (filters && !!Object.keys(filters).length) {
    if (filters.duration) {
      body.rates = {
        duration: filters.duration,
        min_amount: filters.price ? filters.price[0] : undefined,
        max_amount: filters.price ? filters.price[1] : undefined,
      };
    }

    if (
      filters.weight__kg[0] !== filters.weight__kg_min
      || filters.weight__lbs[0] !== filters.weight__lbs_min
    ) {
      body.min_weight = filters.weight_type === 'kg'
        ? filters.weight__kg[0]
        : Math.round(filters.weight__lbs[0] * 0.453592);
    }

    if (
      filters.weight__kg[1] !== filters.weight__kg_max
      || filters.weight__lbs[1] !== filters.weight__lbs_max
    ) {
      body.max_weight = filters.weight_type === 'kg'
        ? filters.weight__kg[1]
        : Math.round(filters.weight__lbs[1] * 0.453592);
    }

    if (
      filters.height__cm[0] !== filters.height__cm_min
      || filters.height__ft[0] !== filters.height__ft_min
    ) {
      body.min_height = filters.height_type === 'cm'
        ? filters.height__cm[0]
        : Math.round(filters.height__ft[0] * 30.48);
    }

    if (
      filters.height__cm[1] !== filters.height__cm_max
      || filters.height__ft[1] !== filters.height__ft_max
    ) {
      body.max_height = filters.height_type === 'cm'
        ? filters.height__cm[1]
        : Math.round(filters.height__ft[1] * 30.48);
    }

    if (filters.age[0] !== filters.age_min) {
      const [minAge] = filters.age;
      body.min_age = minAge;
    }

    if (filters.age[1] !== filters.age_max) {
      const [, maaxAge] = filters.age;
      body.max_age = maaxAge;
    }

    if (filters.hot_deal_page) {
      body.hot_deal_page = filters.hot_deal_page;
    }

    body = {
      ...body,
      type_of_service: filters.type_of_service
        ? [filters.type_of_service]
        : undefined,
      breast_sizes: isOnlUndefinedValue(filters.cup_size)
        ? undefined
        : filters.cup_size,
      breast_types: isOnlUndefinedValue(filters.type)
        ? undefined
        : filters.type,
      hair_colors: isOnlUndefinedValue(filters.color)
        ? undefined
        : filters.color,
      hair_length: isOnlUndefinedValue(filters.length)
        ? undefined
        : filters.length,
      pubic_hairs: isOnlUndefinedValue(filters.pubic_hair)
        ? undefined
        : filters.pubic_hair,
      travel: filters.travel,
      ethnicities: isOnlUndefinedValue(filters.ethnicity)
        ? undefined
        : filters.ethnicity,
      nationalities: isOnlUndefinedValue(filters.nationality)
        ? undefined
        : filters.nationality,
      languages: isOnlUndefinedValue(filters.languages)
        ? undefined
        : filters.languages,
      tattoo: filters.tattoo,
      piercing: isOnlUndefinedValue(filters.piercing)
        ? undefined
        : filters.piercing,
      smoker: filters.smoking,
      eye_colors: isOnlUndefinedValue(filters.eye_color)
        ? undefined
        : filters.eye_color,
      zodiac_signs: isOnlUndefinedValue(filters.zodiac)
        ? undefined
        : filters.zodiac,
      favors: isOnlUndefinedValue(filters.services)
        ? undefined
        : filters.services,
    };
  }
  const filteredBody = filterObject(body);
  return {
    ...filteredBody,
  };
};
