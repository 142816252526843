import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'components/ActiveIcon/CloseIcon';
import { OkIcon } from 'components/ActiveIcon/OkIcon';

import styles from './styled.module.css';

const Availability = ({ availability }) => {
  const { t } = useTranslation();

  const today = useMemo(() => {
    const date = new Date();
    return date.toLocaleDateString('en-EN', { weekday: 'long' }).toLowerCase();
  }, []);

  const [actualDay, setActualDay] = useState(
    availability?.find((el) => el.day === today) || today,
  );

  return (
    <div className={styles.availability}>
      <p className={styles.blockTitle}>{t('pages.profileCreation.7')}</p>
      <div className={styles.blockDescription}>
        {availability.map((item) => (
          <div
            key={item.day}
            onClick={() => setActualDay(item)}
            className={`${styles.availabilityBlock} 
              ${item.day === actualDay?.day ? styles.actualDay : ''}`}
          >
            <div className={styles.availabilityDay}>
              <span>{item.day.slice(0, 3)}</span>
              {item.available ? (
                <OkIcon
                  className={`${styles.okIcon} ${
                    item.day === actualDay?.day ? styles.actual : ''
                  }`}
                />
              ) : (
                <CloseIcon
                  className={`${styles.closeIcon} ${
                    item.day === actualDay?.day ? styles.actual : ''
                  }`}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {(actualDay?.start_time || actualDay?.end_time) && (
        <div className={styles.time}>
          {actualDay?.start_time === '00:00'
          && actualDay?.end_time === '23:59' ? (
            <span>
              <b>{t('components.aboutUser.availability.24')}</b>
            </span>
            ) : (
              <span>
                {actualDay?.start_time && `${t('common.from')} `}
                {actualDay?.start_time && <b>{actualDay.start_time}</b>}
                {actualDay?.end_time && ` ${t('common.to')} `}
                {actualDay?.end_time && <b>{actualDay.end_time}</b>}
              </span>
            )}
        </div>
      )}
    </div>
  );
};

Availability.propTypes = {
  availability: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      available: PropTypes.bool.isRequired,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
    }),
  ).isRequired,
};

export { Availability };
