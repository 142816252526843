import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants/paths';
import { userProvider } from 'constants/registrationUserType';

import { SettingsIcon } from 'components/ActiveIcon/SettingsIcon';
import { LogoutIcon } from 'components/ActiveIcon/LogoutIcon';
import { UserIcon } from 'components/ActiveIcon/UserIcon';
import { DropdownArrowIcon } from 'components/ActiveIcon/DropdownArrowIcon';
import { InfoModal } from 'pages/ProfileCreation/components/InfoModal';

import styles from './styled.module.css';
import './styles.css';

const UserDropDown = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const userDown = [
    {
      key: 'settings',
      label:
        pathname !== paths.profileCreation ? (
          <Link to={paths.myProfile} className={styles.userDropItem}>
            <SettingsIcon className={styles.settingsIcon} />
            <p>{t('common.myProfile')}</p>
          </Link>
        ) : (
          <div
            className={styles.userDropItem}
            onClick={() => {
              if (pathname === paths.profileCreation) {
                setOpenInfoModal(true);
              }
            }}
          >
            <SettingsIcon className={styles.settingsIcon} />
            <p>{t('common.myProfile')}</p>
          </div>
        ),
    },
    {
      key: 'logout',
      label: (
        <div
          className={styles.userDropItem}
          onClick={() => {
            localDispatch({ type: 'LOGOUT' });
            navigate(paths.main);
          }}
        >
          <LogoutIcon className={styles.logoutIcon} />
          <p>{t('common.logout')}</p>
        </div>
      ),
    },
  ];
  return (
    <>
      <Dropdown
        menu={{
          items: userDown,
        }}
        trigger={['click']}
        rootClassName="userDropDown"
      >
        <div
          className={`${styles.userDrop} ${
            userInfo?.user_type === userProvider ? styles.user : ''
          }`}
        >
          {userInfo && userInfo.user_type === userProvider && (
            <p>
              {+userInfo.balance}
              {' '}
              GTL
            </p>
          )}
          <UserIcon className={styles.userIcon} />
          <DropdownArrowIcon className={styles.downArrowIcon} />
        </div>
      </Dropdown>

      <InfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        onClick={() => navigate(paths.myProfile)}
      />
    </>
  );
};

export { UserDropDown };
