import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { paths } from 'constants';
import image404 from 'assets/404.svg';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <img className={styles.errorImage} src={image404} alt="error" />
      <p className={styles.message}>{t('common.ohNoNothingHere')}</p>
      <Button
        type="primary"
        className={`${styles.button}`}
        onClick={() => navigate(paths.main)}
      >
        {t('common.goToMain')}
      </Button>
    </div>
  );
};

export { ErrorPage };
