import { Route } from 'react-router-dom';

import { paths } from 'constants';
import {
  About,
  AdvertisingPolicy,
  AntiExploitationPolicy,
  CodeOfConduct,
  DMCAPage,
  DeletePolicy,
  PrivacyPolicy,
  Terms,
} from 'pages';

const useStaticFooterRoutes = () => (
  <>
    <Route path={paths.terms} element={<Terms />} />
    <Route path={paths.about} element={<About />} />
    <Route
      path={paths.antiExploitationPolicy}
      element={<AntiExploitationPolicy />}
    />
    <Route path={paths.advertisingPolicy} element={<AdvertisingPolicy />} />
    <Route path={paths.codeOfConduct} element={<CodeOfConduct />} />
    <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
    <Route path={paths.deletePolicy} element={<DeletePolicy />} />
    <Route path={paths.dmcaPage} element={<DMCAPage />} />
  </>
);

export { useStaticFooterRoutes };
