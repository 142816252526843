import { useMemo } from 'react';
import { Menu, Steps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { isProductionVisible } from 'config';
import { profileStatus } from 'constants/profileStatus';
import { paths, savedConstants } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { useLocalContext } from 'LocalContext';
import burger from 'assets/burger.svg';

import { StepTitle } from '../StepTitle';

import styles from './styled.module.css';
import './styles.css';

const Navigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const lng = localStorage.getItem(savedConstants.LNG);

  const {
    localState: { userInfo, notificationsCount },
  } = useLocalContext();

  const steps = useMemo(
    () => [
      {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.1')}
            link={paths.myProfile}
          />
        ),
        link: paths.myProfile,
      },
      {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.2')}
            link={paths.profileSettings}
          />
        ),
        link: paths.profileSettings,
      },
      userInfo?.user_type === userProvider
        && userInfo?.status !== profileStatus.new
        && userInfo?.status !== profileStatus.draft && {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.3')}
            link={isProductionVisible ? '.' : paths.profileWallet}
          />
        ),
        link: paths.profileWallet,
      },
      userInfo?.status !== profileStatus.new
        && userInfo?.status !== profileStatus.draft && {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.4')}
            link={
                isProductionVisible && userInfo?.user_type === userVisitor
                  ? '.'
                  : paths.profileNotifications
              }
            badge={
                isProductionVisible && userInfo?.user_type === userVisitor
                  ? ''
                  : notificationsCount || ''
              }
          />
        ),
        link: paths.profileNotifications,
      },
      userInfo?.status !== profileStatus.new
        && userInfo?.status !== profileStatus.draft && {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.5')}
            link={paths.profileBlackList}
          />
        ),
        link: paths.profileBlackList,
      },
      {
        title: (
          <StepTitle
            name={t('pages.profile.components.navigation.6')}
            link={paths.profileContactUs}
          />
        ),
        link: paths.profileContactUs,
      },
    ],
    [userInfo, pathname, notificationsCount, lng],
  );

  const currentStep = useMemo(
    () => steps.find((step) => step.link === pathname),
    [pathname, steps],
  );

  const menuSteps = useMemo(
    () => steps.map((step) => ({
      key: step.link,
      label: (
        <Link to={step.link}>
          <span>{step.title}</span>
        </Link>
      ),
    })),
    [steps, pathname],
  );

  return (
    <div className={`${styles.wrapper} profile`}>
      <Menu
        mode="inline"
        rootClassName={`${styles.menu} menu`}
        items={[
          {
            key: 'title',
            label: (
              <div className={styles.menuLabel}>
                <div className={styles.menuTitle}>
                  {currentStep?.title}
                  <img src={burger} alt="burger" />
                </div>
                {currentStep?.description}
              </div>
            ),
            children: menuSteps,
          },
        ]}
      />
      <Steps
        current={pathname}
        items={steps}
        direction="horizontal"
        rootClassName={`${styles.steps} steps `}
      />
    </div>
  );
};

export { Navigation };
