export const theme = {
  token: {
    fontSize: 16,
    borderRadius: 10,
    colorPrimary: '#633976',
    colorInfo: '#633976',
    sizeStep: 4,
  },
  components: {
    Input: {
      colorBorder: 'transparent',
      colorIcon: 'rgb(99, 57, 118)',
      colorText: 'rgb(99, 57, 118)',
      controlHeight: 51,
      colorTextDescription: 'rgba(117, 83, 130, 0.7)',
      colorTextPlaceholder: 'rgba(117, 83, 130, 0.71)',
      fontSizeIcon: 16,
    },
    Modal: {
      contentBg: 'rgb(244, 245, 246)',
      borderRadiusLG: 10,
      borderRadiusSM: 10,
      boxShadow: '0 0 30px 0 rgba(255, 26, 122, 1)',
    },
    Drawer: {
      colorBgElevated: 'rgb(244, 245, 246)',
      boxShadow: '0 0 30px 0 rgba(255, 26, 122, 1)',
    },
    Checkbox: {
      controlInteractiveSize: 18,
      fontSizeLG: 16,
      lineWidthBold: 2,
      lineWidthFocus: 4,
      colorText: 'rgb(99, 54, 116)',
    },
    Button: {
      colorBorder: 'rgb(99, 57, 118)',
      colorText: 'rgba(117, 83, 130, 0.7)',
      borderColorDisabled: 'rgb(99, 57, 118)',
      colorBgContainerDisabled: '#eaebec',
    },
    Divider: {
      colorText: 'rgb(99, 57, 118)',
      colorSplit: 'rgb(99, 57, 116)',
    },
    DatePicker: {
      colorBorder: 'transparent',
      colorIcon: 'rgb(99, 57, 116)',
      colorText: 'rgb(99, 54, 116)',
      colorTextHeading: 'rgb(99, 57, 116)',
      colorTextPlaceholder: 'rgba(117, 83, 130, 0.7)',
      colorTextDisabled: 'rgb(99, 57, 118)',
      controlHeight: 51,
    },
    InputNumber: {
      colorBorder: 'transparent',
      colorText: 'rgb(99, 57, 118)',
      colorTextPlaceholder: 'rgba(117, 83, 130, 0.7)',
      controlHeight: 51,
    },
    Select: {
      colorBorder: 'transparent',
      colorIcon: 'rgb(99, 57, 118)',
      colorTextPlaceholder: 'rgba(117, 83, 130, 0.7)',
      controlHeight: 51,
      fontSizeIcon: 16,
      colorText: 'rgb(99, 57, 118)',
      controlItemBgActive: 'rgba(255, 26, 122, 0.46)',
      controlItemBgHover: 'rgba(255, 26, 122, 0.16)',
      colorFillSecondary: 'rgba(255, 26, 122, 0.16)',
    },
    Slider: {
      controlHeight: 51,
      colorPrimaryBorder: 'rgb(255, 26, 122)',
      colorBgElevated: 'rgb(255, 26, 122)',
    },
    Tabs: {
      padding: 0,
      colorText: 'rgb(99, 57, 118)',
    },
    Radio: {
      border: 'transparent',
      colorPrimary: 'rgb(255, 26, 122)',
      colorText: '#633976',
    },
    Table: {
      colorBgContainer: 'rgb(244, 245, 246)',
      colorBorderSecondary: 'rgb(244, 245, 246)',
      colorFillAlter: 'rgb(244, 245, 246)',
      colorIcon: 'rgb(99, 57, 118)',
      colorLinkActive: 'rgb(99, 57, 118)',
      colorText: 'rgb(99, 57, 118)',
      colorTextHeading: 'rgb(99, 57, 118)',
    },
  },
};
