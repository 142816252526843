import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePublicVersion } from 'hooks/usePublicVersion';

import { paths } from 'constants';

import styles from './styled.module.css';

const footerLinks = [
  { id: 0, text: 'components.footer.terms', url: paths.terms },
  { id: 1, text: 'components.footer.privacy', url: paths.privacyPolicy },
  {
    id: 2,
    text: 'components.footer.policy',
    url: paths.antiExploitationPolicy,
  },
  { id: 3, text: 'components.footer.about', url: paths.about },
];

export const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isPublicVersion } = usePublicVersion();

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerContainer}>
        {footerLinks.map((link) => (
          <Link
            key={link.id}
            to={link.url}
            className={`${styles.footerLink} ${
              pathname === link.url && styles.footerLink__active
            }`}
          >
            {t(link.text)}
          </Link>
        ))}
      </div>
      {!isPublicVersion && (
        <span>2024 ToLips.link - sex ads - all rights reserved</span>
      )}
    </div>
  );
};
