/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import PropTypes from 'prop-types';

import 'react-international-phone/style.css';
import './styles.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneCode = ({
  form,
  dependencies,
  phoneCode,
  name,
  withInitialValue,
  initialValue,
  className,
  optional = false,
  setErrors,
  errors,
  status,
  help,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState(phoneCode || '+48');

  return form ? (
    <Form.Item
      rootClassName="phoneFormItem"
      name={name}
      className={className}
      dependencies={dependencies && [dependencies]}
      initialValue={initialValue || (withInitialValue && '+48')}
      validateStatus={status}
      help={help}
      rules={[
        {
          validator: (_, value) => new Promise((resolve, reject) => {
            let actualValue = value;

            if (code === value) {
              actualValue = undefined;
              form.setFieldValue(name, undefined);
            }

            if (
              dependencies
                && !form.getFieldValue(dependencies)
                && !actualValue
            ) {
              reject(new Error(t('errors.visitorNumberOrSocial')));
            }

            if (!dependencies && !actualValue && !optional) {
              reject(new Error(t('errors.inputPhone')));
            }

            if (!isPhoneValid(value) && code !== value && value) {
              reject(new Error(t('errors.enterValidPhone')));
            }
            resolve();
          }),
        },
      ]}
    >
      <PhoneInput
        className="phoneInput"
        defaultCountry="pl"
        forceDialCode
        inputProps={{
          id: 'phone_number',
        }}
        onChange={(_, meta) => {
          if (status === 'error') {
            const filteredErrors = { ...errors };
            if (filteredErrors.phone_number) {
              delete filteredErrors.phone_number;
              setErrors(filteredErrors);
            }
          }
          setCode(`+${meta.country.dialCode}`);
        }}
      />
    </Form.Item>
  ) : (
    <div className="phoneFormItem">
      <PhoneInput
        className="phoneInput"
        defaultCountry="pl"
        forceDialCode
        value={initialValue}
        onChange={(_, meta) => {
          onChange(meta);
        }}
      />
    </div>
  );
};

PhoneCode.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
  }),
  withInitialValue: PropTypes.bool,
  initialValue: PropTypes.string,
  optional: PropTypes.bool,
  dependencies: PropTypes.string,
  phoneCode: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setErrors: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.objectOf(PropTypes.any),
};

export { PhoneCode };
