import { useState } from 'react';

import { useLocalContext } from 'LocalContext';
import { profileStatus } from 'constants/profileStatus';
import { verificationStatus } from 'constants/verificationStatus';

import { UpModal } from '../UpModal';
import { HotDealModal } from '../HotDealModal';
import { WeeklyOfferModal } from '../WeeklyOfferModal';

import styles from './styled.module.css';

const LiftingBlockButton = () => {
  const {
    localState: {
      userInfo: { status, verification_status },
    },
  } = useLocalContext();

  const [openUpModal, setOpenUpModal] = useState(false);
  const [openWeeklyOfferModal, setOpenWeeklyOfferModal] = useState(false);
  const [openHotDealModal, setOpenHotDealModal] = useState(false);

  if (status !== profileStatus.published) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.options} ${
          verification_status === verificationStatus.verified
            ? ''
            : styles.disabled
        }`}
      >
        <h4
          className={styles.option}
          onClick={() => {
            setOpenWeeklyOfferModal(true);
          }}
        >
          Weekly offer
        </h4>
        <h4
          className={styles.option}
          onClick={() => {
            setOpenHotDealModal(true);
          }}
        >
          Hot Deal
        </h4>
      </div>
      <div className={styles.up} onClick={() => setOpenUpModal(true)}>
        <p>UP</p>
      </div>
      <UpModal open={openUpModal} setOpen={setOpenUpModal} />
      <WeeklyOfferModal
        open={openWeeklyOfferModal}
        setOpen={setOpenWeeklyOfferModal}
      />
      <HotDealModal open={openHotDealModal} setOpen={setOpenHotDealModal} />
    </div>
  );
};

export { LiftingBlockButton };
