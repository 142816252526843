import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useResetPrivatePage } from 'hooks/useResetPrivatePage';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';

import { VerificationPage } from './components/VerificationPage';
import { StartPage } from './components/StartPage';

const Verification = () => {
  useResetPrivatePage();
  const {
    localState: { isPrivatePage },
  } = useLocalContext();
  const [showVerificationPage, setShowVerificationPage] = useState(false);

  if (!isPrivatePage) {
    return <Navigate to={paths.myProfile} replace />;
  }

  return showVerificationPage ? (
    <VerificationPage setShowVerificationPage={setShowVerificationPage} />
  ) : (
    <StartPage setShowVerificationPage={setShowVerificationPage} />
  );
};

export { Verification };
