import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';

import { deleteProfileAlbum } from 'api/requests/profileAlbums';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const PhotoAlbumModal = ({
  open = false,
  setOpen,
  photoAlbumId,
  setPhotoAlbum,
  setPhotoAlbumId,
  setBackDataAlbum,
}) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const handleDeleteAlbum = async () => {
    try {
      await deleteProfileAlbum({ id: photoAlbumId });
      setPhotoAlbumId(null);
      setPhotoAlbum([]);
      setBackDataAlbum([]);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: { ...userInfo, albums: [] },
      });
      setOpen(false);
    } catch {
      console.log('delete album has problem');
    }
  };
  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <div className={styles.deleteModal}>
        <h4 className={styles.deleteModalTitle}>
          {t('components.photoAlbum.7')}
        </h4>
        <p className={styles.deleteModalText}>{t('components.photoAlbum.8')}</p>
        <div className={styles.deleteModalFooter}>
          <Button secondary onClick={handleDeleteAlbum}>
            {t('components.photoAlbum.9')}
          </Button>
          <Button primary onClick={() => setOpen(false)}>
            {t('components.photoAlbum.10')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

PhotoAlbumModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  photoAlbumId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  setPhotoAlbum: PropTypes.func.isRequired,
  setPhotoAlbumId: PropTypes.func.isRequired,
  setBackDataAlbum: PropTypes.func.isRequired,
};

export { PhotoAlbumModal };
