import { useState, useEffect } from 'react';

import { savedConstants } from 'constants';
import { useLocalContext } from 'LocalContext';

const useBlocking = () => {
  const { localDispatch } = useLocalContext();

  const blockingData = JSON.parse(
    localStorage.getItem(savedConstants.BLOCKING),
  );
  const today = new Date().getTime();
  const blockingTime = blockingData?.t && blockingData?.c
    ? +blockingData.t
        + 3 ** (+blockingData.c > 5 ? 5 : +blockingData.c) * 60 * 60 * 1000
    : today;

  const [isBlocking, setIsBlocking] = useState(blockingTime > today);

  useEffect(() => {
    if (blockingData) {
      setIsBlocking(blockingTime > today);
      if (blockingTime > today) {
        setTimeout(() => {
          setIsBlocking(false);
          localDispatch({ type: 'SET_IS_BLOCKING', payload: false });
        }, +blockingTime - today);
      }
    }
  }, [isBlocking]);

  return {
    isBlocking,
    blockingTime,
  };
};

export { useBlocking };
