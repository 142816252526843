import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { uploadProfileAlbumImages } from 'api/requests/profileAlbums';
import { getUserData } from 'api/requests';
import { setImages } from 'api/requests/images';
import { sendProfileToModeration } from 'api/requests/profile';

import {
  formatAlbumForBackend,
  formatBackImagesToFront,
  formatFrontImagesToBack,
} from 'utils/images';
import { paths } from 'constants';
import { useLocalContext } from 'LocalContext';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const formatItems = (items) => items.map((item) => ({
  id: item.id,
  position: item.position,
  name: item.name,
  url: item.url,
  status: item.status,
}));

const getModalText = (loading, error) => {
  const { t } = useTranslation();
  let title;
  let firstText;
  let secondText;

  if (loading) {
    title = t('pages.profileCreation.photos.photosModal.1');
    firstText = (
      <p>
        {t('pages.profileCreation.photos.photosModal.2')}
        <br />
        {t('pages.profileCreation.photos.photosModal.3')}
      </p>
    );
    secondText = <p>{t('pages.profileCreation.photos.photosModal.4')}</p>;
  } else if (error) {
    title = t('common.ohNo');
    firstText = <p>{t('pages.profileCreation.photos.photosModal.5')}</p>;
    secondText = <p>{t('pages.profileCreation.photos.photosModal.6')}</p>;
  } else {
    title = t('pages.profileCreation.photos.photosModal.7');
    firstText = (
      <p>
        {t('pages.profileCreation.photos.photosModal.8')}
        <br />
        {t('pages.profileCreation.photos.photosModal.9')}
      </p>
    );
    secondText = (
      <p>
        {t('pages.profileCreation.photos.photosModal.10')}
        <br />
        {t('common.administration')}
      </p>
    );
  }

  return { title, firstText, secondText };
};

const PhotosModal = memo(
  ({
    open = false,
    setOpen,
    avatar,
    photoCollection,
    photoAlbum,
    photoAlbumId,
    backDataAlbum,
    isImageConverterEnabled,
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
      localState: { userInfo },
      localDispatch,
    } = useLocalContext();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { title, firstText, secondText } = getModalText(loading, error);

    const getUserInfo = async () => {
      const res = await getUserData();
      if (res.status === 200) {
        localDispatch({ type: 'SET_IS_CREATED_PROFILE', payload: true });
        localDispatch({ type: 'SET_USER_INFO', payload: res.data.card });
      }
    };

    const sendPhotoAvatar = async () => {
      const backData = await formatBackImagesToFront(userInfo.images);
      const images = await formatFrontImagesToBack(
        avatar,
        photoCollection,
        userInfo.images,
        isImageConverterEnabled,
      );
      const isEmptyPhotos = JSON.stringify(formatItems([...avatar, ...photoCollection]))
        === JSON.stringify(
          formatItems([
            ...backData.avatarImages,
            ...backData.photoCollectionImages,
          ]),
        );

      const query = {
        images,
      };

      let status = true;
      if (!isEmptyPhotos) {
        const res = await setImages(query);
        status = res?.status === 200;
      }
      return {
        isSendAvatar: !isEmptyPhotos,
        isSendAvatarOk: status,
      };
    };

    const sendAlbumPhotos = async () => {
      const images = await formatAlbumForBackend(
        photoAlbum,
        backDataAlbum,
        isImageConverterEnabled,
      );
      let status = true;
      if (images.length) {
        const query = {
          id: photoAlbumId,
          images,
        };
        const res = await uploadProfileAlbumImages(query);
        status = res?.status === 200;
      }

      return { isSendAlbum: !!images.length, isSendAlbumOk: status };
    };

    const sendToModeration = async () => {
      const {
        data: { status },
      } = await sendProfileToModeration();
      localDispatch({
        type: 'SET_USER_INFO',
        payload: { ...userInfo, status },
      });
    };

    const commonRequest = async () => {
      setError(false);
      setLoading(true);
      try {
        const { isSendAvatar, isSendAvatarOk } = await sendPhotoAvatar();
        const { isSendAlbum, isSendAlbumOk } = await sendAlbumPhotos();

        if (isSendAlbumOk && isSendAvatarOk) {
          await sendToModeration();
          if (isSendAvatar || isSendAlbum) {
            await getUserInfo();
          }
          setError(false);
          navigate(paths.myProfile);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };

    return (
      <ModalWrapper open={open} setOpen={setOpen}>
        <div className={styles.wrapper}>
          <div className={styles.titleBlock}>
            <h2 className={styles.title}>{title}</h2>
            {firstText}
            {secondText}
          </div>
          <div className={styles.buttonBlock}>
            {!loading && (
              <Button
                secondary
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t('pages.profileCreation.photos.photosModal.11')}
              </Button>
            )}
            <Button
              primary
              loading={loading}
              disabled={loading}
              onClick={commonRequest}
            >
              {!loading
                && (error
                  ? t('pages.profileCreation.photos.photosModal.12')
                  : t('pages.profileCreation.photos.photosModal.13'))}
            </Button>
          </div>
        </div>
      </ModalWrapper>
    );
  },
);

PhotosModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  avatar: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        position: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.array,
  ]).isRequired,
  photoCollection: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        position: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.array,
  ]).isRequired,
  photoAlbum: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        position: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.array,
  ]),
  photoAlbumId: PropTypes.number,
  backDataAlbum: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image_url: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.array,
  ]),
  isImageConverterEnabled: PropTypes.bool,
};

export { PhotosModal };
