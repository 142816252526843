import { Button, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import { useLocalContext } from 'LocalContext';
import {
  getMainData,
  getMainDataForVisitor,
  getModalRanges,
} from 'api/requests/catalog';
import fire from 'assets/fire.svg';
import errorIcon from 'assets/error.svg';
import { paths } from 'constants';
import { userVisitor } from 'constants/registrationUserType';
import { initialFilters } from 'constants/initialFilters';
import { quantityPerCatalogPage } from 'constants/quantityPerPage';
import { prepareBody } from 'utils/prepareBody';
import { catalogCarousel } from 'utils/catalog';
import {
  formatActualRangesData,
  getClearFilters,
} from 'utils/setInitialFilters';

import { RegistrationModal } from 'components/RegistrationModal';
import { LoginModal } from 'components/LoginModal';
import { CatalogList } from 'components/CatalogList';
import { DividerWithLogo } from 'components/DividerWithLogo';
import { InnerCard } from 'components/InnerCard';
import { CatalogCarousel } from './components/CatalogCarousel';
import { GlobalOverview } from './components/GlobalOverview';
import { Pagination } from 'components/Pagination';

import styles from './styled.module.css';
import './styles.css';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 965,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 370,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Catalog = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { localState, localDispatch } = useLocalContext();
  const navigate = useNavigate();

  const city = searchParams.get('city');
  const gender = searchParams.get('gender');
  const orientation = searchParams.get('orientation');
  const verified = searchParams.get('verified');
  const service = searchParams.get('service');
  const page = searchParams.get('page');
  const district = searchParams.get('district')?.split('__');

  const isHotDealCarousel = localState.activeCarouselItem === 1;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [registrationOpen, setRegistrationOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  const handleSetSearchParams = useCallback(
    (val) => {
      setSearchParams({
        page: val.toString(),
        ...{ city: city || '' },
        ...(gender && { gender }),
        ...(orientation && { orientation }),
        ...(verified && { verified }),
        ...(service && { service }),
        ...(district && { district: district.join('__') }),
      });
      localDispatch({ type: 'SET_RELOAD_DATA', payload: true });
    },
    [city, district, gender, orientation, page, service, verified],
  );

  const getActualModalRanges = async () => {
    let actualRanges;
    try {
      const { data: ranges } = await getModalRanges({
        gender,
        orientation,
        ...(city && { city }),
        ...(district && { district }),
        ...(verified && { verified: true }),
        ...(service && {
          service_type: 'outcall',
        }),
      });
      if (ranges) {
        actualRanges = formatActualRangesData(ranges);
        localDispatch({
          type: 'SET_FILTERS',
          payload: {
            ...initialFilters,
            ...actualRanges,
          },
        });
      }
    } catch {
      console.log('modal range has problem');
    }
    return actualRanges;
  };

  async function fetchData({ withRanges = false }) {
    setLoading(true);
    try {
      let actualRanges;
      if (withRanges) {
        actualRanges = await getActualModalRanges();
      }
      const query = {
        ...prepareBody(
          city,
          district,
          gender,
          orientation,
          verified,
          service,
          page,
          {
            ...(JSON.stringify(localState.filters)
              !== JSON.stringify(initialFilters) && localState.filters),
            ...(actualRanges && actualRanges),
          },
        ),
      };

      if (
        localState.userInfo
        && localState.userInfo.user_type === userVisitor
      ) {
        const res = await getMainDataForVisitor(query);
        setData(res.data);
      } else {
        const res = await getMainData(query);
        setData(res.data);
      }
      setError(false);
    } catch (e) {
      navigate(paths.errorPage, { replace: true });
      setError(true);
    }
    setLoading(false);
    localDispatch({ type: 'SET_RELOAD_DATA', payload: false });
  }

  useEffect(() => {
    if (!localState.user && localState.isFirstCatalogRequest) {
      localDispatch({ type: 'SET_IS_FIRST_CATALOG_REQUEST', payload: false });
      setRegistrationOpen(true);
    }
  }, []);

  useEffect(() => {
    fetchData({ withRanges: true });
  }, [gender, orientation, city, district?.length, verified]);

  useEffect(() => {
    if (localState.reloadData) {
      fetchData({ withRanges: false });
    }
  }, [localState.reloadData]);

  if (error) {
    return (
      <div className={styles.main}>
        <div className={styles.contentWrapper}>{t('common.error')}</div>
        <GlobalOverview />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.contentWrapper}>
        <div className={styles.filtersBlock}>
          <CatalogCarousel />
        </div>
        {loading ? (
          <div className={styles.loader}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {data.hot_deals && !!data.hot_deals.length && (
              <>
                <div className={styles.titleBlock}>
                  <h4 className={styles.title}>
                    <img src={fire} alt="fire" />
                    {t('common.hotDeal')}
                  </h4>
                  {isHotDealCarousel && (
                    <div
                      className={styles.back}
                      onClick={() => {
                        localDispatch({
                          type: 'SET_ACTIVE_CAROUSEL_ITEM',
                          payload: null,
                        });
                        localDispatch({
                          type: 'SET_FILTERS',
                          payload: getClearFilters(localState.filters),
                        });
                        localDispatch({
                          type: 'SET_RELOAD_DATA',
                          payload: true,
                        });
                      }}
                    >
                      {t('common.goBack')}
                    </div>
                  )}
                  {!isHotDealCarousel && (
                    <h5
                      onClick={() => {
                        const hotDealItem = catalogCarousel.find(
                          (item) => item.id === 1,
                        );
                        localDispatch({
                          type: 'SET_ACTIVE_CAROUSEL_ITEM',
                          payload: hotDealItem.id,
                        });
                        localDispatch({
                          type: 'SET_FILTERS',
                          payload: {
                            ...getClearFilters(localState.filters),
                            ...hotDealItem.queryForFilters,
                          },
                        });

                        localDispatch({
                          type: 'SET_RELOAD_DATA',
                          payload: true,
                        });
                      }}
                    >
                      {t('common.seeAll')}
                    </h5>
                  )}
                </div>
                {!isHotDealCarousel && (
                  <CatalogList
                    items={data.hot_deals.slice(0, 4)}
                    className={`${styles.hotWeekly}`}
                  />
                )}
                {!isHotDealCarousel && (
                  <div className={styles.carouselWrapper}>
                    <Slider
                      {...settings}
                      className="cards"
                      beforeChange={() => setScroll(false)}
                      afterChange={() => setScroll(true)}
                    >
                      {data.hot_deals.slice(0, 4).map((item) => (
                        <InnerCard
                          data={item}
                          key={item.id}
                          scroll={scroll}
                          albumCount={item.album_images_count}
                        />
                      ))}
                    </Slider>
                  </div>
                )}
              </>
            )}
            {!isHotDealCarousel
              && data.weekly_offer
              && !!data.weekly_offer.length && (
                <div className={styles.weeklyOfferBlock}>
                  <div className={styles.titleBlock}>
                    <h4 className={styles.title}>{t('common.weeklyOffer')}</h4>
                  </div>
                  <CatalogList
                    items={data.weekly_offer}
                    className={`${styles.hotWeekly} ${styles.weeklyOffer}`}
                    weeklyOffer
                  />
                  <div className={styles.carouselWrapper}>
                    <Slider
                      {...settings}
                      className="cards weeklyOffer"
                      beforeChange={() => setScroll(false)}
                      afterChange={() => setScroll(true)}
                    >
                      {data.weekly_offer.map((item) => (
                        <InnerCard
                          data={item}
                          key={item.id}
                          scroll={scroll}
                          albumCount={item.album_images_count}
                          weeklyOffer
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
            )}
            {!isHotDealCarousel
              && ((data.hot_deals && !!data.hot_deals.length)
                || (data.weekly_offer && !!data.weekly_offer.length)) && (
                <div className={styles.divider}>
                  <DividerWithLogo />
                </div>
            )}
            {data.total > quantityPerCatalogPage && !loading && (
              <Pagination
                total={data.total}
                onChange={handleSetSearchParams}
                current={+page}
                quantityPerPage={quantityPerCatalogPage}
              />
            )}
            {(
              isHotDealCarousel
                ? data.hot_deals && !!data.hot_deals.length
                : data.catalog && !!data.catalog.length
            ) ? (
              <CatalogList
                items={isHotDealCarousel ? data.hot_deals : data.catalog}
              />
              ) : (
                <div className={styles.errorCatalog}>
                  <img src={errorIcon} alt="error" />
                  <p className={styles.message}>{t('common.ohNoNoprofiles')}</p>
                  <span>{t('common.changeFilters')}</span>
                  <Button
                    type="primary"
                    className={`${styles.button}`}
                    onClick={() => navigate(paths.main)}
                  >
                    {t('common.goToMain')}
                  </Button>
                </div>
              )}
          </div>
        )}
        {data.total > quantityPerCatalogPage && !loading && (
          <Pagination
            total={data.total}
            onChange={handleSetSearchParams}
            current={+page}
            quantityPerPage={quantityPerCatalogPage}
          />
        )}
      </div>
      <GlobalOverview />
      <RegistrationModal
        open={registrationOpen}
        setOpen={setRegistrationOpen}
        setOpenLoginModal={setLoginOpen}
      />
      <LoginModal
        open={loginOpen}
        setOpen={setLoginOpen}
        setOpenRegistrationModal={setRegistrationOpen}
      />
    </div>
  );
};

export { Catalog };
