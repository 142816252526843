import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { catalogCarousel } from 'utils/catalog';
import { getClearFilters } from 'utils/setInitialFilters';
import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';

import hot from 'assets/hot.svg';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const HotDealModal = memo(({ open = false, setOpen, error }) => {
  const { localState, localDispatch } = useLocalContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { gender, orientation } = localState.userInfo.profile;

  const onCloseModal = () => {
    setOpen(false);
    navigate(paths.myProfile);
  };

  return (
    <ModalWrapper open={open} setOpen={onCloseModal}>
      <div className={styles.wrapper}>
        <div className={styles.titleBlock}>
          {!error && <img alt="hot" src={hot} className={styles.hot} />}
          {error ? (
            <p className={styles.title}>{t('common.ohNo')}</p>
          ) : (
            <p className={styles.title}>
              {t('pages.hotDeal.title')}
              <br />
              {t('common.successfullyCreated')}
            </p>
          )}
        </div>
        <div className={styles.description}>
          <p>
            {error
              ? t('common.somethingWrong')
              : t('pages.hotDeal.hotDealModalContent.1')}
          </p>
          {!error && (
            <p>
              {t('pages.hotDeal.hotDealModalContent.2')}
              <br />
              {t('pages.hotDeal.hotDealModalContent.3')}
            </p>
          )}
        </div>
        <div className={styles.buttonBlock}>
          <Button
            primary
            onClick={() => {
              if (error) {
                navigate(paths.profileContactUs);
              } else {
                const hotDealItem = catalogCarousel.find(
                  (item) => item.id === 1,
                );
                localDispatch({
                  type: 'SET_ACTIVE_CAROUSEL_ITEM',
                  payload: hotDealItem.id,
                });
                localDispatch({
                  type: 'SET_FILTERS',
                  payload: {
                    ...getClearFilters(localState.filters),
                    ...hotDealItem.queryForFilters,
                  },
                });
                navigate(
                  `/catalog/?page=1&city=&gender=${gender}&orientation=${
                    orientation === 'bi' && gender !== 'trans'
                      ? 'hetero'
                      : orientation
                  }`,
                );
              }
              setOpen(false);
            }}
          >
            {error
              ? t('common.reportUs')
              : t('pages.hotDeal.hotDealModalContent.4')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
});

HotDealModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export { HotDealModal };
