import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OpenArrow } from 'components/ActiveIcon/OpenArrow';

import styles from './styled.module.css';

const QuestionItem = ({ title, text }) => {
  const [showQuestion, setShowQuestion] = useState(false);
  return (
    <section className={styles.borderBlock}>
      <div
        className={styles.borderBlockTitle}
        onClick={() => setShowQuestion(!showQuestion)}
      >
        <h4>{title}</h4>
        <OpenArrow
          className={`${styles.arrow} ${showQuestion ? styles.show : ''}`}
        />
      </div>
      {showQuestion && text}
    </section>
  );
};

QuestionItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
};

export { QuestionItem };
