import React from 'react';
import PropTypes from 'prop-types';

const PlayIcon = ({ className, onClick }) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    onClick={onClick}
    className={className}
  >
    <path
      d="M18.409 8.35331C18.8893 8.60872 19.291 8.99 19.5712 9.45629C19.8514 9.92259 19.9994 10.4563 19.9994 11.0003C19.9994 11.5443 19.8514 12.078 19.5712 12.5443C19.291 13.0106 18.8893 13.3919 18.409 13.6473L5.597 20.6143C3.534 21.7363 1 20.2763 1 17.9683V4.03331C1 1.72331 3.534 0.264307 5.597 1.38531L18.409 8.35331Z"
      strokeWidth="1.5"
    />
  </svg>
);

PlayIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { PlayIcon };
