import React from 'react';
import PropTypes from 'prop-types';

const LikeIcon = ({ className, onClick }) => (
  <svg
    width="33"
    height="29"
    viewBox="0 0 33 29"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#D9D9D9"
    className={className}
    onClick={onClick}
  >
    <path
      d="M10.0208 2C5.59115 2 2 5.59115 2 10.0208C2 18.0417 11.4792 25.3333 16.5833 27.0294C21.6875 25.3333 31.1667 18.0417 31.1667 10.0208C31.1667 5.59115 27.5755 2 23.1458 2C20.4333 2 18.0344 3.34677 16.5833 5.40813C15.8437 4.35463 14.8612 3.49486 13.7188 2.90161C12.5765 2.30835 11.308 1.99909 10.0208 2Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

LikeIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { LikeIcon };
