import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';
import { editProfile } from 'api/requests/profile';
import { days } from 'utils/filters';
import { useLocalContext } from 'LocalContext';
import {
  formatAvailabilityToBack,
  formatBackToAvailability,
} from 'utils/profileCreationValues';

import { TimePicker } from 'components/TimePicker';
import { AvailabilityArrow } from 'components/ActiveIcon/AvailabilityArrow';
import { Button } from 'components/Button';

import styles from '../../styled.module.css';
import './styles.css';

const Availability = ({ nextStep, setLoading, setDisabled }) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const isMobile = useIsMobile();

  const { activeDays, available } = formatBackToAvailability(
    userInfo.availability_calendar,
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState(activeDays);
  const [dayAndTime, setDayAndTime] = useState(available);
  const [error, setError] = useState(false);

  const isDidabled = !Object.values(dayAndTime)?.length
    || Object.values(dayAndTime)?.some((item) => !item)
    || Object.values(dayAndTime)?.some((item) => item?.some((el) => !el));

  useEffect(() => {
    setDisabled(isDidabled);
  }, []);

  const testTimePickerOnChange = (position, item, e) => {
    if (position === 'start') {
      setDayAndTime({
        ...dayAndTime,
        [item.value]: [e, dayAndTime[item.value]?.[1] || ''],
      });
    }
    if (position === 'end') {
      setDayAndTime({
        ...dayAndTime,
        [item.value]: [dayAndTime[item.value]?.[0] || '', e],
      });
    }
  };

  const isDisabled = useMemo(
    () => (item) => dayAndTime[item.value] === 24 || !selectedRowKeys.includes(item.value),
    [dayAndTime, selectedRowKeys],
  );

  const CheckBoxOnChange = useMemo(
    () => (e, item) => {
      if (e.target.checked) {
        setDayAndTime({
          ...dayAndTime,
          [item.value]: ['00:00', '23:59'],
        });
      } else {
        const { [item.value]: _, ...newDayAndTime } = dayAndTime;
        setDayAndTime(newDayAndTime);
      }
    },
    [setDayAndTime, dayAndTime],
  );

  const TableOnChange = useMemo(
    () => (newSelectedRowKeys) => {
      selectedRowKeys.forEach((key) => {
        if (!newSelectedRowKeys.includes(key)) {
          const updatedDayAndTime = { ...dayAndTime };
          delete updatedDayAndTime[key];
          setDayAndTime(updatedDayAndTime);
        }
      });
      setSelectedRowKeys(newSelectedRowKeys);
    },
    [selectedRowKeys, dayAndTime, setDayAndTime],
  );

  const sendAvailability = async () => {
    const availability_calendar = formatAvailabilityToBack(
      selectedRowKeys,
      dayAndTime,
    );

    const query = {
      availability_calendar,
    };

    if (
      JSON.stringify((userInfo && userInfo.availability_calendar) || [])
      === JSON.stringify(availability_calendar)
    ) {
      nextStep();
    } else {
      setLoading(true);
      try {
        const {
          data: { card },
        } = await editProfile(query);
        localDispatch({ type: 'SET_USER_INFO', payload: card });
        setError(false);
        nextStep();
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'times',
    },
    {
      dataIndex: 'all',
    },
  ];

  const data = days.map((item) => ({
    key: item.value,
    name: t(`common.${item.value}`),
    times: JSON.stringify(dayAndTime[item.value])
      !== JSON.stringify(['00:00', '23:59']) && (
      <div className={styles.time}>
        <p>{t('common.from')}</p>
        <TimePicker
          value={dayAndTime[item.value]?.[0] || ''}
          disabled={isDisabled(item)}
          setValue={(e) => testTimePickerOnChange('start', item, e)}
          error={!!dayAndTime[item.value]?.[1] && !dayAndTime[item.value]?.[0]}
        />
        <p>{t('common.to')}</p>
        <AvailabilityArrow className={styles.arrow} />
        <TimePicker
          value={dayAndTime[item.value]?.[1] || ''}
          disabled={isDisabled(item)}
          setValue={(e) => testTimePickerOnChange('end', item, e)}
          error={!!dayAndTime[item.value]?.[0] && !dayAndTime[item.value]?.[1]}
        />
      </div>
    ),
    all: (
      <Checkbox
        className="availability"
        onChange={(e) => CheckBoxOnChange(e, item)}
        checked={
          !selectedRowKeys.includes(item.value)
            ? false
            : JSON.stringify(dayAndTime[item.value])
              === JSON.stringify(['00:00', '23:59'])
        }
        disabled={!selectedRowKeys.includes(item.value)}
      >
        24hr
      </Checkbox>
    ),
  }));

  if (error) {
    return <span>{t('common.error')}</span>;
  }

  return (
    <div className={styles.form}>
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Button
            primary
            className={`${styles.button} ${styles.primary}`}
            disabled={isDidabled}
            onClick={() => {
              sendAvailability();
            }}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
      <Table
        className="availability"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => TableOnChange(newSelectedRowKeys),
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        style={{ width: '100%' }}
      />
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Button
            primary
            className={`${styles.button} ${styles.primary}`}
            disabled={isDidabled}
            onClick={() => {
              sendAvailability();
            }}
          >
            {t('common.next')}
          </Button>
        </div>
      )}
    </div>
  );
};

Availability.propTypes = {
  nextStep: PropTypes.func,
  setLoading: PropTypes.func,
  setDisabled: PropTypes.func,
};

export { Availability };
