import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import styles from './styled.module.css';
import { paths } from 'constants';

import { Link } from 'react-router-dom';

const StartPage = ({ setShowVerificationPage }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>{t('pages.verification.startPage.1')}</h1>
        <p>{t('pages.verification.startPage.2')}</p>
      </div>
      <div className={styles.content}>
        <div>
          <h3>{t('pages.verification.startPage.3')}</h3>
          <p>
            {t('pages.verification.startPage.4')}
          </p>
          <p>
            {t('pages.verification.startPage.5')}
          </p>
          <ul>
            <li>
              <p>{t('pages.verification.startPage.6')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.7')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.8')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.9')}</p>
            </li>
          </ul>
        </div>
        <div>
          <h4>{t('pages.verification.startPage.10')}</h4>
          <ul>
            <li>
              <p>{t('pages.verification.startPage.11')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.12')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.13')}</p>
            </li>
            <li>
              <p>{t('pages.verification.startPage.14')}</p>
            </li>
            <li>
              <b>{t('pages.verification.startPage.17')}</b>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.buttonBlock}>
        <Button className={styles.cancelButton} type="link">
          <Link to={paths.myProfile}>{t('pages.verification.startPage.15')}</Link>
        </Button>
        <Button
          className={styles.button}
          type="primary"
          onClick={() => setShowVerificationPage(true)}
        >
          {t('pages.verification.startPage.16')}
        </Button>
      </div>
    </div>
  );
};

StartPage.propTypes = {
  setShowVerificationPage: PropTypes.func.isRequired,
};

export { StartPage };
