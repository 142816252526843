import heic2any from 'heic2any';
import FileResizer from 'react-image-file-resizer';

const useConvertToJpeg = () => {
  const getImageDimensions = (file) => new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      console.log('Upload error');
    };
    img.src = URL.createObjectURL(file);
  });

  const convertToJPEG = async (file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
      return file;
    }
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      const jpegBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
      const jpegFile = new File([jpegBlob], file.name, {
        type: 'image/jpeg',
      });
      return jpegFile;
    }

    const dimensions = await getImageDimensions(file);

    return new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        dimensions.width,
        dimensions.height,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
      );
    });
  };

  return { convertToJPEG };
};

export { useConvertToJpeg };
