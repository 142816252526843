import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as AntdPagination } from 'antd';

import styles from './styled.module.css';
import './styles.css';

const Pagination = ({
  total,
  onChange,
  current,
  quantityPerPage,
  disabled = false,
  className = '',
}) => (
  <div className={`${styles.paginationWrapper} ${className}`}>
    <AntdPagination
      disabled={disabled}
      rootClassName="pagination"
      total={total}
      showSizeChanger={false}
      onChange={onChange}
      pageSize={quantityPerPage}
      current={parseInt(current, 10)}
    />
  </div>
);

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  quantityPerPage: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export { Pagination };
