import { reactAppPublicApiUrl } from '../../config';
import { Image } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useBlockScroll } from 'hooks/useBlockScroll';

import './styles.css';

const generateItems = (images) => images.map((el) => `${reactAppPublicApiUrl}${el.image_url}`);

const ImagePreviewWrapper = ({ children, images, initialImage }) => {
  const [currentImage, setCurrentImage] = useState(initialImage);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [isZoom, setIsZoom] = useState(false);

  useBlockScroll(isPreviewOpen);

  const onLeft = () => (currentImage > 0
    ? setCurrentImage(currentImage - 1)
    : setCurrentImage(images.length - 1));

  const onRight = () => (currentImage < images.length - 1
    ? setCurrentImage(currentImage + 1)
    : setCurrentImage(0));

  useEffect(() => {
    const rightButton = document.querySelector(
      '.imagePreviewWrapper-preview-switch-right',
    );
    const leftSwitch = document.querySelector(
      '.imagePreviewWrapper-preview-switch-left',
    );

    const handleRightClick = () => {
      if (isPreviewOpen) {
        onRight();
      }
    };

    const handleLeftClick = () => {
      if (isPreviewOpen) {
        onLeft();
      }
    };

    if (rightButton) {
      rightButton.addEventListener('click', handleRightClick);
    }

    if (leftSwitch) {
      leftSwitch.addEventListener('click', handleLeftClick);
    }

    return () => {
      if (rightButton) {
        rightButton.removeEventListener('click', handleRightClick);
      }
      if (leftSwitch) {
        leftSwitch.removeEventListener('click', handleLeftClick);
      }
    };
  }, [currentImage, isPreviewOpen, initialImage]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (isPreviewOpen) {
        if (event.key === 'ArrowLeft') {
          onLeft();
        } else if (event.key === 'ArrowRight') {
          onRight();
        }
      }
    };

    const handleTouchStart = (event) => {
      if (
        isPreviewOpen
        && event.target.tagName !== 'svg'
        && event.target.tagName !== 'path'
      ) {
        setTouchStart(event.touches[0].clientX);
      }
    };

    const handleTouchEnd = (event) => {
      if (
        isPreviewOpen
        && event.target.tagName !== 'svg'
        && event.target.tagName !== 'path'
        && !isZoom
      ) {
        if (touchStart - event.changedTouches[0].clientX > 50) {
          onRight();
        }

        if (touchStart - event.changedTouches[0].clientX < -50) {
          onLeft();
        }
      }
    };
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isPreviewOpen, touchStart, currentImage, initialImage]);

  return (
    <Image.PreviewGroup
      previewPrefixCls="imagePreviewWrapper"
      preview={{
        maxScale: 3,
        current: currentImage,
        onVisibleChange: () => {
          setCurrentImage(initialImage);
          setIsPreviewOpen(!isPreviewOpen);
        },
        movable: isZoom,
        onTransform: ({ transform }) => {
          if (transform.scale === 1) {
            setIsZoom(false);
          } else {
            setIsZoom(true);
          }
        },
      }}
      items={generateItems(images, currentImage)}
    >
      {children}
    </Image.PreviewGroup>
  );
};

ImagePreviewWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number,
      image_url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  initialImage: PropTypes.number.isRequired,
};

export { ImagePreviewWrapper };
