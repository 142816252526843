import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useActualDistrict } from 'hooks/useActualDictrict';

import styles from './styled.module.css';

const NameInfo = ({ profile }) => {
  const { t } = useTranslation();
  const actualDistrict = useActualDistrict(profile.city, profile.district);

  return (
    <div className={styles.nameInfo}>
      <h4>{profile.nickname}</h4>
      <div>
        <p>
          {profile.city}
          {actualDistrict && `, ${actualDistrict}`}
          ,
        </p>
        <p>
          {t('common.age')}
          {' '}
          {profile.age}
        </p>
      </div>
    </div>
  );
};

NameInfo.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    district: PropTypes.string,
    age: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
};

export { NameInfo };
