import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { paths } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import publicLogo from 'assets/publicIcons/publicLogo.svg';
import logo from 'assets/logo.svg';

import styles from './styled.module.css';

const RegistrationModal = memo(
  ({ open = false, setOpen, setOpenLoginModal }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isPublicVersion } = usePublicVersion();

    return (
      <ModalWrapper open={open} setOpen={setOpen}>
        <div className={styles.wrapper}>
          <div className={styles.titleBlock}>
            <img src={isPublicVersion ? publicLogo : logo} alt="logo" className={styles.logo} />
            <h2 className={styles.title}>
              {t('common.joinTolips')}
              {' '}
              !
            </h2>
            <p>{t('components.registrationModal.1')}</p>
            <p>{t('components.registrationModal.2')}</p>
          </div>
          <div className={styles.buttonBlock}>
            <Button
              primary
              fullWidth
              onClick={() => {
                setOpen(false);
                navigate(paths.registration, {
                  state: { userType: userProvider },
                });
              }}
            >
              {t('components.registrationModal.3')}
            </Button>
            <p>
              {t('components.registrationModal.4')}
            </p>
          </div>
          <div className={styles.buttonBlock}>
            <Button
              primary
              fullWidth
              onClick={() => {
                setOpen(false);
                navigate(paths.registration, {
                  state: { userType: userVisitor },
                });
              }}
            >
              {t('components.registrationModal.5')}
            </Button>
            <p>{t('components.registrationModal.6')}</p>
          </div>
          <Divider
            style={{
              color: '#633976',
              margin: '40px 0 20px',
            }}
          >
            {t('components.registrationModal.7')}
            {' '}
            ?
          </Divider>
          <Button
            secondary
            fullWidth
            onClick={() => {
              setOpenLoginModal(true);
              setOpen(false);
            }}
          >
            {t('common.login')}
          </Button>
        </div>
      </ModalWrapper>
    );
  },
);

RegistrationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOpenLoginModal: PropTypes.func.isRequired,
};

export { RegistrationModal };
