import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { getMainData, getMainDataForVisitor } from 'api/requests';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useIsMobile } from 'hooks/useIsMobile';
import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { cities } from 'constants';
import { country_districts } from 'constants/country_districts';
import { initialFilters } from 'constants/initialFilters';
import { userVisitor } from 'constants/registrationUserType';
import { genders } from 'utils/genders';

import { Button } from 'components/Button';
import { ModalWrapper } from 'components/ModalWrapper';
import { Select } from 'components/Select';

import styles from './styled.module.css';

const GendersModal = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const { gaClickSearch } = useGoogleAnalytics();
  const { isPublicVersion } = usePublicVersion();
  const showSmallModal = useIsMobile({ maxWidth: 900 });

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [searchParams] = useSearchParams();
  const [city, setCity] = useState(searchParams.get('city'));
  const [district, setDistrict] = useState(
    searchParams.get('district')?.split('__') || [],
  );
  const [gender, setGender] = useState(searchParams.get('gender'));
  const [verified, setVerified] = useState(searchParams.get('verified'));
  const [orientation, setOrientation] = useState(
    searchParams.get('orientation'),
  );

  const getTotalCount = async () => {
    const query = {
      gender,
      orientation,
      ...(city && { city }),
      ...(verified && { verified: true }),
      ...(district.length && { district }),
    };
    setLoading(true);
    try {
      if (userInfo && userInfo.user_type === userVisitor) {
        const { data } = await getMainDataForVisitor({
          ...query,
          count_total: true,
        });
        setTotalCount(data.total);
      } else {
        const { data } = await getMainData({ ...query, count_total: true });
        setTotalCount(data.total);
      }
    } catch {
      console.log('catalog has problem');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTotalCount();
  }, [city, orientation, gender, verified, district.length]);

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      widthModal={!showSmallModal && 800}
      minHeightDrawer={city ? 430 : 355}
      footer={(
        <Link
          className={styles.buttonLink}
          to={`/catalog/?page=1&city=${
            city || ''
          }&gender=${gender}&orientation=${orientation}${
            verified ? `&verified=${verified}` : ''
          }${district.length ? `&district=${district.join('__')}` : ''}`}
          onClick={() => {
            setOpen(false);
            localDispatch({ type: 'SET_ACTIVE_CAROUSEL_ITEM', payload: null });
            localDispatch({
              type: 'SET_FILTERS',
              payload: initialFilters,
            });
            gaClickSearch({
              gender,
              orientation,
              city,
              verified,
              district,
            });
          }}
        >
          <Button loading={loading} primary className={styles.button}>
            {!loading && <span>{t('common.search')}</span>}
            {!loading && <span>{totalCount || 0}</span>}
          </Button>
        </Link>
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.inputCheckbox}>
          <Select
            options={cities}
            placeholder={t('common.chooseCity')}
            defaultValue={city}
            onSelect={(e) => {
              setDistrict([]);
              setCity(e);
            }}
          />
          {city && country_districts[city.toLowerCase()] && (
            <Select
              options={country_districts[city.toLowerCase()].slice(1)}
              placeholder={t('pages.home.district')}
              defaultValue={district}
              multi
              onSelect={(e) => {
                setDistrict(e);
              }}
            />
          )}
        </div>
        <Checkbox
          checked={verified}
          onChange={(e) => setVerified(e.target.checked)}
        >
          {t('common.verifiedProfiles')}
        </Checkbox>
        <div className={styles.genders}>
          {genders.map((item) => (
            <div
              key={item.title}
              className={`
          ${styles.gender} 
          ${
            gender === item.gender && orientation === item.orientation
              ? styles.active
              : ''
          }
        `}
              onClick={() => {
                setGender(item.gender);
                setOrientation(item.orientation);
              }}
            >
              {!isPublicVersion && <img src={item.image} alt={item.gender} />}
              <p>{t(`pages.home.genders.${item.title}`)}</p>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

GendersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export { GendersModal };
