import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { resetPassword } from 'api/requests';
import { paths, savedConstants } from 'constants';
import { scrollToErrorField } from 'utils/scrollToErrorField';

import styles from '../../styled.module.css';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const access_token = searchParams.get('access_token');

  const onChangePassword = async () => {
    const password = form.getFieldValue('password');
    const query = { password };
    setLoading(true);
    try {
      const { data } = await resetPassword(access_token, query);
      localStorage.setItem(savedConstants.AUTH_TOKEN, data.token);
      localStorage.setItem(savedConstants.USER_TYPE, data.user_type);
      navigate(paths.main, { replace: true });
      setError(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Form
      form={form}
      onFinish={onChangePassword}
      autoComplete="off"
      className={styles.form}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      <div className={styles.contentBlockTitle}>
        <h4>{t('pages.forgotPasswordPage.4')}</h4>
      </div>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t('errors.inputPassword'),
          },
          {
            min: 8,
            message: t('errors.passwordAtLeast', { number: 8 }),
          },
          {
            max: 32,
            message: t('errors.password.less', { number: 32 }),
          },
          {
            pattern: /\d/,
            message: t('errors.passwordDigit'),
          },
          {
            pattern: /[A-Z]/,
            message: t('errors.passwordCapital.'),
          },
        ]}
      >
        <Input.Password placeholder={t('common.password')} autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            validator: (_, value) => new Promise((resolve, reject) => {
              const { getFieldValue } = form;
              if (!value || value !== getFieldValue('password')) {
                reject(new Error(t('errors.passwords')));
              }
              resolve();
            }),
          },
        ]}
      >
        <Input.Password
          placeholder={t('common.confirmPassword')}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            loading={loading}
            disabled={
              !form.isFieldsTouched(true)
              || !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {t('common.send')}
          </Button>
        )}
      </Form.Item>
      {error && <p className={styles.message}>{t('errors.somethingWrong')}</p>}
    </Form>
  );
};

export { ResetPassword };
