import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../styled.module.css';
import { useTranslation } from 'react-i18next';

const StepDescription = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.stepDescription} stepDescription`}>
      <p>{t('pages.profileCreation.2')}</p>
      <p>
        {t('pages.profileCreation.3')}
        {' '}
        {name}
        {' '}
        {t('pages.profileCreation.4')}
      </p>
    </div>
  );
};

StepDescription.propTypes = {
  name: PropTypes.string.isRequired,
};

export { StepDescription };
