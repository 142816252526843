import { Form, Radio } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styled.module.css';

const RadioButton = ({
  title,
  noLabel = false,
  uniquelabel,
  options,
  width,
  withDefaultValue = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const formatTitle = title.replace(/_/g, ' ');
  const defaultValue = {
    name: t('common.doesNotMatter'),
    value: undefined,
  };
  return (
    <div className={styles.radioButtonBlock}>
      {!noLabel && (
      <p>
        {uniquelabel || formatTitle}
        :
      </p>
      )}
      <Form.Item
        name={title.toLowerCase()}
        defaultValue={withDefaultValue ? defaultValue.value : null}
      >
        <Radio.Group
          buttonStyle="solid"
          className={styles.radioGroup}
          onChange={onChange}
        >
          {withDefaultValue && (
            <Radio.Button
              key={defaultValue}
              className={styles.radioItem}
              value={defaultValue.value}
            >
              {defaultValue.name}
            </Radio.Button>
          )}
          {options.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              className={styles.radioItem}
              style={{ width: width && width }}
            >
              <div className={styles.optionBlock}>
                {option.color && (
                  <div
                    className={styles.colorItem}
                    style={{
                      background: option.color,
                    }}
                  />
                )}
                {option.image && (
                  <img
                    src={option.image}
                    alt={option.value}
                    style={{ height: '23px', width: '23px' }}
                  />
                )}
                <span>{t(option.label)}</span>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

RadioButton.propTypes = {
  title: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  uniquelabel: PropTypes.string,
  width: PropTypes.string,
  withDefaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { RadioButton };
