import React from 'react';
import PropTypes from 'prop-types';

const RigthIcon = ({ className, onClick }) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="#757575"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M10.1931 8.45705L1.83363 0.492055C1.69415 0.359088 1.50884 0.284912 1.31613 0.284912C1.12342 0.284912 0.938114 0.359088 0.798632 0.492055L0.789631 0.501055C0.721778 0.565515 0.667748 0.643105 0.630828 0.729104C0.593907 0.815104 0.574868 0.907715 0.574868 1.00131C0.574868 1.09489 0.593907 1.18751 0.630828 1.27351C0.667748 1.35951 0.721778 1.4371 0.789631 1.50155L8.66163 9.00156L0.789631 16.4986C0.721778 16.563 0.667748 16.6406 0.630828 16.7266C0.593907 16.8126 0.574868 16.9052 0.574868 16.9988C0.574868 17.0924 0.593907 17.185 0.630828 17.271C0.667748 17.357 0.721778 17.4346 0.789631 17.4991L0.798632 17.5081C0.938114 17.641 1.12342 17.7152 1.31613 17.7152C1.50884 17.7152 1.69415 17.641 1.83363 17.5081L10.1931 9.54305C10.2666 9.47301 10.3252 9.38876 10.3652 9.29542C10.4052 9.20209 10.4258 9.1016 10.4258 9.00005C10.4258 8.89851 10.4052 8.79802 10.3652 8.70468C10.3252 8.61135 10.2666 8.5271 10.1931 8.45705Z"
    />
  </svg>
);

RigthIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { RigthIcon };
