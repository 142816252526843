import React from 'react';
import { Dropdown } from 'antd';
import i18n from 'translate/i18n';

import { savedConstants } from 'constants/localStorage';
import { GlobeIcon } from 'components/ActiveIcon/GlobeIcon';
import { translateLanguage } from 'constants/translateLanguage';

import styles from './styled.module.css';

const LanguagesDropDown = () => {
  const lng = localStorage.getItem(savedConstants.LNG);
  const language = (lang) => translateLanguage.find((item) => item.key === lang);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang.key);
    localStorage.setItem(savedConstants.LNG, lang.key);
  };

  return (
    <Dropdown
      menu={{
        items: translateLanguage,
        selectable: true,
        defaultSelectedKeys: lng,
        onClick: handleChangeLanguage,
      }}
      style={{ background: '#633976' }}
      trigger={['click']}
    >
      <div className={styles.language}>
        <GlobeIcon alt="globo" className={styles.image} />
        <p className={styles.text}>{language(lng || 'en')?.name}</p>
      </div>
    </Dropdown>
  );
};

export { LanguagesDropDown };
