import React from 'react';
import PropTypes from 'prop-types';

const LeftIcon = ({ className, onClick }) => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="#757575"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path d="M0.806869 9.54295L9.16637 17.5079C9.30585 17.6409 9.49116 17.7151 9.68387 17.7151C9.87658 17.7151 10.0619 17.6409 10.2014 17.5079L10.2104 17.4989C10.2782 17.4345 10.3323 17.3569 10.3692 17.2709C10.4061 17.1849 10.4251 17.0923 10.4251 16.9987C10.4251 16.9051 10.4061 16.8125 10.3692 16.7265C10.3323 16.6405 10.2782 16.5629 10.2104 16.4984L2.33837 8.99844L10.2104 1.50145C10.2782 1.43699 10.3323 1.3594 10.3692 1.2734C10.4061 1.1874 10.4251 1.09479 10.4251 1.0012C10.4251 0.907606 10.4061 0.814995 10.3692 0.728996C10.3323 0.642996 10.2782 0.565407 10.2104 0.500946L10.2014 0.491945C10.0619 0.358978 9.87658 0.284801 9.68387 0.284801C9.49116 0.284801 9.30585 0.358978 9.16637 0.491945L0.806869 8.45695C0.733351 8.52699 0.674822 8.61124 0.634831 8.70458C0.59484 8.79791 0.574219 8.8984 0.574219 8.99995C0.574219 9.10149 0.59484 9.20198 0.634831 9.29532C0.674822 9.38865 0.733351 9.4729 0.806869 9.54295Z" />
  </svg>
);

LeftIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { LeftIcon };
