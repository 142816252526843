import anal from 'assets/catalogCarouselImages/anal.svg';
import bdsm from 'assets/catalogCarouselImages/bdsm.svg';
import blowjob from 'assets/catalogCarouselImages/blowjob.svg';
import costumes from 'assets/catalogCarouselImages/costumes.svg';
import couple from 'assets/catalogCarouselImages/couple.svg';
import deepthroat from 'assets/catalogCarouselImages/deepthroaght.svg';
import gangbang from 'assets/catalogCarouselImages/gangbang.svg';
import outcall from 'assets/catalogCarouselImages/outcall.svg';
import sexual_intercourse from 'assets/catalogCarouselImages/sexual_intercourse.svg';
import striptease from 'assets/catalogCarouselImages/streeptis.svg';
import toys from 'assets/catalogCarouselImages/toys.svg';
import type69 from 'assets/catalogCarouselImages/type69.svg';
import fire from 'assets/fire.svg';

export const catalogCarousel = [
  {
    id: 1,
    label: 'Hot deal',
    query: { hot_deal_page: true },
    queryForFilters: { hot_deal_page: true },
    icon: fire,
  },
  {
    id: 2,
    label: 'BDSM',
    query: { favors: [14] },
    queryForFilters: { services: [14] },
    icon: bdsm,
  },
  {
    id: 3,
    label: 'Anal',
    query: { favors: [7] },
    queryForFilters: { services: [7] },
    icon: anal,
  },
  {
    id: 4,
    label: 'Couple',
    query: { favors: [19] },
    queryForFilters: { services: [19] },
    icon: couple,
  },
  {
    id: 5,
    label: 'GangBang',
    query: { favors: [48] },
    queryForFilters: { services: [48] },
    icon: gangbang,
  },
  {
    id: 6,
    label: 'BlowJob (without condom)',
    query: { favors: [13] },
    queryForFilters: { services: [13] },
    icon: blowjob,
  },
  {
    id: 7,
    label: 'Toys',
    query: {},
    queryForFilters: { services: [81] },
    icon: toys,
  },
  {
    id: 8,
    label: 'Outcall',
    query: { type_of_service: ['outcall'] },
    queryForFilters: { type_of_service: 'outcall' },
    icon: outcall,
  },
  {
    id: 9,
    label: 'Sexual Intercourses',
    query: { favors: [1, 2, 3] },
    queryForFilters: { services: [1, 2, 3] },
    icon: sexual_intercourse,
  },
  {
    id: 10,
    label: '69',
    query: { favors: [6] },
    queryForFilters: { services: [6] },
    icon: type69,
  },
  {
    id: 11,
    label: 'Costumes',
    query: { favors: [18] },
    queryForFilters: { services: [18] },
    icon: costumes,
  },
  {
    id: 12,
    label: 'Deepthroat',
    query: { favors: [29] },
    queryForFilters: { services: [29] },
    icon: deepthroat,
  },
  {
    id: 13,
    label: 'Striptease',
    query: { favors: [90] },
    queryForFilters: { services: [90] },
    icon: striptease,
  },
];
