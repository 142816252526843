const warsaw = [
  { value: 'all', label: 'All districts' },
  { value: 'Mokotow', label: 'Mokotów' },
  { value: 'Praga_Poludnie', label: 'Praga Południe' },
  { value: 'Wola', label: 'Wola' },
  { value: 'Ursynow', label: 'Ursynów' },
  { value: 'Bielany', label: 'Bielany' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Targowek', label: 'Targówek' },
  { value: 'Bemowo', label: 'Bemowo' },
  { value: 'Ochota', label: 'Ochota' },
  { value: 'Praga_Polnoc', label: 'Praga Północ' },
  { value: 'Bialoleka', label: 'Białołęka' },
  { value: 'Wawer', label: 'Wawer' },
  { value: 'Zoliborz', label: 'Żoliborz' },
  { value: 'Ursus', label: 'Ursus' },
  { value: 'Wlochy', label: 'Włochy' },
  { value: 'Rembertow', label: 'Rembertów' },
  { value: 'Wesola', label: 'Wesoła' },
  { value: 'Wilanow', label: 'Wilanów' },
];

const krakow = [
  { value: 'all', label: 'All districts' },
  { value: 'Bienczyce', label: 'Bieńczyce' },
  { value: 'Biezanow_Prokocim', label: 'Bieżanów-Prokocim' },
  { value: 'Bronowice', label: 'Bronowice' },
  { value: 'Czyzyny', label: 'Czyżyny' },
  { value: 'Debniki', label: 'Dębniki' },
  { value: 'Grzegorzki', label: 'Grzegórzki' },
  { value: 'Krowodrza', label: 'Krowodrza' },
  { value: 'Lagiewniki_Borek_Falecki', label: 'Łagiewniki-Borek Fałęcki' },
  { value: 'Mistrzejowice', label: 'Mistrzejowice' },
  { value: 'Nowa_Huta', label: 'Nowa Huta' },
  { value: 'Podgorze', label: 'Podgórze' },
  { value: 'Podgorze_Duchackie', label: 'Podgórze Duchackie' },
  { value: 'Pradnik_Bialy', label: 'Prądnik Biały' },
  { value: 'Pradnik_Czerwony', label: 'Prądnik Czerwony' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Swoszowice', label: 'Swoszowice' },
  { value: 'Wzgorza_Krzeslawickie', label: 'Wzgórza Krzesławickie' },
  { value: 'Zwierzyniec', label: 'Zwierzyniec' },
];

const lodz = [
  { value: 'all', label: 'All districts' },
  { value: 'Baluty', label: 'Baluty' },
  { value: 'Centrum', label: 'Centrum' },
  { value: 'Dabrowa', label: 'Dabrowa' },
  { value: 'Gorna', label: 'Górna' },
  { value: 'Jelenia_Gora', label: 'Jelenia Gora' },
  { value: 'Osiedle_Retkinia', label: 'Osiedle Retkinia' },
  { value: 'Polesie', label: 'Polesie' },
  { value: 'Radogoszcz', label: 'Radogoszcz' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Teofilow', label: 'Teofilow' },
];

const wroclaw = [
  { value: 'all', label: 'All districts' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Biskupin', label: 'Biskupin' },
  { value: 'Sepolno', label: 'Sępolno' },
  { value: 'Zalesie', label: 'Zalesie' },
  { value: 'Plac_Grunwaldzki', label: 'Plac Grunwaldzki' },
  { value: 'Olbin', label: 'Ołbin' },
  { value: 'Nadodrze', label: 'Nadodrze' },
  { value: 'Krzyki', label: 'Krzyki' },
  { value: 'Klecina', label: 'Klecina' },
  { value: 'Borek', label: 'Borek' },
  { value: 'Partynice', label: 'Partynice' },
  { value: 'Gaj', label: 'Gaj' },
  { value: 'Tarnogaj', label: 'Tarnogaj' },
  { value: 'Poludnie', label: 'Południe' },
  { value: 'Oltaszyn', label: 'Ołtaszyn' },
  { value: 'Fabryczna', label: 'Fabryczna' },
  { value: 'Grabiszyn', label: 'Grabiszyn' },
  { value: 'Lesnica', label: 'Leśnica' },
  { value: 'Nowy_Dwor', label: 'Nowy Dwór' },
  { value: 'Kuzniki', label: 'Kuźniki' },
  { value: 'Pilczyce', label: 'Pilczyce' },
  { value: 'Stablowice', label: 'Stabłowice' },
  { value: 'Gadow_Maly', label: 'Gądów Mały' },
  { value: 'Popwice', label: 'Popwice' },
  { value: 'Zlotniki', label: 'Złotniki' },
  { value: 'Psie_Pole', label: 'Psie Pole' },
  { value: 'Karlowice', label: 'Karłowice' },
  { value: 'Rozanka', label: 'Różanka' },
  { value: 'Osobowice', label: 'Osobowice' },
  { value: 'Kowale', label: 'Kowale' },
  { value: 'Swojczyce', label: 'Swojczyce' },
  { value: 'Swiniary', label: 'Świniary' },
  { value: 'Lipa_Piotrowska', label: 'Lipa Piotrowska' },
  { value: 'Redzin', label: 'Rędzin' },
  { value: 'Zakrzow', label: 'Zakrzów' },
  { value: 'Kleczkow', label: 'Kleczków' },
];

const poznan = [
  { value: 'all', label: 'All districts' },
  {
    value: 'Antoninek_Zieliniec_Kobylepole',
    label: 'Antoninek-Zieliniec-Kobylepole',
  },
  { value: 'Chartowo', label: 'Chartowo' },
  { value: 'Fabianowo_Kotowo', label: 'Fabianowo-Kotowo' },
  { value: 'Glowna', label: 'Główna' },
  { value: 'Gluszyna', label: 'Głuszyna' },
  { value: 'Gorczyn', label: 'Górczyn' },
  { value: 'Grunwald_Poludnie', label: 'Grunwald Południe' },
  { value: 'Grunwald_Polnoc', label: 'Grunwald Północ' },
  { value: 'Piatkowo_Polnoc', label: 'Piątkowo Północ' },
  { value: 'Jezyce', label: 'Jeżyce' },
  { value: 'Junikowo', label: 'Junikowo' },
  { value: 'Kiekrz', label: 'Kiekrz' },
  {
    value: 'Krzesiny_Pokrzywno_Garaszewo',
    label: 'Krzesiny-Pokrzywno-Garaszewo',
  },
  { value: 'Krzyzowniki_Smochowice', label: 'Krzyżowniki-Smochowice' },
  { value: 'Kwiatowe', label: 'Kwiatowe' },
  { value: 'Lawica', label: 'Ławica' },
  { value: 'Morasko_Radojewo', label: 'Morasko-Radojewo' },
  { value: 'Naramowice', label: 'Naramowice' },
  { value: 'Nowe_Winogrady_Poludnie', label: 'Nowe Winogrady Południe' },
  { value: 'Nowe_Winogrady_Polnoc', label: 'Nowe Winogrady Północ' },
  { value: 'Nowe_Winogrady_Wschod', label: 'Nowe Winogrady Wschód' },
  { value: 'Ogrody', label: 'Ogrody' },
  {
    value: 'Ostrow_Tumski_Srodka_Zawady_Komandoria',
    label: 'Ostrów Tumski-Śródka-Zawady-Komandoria',
  },
  { value: 'Piatkowo', label: 'Piątkowo' },
  { value: 'Podolany', label: 'Podolany' },
  { value: 'Rataje', label: 'Rataje' },
  { value: 'Solacz', label: 'Sołacz' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Stare_Winogrady', label: 'Stare Winogrady' },
  { value: 'Staroleka_Minikowo_Marlewo', label: 'Starołęka-Minikowo-Marlewo' },
  { value: 'Stary_Grunwald', label: 'Stary Grunwald' },
  { value: 'Strzeszyn', label: 'Strzeszyn' },
  {
    value: 'Szczepankowo_Splawie_Krzesinki',
    label: 'Szczepankowo-Spławie-Krzesinki',
  },
  { value: 'Swierczewo', label: 'Świerczewo' },
  { value: 'Sw_Lazarz', label: 'Św. Łazarz' },
  { value: 'Umultowo', label: 'Umultowo' },
  {
    value: 'Warszawskie_Pomet_Maltanskie',
    label: 'Warszawskie-Pomet-Maltańskie',
  },
  { value: 'Wilda', label: 'Wilda' },
  { value: 'Winiary', label: 'Winiary' },
  { value: 'Wola', label: 'Wola' },
  { value: 'Zielony_Debiec', label: 'Zielony Dębiec' },
  { value: 'Zegrze', label: 'Żegrze' },
];

const gdansk = [
  { value: 'all', label: 'All districts' },
  { value: 'Aniolki', label: 'Aniołki' },
  { value: 'Bretowo', label: 'Brętowo' },
  { value: 'Brzezno', label: 'Brzeźno' },
  { value: 'Chelm', label: 'Chełm' },
  { value: 'Jasien', label: 'Jasień' },
  { value: 'Kokoszki', label: 'Kokoszki' },
  { value: 'Krakowiec_Gorki_Zachodnie', label: 'Krakowiec-Górki Zachodnie' },
  { value: 'Letnica', label: 'Letnica' },
  { value: 'Matarnia', label: 'Matarnia' },
  { value: 'Mlyniska', label: 'Młyniska' },
  { value: 'Nowy_Port', label: 'Nowy Port' },
  { value: 'Oliwa', label: 'Oliwa' },
  { value: 'Olszynka', label: 'Olszynka' },
  {
    value: 'Orunia_Gorna_Gdansk_Poludnie',
    label: 'Orunia Górna-Gdańsk Południe',
  },
  { value: 'Orunia_Sw_Wojciech_Lipce', label: 'Orunia-Św. Wojciech-Lipce' },
  { value: 'Osowa', label: 'Osowa' },
  { value: 'Piecki_Migowo', label: 'Piecki-Migowo' },
  { value: 'Przerobka', label: 'Przeróbka' },
  { value: 'Przymorze_Male', label: 'Przymorze Małe' },
  { value: 'Przymorze_Wielkie', label: 'Przymorze Wielkie' },
  { value: 'Rudniki', label: 'Rudniki' },
  { value: 'Siedlce', label: 'Siedlce' },
  { value: 'Stogi', label: 'Stogi' },
  { value: 'Strzyza', label: 'Strzyża' },
  { value: 'Suchanino', label: 'Suchanino' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Ujescisko_Lostowice', label: 'Ujeścisko-Łostowice' },
  { value: 'VII_Dwor', label: 'VII Dwór' },
  { value: 'Wrzeszcz_Dolny', label: 'Wrzeszcz Dolny' },
  { value: 'Wrzeszcz_Gorny', label: 'Wrzeszcz Górny' },
  { value: 'Wyspa_Sobieszewska', label: 'Wyspa Sobieszewska' },
  { value: 'Wzgorze_Mickiewicza', label: 'Wzgórze Mickiewicza' },
  { value: 'Zaspa_Mlyniec', label: 'Zaspa-Młyniec' },
  { value: 'Zaspa_Rozstaje', label: 'Zaspa-Rozstaje' },
  {
    value: 'Zabianka_Wejhera_Jelitkowo_Tysiaclecia',
    label: 'Zabianka_Wejhera_Jelitkowo_Tysiaclecia-Rozstaje',
  },
];

const szczecin = [
  { value: 'all', label: 'All districts' },
  { value: 'Bukowe', label: 'Bukowe' },
  { value: 'Bukowo', label: 'Bukowo' },
  { value: 'Centrum', label: 'Centrum' },
  { value: 'Dabie', label: 'Dąbie' },
  { value: 'Golecino', label: 'Golęcino' },
  { value: 'Gumience', label: 'Gumieńce' },
  { value: 'Kijewo', label: 'Kijewo' },
  { value: 'Krzekowo', label: 'Krzekowo' },
  { value: 'Majowe', label: 'Majowe' },
  { value: 'Niebuszewo', label: 'Niebuszewo' },
  { value: 'Pogodno', label: 'Pogodno' },
  { value: 'Pomorzany', label: 'Pomorzany' },
  { value: 'Sloneczne', label: 'Słoneczne' },
  { value: 'Swierczewo', label: 'Świerczewo' },
  { value: 'Warszewo', label: 'Warszewo' },
  { value: 'Zdroje', label: 'Zdroje' },
];

const bydgoszcz = [
  { value: 'all', label: 'All districts' },
  { value: 'Bartodzieje', label: 'Bartodzieje' },
  { value: 'Bielawy', label: 'Bielawy' },
  { value: 'Blonie', label: 'Błonie' },
  {
    value: 'Bocianowo_Srodmiescie_Stare_Miasto',
    label: 'Bocianowo-Śródmieście-Stare Miasto',
  },
  { value: 'Brdyujscie', label: 'Brdyujście' },
  {
    value: 'Bydgoszcz_Wschod_Siernieczek',
    label: 'Bydgoszcz Wschód-Siernieczek',
  },
  { value: 'Czyzkowko', label: 'Czyżkówko' },
  { value: 'Flisy', label: 'Flisy' },
  { value: 'Glinki_Rupienica', label: 'Glinki-Rupienica' },
  { value: 'Gorzyskowo', label: 'Górzyskowo' },
  { value: 'Jachcice', label: 'Jachcice' },
  { value: 'Kapusciska', label: 'Kapuściska' },
  { value: 'Lesne', label: 'Leśne' },
  { value: 'Legnowo', label: 'Łęgnowo' },
  { value: 'Legnowo_Wies', label: 'Łęgnowo Wieś' },
  { value: 'Miedzyn_Prady', label: 'Miedzyń-Prądy' },
  { value: 'Nowy_Fordon', label: 'Nowy Fordon' },
  { value: 'Okole', label: 'Okole' },
  { value: 'Osowa_Gora', label: 'Osowa Góra' },
  { value: 'Piaski', label: 'Piaski' },
  { value: 'Smukala_Oplawiec_Janowo', label: 'Smukała-Opławiec-Janowo' },
  { value: 'Stary_Fordon', label: 'Stary Fordon' },
  { value: 'Szwederowo', label: 'Szwederowo' },
  { value: 'Tatrzanskie', label: 'Tatrzańskie' },
  { value: 'Tereny_Nadwislanskie', label: 'Tereny Nadwiślańskie' },
  { value: 'Wilczak_Jary', label: 'Wilczak-Jary' },
  { value: 'Wyzyny', label: 'Wyżyny' },
  { value: 'Wzgorze_Wolnosci', label: 'Wzgórze Wolności' },
  { value: 'Zimne_Wody_Czersko_Polskie', label: 'Zimne Wody–Czersko Polskie' },
];

const lublin = [
  { value: 'all', label: 'All districts' },
  { value: 'Abramowice', label: 'Abramowice' },
  { value: 'Bazylianowka', label: 'Bazylianówka' },
  { value: 'Bialkowska_Gora', label: 'Białkowska Góra' },
  { value: 'Bielszczyzna', label: 'Bielszczyzna' },
  { value: 'Biskupie', label: 'Biskupie' },
  { value: 'Bronowice', label: 'Bronowice' },
  { value: 'Bursaki', label: 'Bursaki' },
  { value: 'Choinki', label: 'Choinki' },
  { value: 'Choiny', label: 'Choiny' },
  { value: 'Chruszczanka', label: 'Chruszczanka' },
  { value: 'Czechow_Dolny', label: 'Czechów Dolny' },
  { value: 'Czechow_Gorny', label: 'Czechów Górny' },
  { value: 'Czechow_Kolonia', label: 'Czechów-Kolonia' },
  { value: 'Czechowka_Dolna', label: 'Czechówka Dolna' },
  { value: 'Czechowka_Gorna', label: 'Czechówka Górna' },
  { value: 'Czechowka_Gorna_Kolonia', label: 'Czechówka Górna-Kolonia' },
  { value: 'Czuby', label: 'Czuby' },
  { value: 'Czwartek', label: 'Czwartek' },
  { value: 'Dabrowa_Las', label: 'Dąbrowa-Las' },
  { value: 'Dzbenin', label: 'Dzbenin' },
  { value: 'Dziesiata', label: 'Dziesiąta' },
  { value: 'Dluga_Wies', label: 'Długa Wieś' },
  { value: 'Felin', label: 'Felin' },
  { value: 'Gajowka_Bor', label: 'Gajówka-Bór' },
  { value: 'Granicznik', label: 'Granicznik' },
  { value: 'Hajdow', label: 'Hajdów' },
  { value: 'Helenow', label: 'Helenów' },
  { value: 'Jerzyslaw', label: 'Jerzysław' },
  { value: 'Kalinowszczyzna', label: 'Kalinowszczyzna' },
  { value: 'Kolonia_Slawin', label: 'Kolonia Sławin' },
  { value: 'Kolonia_Slawinek', label: 'Kolonia Sławinek' },
  { value: 'Konstantynow', label: 'Konstantynów' },
  { value: 'Kosminek', label: 'Kośminek' },
  { value: 'Lemszczyzna', label: 'Lemszczyzna' },
  { value: 'Listkow', label: 'Listków' },
  { value: 'Majdan_Tatarski', label: 'Majdan Tatarski' },
  { value: 'Majdan_Wrotkowski', label: 'Majdan Wrotkowski' },
  { value: 'Majdanek', label: 'Majdanek' },
  { value: 'Nowiny', label: 'Nowiny' },
  { value: 'Nowy_Kosminek', label: 'Nowy Kośminek' },
  { value: 'Partanina', label: 'Partanina' },
  { value: 'Piaski', label: 'Piaski' },
  { value: 'Piorunowka', label: 'Piorunówka' },
  { value: 'Poczekajka', label: 'Poczekajka' },
  { value: 'Podgrodzie', label: 'Podgrodzie' },
  { value: 'Podzamcze', label: 'Podzamcze' },
  { value: 'Ponikwoda', label: 'Ponikwoda' },
  { value: 'Prawiedniki', label: 'Prawiedniki' },
  { value: 'Rudnik', label: 'Rudnik' },
  { value: 'Rury_Bonifraterskie', label: 'Rury Bonifraterskie' },
  { value: 'Rury_Brygidkowskie', label: 'Rury Brygidkowskie' },
  { value: 'Rury_Jezuickie', label: 'Rury Jezuickie' },
  { value: 'Rury_Swietoduskie', label: 'Rury Świętoduskie' },
  { value: 'Rury_Wizytkowskie', label: 'Rury Wizytkowskie' },
  { value: 'Semborowka', label: 'Semborówka' },
  { value: 'Sierakowszczyzna', label: 'Sierakowszczyzna' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Stary_Gaj', label: 'Stary Gaj' },
  { value: 'Swistanowka', label: 'Świstanówka' },
  { value: 'Slawin', label: 'Sławin' },
  { value: 'Slawinek', label: 'Sławinek' },
  { value: 'Tatary', label: 'Tatary' },
  { value: 'Tomaszowka', label: 'Tomaszówka' },
  { value: 'Trzesniow', label: 'Trześniów' },
  { value: 'Weglin', label: 'Węglin' },
  { value: 'Weglinek', label: 'Węglinek' },
  { value: 'Wieniawa', label: 'Wieniawa' },
  { value: 'Wieniawka', label: 'Wieniawka' },
  { value: 'Wiktoryn', label: 'Wiktoryn' },
  { value: 'Wola_Slawinska', label: 'Wola Sławińska' },
  { value: 'Wrotkow', label: 'Wrotków' },
  { value: 'Wyscigi', label: 'Wyścigi' },
  { value: 'Zadebie_Drugie', label: 'Zadębie Drugie' },
  { value: 'Zadebie_Pierwsze', label: 'Zadębie Pierwsze' },
  { value: 'Zadebie_Stare', label: 'Zadębie Stare' },
  { value: 'Zadebie_Trzecie', label: 'Zadębie Trzecie' },
  { value: 'Zemborzyce', label: 'Zemborzyce' },
  { value: 'Zemborzyce_Gorne', label: 'Zemborzyce Górne' },
  { value: 'Zimne_Doly', label: 'Zimne Doły' },
  { value: 'Zosinek', label: 'Zosinek' },
  { value: 'Zygmuntow', label: 'Zygmuntów' },
];

const bialystok = [
  { value: 'all', label: 'All districts' },
  { value: 'Antoniuk', label: 'Antoniuk' },
  { value: 'Bacieczki', label: 'Bacieczki' },
  { value: 'Bema', label: 'Bema' },
  { value: 'Bialostoczek', label: 'Białostoczek' },
  { value: 'Bojary', label: 'Bojary' },
  { value: 'Centrum', label: 'Centrum' },
  { value: 'Dojlidy', label: 'Dojlidy' },
  { value: 'Dojlidy_Gorne', label: 'Dojlidy Górne' },
  { value: 'Dziesieciny_I', label: 'Dziesięciny I' },
  { value: 'Dziesieciny_II', label: 'Dziesięciny II' },
  { value: 'Jaroszowka', label: 'Jaroszówka' },
  { value: 'Kawaleryjskie', label: 'Kawaleryjskie' },
  { value: 'Lesna_Dolina', label: 'Leśna Dolina' },
  { value: 'Mickiewicza', label: 'Mickiewicza' },
  { value: 'Mlodych', label: 'Młodych' },
  { value: 'Nowe_Miasto', label: 'Nowe Miasto' },
  { value: 'Piaski', label: 'Piaski' },
  { value: 'Piasta_I', label: 'Piasta I' },
  { value: 'Piasta_II', label: 'Piasta II' },
  { value: 'Przydworcowe', label: 'Przydworcowe' },
  { value: 'Sienkiewicza', label: 'Sienkiewicza' },
  { value: 'Skorupy', label: 'Skorupy' },
  { value: 'Sloneczny_Stok', label: 'Słoneczny Stok' },
  { value: 'Starosielce', label: 'Starosielce' },
  { value: 'Wygoda', label: 'Wygoda' },
  { value: 'Wysoki_Stoczek', label: 'Wysoki Stoczek' },
  { value: 'Zawady', label: 'Zawady' },
  { value: 'Zielone_Wzgorza', label: 'Zielone_Wzgorza' },
];

const katowice = [
  { value: 'all', label: 'All districts' },
  { value: 'Bederowiec', label: 'Bederowiec' },
  { value: 'Bogucice', label: 'Bogucice' },
  { value: 'Borki', label: 'Borki' },
  { value: 'Brynow', label: 'Brynów' },
  { value: 'Burowiec', label: 'Burowiec' },
  { value: 'Cegielnia_Murcki', label: 'Cegielnia-Murcki' },
  { value: 'Dab', label: 'Dąb' },
  { value: 'Dabrowka_Mala', label: 'Dąbrówka Mała' },
  { value: 'Dolina_Trzech_Stawow', label: 'Dolina Trzech Stawów' },
  { value: 'Giszowiec', label: 'Giszowiec' },
  { value: 'Janow', label: 'Janów' },
  { value: 'Jozefowiec', label: 'Józefowiec' },
  { value: 'Karbowa', label: 'Karbowa' },
  { value: 'Kokociniec', label: 'Kokociniec' },
  { value: 'Kolonia_Agnieszki', label: 'Kolonia Agnieszki' },
  { value: 'Kolonia_Amandy', label: 'Kolonia Amandy' },
  { value: 'Kolonia_Boze_Dary', label: 'Kolonia Boże Dary' },
  { value: 'Kolonia_Wysockiego', label: 'Kolonia_Wysockiego' },
  { value: 'Kolonia_Zuzanny', label: 'Kolonia Zuzanny' },
  { value: 'Kostuchna', label: 'Kostuchna' },
  { value: 'Koszutka', label: 'Koszutka' },
  { value: 'Ligota', label: 'Ligota' },
  { value: 'Muchowiec', label: 'Muchowiec' },
  { value: 'Murcki', label: 'Murcki' },
  { value: 'Nikiszowiec', label: 'Nikiszowiec' },
  { value: 'Ochojec', label: 'Ochojec' },
  { value: 'Panewniki', label: 'Panewniki' },
  { value: 'Piotrowice', label: 'Piotrowice' },
  { value: 'Podlesie', label: 'Podlesie' },
  { value: 'Rozdzien', label: 'Roździeń' },
  { value: 'Stawiska', label: 'Stawiska' },
  { value: 'Szadok', label: 'Szadok' },
  { value: 'Szopienice', label: 'Szopienice' },
  { value: 'Welnowiec', label: 'Wełnowiec' },
  { value: 'Wilhelmina', label: 'Wilhelmina' },
  { value: 'Wymyslow', label: 'Wymysłów' },
  { value: 'Zadole', label: 'Zadole' },
  { value: 'Zarzecze', label: 'Zarzecze' },
  { value: 'Zawodzie', label: 'Zawodzie' },
  { value: 'Zaleska_Halda', label: 'Załęska Hałda' },
  { value: 'Zaleze', label: 'Załęże' },
];

const gdynia = [
  { value: 'all', label: 'All districts' },
  { value: 'Babie_Doly', label: 'Babie Doły' },
  { value: 'Chwarzno_Wiczlino', label: 'Chwarzno-Wiczlino' },
  { value: 'Chylonia', label: 'Chylonia' },
  { value: 'Cisowa', label: 'Cisowa' },
  { value: 'Dabrowa', label: 'Dąbrowa' },
  { value: 'Dzialki_Lesne', label: 'Działki Leśne' },
  { value: 'Grabowek', label: 'Grabówek' },
  { value: 'Kamienna_Gora', label: 'Kamienna Góra' },
  { value: 'Karwiny', label: 'Karwiny' },
  { value: 'Leszczynki', label: 'Leszczynki' },
  { value: 'Maly_Kack', label: 'Mały Kack' },
  { value: 'Obluze', label: 'Obłuże' },
  { value: 'Oksywie', label: 'Oksywie' },
  { value: 'Orlowo', label: 'Orłowo' },
  { value: 'Pogorze', label: 'Pogórze' },
  { value: 'Pustki_Cisowskie_Demptowo', label: 'Pustki Cisowskie-Demptowo' },
  { value: 'Redlowo', label: 'Redłowo' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Wielki_Kack', label: 'Wielki Kack' },
  { value: 'Witomino_Lesniczowka', label: 'Witomino-Leśniczówka' },
  { value: 'Witomino_Radiostacja', label: 'Witomino-Radiostacja' },
  {
    value: 'Wzgorze_Swietego_Maksymiliana',
    label: 'Wzgórze Świętego Maksymiliana',
  },
];

const czestochowa = [
  { value: 'all', label: 'All districts' },
  { value: 'Bleszno', label: 'Błeszno' },
  { value: 'Dzbow', label: 'Dźbów' },
  { value: 'Grabowka', label: 'Grabówka' },
  { value: 'Kawodrza', label: 'Kawodrza' },
  { value: 'Kiedrzyn', label: 'Kiedrzyn' },
  { value: 'Lisiniec', label: 'Lisiniec' },
  { value: 'Mirow', label: 'Mirów' },
  { value: 'Ostatni_Grosz', label: 'Ostatni Grosz' },
  { value: 'Parkitka', label: 'Parkitka' },
  { value: 'Podjasnogorska', label: 'Podjasnogórska' },
  { value: 'Polnoc', label: 'Północ' },
  { value: 'Rakow', label: 'Raków' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Stradom', label: 'Stradom' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Trzech_Wieszczow', label: 'Trzech Wieszczów' },
  { value: 'Tysiaclecie', label: 'Tysiąclecie' },
  { value: 'Wrzosowiak', label: 'Wrzosowiak' },
  { value: 'Zawodzie_Dabie', label: 'Zawodzie-Dąbie' },
];

const radom = [
  { value: 'all', label: 'All districts' },
  { value: 'Augustow', label: 'Augustów' },
  { value: 'Borki', label: 'Borki' },
  { value: 'Bronislawow', label: 'Bronisławów' },
  { value: 'Brzustowka', label: 'Brzustówka' },
  { value: 'Dzierzkow', label: 'Dzierzków' },
  { value: 'Dlugojow', label: 'Długojów' },
  { value: 'Dlugojow_Gorny', label: 'Długojów Górny' },
  { value: 'Dlugojow_Kolonie', label: 'Długojów-Kolonie' },
  { value: 'Firlej', label: 'Firlej' },
  { value: 'Firlej_Cegielnia', label: 'Firlej-Cegielnia' },
  { value: 'Glinice', label: 'Glinice' },
  { value: 'Godow', label: 'Godów' },
  { value: 'Golebiow', label: 'Gołębiów' },
  { value: 'Golebiow_Wojtostwo', label: 'Gołębiów-Wójtostwo' },
  { value: 'Halinow', label: 'Halinów' },
  { value: 'Huta', label: 'Huta' },
  { value: 'Idalin', label: 'Idalin' },
  { value: 'Janiszpol', label: 'Janiszpol' },
  { value: 'Jezowa_Wola', label: 'Jeżowa Wola' },
  { value: 'Jozefow', label: 'Józefów' },
  { value: 'Kaptur', label: 'Kaptur' },
  { value: 'Kierzkow', label: 'Kierzków' },
  { value: 'Komorniki_Rajeckie', label: 'Komorniki Rajeckie' },
  { value: 'Konczyce', label: 'Kończyce' },
  { value: 'Koniowka', label: 'Koniówka' },
  { value: 'Kozia_Gora', label: 'Kozia Góra' },
  { value: 'Krychnowice', label: 'Krychnowice' },
  { value: 'Krzewien', label: 'Krzewień' },
  { value: 'Las_Kapturski', label: 'Las Kapturski' },
  { value: 'Malczew', label: 'Malczew' },
  { value: 'Malenice', label: 'Malenice' },
  { value: 'Mariackie', label: 'Mariackie' },
  { value: 'Miasto_Kazimierzowskie', label: 'Miasto Kazimierzowskie' },
  { value: 'Michalow', label: 'Michałów' },
  { value: 'Mleczna', label: 'Mleczna' },
  { value: 'Mlodzianow', label: 'Młodzianów' },
  { value: 'Mlyn_Wojtostwo', label: 'Młyn-Wójtostwo' },
  { value: 'Mlynek_Janiszewski', label: 'Młynek Janiszewski' },
  { value: 'Nowa_Wola_Golebiowska', label: 'Nowa Wola Gołębiowska' },
  { value: 'Nowiny_Malczewskie', label: 'Nowiny Malczewskie' },
  { value: 'Nowy_Swiat', label: 'Nowy Świat' },
  { value: 'Obozisko', label: 'Obozisko' },
  { value: 'Piotrowka', label: 'Piotrówka' },
  { value: 'Planty', label: 'Planty' },
  { value: 'Potkanow', label: 'Potkanów' },
  { value: 'Predocinek', label: 'Prędocinek' },
  { value: 'Pruszakow', label: 'Pruszaków' },
  { value: 'Rajec_Poduchowny', label: 'Rajec Poduchowny' },
  { value: 'Rajec_Szlachecki', label: 'Rajec Szlachecki' },
  { value: 'Srodmiescie', label: 'Śródmieście' },
  { value: 'Stara_Wola_Golebiowska', label: 'Stara Wola Gołębiowska' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  { value: 'Szczygiel', label: 'Szczygieł' },
  { value: 'Ustronie', label: 'Ustronie' },
  { value: 'Wacyn', label: 'Wacyn' },
  { value: 'Weronow', label: 'Weronów' },
  { value: 'Wielogora', label: 'Wielogóra' },
  { value: 'Wincentow', label: 'Wincentów' },
  { value: 'Wolka_Klwatecka', label: 'Wólka Klwatecka' },
  { value: 'Wosniki', label: 'Wośniki' },
  { value: 'Zakowice', label: 'Żakowice' },
  { value: 'Zamlynie', label: 'Zamłynie' },
];

const sosnowiec = [
  { value: 'all', label: 'All districts' },
  { value: 'Bobrek', label: 'Bobrek' },
  { value: 'Bor', label: 'Bór' },
  { value: 'Ciesle', label: 'Cieśle' },
  { value: 'Dandowka', label: 'Dańdówka' },
  { value: 'Debowa_Gora', label: 'Dębowa Góra' },
  { value: 'Feliks', label: 'Feliks' },
  { value: 'Jezor', label: 'Jęzor' },
  { value: 'Jozefow', label: 'Józefów' },
  { value: 'Juliusz', label: 'Juliusz' },
  { value: 'Kalety', label: 'Kalety' },
  { value: 'Kazimierz', label: 'Kazimierz' },
  { value: 'Klimontow', label: 'Klimontów' },
  { value: 'Kolonia_Przyszlosc', label: 'Kolonia Przyszłość' },
  { value: 'Konstantynow', label: 'Konstantynów' },
  { value: 'Ludmila', label: 'Ludmiła' },
  { value: 'Maczki', label: 'Maczki' },
  { value: 'Mec', label: 'Mec' },
  { value: 'Milowice', label: 'Milowice' },
  { value: 'Modrzejow', label: 'Modrzejow' },
  { value: 'Niwka', label: 'Niwka' },
  { value: 'Nowe_Zawodzie', label: 'Nowe Zawodzie' },
  { value: 'Ostrowy_Gornicze', label: 'Ostrowy Górnicze' },
  { value: 'Pogon', label: 'Pogoń' },
  { value: 'Porabka', label: 'Porąbka' },
  { value: 'Sielec', label: 'Sielec' },
  { value: 'Srodula', label: 'Środula' },
  { value: 'Stare_Zawodzie', label: 'Stare Zawodzie' },
  { value: 'Stary_Sosnowiec', label: 'Stary Sosnowiec' },
  { value: 'Zagorze', label: 'Zagórze' },
];

const torun = [
  { value: 'all', label: 'All districts' },
  { value: 'Bielany', label: 'Bielany' },
  { value: 'Bielawy', label: 'Bielawy' },
  { value: 'Bydgoskie_Przedmiescie', label: 'Bydgoskie Przedmieście' },
  { value: 'Chelminskie_Przedmiescie', label: 'Chełmińskie Przedmieście' },
  { value: 'Czerniewice', label: 'Czerniewice' },
  { value: 'Glinki', label: 'Glinki' },
  { value: 'Grebocin_nad_Struga', label: 'Grębocin nad Strugą' },
  { value: 'Jakubskie_Przedmiescie', label: 'Jakubskie Przedmieście' },
  { value: 'Kaszczorek', label: 'Kaszczorek' },
  { value: 'Katarzynka', label: 'Katarzynka' },
  { value: 'Koniuchy', label: 'Koniuchy' },
  { value: 'Mokre', label: 'Mokre' },
  { value: 'Na_Skarpie', label: 'Na Skarpie' },
  { value: 'Piaski', label: 'Piaski' },
  { value: 'Podgorz', label: 'Podgórz' },
  { value: 'Rubinkowo', label: 'Rubinkowo' },
  { value: 'Rudak', label: 'Rudak' },
  { value: 'Rybaki', label: 'Rybaki' },
  { value: 'Stare_Miasto', label: 'Stare Miasto' },
  {
    value: 'Starotorunskie_Przedmiescie',
    label: 'Starotoruńskie Przedmieście',
  },
  { value: 'Stawki', label: 'Stawki' },
  { value: 'Winnica', label: 'Bobrek' },
  { value: 'Wrzosy', label: 'Wrzosy' },
];

const kielce = [
  { value: 'all', label: 'All districts' },
  { value: 'Baranowek', label: 'Baranówek' },
  { value: 'Barwinek', label: 'Barwinek' },
  { value: 'Bialogon', label: 'Białogon' },
  { value: 'Biesaki', label: 'Biesaki' },
  { value: 'Bocianek', label: 'Bocianek' },
  { value: 'Bor', label: 'Bór' },
  { value: 'Bukowka', label: 'Bukówka' },
  { value: 'Cedro_Mazur', label: 'Cedro-Mazur' },
  { value: 'Cegielnia', label: 'Cegielnia' },
  { value: 'Chrusty', label: 'Chrusty' },
  { value: 'Czarnow', label: 'Czarnów' },
  { value: 'Czarnow_Rzadowy', label: 'Czarnów Rządowy' },
  { value: 'Czarnow_Osiedle', label: 'Czarnów-Osiedle' },
  { value: 'Dobromysl', label: 'Dobromyśl' },
  { value: 'Dodatki', label: 'Dodatki' },
  { value: 'Domaszewice_Wikaryjskie', label: 'Domaszewice Wikaryjskie' },
  { value: 'Dyminy_Wies', label: 'Dyminy-Wieś' },
  { value: 'Gorka', label: 'Górka' },
  { value: 'Gruchawka', label: 'Gruchawka' },
  { value: 'Gleboczka', label: 'Głęboczka' },
  { value: 'Herby', label: 'Herby' },
  { value: 'Kadzielnia', label: 'Kadzielnia' },
  { value: 'Karczowka', label: 'Karczówka' },
  { value: 'Kawetczyzna', label: 'Kawetczyzna' },
  { value: 'Kleckie', label: 'Kleckie' },
  { value: 'Koszarka', label: 'Koszarka' },
  { value: 'Koszary', label: 'Koszary' },
  { value: 'Kozi_Lasek', label: 'Kozi Lasek' },
  { value: 'Krzetle', label: 'Krzetle' },
  { value: 'Lesniowka', label: 'Leśniówka' },
  { value: 'Malikow', label: 'Malików' },
  { value: 'Markowizna', label: 'Markowizna' },
  { value: 'Nastole', label: 'Nastole' },
  { value: 'Naleczow', label: 'Nałęczów' },
  { value: 'Niewachlow_Drugi', label: 'Niewachlów Drugi' },
  { value: 'Niewachlow_Pierwszy', label: 'Niewachlów Pierwszy' },
  { value: 'Nowy_Folwark', label: 'Nowy Folwark' },
  { value: 'Ogrod_Kolonia', label: 'Ogród-Kolonia' },
  { value: 'Ostra_Gorka', label: 'Ostra Górka' },
  { value: 'Pakosz', label: 'Pakosz' },
  { value: 'Piaski', label: 'Piaski' },
  { value: 'Pietraszki', label: 'Pietraszki' },
  { value: 'Pocieszka', label: 'Pocieszka' },
  { value: 'Pod_Telegrafem', label: 'Pod Telegrafem' },
  { value: 'Poslowice', label: 'Posłowice' },
  { value: 'Psie_Gorki', label: 'Psie Górki' },
  { value: 'Radostowa', label: 'Radostowa' },
  { value: 'Schaby', label: 'Schaby' },
  { value: 'Sieje', label: 'Sieje' },
  { value: 'Slichowice', label: ' Ślichowice' },
  { value: 'Stadion', label: 'Stadion' },
  { value: 'Stanislawow', label: 'Stanisławów' },
  { value: 'Stara_Gora', label: 'Stara Góra' },
  { value: 'Stara_Wies', label: 'Stara Wieś' },
  { value: 'Szatan', label: 'Szatan' },
  { value: 'Szydlowek', label: 'Szydłówek' },
  { value: 'Slowik_Willa', label: 'Słowik-Willa' },
  { value: 'Wasosz', label: 'Wąsosz' },
  { value: 'Wietrznia', label: 'Wietrznia' },
  { value: 'Zagorze_Dworskie', label: 'Zagórze Dworskie' },
  { value: 'Zagorze_Gorne', label: 'Zagórze Górne' },
  { value: 'Zagorze_Zastawie', label: 'Zagórze-Zastawie' },
  { value: 'Zakaplica', label: 'Zakaplica' },
  { value: 'Zalesie', label: 'Zalesie' },
  { value: 'Zalesie_Drugie', label: 'Zalesie Drugie' },
  { value: 'Zalesie_Pierwsze', label: 'Zalesie Pierwsze' },
  { value: 'Zalesie_Slowik', label: 'Zalesie-Słowik' },
  { value: 'Zaporebie', label: 'Zaporębie' },
  { value: 'Zaszosie', label: 'Zaszosie' },
  { value: 'Lan', label: 'Łan' },
  { value: 'Lazy', label: 'Łazy' },
];

const rzeszow = [
  { value: 'all', label: 'All districts' },
  { value: 'Baranowka', label: 'Baranówka' },
  { value: 'Biala', label: 'Biała' },
  { value: 'Budy', label: 'Budy' },
  { value: 'Budziwoj', label: 'Budziwój' },
  { value: 'Bzianka', label: 'Bzianka' },
  { value: 'Chabka', label: 'Chabka' },
  { value: 'Debina', label: 'Dębina' },
  { value: 'Dol', label: 'Dół' },
  { value: 'Drabinianka', label: 'Drabinianka' },
  { value: 'Dworzysko', label: 'Dworzysko' },
  { value: 'Gora', label: 'Góra' },
  { value: 'Gorka', label: 'Górka' },
  { value: 'Gorna_Slocina', label: 'Górna Słocina' },
  { value: 'Hiszpanka', label: 'Hiszpanka' },
  { value: 'Kogutowka', label: 'Kogutówka' },
  { value: 'Kolonia', label: 'Kolonia' },
  { value: 'Milocin', label: 'Miłocin' },
  { value: 'Na_Okrazkach', label: 'Na Okrążkach' },
  { value: 'Nowe_Budy', label: 'Nowe Budy' },
  { value: 'Nowe_Miasto', label: 'Nowe Miasto' },
  { value: 'Pobitno', label: 'Pobitno' },
  { value: 'Pod_Raclawowka', label: 'Pod Racławówką' },
  { value: 'Pod_Staroniwa', label: 'Pod Staroniwą' },
  { value: 'Podlesie', label: 'Podlesie' },
  { value: 'Podleze', label: 'Podłęże' },
  { value: 'Porabki', label: 'Porąbki' },
  { value: 'Potoki', label: 'Potoki' },
  { value: 'Polanek', label: 'Półanek' },
  { value: 'Przybyszowka', label: 'Przybyszówka' },
  { value: 'Przybyszowka_Dolna', label: 'Przybyszówka Dolna' },
  { value: 'Przybyszowka_Gorna', label: 'Przybyszówka Górna' },
  { value: 'Przylasek', label: 'Przylasek' },
  { value: 'Pustki', label: 'Pustki' },
  { value: 'Stare_Budy', label: 'Stare Budy' },
  { value: 'Staromiescie', label: 'Staromieście' },
  { value: 'Staroniwa', label: 'Staroniwa' },
  { value: 'Stawki', label: 'Stawki' },
  { value: 'Swidrowka', label: 'Świdrówka' },
  { value: 'Swiety_Roch', label: 'Święty Roch' },
  { value: 'Szajerowka', label: 'Szajerówka' },
  { value: 'Slocina', label: 'Słocina' },
  { value: 'Wilkowyja', label: 'Wilkowyja' },
  { value: 'Za_Koleja', label: 'Za Koleją' },
  { value: 'Zabnik', label: 'Żabnik' },
  { value: 'Zagumnie', label: 'Zagumnie' },
  { value: 'Zalesie', label: 'Zalesie' },
  { value: 'Zaleze', label: 'Załęże' },
  { value: 'Zwieczyca', label: 'Zwięczyca' },
  { value: 'Lany', label: 'Łany' },
];

const gliwice = [
  { value: 'all', label: 'All districts' },
  { value: 'Bojkow', label: 'Bojków' },
  { value: 'Brzezinka', label: 'Brzezinka' },
  { value: 'Czechowice', label: 'Czechowice' },
  { value: 'Ligota_Zabrska', label: 'Ligota Zabrska' },
  { value: 'Niepaszyce', label: 'Niepaszyce' },
  { value: 'Ostropa', label: 'Ostropa' },
  { value: 'Przyszowka', label: 'Przyszówka' },
  { value: 'Sosnica', label: 'Sośnica' },
  { value: 'Stare_Gliwice', label: 'Stare Gliwice' },
  { value: 'Szobiszowice', label: 'Szobiszowice' },
  { value: 'Trynek', label: 'Trynek' },
  { value: 'Wilcze_Gardlo', label: 'Wilcze Gardło' },
  { value: 'Wojtowa_Wies', label: 'Wójtowa Wieś' },
  { value: 'Zerniki', label: 'Żerniki' },
  { value: 'Labedy', label: 'Łabędy' },
];

const zabrze = [
  { value: 'all', label: 'All districts' },
  { value: 'Biskupice', label: 'Biskupice' },
  { value: 'Grzybowice', label: 'Grzybowice' },
  { value: 'Helenka', label: 'Helenka' },
  { value: 'Konczyce', label: 'Kończyce' },
  { value: 'Maciejow', label: 'Maciejów' },
  { value: 'Makoszowy', label: 'Makoszowy' },
  { value: 'Mikulczyce', label: 'Mikulczyce' },
  { value: 'Pawlow', label: 'Pawłów' },
  { value: 'Rokitnica', label: 'Rokitnica' },
  { value: 'Zaborze', label: 'Zaborze' },
];

const olsztyn = [
  { value: 'all', label: 'All districts' },
  { value: 'Dajtki', label: 'Dajtki' },
  { value: 'Gradek', label: 'Grądek' },
  { value: 'Hubertow', label: 'Hubertów' },
  { value: 'Karolin', label: 'Karolin' },
  { value: 'Kortowo', label: 'Kortowo' },
  { value: 'Likusy', label: 'Likusy' },
  { value: 'Pieczewo', label: 'Pieczewo' },
  { value: 'Pozorty', label: 'Pozorty' },
  { value: 'Stary_Dwor', label: 'Stary Dwór' },
  { value: 'Track', label: 'Track' },
  { value: 'Centrum', label: 'Centrum' },
];

const opole = [
  { value: 'all', label: 'All districts' },
  { value: 'Bierkowice', label: 'Bierkowice' },
  { value: 'Borki', label: 'Borki' },
  { value: 'Brody', label: 'Brody' },
  { value: 'Brzezie', label: 'Brzezie' },
  { value: 'Chmielowice', label: 'Chmielowice' },
  { value: 'Chwalkowice', label: 'Chwałkowice' },
  { value: 'Czarnowasy', label: 'Czarnowąsy' },
  { value: 'Gasow', label: 'Gąsów' },
  { value: 'Goslawice', label: 'Gosławice' },
  { value: 'Groszowice', label: 'Groszowice' },
  { value: 'Grotowice', label: 'Grotowice' },
  { value: 'Grudzice', label: 'Grudzice' },
  { value: 'Kolonia_Goslawicka', label: 'Kolonia Gosławicka' },
  { value: 'Krzanowice', label: 'Krzanowice' },
  { value: 'Krzyze', label: 'Krzyże' },
  { value: 'Klapacz', label: 'Kłapacz' },
  { value: 'Malina', label: 'Malina' },
  { value: 'Nowa_Wies_Krolewska', label: 'Nowa Wieś Królewska' },
  { value: 'Okol', label: 'Okół' },
  { value: 'Pasieka', label: 'Pasieka' },
  { value: 'Podlesie_Lewe', label: 'Podlesie Lewe' },
  { value: 'Podlesie_Prawe', label: 'Podlesie Prawe' },
  { value: 'Polwies', label: 'Półwieś' },
  { value: 'Przeskok', label: 'Przeskok' },
  { value: 'Stara_Droga', label: 'Stara Droga' },
  { value: 'Swierkle', label: 'Świerkle' },
  { value: 'Szczepanowice', label: 'Szczepanowice' },
  { value: 'Slawice', label: 'Sławice' },
  { value: 'Winow', label: 'Winów' },
  { value: 'Wojtowa_Wies', label: 'Wójtowa Wieś' },
  { value: 'Wroblin', label: 'Wróblin' },
  { value: 'Wrzoski', label: 'Wrzoski' },
  { value: 'Wyrebiny', label: 'Wyrębiny' },
  { value: 'Wyspa_Bolko', label: 'Wyspa Bolko' },
  { value: 'Zaba', label: 'Żaba' },
  { value: 'Zabia_Gorka', label: 'Żabia Górka' },
  { value: 'Zakrzow', label: 'Zakrzów' },
  { value: 'Zaodrze', label: 'Zaodrze' },
  { value: 'Zaplocie', label: 'Zapłocie' },
  { value: 'Zerkowice', label: 'Żerkowice' },
];

const gorzow_wielkopolski = [
  { value: 'all', label: 'All districts' },
  { value: 'Chroscik', label: 'Chróścik' },
  { value: 'Chwalecice_Dolne', label: 'Chwalęcice Dolne' },
  { value: 'Gorczyn', label: 'Górczyn' },
  { value: 'Kopystno', label: 'Kopystno' },
  { value: 'Klodawa', label: 'Kłodawa' },
  { value: 'Malyszyn_Maly', label: 'Małyszyn Mały' },
  { value: 'Malyszyn_Wielki', label: 'Małyszyn Wielki' },
  { value: 'Nowe_Siedlice', label: 'Nowe Siedlice' },
  { value: 'Siedlice', label: 'Siedlice' },
  { value: 'Siedliczki', label: 'Siedliczki' },
  { value: 'Zamoscie', label: 'Zamoście' },
  { value: 'Zieleniec', label: 'Zieleniec' },
];

const zielona_gora = [
  { value: 'all', label: 'All districts' },
  { value: 'Zielona_Gora_Barcikowice', label: 'Zielona Góra Barcikowice' },
  { value: 'Barcikowiczki', label: 'Barcikowiczki' },
  { value: 'Chynow', label: 'Chynów' },
  { value: 'Drzonkow', label: 'Drzonków' },
  { value: 'Jany', label: 'Jany' },
  { value: 'Jarogniewice', label: 'Jarogniewice' },
  { value: 'Jedrzychow', label: 'Jędrzychów' },
  { value: 'Jeleniow', label: 'Jeleniów' },
  { value: 'Kielpin', label: 'Kiełpin' },
  { value: 'Krepa', label: 'Krępa' },
  { value: 'Krepa_Mala', label: 'Krępa Mała' },
  { value: 'Marzecin', label: 'Marzęcin' },
  { value: 'Nowy_Kisielin', label: 'Nowy Kisielin' },
  { value: 'Ochla', label: 'Ochla' },
  { value: 'Piskorze', label: 'Piskorze' },
  { value: 'Przydroze', label: 'Przydroże' },
  { value: 'Przylep', label: 'Przylep' },
  { value: 'Racula', label: 'Racula' },
  { value: 'Raculka', label: 'Raculka' },
  { value: 'Stary_Kisielin', label: 'Stary Kisielin' },
  { value: 'Stozne', label: 'Stożne' },
  { value: 'Sucha', label: 'Sucha' },
  { value: 'Wlosan', label: 'Włosań' },
  { value: 'Zatonie', label: 'Zatonie' },
  { value: 'Zawada', label: 'Zawada' },
  { value: 'Lezyca', label: 'Łężyca' },
  { value: 'Lugowo', label: 'Ługowo' },
];

export const country_districts = {
  warsaw,
  krakow,
  lodz,
  wroclaw,
  poznan,
  gdansk,
  szczecin,
  bydgoszcz,
  lublin,
  bialystok,
  katowice,
  gdynia,
  czestochowa,
  radom,
  sosnowiec,
  torun,
  kielce,
  rzeszow,
  gliwice,
  zabrze,
  olsztyn,
  opole,
  gorzow_wielkopolski,
  zielona_gora,
};
