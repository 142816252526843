import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useIsMobile } from 'hooks/useIsMobile';

import { Contacts } from 'components/AboutUser';
import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const ContactsMobile = ({
  profile, rates, messengers, social_networks,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const showDrawer = useIsMobile({ maxWidth: 1200 });

  return showDrawer ? (
    <div className={styles.contactsMobile}>
      <Button primary onClick={() => setOpen(true)}>
        {t('common.contacts')}
      </Button>
      <ModalWrapper
        open={open}
        setOpen={setOpen}
        onlyDrawer
        footer={(
          <Link to={`tel:${profile.phone_number}`}>
            <Button primary className={styles.button}>
              {t('common.callTo')}
              {' '}
              {profile.nickname}
            </Button>
          </Link>
        )}
      >
        <Contacts
          profile={profile}
          rates={rates}
          messengers={messengers}
          social_networks={social_networks}
        />
      </ModalWrapper>
    </div>
  ) : null;
};

ContactsMobile.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    age: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      duration: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      type_of_service: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      discount_type: PropTypes.string,
      amount_after_discount: PropTypes.number.isRequired,
      discount_amount: PropTypes.number,
    }),
  ).isRequired,
  messengers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  social_networks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
};

export { ContactsMobile };
