import { Link, useNavigate } from 'react-router-dom';

import { paths } from 'constants';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const AdvertisingPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>Advertising Policy</h1>
      <p>
        All providers on ToLips.link agree to be bound by this Advertising
        Policy. This policy is an extension to the Code of Conduct which applies
        to all users of ToLips.link and all content shared on the platform. This
        policy exists alongside our
        {' '}
        <Link to={paths.terms}>{t('Terms of Service')}</Link>
        {' '}
        and
        {' '}
        <Link to={paths.codeOfConduct}>{t('Code of Conduct')}</Link>
        {' '}
        which also apply
        to all providers on ToLips.link . Your advertisement must also meet the
        requirements of these policies.
      </p>
      <div className={styles.bigBlock}>
        <h2 className={styles.subtitle}>{t('TL;DR short version')}</h2>
        <p>
          This is the &apos;too long, didn&apos;t read&apos; version of our
          advertising policy. Please note that the full version below takes
          precedence over this short quick reference version.
        </p>
        <ul className={styles.policyList}>
          <li>
            <p>
              You must be over the age of 18 and the age of majority in your
              jurisdiction to list on ToLips.link.
            </p>
          </li>
          <li>
            <p>You must be using the platform of your own free will.</p>
          </li>
          <li>
            <p>You must be verified before you can create a profile.</p>
          </li>
          <li>
            <p>You must verify your contact details.</p>
          </li>
          <li>
            <p>
              You must be in sole control of your profile, management of it may
              not be outsourced or automated.
            </p>
          </li>
          <li>
            <p>
              The content of your profile, including all text and photos, must
              be your own and you must have the rights to use that content.
            </p>
          </li>
          <li>
            <p>
              Assistants may help you handle enquiries, but not manage your
              profile.
            </p>
          </li>
          <li>
            <p>
              Your contact details must be your own and not those of an agency
              or other third party.
            </p>
          </li>
          <li>
            <p>
              ToLips.link advertisements are for independent workers only, we do
              not allow agency or brothel listings (though you can still work
              with agencies or brothels separately).
            </p>
          </li>
          <li>
            <p>
              Your profile must comply with our
              {' '}
              <Link to={paths.codeOfConduct}>Code of Conduct</Link>
            </p>
          </li>
        </ul>
      </div>
      <h2 className={styles.subtitle}>Listing on ToLips.link</h2>
      <p>
        ToLips.link was created for independent workers. We have a few basic
        rules that apply to all advertisements we host on the platform. To
        maintain a profile on ToLips.link you must:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            Be over the age of 18 and the age of majority in your jurisdiction.
          </p>
        </li>
        <li>
          <p>Using the platform of your own free will.</p>
        </li>
        <li>
          <p>
            Sign up, complete the verification process, and create a profile.
          </p>
        </li>
        <li>
          <p>Maintain control and management of that profile.</p>
        </li>
        <li>
          <p>
            Participate in on-going contact information verification to protect
            your account.
          </p>
        </li>
        <li>
          <p>
            Have control over the contact information listed and not share it
            with other people or entities.
          </p>
        </li>
        <li>
          <p>
            The content of your profile, including all text and photos, must be
            your own and you must have the rights to use that content.
          </p>
        </li>
        <li>
          <p>Not present yourself to be a minor.</p>
        </li>
        <li>
          <p>
            Not advertise your services or target your advertisement to anyone
            under the age of 18 and the age of majority in their jurisdiction.
          </p>
        </li>
        <li>
          <p>Not include or insinuate the sale of illicit substances.</p>
        </li>
        <li>
          <p>
            Fully comply with the
            {' '}
            <Link to={paths.codeOfConduct}>Code of Conduct</Link>
            .
          </p>
        </li>
      </ul>
      <p>
        You may only have one listing on ToLips.link for all services categories
        (Online, BDSM, Massage and/or Escort, etc.), with each profile being
        tailored to that category with unique photos, profile text, and
        verification. The only exception to this rule is for doubles who may
        have a combined listing with unique photos, profile text, and
        verification.
      </p>
      <p>
        Your profile may not be used to direct users to another advertising
        platform, agency website, or competitor of ToLips.link on the profile or
        in communication with clients who reach out to you via ToLips.link.
      </p>
      <p>
        In addition, we have a few rules about specific types of advertisers,
        outlined below.
      </p>
      <h2 className={styles.subtitle}>Disclosure of health information</h2>
      <p>
        Whilst it’s not permissible to advertise any health or sexual health
        screening information on your profile, If you have health concerns or a
        notifiable disease that you need to raise this should be handled during
        the screening process.
      </p>
      <div className={styles.smallBlock}>
        <p className={styles.italic}>
          This decision is not based on the beliefs we hold, and we know that
          these requirements fuel stigma and rarely result in harm reduction,
          but we are unable verify every health claim which puts the platform at
          risk.
        </p>
      </div>
      <h2 className={styles.subtitle}>Agencies</h2>
      <p>
        Agencies are not a supported advertising type on ToLips.link. This means
        that all listings on ToLips.link must be directly from independent
        workers, however this does not prevent you also working with an agency
        separate to your ToLips.link listing. If you work with an agency, to
        advertise on ToLips.link you must:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            Have your own contact details that you control and are not listed on
            an agency website.
          </p>
        </li>
        <li>
          <p>
            Have separate photos, profile text, and contact details to those
            used on any agency listing.
          </p>
        </li>
      </ul>
      <p>
        In addition to these profile rules, you may not send another person in
        your place to a booking without the client&apos;s knowledge and consent.
      </p>

      <h2 className={styles.subtitle}>
        Using assistants and third party management services
      </h2>
      <p>
        We allow the use of assistants for handling your enquiries but neither
        assistants nor management services may maintain your ToLips.link profile
        on your behalf. This includes the use of bots and scripted or automated
        solutions to change your profile or interact with the platform.
      </p>

      <h2 className={styles.subtitle}>Accessibility of your profile content</h2>
      <p>
        ToLips.link has a stated mission of ensuring that the platform is
        accessible to as wide an audience as possible. This means that we care
        about the accessibility of the platform and all profiles on it. To
        ensure your profile is as accessible as possible, and to ensure that we
        can process and publicise it well, we recommend:
      </p>
      <p>
        Limiting the use of emojis, especially in your profile name, tagline,
        and about text. Not using non-standard characters, such as unicode
        characters or emoji to change the appearance of the text on your
        profile. When your profile is accessible, it is also easier for our
        system to process and ensure it appears in search results correctly. An
        accessible profile is good for you and for every user, no matter how
        they access ToLips.link.
      </p>

      <h2 className={styles.subtitle}>Violations of this policy</h2>
      <p>
        If you are found to have violated any of the content of this policy, you
        will be given five (5) days to respond or make any changes, your profile
        during this time will remain online unless it is considered a serious
        breach of
        {' '}
        <Link to={paths.privacyPolicy}>our policies</Link>
        , such as,
        but not limited to, targeted harassment or chargebacks.
      </p>
      <p>
        Repeated violations of this, or any other ToLips.link policy, may result
        in your account being permanently suspended.
      </p>
    </div>
  );
};

export { AdvertisingPolicy };
