import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { getNotifications } from 'api/requestFunctions/getNotifications';
import { userVisitor } from 'constants/registrationUserType';

import { Notification } from './components/Notification';
import { ProfileLayout } from '../components/ProfileLayout';

import errorLogo from 'assets/error.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import styles from './styled.module.css';

const Notifications = () => {
  const { t } = useTranslation();
  const {
    localState: {
      userInfo: { user_type },
    },
    localDispatch,
  } = useLocalContext();
  const { isPublicVersion } = usePublicVersion();

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getNotifications({
      setNotifications,
      localDispatch,
      setLoading,
      setError,
    });
  }, []);

  if (error) {
    return (
      <ProfileLayout>
        <div
          className={`
          ${styles.wrapper}
          ${user_type === userVisitor ? styles.visitor : ''}
          ${error ? styles.error : ''}
          `}
        >
          <img src={isPublicVersion ? publicLogo : errorLogo} alt="errorLogo" />
          <p className={styles.message}>
            {t('pages.profile.notifications.1')}
            <br />
            {t('errors.weAreWorking')}
          </p>
        </div>
      </ProfileLayout>
    );
  }

  if (loading) {
    return (
      <ProfileLayout>
        <div
          className={`
  ${styles.wrapper}
  ${user_type === userVisitor ? styles.visitor : ''}
  ${loading ? styles.error : ''}
  `}
        >
          <Spin size="large" />
        </div>
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <div
        className={`
          ${styles.wrapper}
          ${user_type === userVisitor ? styles.visitor : ''}
          ${notifications && !!notifications.length ? '' : styles.error}
        `}
      >
        {notifications?.length > 0 ? (
          notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              setNotifications={setNotifications}
            />
          ))
        ) : (
          <>
            <img
              src={isPublicVersion ? publicLogo : errorLogo}
              alt="errorLogo"
            />
            <p className={styles.empty}>{t('common.noNotifications')}</p>
          </>
        )}
      </div>
    </ProfileLayout>
  );
};

export { Notifications };
