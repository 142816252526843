import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { useLocalContext } from 'LocalContext';
import { catalogCarousel } from 'utils/catalog';
import { getClearFilters } from 'utils/setInitialFilters';

import { RigthIcon } from 'components/ActiveIcon/RigthIcon';
import { LeftIcon } from 'components/ActiveIcon/LeftIcon';

import styles from './styled.module.css';
import './styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <RigthIcon className="arrow" />
    </div>
  );
};

SampleNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <LeftIcon className="arrow" />
    </div>
  );
};

SamplePrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

const CatalogCarousel = () => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(false);
  const {
    localDispatch,
    localState: { filters, activeCarouselItem },
  } = useLocalContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const { isPublicVersion } = usePublicVersion();

  const city = searchParams.get('city');
  const gender = searchParams.get('gender');
  const orientation = searchParams.get('orientation');
  const verified = searchParams.get('verified');
  const service = searchParams.get('service');
  const district = searchParams.get('district')?.split('__');

  const settings = {
    className: 'center',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 9,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleFilterClick = async (filter) => {
    const clearFilters = getClearFilters(filters);
    if (!scroll) {
      if (activeCarouselItem === filter.id) {
        localDispatch({ type: 'SET_ACTIVE_CAROUSEL_ITEM', payload: null });
        localDispatch({
          type: 'SET_FILTERS',
          payload: clearFilters,
        });
      } else {
        localDispatch({ type: 'SET_ACTIVE_CAROUSEL_ITEM', payload: filter.id });
        localDispatch({
          type: 'SET_FILTERS',
          payload: {
            ...clearFilters,
            ...filter.queryForFilters,
          },
        });
      }
      setSearchParams({
        page: 1,
        ...(city && { city }),
        ...(gender && { gender }),
        ...(orientation && { orientation }),
        ...(verified && { verified }),
        ...(service && { service }),
        ...(district && { district: district.join('__') }),
      });
      localDispatch({ type: 'SET_RELOAD_DATA', payload: true });
    }
  };

  return (
    <Slider
      {...settings}
      className={`${styles.slider} catalogCarousel ${
        isPublicVersion ? 'public ' : ''
      }`}
      beforeChange={() => setScroll(true)}
      afterChange={() => setScroll(false)}
    >
      {catalogCarousel.map((filter) => (
        <div
          key={filter.id}
          onClick={() => handleFilterClick(filter)}
          className={`${styles.filterItem} ${
            filter.id === activeCarouselItem ? styles.activeFilter : ''
          } ${isPublicVersion ? styles.public : ''}`}
        >
          {!isPublicVersion && (
            <div className={styles.filterIcon}>
              <img
                src={filter.icon}
                alt={filter.label}
                width={24}
                height={24}
              />
            </div>
          )}
          <span>{t(`catalogCarousel.${filter.label}`)}</span>
        </div>
      ))}
    </Slider>
  );
};

export { CatalogCarousel };
