import { createToken } from 'utils/base64url';
import { savedConstants } from 'constants/localStorage';
import { axiosInstance } from './axiosInstance';

export const setHeader = () => {
  const savedItem = localStorage.getItem(savedConstants.CLIENT_ID);
  const token = createToken(savedItem, 'device_id');
  axiosInstance.defaults.headers.common.Authorization = `Token ${token}`;
};

export const setConfirmHeader = (key) => {
  const token = createToken(key, 'access_token');
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setAuthHeader = () => {
  const authToken = localStorage.getItem(savedConstants.AUTH_TOKEN);
  const token = createToken(authToken, 'access_token');

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};
