import { performAuthorizedRequest } from '../apiConfig';

const clientActionsPath = '/client_actions';

export const createProfile = (query) => performAuthorizedRequest(`${clientActionsPath}/create_profile`, query);

export const editProfile = (query) => performAuthorizedRequest(`${clientActionsPath}/edit_profile`, query);

export const setShowProfileModals = (query) => performAuthorizedRequest(`${clientActionsPath}/showed_profile_modals`, query);

export const sendProfileToModeration = () => performAuthorizedRequest(`${clientActionsPath}/send_profile_to_moderation`);

export const getUserData = () => performAuthorizedRequest(`${clientActionsPath}/fetch_card_info`);

export const setHideShowProfile = () => performAuthorizedRequest(`${clientActionsPath}/toggle_hide_profile`);
