import { useLocalContext } from 'LocalContext';
import { useNavigate } from 'react-router-dom';

const usePrivateNavigate = () => {
  const navigate = useNavigate();
  const { localDispatch } = useLocalContext();

  const privateNavigate = (props = {}) => {
    const { paths = null, state = null, replace = false } = props;
    localDispatch({ type: 'SET_IS_PRIVATE_PAGE', payload: true });
    if (paths) {
      navigate(paths, {
        state,
        replace,
      });
    }
  };
  return privateNavigate;
};

export { usePrivateNavigate };
