import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { arrayOf, node, oneOfType } from 'prop-types';

import { useLocalContext } from 'LocalContext';
import { getUserData } from 'api/requests';
import { getVisitorData } from 'api/requests/visitor';
import { paths, savedConstants } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';

import { Spinner } from 'components/Spinner';
import { Navigation } from '../Navigation';

import styles from './styled.module.css';

const ProfileLayout = ({ children }) => {
  const { localState, localDispatch } = useLocalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const userType = localStorage.getItem(savedConstants.USER_TYPE);

  useEffect(() => {
    if (localState.isFetchedUser && !localState.userInfo) {
      navigate(paths.notCreatedProfile, { replace: true });
    }
  }, [localState.userInfo, localState.isFetchedUser]);

  async function fetchUserInfo() {
    setLoading(true);
    try {
      const res = userType === userProvider
        ? await getUserData()
        : await getVisitorData();
      let data;
      if (res.status === 200) {
        if (userType === userProvider) {
          data = res.data.card;
        } else {
          data = {
            ...res.data.card,
            favorites: [...res.data.favorites],
            user_type: userVisitor,
          };
        }
        localDispatch({ type: 'SET_IS_CREATED_PROFILE', payload: true });
        localDispatch({ type: 'SET_USER_INFO', payload: data });
        setLoading(false);
      }
      if (res.status === 203) {
        localDispatch({ type: 'SET_USER_ID', payload: res.data.user_id });
        navigate(paths.notCreatedProfile, { replace: true });
      }
    } catch (e) {
      localDispatch({ type: 'LOGOUT' });
    }
    localDispatch({ type: 'SET_IS_FETCHED_USER', payload: true });
    setLoading(false);
  }

  useEffect(() => {
    if (
      !localState.isFetchedUser
      || (localState.userInfo && !localState.userInfo.user_type)
    ) {
      fetchUserInfo();
    }
  }, [pathname.startsWith('/profile')]);

  return (loading || !localState.userInfo?.user_type) ? (
    <Spinner />
  ) : (
    <div className={styles.wrapper}>
      <Navigation />
      {children}
    </div>
  );
};

ProfileLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export { ProfileLayout };
