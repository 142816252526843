import React from 'react';
import PropTypes from 'prop-types';

import { InnerCard } from 'components/InnerCard';

import styles from './styled.module.css';

const CatalogList = ({ items, weeklyOffer = false, className }) => (
  <div className={`${styles.wrapper} ${className || ''}`}>
    {items.map((item) => (
      <InnerCard
        data={item}
        key={item.id}
        albumCount={item.album_images_count}
        weeklyOffer={weeklyOffer}
      />
    ))}
  </div>
);

CatalogList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number.isRequired,
      album_images_count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      avatar: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      district: PropTypes.string,
      duration: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      id_name: PropTypes.string.isRequired,
      nickname: PropTypes.string.isRequired,
      promoted_at: PropTypes.string.isRequired,
      verified: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  weeklyOffer: PropTypes.bool,
};

export { CatalogList };
