import { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ok from 'assets/ok.svg';
import { catalogProfileFormatServices } from 'utils/catalogProfile';

import styles from './styled.module.css';
import './styles.css';

const Services = ({ services }) => {
  const { t } = useTranslation();
  const formatServices = catalogProfileFormatServices(services);
  const showAllButton = formatServices.some((item) => item.length > 3);
  const [showAll, setShowAll] = useState(false);

  return (
    <div className={styles.services}>
      <p>{t('common.services')}</p>
      <div className={styles.servicesBlock}>
        {formatServices.map((servicesItem) => (
          <div
            className={`${styles.itemsBlock} ${
              formatServices.length < 2 ? styles.oneBlock : ''
            }`}
            key={servicesItem[0].id || servicesItem[0].service_name}
          >
            {(showAll ? servicesItem : servicesItem.slice(0, 3)).map((item) => (
              <div
                className={`${styles.item} ${
                  item.comment && styles.itemWithComment
                }`}
                key={item.id || item.service_name}
              >
                <div
                  className={
                    !item.included_free ? styles.additionalService : ''
                  }
                >
                  <p>{item.service_name.replace(/_/g, ' ')}</p>
                  <p>{item.comment ? item.comment : ''}</p>
                </div>
                {item.included_free ? (
                  <img src={ok} alt="ok" />
                ) : (
                  <p>
                    +
                    {item.extra_amount}
                    {' '}
                    PLN
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {showAllButton && (
        <Button
          className={`${styles.showMoreButton} ${
            showAll ? styles.showInfo : ''
          }`}
          onClick={() => setShowAll(!showAll)}
          type="link"
        >
          {showAll
            ? t('common.hide')
            : `${t('common.show')} ${t('common.more')}`}
        </Button>
      )}
    </div>
  );
};

Services.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      service_name: PropTypes.string.isRequired,
      id: PropTypes.number,
      included_free: PropTypes.bool.isRequired,
      extra_amount: PropTypes.number,
      comment: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { Services };
