import { useNavigate } from 'react-router-dom';

import { globalEmail } from 'constants/globalEmail';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const AntiExploitationPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>Anti-Exploitation Policy</h1>
      <p>
        ToLips.link is vehemently against all forms of exploitation and harm.
      </p>
      <p>
        Modern slavery, human and labor trafficking are abhorrent crimes that
        are often felt by the most vulnerable in our communities such as
        migrants, people of color, and members of the LGBTQIA+ community.
      </p>
      <p>
        ToLips.link is committed to acting ethically by promoting a culture that
        supports harm reduction, human rights, transparency and accountability
        to all of our business dealings and believe everyone has the right to
        autonomy, safe working conditions, and to be treated with kindness,
        dignity and respect.
      </p>

      <h2 className={styles.subtitle}>
        Working towards fighting exploitation on and offline
      </h2>
      <p>
        ToLips.link actively works towards fighting exploitation on and offline
        by:
      </p>
      <p>
        <b>Informed Consent</b>
        <br />
        This platform does not target or intend to target anyone under the age
        of 18, nor does it allow users under the 18 to advertise on the
        platform.
      </p>
      <p>
        <b>Supporting organizations based in harm reduction and fact</b>
        <br />
        We also support groups that work towards legislative changes that are
        proven to reduce exploitation such as the decriminalisation of sex work
        and the SAFE sex work act.
      </p>
      <p>
        <b>Community Driven Policy</b>
        <br />
        ToLips.link actively works with experts, advocates and members of our
        community based in anti-human trafficking, anti-exploitation, and human
        rights to produce the best possible policy outcomes for our users. Our
        policies are informed by credible research from well respected
        authoritative organizations such as the World Health Organisation,
        Amnesty International and Freedom Network USA.
      </p>
      <p>
        <b>Active moderation</b>
        <br />
        We hire directly from our community to ensure that moderation decisions
        are approached with a nuanced understanding of the unique challenges,
        circumstances and sensitivities our users face and can provide
        appropriate support.
      </p>
      <p>
        <b>Decriminalisation of sex work prevents trafficking and abuse</b>
        <br />
        We support the full decriminalisation of sex work as an approach to
        building resilience against trafficking for sexual exploitation. While
        it is often presented as an option, the Nordic Model (also known as the
        Swedish Model, the Entrapment Model , the Equality Model or the
        decriminalization of ONLY sex workers but not their clients) is not
        decriminalization. These models cause harm and perpetuate discrimination
        and stigma against both sex workers and victims of exploitation.
      </p>
      <p>
        You can read more about the need to advocate for full decriminalisation
        of sex work and the need to establish clear legal distinctions between
        consensual sex work and crimes like human trafficking over at Freedom
        United USA, Amnesty International.
      </p>
      <p>
        <b>Report Policy Violation</b>
        <br />
        If you suspect a user, us, team, contractors, or any third party doing
        business on our behalf has violated this policy, please contact
        {' '}
        <b>{globalEmail}</b>
      </p>
    </div>
  );
};

export { AntiExploitationPolicy };
