import { useMemo } from 'react';

import { country_districts } from 'constants/country_districts';

const useActualDistrict = (city, district) => {
  const actualDistrict = useMemo(() => {
    if (district && district !== 'all') {
      return country_districts[city.toLowerCase()]?.find(
        (el) => el.value === district,
      ).label;
    }
    return null;
  }, [district, city, country_districts]);

  return actualDistrict;
};

export { useActualDistrict };
