import { Button, Spin, Table } from 'antd';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { getCatalogProfileData } from 'api/requests/catalog';
import {
  catalogProfileRatesColums,
  catalogProfileRatesRows,
} from 'utils/catalogProfile';

import {
  Availability,
  Contacts,
  NameInfo,
  ProfileDescriptions,
  Services,
  TabsMobile,
  UserInfo,
} from 'components/AboutUser';
import { DividerWithLogo } from 'components/DividerWithLogo';
import { PhotoCarousel } from 'components/PhotoCarousel';
import { ContactsMobile } from './components/ContactsMobile';
import { Avatar } from 'components/Avatar';
import { AddToBlackListModal } from './components/AddToBlackListModal';

import left from 'assets/left.svg';

import styles from './styled.module.css';

const CatalogProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { state } = useLocation();
  const {
    localState: { userInfo },
  } = useLocalContext();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [openAddToBlackListModal, setOpenAddToBlackListModal] = useState(false);
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  const album = data?.albums?.[0]?.images;

  useEffect(() => {}, [(document.title = `ToLips - ${id}`)]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const query = {
        id_name: id,
      };
      try {
        const res = await getCatalogProfileData(query);
        if (res.data.card.status === 'not_found') {
          navigate(paths.errorPage, { replace: true });
        }
        setData(res.data.card);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
    if (!state) {
      fetchData();
    } else {
      setLoading(false);
      setData(state);
    }
  }, []);

  const hasAvailableDay = useMemo(
    () => !!data
      && !!data.availability_calendar
      && data.availability_calendar.some((day) => day.available === true),
    [data && data.availability_calendar],
  );

  if (error) {
    return (
      <div className={styles.wrapper}>
        <span>{t('common.error')}</span>
      </div>
    );
  }

  return loading ? (
    <div className={styles.loader}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.route} onClick={() => navigate(-1)}>
        <img src={left} alt="left" style={{ height: '11px' }} />
        <span>{t('common.back')}</span>
      </div>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.leftTop}>
            <div className={styles.name}>
              <NameInfo profile={data.profile} />
            </div>
            <div className={styles.avatarAvailability}>
              <Avatar
                ref={carouselRef}
                images={data?.images}
                isVerified={data?.profile?.verified}
                id={data.profile.id}
                albumCount={album?.length || 0}
              />
              {hasAvailableDay && (
                <Availability availability={data.availability_calendar} />
              )}
            </div>
            <div className={styles.info}>
              <NameInfo profile={data.profile} />
              <UserInfo profile={data.profile} />
            </div>
          </div>
          <DividerWithLogo />
          {(data.profile || data.services || data.rates) && (
            <TabsMobile
              profile={data.profile}
              services={data.services}
              rates={data.rates}
            />
          )}
          {!!data.rates && (
            <div className={styles.rates}>
              <p>{t('common.rates')}</p>
              <Table
                className="contacts"
                dataSource={catalogProfileRatesRows(data.rates)}
                columns={catalogProfileRatesColums}
                pagination={false}
              />
              <DividerWithLogo />
            </div>
          )}
          {data.profile_descriptions && (
            <div className={styles.description}>
              <ProfileDescriptions
                descriptions={data.profile_descriptions}
                profile={data.profile}
              />
              <DividerWithLogo />
            </div>
          )}

          {!!data.services && (
            <div className={styles.services}>
              <Services services={data.services} />
            </div>
          )}
        </div>
        <div className={styles.contacts}>
          <Contacts
            profile={data.profile}
            rates={data.rates}
            messengers={data.messengers}
            social_networks={data.social_networks}
          />
        </div>
      </div>
      <div ref={carouselRef}>
        {!!album?.length && <PhotoCarousel images={album} />}
      </div>
      {!(userInfo && userInfo?.user_type === userProvider) && (
        <div className={styles.blackListBlock}>
          <Button
            type="link"
            className={styles.blackListButton}
            onClick={() => {
              if (userInfo?.user_type === userVisitor) {
                setOpenAddToBlackListModal(true);
              } else {
                navigate(paths.registration, {
                  state: { userType: userVisitor },
                });
              }
            }}
          >
            {t('pages.catalogProfile.4')}
          </Button>
        </div>
      )}
      <ContactsMobile
        profile={data.profile}
        rates={data.rates}
        messengers={data.messengers}
        social_networks={data.social_networks}
      />
      {data?.profile && (
        <AddToBlackListModal
          open={openAddToBlackListModal}
          setOpen={setOpenAddToBlackListModal}
          profile={data?.profile}
        />
      )}
    </div>
  );
};

export { CatalogProfile };
