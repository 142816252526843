import { useNavigate } from 'react-router-dom';

import { Box } from 'components/Box';
import logo from 'assets/logo.svg';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={`${styles.policies} ${styles.wrapper}`}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>
        {t('About ToLips')}
        {' '}
        <img src={logo} alt="logo" style={{ width: '38px', height: '52px' }} />
      </h1>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: '20px',
          gap: '20px',
        }}
      >
        <Box customClass={`${styles.about__box} ${styles.about__box__mobile}`}>
          <p>
            <b>
              {t('ToLips is a platform which puts escorts, BDSM, massage providers, and adult entertainers first. We include all genders and demographics, provide fair pricing, and focus on continual development. Sex Workers across the globe needed a new, reliable, and better alternative, so we stepped in.')}
            </b>
          </p>
          <p>
            {t('We believe in being part of change in the sex industry in digital spaces. The internet has been a great boon for sex workers throughout the world, giving most the ability to work independently. This has dramatically decreased the risk of exploitation from third parties. Our research and experience in sex work has lead us to three main goals.')}
          </p>
        </Box>
        <Box customClass={`${styles.about__box} ${styles.about__box__mobile}`}>
          <p>
            <b>{t('Bring the overall cost of advertising down for sex workers')}</b>
          </p>

          <p>
            {t('Part of our aim with ToLips, is to build a platform which allows sex workers of all financial standings to access clients. To execute this thus far we are offering a free plan which is manually verified with access to features like available now. We have also structured our memberships to be non exploitative and significantly less expensive than any other large verified platform.')}
          </p>
        </Box>
        <Box customClass={`${styles.about__box} ${styles.about__box__mobile}`}>
          <p>
            <b>
              {t('Provide a platform which helps cater to all genders and demographics')}
            </b>
          </p>
          <p>
            {t('Our team is diverse, supportive of one another and committed to the platform. Where we are lacking experience, we endeavor to always consult with workers from relevant communities.')}
          </p>
        </Box>
      </div>
    </div>
  );
};

export { About };
