import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { usePrivateNavigate } from 'hooks/usePrivateNavigate';

import { useLocalContext } from 'LocalContext';
import { priceForWeeklyOfferAndHotDeal } from 'constants/prices';
import { paths } from 'constants';
import { verificationStatus } from 'constants/verificationStatus';

import { ModalWrapper } from 'components/ModalWrapper';
import { TimerFlipClock } from 'components/TimerFlipClock';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const HotDealModal = ({ open = false, setOpen }) => {
  const privateNavigate = usePrivateNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: {
      userInfo: {
        verification_status,
        balance,
        rates,
        promo: {
          hot_deal: { unblock_at: hot_deal_unblock_at },
        },
      },
    },
    localDispatch,
  } = useLocalContext();

  const [timerState, setTimerState] = useState({
    hotDeal: new Date().getTime() >= +hot_deal_unblock_at * 1000,
  });

  const isHaveMoney = +balance >= priceForWeeklyOfferAndHotDeal;

  const generateHotDealText = () => {
    if (!rates?.length && verification_status === verificationStatus.verified) {
      return {
        firstText:
          verification_status !== verificationStatus.verification
            ? t(
              'pages.profile.components.liftingBlock.components.hotDealModal.1',
            )
            : t('pages.profile.components.liftingBlock.21'),
        secondText:
          verification_status !== verificationStatus.verification
            ? t(
              'pages.profile.components.liftingBlock.components.hotDealModal.2',
            )
            : t('pages.profile.components.liftingBlock.22'),
        buttonText: t(
          'pages.profile.components.liftingBlock.components.hotDealModal.3',
        ),
        buttonLink: () => navigate(paths.profileCreation, { state: 5 }),
      };
    }

    return {
      firstText:
        verification_status !== verificationStatus.verification
          ? t('pages.profile.components.liftingBlock.components.hotDealModal.4')
          : t('pages.profile.components.liftingBlock.21'),
      secondText:
        verification_status !== verificationStatus.verification
          ? t('pages.profile.components.liftingBlock.components.hotDealModal.1')
          : t('pages.profile.components.liftingBlock.22'),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.hotDealModal.6',
      ),
      buttonLink: () => navigate(paths.hotDealPage),
    };
  };

  const hotDealText = generateHotDealText();

  let modalText = {
    title: <p className={styles.title}>Hot deal</p>,
    firstText: <p>{hotDealText.firstText}</p>,
    secondText: <p>{hotDealText.secondText}</p>,
    buttonText: hotDealText.buttonText,
    buttonFunc: () => {
      localDispatch({ type: 'SET_IS_PRIVATE_PAGE', payload: true });
      hotDealText.buttonLink();
    },
  };

  if (verification_status !== verificationStatus.verified) {
    modalText = {
      ...modalText,
      buttonText: 'Available after verification',
      buttonFunc: () => {
        privateNavigate({ paths: paths.verification });
      },
    };
  }

  if (!isHaveMoney && verification_status === verificationStatus.verified) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.7')}
          <br />
          {t('pages.profile.components.liftingBlock.components.hotDealModal.8')}
        </p>
      ),
      firstText: (
        <p>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.9')}
        </p>
      ),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.hotDealModal.10',
      ),
      buttonFunc: () => navigate(paths.profileWallet),
    };
  }

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <div
        className={`${styles.wrapper} ${
          verification_status !== verificationStatus.verified || !rates?.length
            ? styles.disabled
            : ''
        }`}
      >
        {modalText.title}
        {!timerState.hotDeal && (
        <p className={styles.active}>
          {t('pages.profile.components.liftingBlock.23')}
          <br />
          {t('pages.profile.components.liftingBlock.24')}
        </p>
        )}
        {verification_status === verificationStatus.verified
        && !!rates?.length && (
          <TimerFlipClock
            hotDeal
            setTimerState={setTimerState}
            time={+hot_deal_unblock_at * 1000}
          />
        )}
        <div className={styles.description}>
          {modalText.firstText}
          {modalText.secondText}
          {modalText?.lastType}
        </div>
        {verification_status !== verificationStatus.verification && (
        <div className={styles.buttonBlock}>
          <Button
            primary
            disabled={!timerState.hotDeal || !isHaveMoney}
            onClick={() => {
              modalText.buttonFunc();
              setOpen(false);
            }}
          >
            {modalText.buttonText}
          </Button>
        </div>
        )}
      </div>
    </ModalWrapper>
  );
};

HotDealModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export { HotDealModal };
