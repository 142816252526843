import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Input, DatePicker, Checkbox, Form, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';

import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useScrollToError } from 'hooks/useScrollToError';

import { useLocalContext } from 'LocalContext';
import { reactAppPublicV2 } from 'config';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import { getErrorInForm } from 'utils/error';
import { latinLettersPattern, patternForNickname } from 'constants/patterns';
import { paths, savedConstants } from 'constants';
import { userProvider } from 'constants/registrationUserType';
import {
  onRegistrationProvider,
  onRegistrationVisitor,
} from 'api/requests/auth';

import { Button } from 'components/Button';
import { RegistrationBlockingModal } from '../RegistrationBlockingModal';

import info from 'assets/info.svg';

import styles from '../../styled.module.css';

const RegistrationForm = ({ form, isRecaptchaEnabled }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const { gaClickEvent } = useGoogleAnalytics();
  const { t } = useTranslation();
  const { localDispatch } = useLocalContext();

  const userActualType = locationState.userType === userProvider ? 'provider' : 'visitor';

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [opeBlockingModal, setOpenBlockingModal] = useState(false);
  const [isShowV2Captcha, setIsShowV2Captcha] = useState(false);

  useScrollToError(error);

  const recaptchaRef = useRef();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      let token;
      if (isRecaptchaEnabled && executeRecaptcha) {
        token = isShowV2Captcha
          ? await recaptchaRef.current.executeAsync()
          : await executeRecaptcha('registration');
      }

      const query = {
        email: values[`${userActualType}_email`].toLowerCase(),
        nickname: values[`${userActualType}_nickName`],
        birthday: values[`${userActualType}_date`],
        password: values[`${userActualType}_password`],
        password_confirmation: values[`${userActualType}_confirmPassword`],
        ...(!isShowV2Captcha && { 'g-recaptcha-response-data': token }),
        ...(isShowV2Captcha
          && isRecaptchaEnabled && { recaptcha_v2_valid: !!token }),
      };
      if (locationState.userType === userProvider) {
        await onRegistrationProvider(query);
      } else {
        await onRegistrationVisitor(query);
      }

      gaClickEvent({
        action: 'Registration',
        label: `${query.email}, ${locationState.userType}`,
      });

      navigate(paths.emailConfirmation, {
        state: {
          userEmail: query.email,
          userType: locationState.userType,
        },
      });
      setError({});
      setIsShowV2Captcha(false);
    } catch (e) {
      const errorResponse = e.response;
      if (isRecaptchaEnabled && errorResponse?.status === 428) {
        if (!isShowV2Captcha) {
          setIsShowV2Captcha(true);
          setOpenBlockingModal(true);
        } else {
          const blockItem = JSON.parse(
            localStorage.getItem(savedConstants.BLOCKING),
          );
          localStorage.setItem(
            savedConstants.BLOCKING,
            JSON.stringify({
              t: new Date().getTime(),
              c: blockItem ? +blockItem.c + 1 : 1,
            }),
          );
          localDispatch({ type: 'SET_IS_BLOCKING', payload: true });
          navigate(paths.main, { replace: true });
        }
      } else {
        const formatError = getErrorInForm(e, values);
        setError(formatError);
        setOpenBlockingModal(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmValidator = (_, value) => new Promise((resolve, reject) => {
    const { getFieldValue } = form;
    if (!value || value !== getFieldValue(`${userActualType}_password`)) {
      reject(new Error(t('errors.passwords')));
    }
    resolve();
  });

  const confirmPolicy = (_, value) => new Promise((resolve, reject) => {
    if (!value) {
      reject(new Error(t('errors.agreeTerms')));
    } else {
      resolve();
    }
  });

  const ageInputValudator = (_, date) => new Promise((resolve, reject) => {
    if (date.length === 10) {
      const today = new Date();
      const parts = date.split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const day = parseInt(parts[2], 10);
      const daysInMonth = new Date(year, month, 0).getDate();
      if (
        new Date(date).getTime() > today.getTime()
          || month > 12
          || month < 1
          || day < 1
          || day > daysInMonth
      ) {
        reject(new Error(t('errors.invalidDate')));
        return;
      }

      today.setFullYear(today.getFullYear() - 18);
      if (new Date(date).getTime() > today.getTime()) {
        reject(new Error(t('errors.18years')));
        return;
      }
    }
    resolve();
  });

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className={styles.form}
        scrollToFirstError
        onFinishFailed={(values) => scrollToErrorField(values)}
      >
        <Form.Item
          name={`${userActualType}_nickName`}
          id="nickname"
          validateStatus={error[`${userActualType}_nickName`] && 'error'}
          help={
            error[`${userActualType}_nickName`]
              ? `${error[`${userActualType}_nickName`]
                .slice(0, 1)
                .toUpperCase()}${error[
                `${userActualType}_nickName`
              ].slice(1)}`
              : null
          }
          rules={[
            {
              required: true,
              message: t('errors.enterNickname'),
            },
            {
              min: 2,
              message: t('errors.nicknameAtLeast', { number: 2 }),
            },
            {
              max: 32,
              message: t('errors.nicknameLess', { number: 32 }),
            },
            {
              pattern: patternForNickname,
              message: t('errors.onlyLatinLong'),
            },
          ]}
        >
          <Input
            placeholder={t('common.nickname')}
            spellCheck={false}
            onChange={(e) => {
              if (error[`${userActualType}_nickName`]) {
                const errorCopy = { ...error };
                delete errorCopy[`${userActualType}_nickName`];
                setError(errorCopy);
              }
              form.setFieldValue(
                `${userActualType}_nickName`,
                e.target.value.replaceAll(' ', '_'),
              );
            }}
            suffix={(
              <Tooltip
                placement="topRight"
                onClick={(e) => e.stopPropagation()}
                title={(
                  <div className={styles.tooltipTitle}>
                    <p>
                      {t('pages.registration.1')}
                      <br />
                      {t('pages.registration.12')}
                    </p>
                    <p>{t('pages.registration.2')}</p>
                  </div>
                )}
                overlayClassName="registration"
              >
                <img src={info} alt="info" />
              </Tooltip>
            )}
          />
        </Form.Item>
        <Form.Item
          dependencies={['calendar', `${userActualType}_date`]}
          noStyle
        >
          {() => {
            const actualValue = form.getFieldValue(`${userActualType}_date`);
            const dependentValueCalendar = form.getFieldValue('calendar');

            const isErrorDate = actualValue?.length === 10
              && dependentValueCalendar
              && new Date(dependentValueCalendar) > new Date();

            const isErrorAge = actualValue?.length === 10
              && dependentValueCalendar
              && new Date(dependentValueCalendar)
                > new Date().setFullYear(new Date().getFullYear() - 18);

            const isUndefined = actualValue === null;
            return (
              <>
                <Form.Item
                  name={`${userActualType}_date`}
                  noStyle={isErrorAge || isErrorDate || isUndefined}
                  rules={[
                    {
                      required: true,
                      message: t('errors.birthday'),
                    },
                    {
                      pattern: /^\d{4}-\d{2}-\d{2}$/,
                      message: t('errors.invalidFormat'),
                    },
                    {
                      validator: ageInputValudator,
                    },
                  ]}
                >
                  <Input
                    type="text"
                    maxLength={10}
                    status={
                      (isErrorAge || isErrorDate || isUndefined) && 'error'
                    }
                    placeholder={`${t('common.dateOfBirth')} (YYYY-MM-DD)`}
                    onChange={(e) => {
                      if (dependentValueCalendar) {
                        form.resetFields(['calendar']);
                      }
                      let value = e.target.value.replace(/[^\d]/g, '');

                      if (value.length > 4) {
                        value = `${value.slice(0, 4)}-${value.slice(4)}`;
                      }
                      if (value.length > 7) {
                        value = `${value.slice(0, 7)}-${value.slice(7)}`;
                      }

                      form.setFieldValue(`${userActualType}_date`, value);
                    }}
                    suffix={(
                      <div className={styles.calendarSuffix}>
                        <Form.Item name="calendar" noStyle>
                          <DatePicker
                            onChange={(_, value) => {
                              if (!value) {
                                form.setFieldsValue({
                                  [`${userActualType}_date`]: null,
                                });
                              } else {
                                form.setFieldsValue({
                                  [`${userActualType}_date`]: value,
                                });
                              }
                            }}
                          />
                        </Form.Item>
                        <Tooltip
                          placement="topRight"
                          onClick={(e) => e.stopPropagation()}
                          title={(
                            <div className={styles.tooltipTitle}>
                              <p>{t('pages.registration.3')}</p>
                            </div>
                          )}
                          className={styles.tooltip}
                          overlayClassName="registration"
                        >
                          <img src={info} alt="info" />
                        </Tooltip>
                      </div>
                    )}
                  />
                </Form.Item>
                {(isErrorAge || isErrorDate) && (
                  <p style={{ color: '#ff4d4f' }}>
                    {isErrorDate
                      ? t('errors.invalidDate')
                      : t('errors.18years')}
                  </p>
                )}
                {isUndefined && (
                  <p style={{ color: '#ff4d4f' }}>{t('errors.birthday')}</p>
                )}
              </>
            );
          }}
        </Form.Item>
        <Form.Item
          name={`${userActualType}_email`}
          validateStatus={error[`${userActualType}_email`] && 'error'}
          help={
            error[`${userActualType}_email`]
              ? `${error[`${userActualType}_email`]
                .slice(0, 1)
                .toUpperCase()}${error[`${userActualType}_email`].slice(
                1,
              )}`
              : null
          }
          rules={[
            {
              required: true,
              message: t('errors.inputEmail'),
            },
            {
              type: 'email',
              message: t('errors.emailFormat'),
            },
            {
              pattern: latinLettersPattern,
              message: t('errors.onlyLatin'),
            },
          ]}
        >
          <Input
            placeholder={t('common.email')}
            onChange={
              () => {
                if (error[`${userActualType}_email`]) {
                  const errorCopy = { ...error };
                  delete errorCopy[`${userActualType}_email`];
                  setError(errorCopy);
                }
              }
            }
            suffix={(
              <Tooltip
                placement="topRight"
                onClick={(e) => e.stopPropagation()}
                title={(
                  <div className={styles.tooltipTitle}>
                    <p>{t('pages.registration.4')}</p>
                  </div>
                )}
                overlayClassName="registration"
              >
                <img src={info} alt="info" />
              </Tooltip>
            )}
          />
        </Form.Item>
        <Form.Item
          name={`${userActualType}_password`}
          rules={[
            {
              required: true,
              message: t('errors.inputPassword'),
            },
            {
              min: 8,
              message: t('errors.passwordAtLeast', { number: 8 }),
            },
            {
              max: 32,
              message: t('errors.passwordLess', { number: 32 }),
            },
            {
              pattern: /\d/,
              message: t('errors.passwordDigit'),
            },
            {
              pattern: /[A-Z]/,
              message: t('errors.passwordCapital'),
            },
          ]}
        >
          <Input.Password
            placeholder={t('common.password')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name={`${userActualType}_confirmPassword`}
          dependencies={[`${userActualType}_password`]}
          rules={[
            {
              validator: confirmValidator,
            },
          ]}
        >
          <Input.Password
            placeholder={t('common.confirmPassword')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name={`${userActualType}_agePolicy`}
          valuePropName="checked"
          rules={[{ validator: confirmPolicy }]}
          style={{ textAlign: 'start' }}
        >
          <Checkbox style={{ fontSize: '12px' }}>
            {t('pages.registration.5')}
          </Checkbox>
        </Form.Item>
        <Form.Item
          name={`${userActualType}_policy`}
          valuePropName="checked"
          rules={[{ validator: confirmPolicy }]}
          style={{ margin: error ? '0 0 10px 0' : '', textAlign: 'start' }}
        >
          <Checkbox style={{ fontSize: '12px' }}>
            {t('pages.registration.6')}
            {' '}
            <Link className={styles.link} to={paths.terms}>
              {t('components.footer.terms')}
            </Link>
            {', '}
            <Link className={styles.link} to={paths.codeOfConduct}>
              {t('common.codeOfConduct')}
            </Link>
            {locationState.userType === userProvider && ', '}
            {locationState.userType === userProvider && (
              <Link className={styles.link} to={paths.advertisingPolicy}>
                {t('common.advertisingPolicy')}
              </Link>
            )}
            {', '}
            {t('common.and')}
            {' '}
            <Link className={styles.link} to={paths.privacyPolicy}>
              {t('common.privacyPolicy')}
            </Link>
            .
          </Checkbox>
        </Form.Item>
        {/* {error && <span className={styles.error}>{error}</span>} */}
        {isShowV2Captcha && (
          <ReCAPTCHA
            sitekey={reactAppPublicV2}
            size="invisible"
            ref={recaptchaRef}
          />
        )}
        <Form.Item shouldUpdate>
          {() => (
            <Button
              primary
              loading={loading}
              submit
              disabled={
                !form.isFieldTouched(`${userActualType}_nickName`)
                || !form.isFieldTouched(`${userActualType}_date`)
                || !form.isFieldTouched(`${userActualType}_email`)
                || !form.isFieldTouched(`${userActualType}_password`)
                || !form.isFieldTouched(`${userActualType}_confirmPassword`)
                || !form.isFieldTouched(`${userActualType}_agePolicy`)
                || !form.isFieldTouched(`${userActualType}_policy`)
                || !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
                || !form.getFieldValue(`${userActualType}_date`)
                || (form.getFieldValue('calendar')
                  && form.getFieldValue('calendar').$d.getTime()
                    > new Date().setFullYear(new Date().getFullYear() - 18))
                || !!Object.keys(error).length
              }
            >
              {locationState.userType === userProvider
                ? t('pages.registration.7')
                : t('pages.registration.13')}
            </Button>
          )}
        </Form.Item>
      </Form>
      <RegistrationBlockingModal
        open={opeBlockingModal}
        setOpen={setOpenBlockingModal}
        onClick={() => onFinish(form.getFieldsValue())}
        loading={loading}
      />
    </>
  );
};

RegistrationForm.propTypes = {
  form: PropTypes.shape({
    isFieldTouched: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldValue: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
  }),
  isRecaptchaEnabled: PropTypes.bool,
};

export { RegistrationForm };
