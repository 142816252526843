import { Link, useNavigate } from 'react-router-dom';

import { paths } from 'constants';
import { globalEmail } from 'constants/globalEmail';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>{t('components.footer.terms')}</h1>
      <h2 className={styles.subtitle}>1. Who are we and what do we do?</h2>
      <p>
        Hello! We&lsquo;re jumping with joy to see you have decided to use our
        services and products (which we will refer to as the “Services” or the
        “Platform”). The Terms of Service (which we will refer to as the
        “Terms”) set out the rules that will govern our business relationship
        with you. Please read these Terms carefully as they form a legally
        binding contract between you (“you”, “the user”, “user”) and ToLips.link
        (referred to as “ToLips.link”, “the site”, “us, “our”, “ours”).
      </p>

      <p>
        The platform and suite to be accessed via its website ToLips.link. You
        can view ToLips.link without an account, but to access certain features
        you must create an account with a display name, username, and password,
        be verified, and create a profile. Any information you provide us is
        governed by our
        {' '}
        <Link to={paths.antiExploitationPolicy}>{t('components.footer.policy')}</Link>
      </p>
      <p>
        As a user you are able to engage in the following activities which
        include but are not limited to: creating an account and profile,
        uploading content, blogs or testimonials, purchase Golden ToLips.link
        (“GTL”) and activate different levels of memberships by using Golden
        ToLips.link. By using ToLips.link, you are agreeing to the
        {' '}
        <Link to={paths.terms}>{t('components.footer.terms')}</Link>
        ,
        {' '}
        <Link to={paths.advertisingPolicy}>Advertising Policy</Link>
        ,
        {' '}
        <Link to={paths.codeOfConduct}>Code of Conduct</Link>
        , and
        {' '}
        <Link to={paths.privacyPolicy}>Privacy Policy</Link>
        .
      </p>
      <h2 className={styles.subtitle}>2. How do I reach you?</h2>
      <p>
        If you have any issues, questions or concerns with our
        {' '}
        <Link to={paths.terms}>Terms of Service</Link>
        ,
        {' '}
        <Link to={paths.advertisingPolicy}>Advertising Policy</Link>
        ,
        {' '}
        <Link to={paths.codeOfConduct}>Code of Conduct</Link>
        , and
        {' '}
        <Link to={paths.privacyPolicy}>Privacy Policy</Link>
        {' '}
        please contact us
        through
        {' '}
        <b>{globalEmail}</b>
        . PLEASE BE ADVISED THAT ANY EXPLANATION WE PROVIDE CAN NOT BE
        CONSIDERED LEGAL ADVICE.
      </p>
      <h2 className={styles.subtitle}>3. Who can use the service?</h2>
      <p>
        No one under eighteen (18) years old is allowed to create an account or
        use the Platform. In places where the age of majority is not eighteen
        (18) years old, you must be over the age of twenty-one (21) years in
        order to create an account or use the Platform.
      </p>
      <p>By using the Platform, you state that:</p>
      <ul className={styles.policyList}>
        <li>
          <p>You are eighteen (18) years of age or older.</p>
        </li>
        <li>
          <p>
            You are over the age of majority in your country of legal residence.
          </p>
        </li>
        <li>
          <p>You are using the platform of your own free will.</p>
        </li>
        <li>
          <p>
            You will comply with these Terms and all applicable local, state,
            federal and international laws, rules and, regulations.
          </p>
        </li>
      </ul>
      <h2 className={styles.subtitle}>4. User Conduct</h2>
      <p>
        You acknowledge and agree that you, and not the site, are entirely
        responsible for ensuring all content and communications posted or
        exchanged through the platform adhere to the site policies.
      </p>
      <p>
        We do not control submitted User content and as such, do not guarantee
        its accuracy, integrity or quality. You understand that by using the
        platform you may be exposed to content that is offensive, indecent or
        objectionable.
      </p>
      <p>
        To report a suspected breach of these Terms please email
        {' '}
        <b>{globalEmail}</b>
        , or use the built in reporting feature, with supporting evidence.
      </p>
      <p>
        You are solely responsible for your interactions, including any
        communications through the website. We reserve the right, but have no
        obligation, to review disputes between you and other Users.
      </p>
      <p>
        If the platform is used in a manner that violates the Terms in anyway,
        We may at our sole discretion, but are not required to, suspend or
        terminate your account, disable your access to the site and/or take any
        steps that is deemed appropriate to address the situation.
      </p>
      <h2 className={styles.subtitle}>5. Safety</h2>
      <p>
        We work hard to ensure our Platform is a safe place for all users. By
        using our Services, you agree that:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            You will not use the Platform for any purpose that is illegal or
            prohibited in these Terms or the
            {' '}
            <Link to={paths.codeOfConduct}>Code of Conduct</Link>
            ;
          </p>
        </li>
        <li>
          <p>
            You will not advertise these Services to any person under 18 and the
            age of majority in your country;
          </p>
        </li>
        <li>
          <p>
            You will not create multiple accounts, except as permitted in our
            {' '}
            <Link to={paths.advertisingPolicy}>Advertising Policy</Link>
            ;
          </p>
        </li>
        <li>
          <p>
            You will not use any robot, spider, crawler, scraper, or other
            automated means to interface with or access the Platform or extract
            other Users’ information;
          </p>
        </li>
        <li>
          <p>
            You will not upload viruses or other malicious code or otherwise
            compromise the security of the Platform;
          </p>
        </li>
        <li>
          <p>
            You will not post or transmit any communication designed to or
            intended to obtain password, account, or private information from
            any Tryst User;
          </p>
        </li>
        <li>
          <p>
            You will not use or develop any third-party application that
            interacts with other Users’ content or the Platform without our
            written consent;
          </p>
        </li>
        <li>
          <p>
            You will not use the Platform in a way that could interfere with,
            disrupt, negatively affect, or inhibit other users from fully
            enjoying the services, or that could damage, disable, overburden, or
            impair the functioning of the Platform;
          </p>
        </li>
        <li>
          <p>
            You will not submit or transmit any content that is forbidden in
            these terms, libelous, defamatory, graphic violence, threats,
            obscene, abusive, offensive, hate speech, incitements of violence,
            profane, or that infringes on any copyright or other rights of any
            other person.
          </p>
        </li>
        <li>
          <p>
            You will not post or transmit any communication promoting underage,
            non-consensual, or other illegal sexual themes, whether simulated or
            real;
          </p>
        </li>
        <li>
          <p>
            You will not post or transmit any communication promoting the sexual
            exploitation of minor(s);
          </p>
        </li>
        <li>
          <p>
            You will not post or transmit any communication promoting the sexual
            exploitation of any individual(s);
          </p>
        </li>
        <li>
          <p>You will not sell, assign, or otherwise transfer your account;</p>
        </li>
        <li>
          <p>
            You will not attempt to circumvent any content-filtering techniques
            we employ, or attempt to access areas or features of the Platform
            that you are not authorized to access;
          </p>
        </li>
        <li>
          <p>
            You will not probe, scan, or test the vulnerability of our Platform
            or any system or network;
          </p>
        </li>
        <li>
          <p>
            You will not encourage or promote any activity that violates these
            Terms.
          </p>
        </li>
      </ul>
      <h2 className={styles.subtitle}>6. Service Availability</h2>
      <p>
        Your access and use of the platform may be interrupted from time to time
        as a result of an equipment malfunction, maintenance, or repair of the
        platform, or due to any other reason within, or outside of, our control.
        The site reserves the right to suspend or discontinue the availability
        of the platform, any service We offer, and to remove any content at any
        time at its sole discretion and without prior notice. We may impose
        limits on certain features and services or restrict your access to parts
        of the site without notice or liability. The platform should not be used
        or relied upon for storage of your content and you are directed to
        retain your own copies of your data.
      </p>
      <h2 className={styles.subtitle}>7. Termination of Services</h2>
      <p>
        We may terminate or suspend access to your Tryst.link account
        immediately, without prior notice or liability, for any reason
        whatsoever, including without limitation if you breach the terms. Upon
        termination of your account, your right to use the platform will cease
        immediately.
      </p>
      <p>
        If you wish to terminate your account, you may simply discontinue using
        the platform,
        {' '}
        <Link to={paths.deletePolicy}>delete your account</Link>
        , or
        request your account to be removed from the platform. It is your
        responsibility to take a copy of all your content from your account
        prior to termination as we are not able to restore it.
      </p>
      <p>
        Upon termination of your account, in accordance with our
        {' '}
        <Link to={paths.privacyPolicy}>Privacy Policy</Link>
        , the platform will
        remove your profile and photos but we will keep data required for
        business operations such as but not limited to; verification
        information, payment information, support conversations, and audit
        history.
      </p>
      <h2 className={styles.subtitle}>8. Billing and Payments</h2>
      <p>
        You can purchase Golden ToLips.link through supported purchase methods:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>A single Golden ToLips.link is equivalent to 0,1 Euro.</p>
        </li>
        <li>
          <p>All sales are final.</p>
        </li>
        <li>
          <p>Transaction fee(s) may apply.</p>
        </li>
        <li>
          <p>
            ToLips.link is not responsible for any transaction fees incurred.
          </p>
        </li>
      </ul>
      <h2 className={styles.subtitle}>9. Membership services</h2>
      <p>
        The platform has paid memberships at various price points which unlocks
        access to different feature sets which can be redeemed with ToLips.link.
      </p>
      <p>
        Please read the membership entitlements carefully for your selected
        level so you are aware of the services available to you on your
        membership. The platform will not reimburse for incorrect membership
        choices.
      </p>
      <p>
        The platform is not obligated to reimburse credit due to platform
        outages, however we may choose to do so at our own discretion.
      </p>

      <h2 className={styles.subtitle}>10. Account security</h2>
      <p>
        The platform provides multiple ways for you as the user to keep your
        account safe and secure.
      </p>
      <p>
        The platform is not obligated to reimburse credit in any situation,
        however we may choose to do so at our own discretion and are not
        obligated to reimburse credit due to account loss or misuse when
        multi-factor authentication is not enabled.
      </p>
      <h2 className={styles.subtitle}>11. Refund policy</h2>
      <p>All sales are final.</p>
      <p>
        The platform is not obligated to reimburse credit in any situation,
        however we may choose to do so at our own discretion.
      </p>
      <p>
        In the case you are offered a refund, the refund can only be applied to
        the original purchase method, if supported.
      </p>
      <h2 className={styles.subtitle}>12. Chargebacks</h2>
      <p>
        Chargebacks are issued on behalf of a customer from a Bank or Card
        Issuer; to reverse a charge that is believed to be fraudulent.
      </p>
      <p>
        The site will be given the opportunity to contest the chargeback and is
        required to provide information on the account and services provided to
        the Bank and/or Card Issuer. If a chargeback process has been initiated,
        the site may disable the associated profile until the chargeback process
        has been completed.
      </p>
      <p>
        Users who have initiated multiple chargebacks may have their accounts
        terminated.
      </p>
      <p>
        Fees incurred during the chargeback process may be forwarded onto the
        User and may be required to be paid before the account will be
        reinstated.
      </p>
      <h2 className={styles.subtitle}>13. Copyright Complaint</h2>
      <p>
        We respect copyright. It is our policy to promptly respond to any claim
        that content posted on the platform infringes copyright or other
        intellectual property rights (“infringement”) of any person.
      </p>
      <p>
        We will use reasonable efforts to investigate notices of alleged
        infringement and will take appropriate actions under applicable
        intellectual property laws and these terms where it believes
        infringement has occurred, including removing or disabling access to the
        content claimed to be infringing and/or terminating accounts and access
        to the platform.
      </p>
      <p>
        To notify us of a possible infringement, you must submit your notice in
        writing to
        {' '}
        <b>{globalEmail}</b>
        {' '}
        and include a detailed description of the alleged infringement.
      </p>
      <h2 className={styles.subtitle}>14. Legal notices</h2>
      <p>
        Please direct all legal notices or inquiries to
        {' '}
        <b>{globalEmail}</b>
        {' '}
        and please include a detailed description of your request along with any
        supporting evidence or documentation.
      </p>
      <p>
        If you need to send us a DMCA Takedown Notice, please make sure you
        include everything that we need to action it.
        {' '}
        <Link to={paths.dmcaPage}>
          Refer to our article about sending a good, actionable, DMCA Notice.
        </Link>
      </p>
      <h2 className={styles.subtitle}>15. Links</h2>
      <p>
        Third-party services may link to your content (such as search engines or
        blogs), because we have no control over third-party services, we do not
        endorse and are not responsible or liable for any content, advertising,
        products or other materials on available such services.
      </p>
      <p>
        You further acknowledge and agree that the site shall not be held
        responsible or liable, directly or indirectly, for any damage, loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such goods or services available through any such
        third-party service.
      </p>
      <h2 className={styles.subtitle}>16. Acceptance of Terms</h2>
      <p>
        TO AGREE TO THESE TERMS, YOU MUST BE OF LEGAL AGE IN YOUR JURISDICTION
        AND OVER THE AGE OF 18. BY USING THE SERVICES, YOU ARE AGREEING, ON
        BEHALF OF YOURSELF AND/OR THOSE YOU REPRESENT THAT THE SERVICES ARE
        BEING USED OF YOUR OWN FREE WILL, FREE OF ANY OUTSIDE PRESSURE AND THAT
        YOU WILL COMPLY WITH AND BE LEGALLY BOUND BY THESE TERMS AS WELL AS OUR
        PRIVACY POLICY AND ALL APPLICABLE LAWS. IF YOU, FOR YOURSELF OR ON
        BEHALF OF THOSE YOU REPRESENT, DO NOT AGREE TO ANY PROVISION OF THESE
        TERMS, YOU MUST, FOR YOURSELF AND ON BEHALF ANY SUCH PERSON(S),
        DISCONTINUE THE REGISTRATION PROCESS, DISCONTINUE YOUR USE OF THE
        SERVICES, AND, IF YOU ARE ALREADY REGISTERED, DELETE YOUR ACCOUNT
      </p>
      <h2 className={styles.subtitle}>17. Modifications of Terms</h2>
      <p>
        We reserve the right at our sole discretion to modify or replace the
        Terms without notice. We endeavor to notify Users of material changes to
        this agreement at least 30 days prior to your accounts registered email
        address or a notice on the platform.
      </p>
      <p>
        For non material modifications, your continued use of the website
        constitutes agreement to our revision of these Terms. As a User you will
        be able to request previous versions of our Terms of Service from
        {' '}
        <b>{globalEmail}</b>
        .
      </p>
      <p>
        You are responsible for reviewing and becoming familiar with any such
        modifications. By continuing to use the platform that will constitute
        your acceptance of the terms as modified.
      </p>
      <p>
        <b>{t('common.welcome')}</b>
      </p>
    </div>
  );
};

export { Terms };
