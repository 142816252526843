import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { markNotificationAsRead } from 'api/requests';
import { useLocalContext } from 'LocalContext';

import styles from './styled.module.css';

const Notification = ({ notification, setNotifications }) => {
  const {
    params: { post },
    read_at,
  } = notification;
  const {
    localState: { notificationsCount },
    localDispatch,
  } = useLocalContext();

  const [open, setOpen] = useState(false);
  const [read, setRead] = useState(read_at);

  const readNotification = async () => {
    try {
      const query = {
        notification_id: notification.id,
      };
      const res = await markNotificationAsRead(query);
      if (res.status === 200 && res.data.message === 'Notification updated') {
        const newDate = new Date();
        setRead(newDate.toISOString());
        setNotifications((prevNotifications) => prevNotifications.map((not) => {
          if (not.id === notification.id) {
            return { ...not, read_at: new Date().toISOString() };
          }
          return not;
        }));
        localDispatch({
          type: 'SET_NOTIFICATIONS_COUNT',
          payload: notificationsCount - 1,
        });
      }
    } catch (e) {
      // setError(true)
    }
  };

  const clickNotificationHandler = () => {
    setOpen(!open);
    if (!notification.read_at) {
      readNotification();
    }
  };

  return (
    <div
      className={`${styles.wrapper} ${read ? '' : styles.read}`}
      onClick={clickNotificationHandler}
    >
      <div className={styles.title}>
        <h4>{post.title}</h4>
        <h4>{post.updated_at.slice(0, 10)}</h4>
      </div>
      <div className={`${styles.description} ${open ? styles.open : ''}`}>
        {typeof post.message === 'string' ? (
          <p>{post.message}</p>
        ) : (
          <div className={styles.message}>
            {post.message.message_title && (
              <h2>{post.message.message_title}</h2>
            )}
            {post.message.message_text && <p>{post.message.message_text}</p>}
            {post.message.message_alert && (
              <h3>{post.message.message_alert}</h3>
            )}
            {post.message.message_description && (
              <p>{post.message.message_description}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    recipient_type: PropTypes.string.isRequired,
    recipient_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    params: PropTypes.shape({
      post: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    read_at: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  setNotifications: PropTypes.func.isRequired,
};

export { Notification };
