import {
  performNonAuthorizedRequest,
  performConfirmedRequest,
} from '../apiConfig';

const sessionsPath = '/sessions';

export const onLogin = (query) => performNonAuthorizedRequest(`${sessionsPath}/login`, query);

export const sendResetPasswordEmail = (query) => performNonAuthorizedRequest(`${sessionsPath}/reset_password`, query);

export const resetPassword = (key, query) => performConfirmedRequest(`${sessionsPath}/verify_reset_password`, key, query);
