import { useEffect, useState } from 'react';

import { getFlipperFeatures } from 'api/requests/flipper';

const useFlipper = (name = '') => {
  const [isFlipperFeatureEnabled, setIsFlipperFeatureEnabled] = useState(false);
  const [allFlipperFeatures, setAllFlipperFeatures] = useState({});
  const [flipperLoading, setFlipperLoading] = useState(false);

  const getFlipperRecaptcha = async () => {
    setFlipperLoading(true);
    const response = await getFlipperFeatures(name);
    setIsFlipperFeatureEnabled(response.search_feature);
    setAllFlipperFeatures(response.all_features);
    setFlipperLoading(false);
  };

  useEffect(() => {
    getFlipperRecaptcha();
  }, []);

  return {
    isFlipperFeatureEnabled,
    allFlipperFeatures,
    flipperLoading,
  };
};

export { useFlipper };
