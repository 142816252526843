import { Link, useNavigate } from 'react-router-dom';

import { paths } from 'constants';
import { globalEmail } from 'constants/globalEmail';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>Privacy Policy</h1>
      <h2 className={styles.subtitle}>1. Who are we and what do we do?</h2>
      <p>
        Hello! We&apos;re jumping with joy to see you have decided to use our
        services and products (which we will refer to as the “Services” or the
        “Platform”).
      </p>
      <p>
        Privacy is important to us, and it&apos;s our policy to respect your
        privacy for any information we may collect across our website,
        ToLips.link, and any services we may own and operate.
      </p>
      <p>
        This document describes the information we collect, how we use it, how we
        store it and whom it is shared with.
      </p>
      <p>
        If you have any issues, questions or concerns with our Privacy Policy,
        please contact us through
        {' '}
        <b>{globalEmail}</b>
        . Please be advised that any explanation we provide cannot be considered
        legal advice.
      </p>
      <p>
        By using ToLips.link, you are agreeing to the
        {' '}
        <Link to={paths.terms}>Terms of Service</Link>
        ,
        {' '}
        <Link to={paths.advertisingPolicy}>Advertising Policy</Link>
        ,
        {' '}
        <Link to={paths.codeOfConduct}>Code of Conduct</Link>
        , and
        {' '}
        <Link to={paths.privacyPolicy}>Privacy Policy</Link>
        .
      </p>

      <h2 className={styles.subtitle}>2. What information do we collect?</h2>
      <p>
        We will process your personal information lawfully, fairly and in a
        transparent matter. We collect and process information about you only
        where we have legal basis for doing so.
      </p>
      <p>There are three basic ways we collect information:</p>
      <ul className={styles.policyList}>
        <li>
          <p>Information you choose to give us.</p>
        </li>
        <li>
          <p>Information we get automatically when you use our services.</p>
        </li>
        <li>
          <p>Information we get from third parties.</p>
        </li>
      </ul>

      <p>
        Where you consent to our use of information about you for a specific
        purpose, you have the right to change your mind at any time (but this will
        not affect any processing that has already taken place).
      </p>
      <p>
        We don&apos;t keep personal information for longer than is necessary.
        While we retain this information, we will protect it within commercially
        acceptable means to prevent loss and theft, as well as unauthorized
        access, disclosure, copying, use or modification. That said, we advise
        that no method of electronic transmission or storage is 100% secure and
        cannot guarantee absolute data security.
      </p>
      <p>
        When necessary, we will retain your personal information to comply with
        legal obligations (such as payment processor compliance, financial record
        keeping, etc) or in order to protect your vital interests or the vital
        interests of another natural person.
      </p>

      <h3>Information you choose to give us</h3>
      <p>
        When you interact with our services, we collect the information that you
        choose to share with us.
      </p>
      <p>
        Most of our services require you to create an account, so we may need to
        collect information for compliance purposes and to enable features, such
        as but not limited to;
      </p>
      <p>
        Unique username, email address, verification data, payment information,
        your date of birth, your password, phone number, social media handles,
        photos and profile content.
      </p>
      <p>
        <b>Credit Card Information: </b>
        We do not store any Credit Card numbers on our servers, we simply transmit
        them over to our trusted payment provider via encrypted means, following
        the industry standards, including 3DSecure2 where available from the card
        issuer.
      </p>

      <h3>Information we automatically get when you use our services</h3>
      <p>
        When you use our services, we collect information about which of those
        services you&apos;ve used and how you&apos;ve used them. For example, we
        know you have searched for certain terms, viewed content, and posted a
        comment. Below is a more detailed explanation of the types of information
        we collect when you use our services:
      </p>
      <p>
        <b>*Usage information. </b>
        We collect information about your activity through our services. For
        example:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            How you interact with the services, such as which pages, what
            categories you browse and what pages you consult.
          </p>
        </li>
        <li>
          <p>
            How you communicate with the other users, such as the time, date,
            sender, recipient of a message, the number of messages you exchange
            with other users.
          </p>
        </li>
      </ul>

      <p>
        <b>*Device Information.</b>
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            We collect device-specific information, such as the hardware model,
            operating system version, unique device identifiers, browser type,
            language, wireless network, and mobile network information.
          </p>
        </li>
        <li>
          <p>
            We also collect device information that will help us diagnose and
            troubleshoot problems in the event you experience any crash or other
            problem while using our services.
          </p>
        </li>
      </ul>

      <p>
        <b>*Location Information. </b>
        When you use our services we may collect information about your location.
      </p>

      <p>
        <b>*Information Collected by Cookies and Similar Technologies. </b>
        Like most online services and mobile applications, we may use cookies and
        other technologies, such as web beacons, web storage, and unique device
        identifiers, to collect information about your activity, browser, and
        device.
      </p>

      <p>
        <b>*Log Information. </b>
        We collect log file information when you use our services. That
        information includes, but is not limited to, details about how you’ve used
        our services, device information, such as your web browser type and
        language, access times, pages viewed, your IP address, pages you visited
        before navigating to our services, cookies that may uniquely identify your
        device or browser.
      </p>

      <h3>Information we automatically get when you use our services</h3>
      <p>
        We may collect information that other users provide about you when they
        use our services. For example, if another user has referred you to our
        services we may combine the information we collect from that user with
        other information we have collected about you. We may also obtain
        information from other third-party sources and combine that with the
        information we collect through our services.
      </p>

      <h2 className={styles.subtitle}>3. Collection and use of information</h2>
      <p>
        We may collect, hold, use and disclose information for the following
        purposes and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>

      <ul className={styles.policyList}>
        <li>
          <p>
            to enable you to customize or personalize your experience of our
            website;
          </p>
        </li>
        <li>
          <p>
            to enable you to access and use our website, associated applications
            and associated social media platforms;
          </p>
        </li>
        <li>
          <p>to contact and communicate with you;</p>
        </li>
        <li>
          <p>
            for internal record keeping and administrative purposes; for billing,
            analytics, market research and business development, including to
            operate and improve our website, associated applications and
            associated social media platforms;
          </p>
        </li>
        <li>
          <p>
            for advertising and marketing, including to send you promotional
            information about our products and services and information about
            third parties that we consider may be of interest to you;
          </p>
        </li>
        <li>
          <p>
            verify your identify and prevent fraud or other unauthorized or
            illegal activity;
          </p>
        </li>
        <li>
          <p>
            to comply with our legal obligations and resolve any disputes that we
            may have.
          </p>
        </li>
      </ul>

      <p>
        We may also store some information locally on your device. For example, we
        may store information as local cache so that you can view content faster
        while consuming less bandwidth. If you wish to delete this information,
        please refer to your web browser documentation.
      </p>

      <h2 className={styles.subtitle}>
        4. Disclosure of personal information to third parties
      </h2>
      <p>We may disclose personal information to:</p>
      <ul className={styles.policyList}>
        <li>
          <p>
            third party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, web-hosting and server providers, debt
            collectors, maintenance or problem-solving providers, marketing or
            advertising providers, professional advisors and payment systems
            operators;
          </p>
        </li>
        <li>
          <p>our employees, contractors and/or related entities;</p>
        </li>
        <li>
          <p>
            courts, tribunals, regulatory authorities and law enforcement
            officers, as required by law, in connection with any actual or
            prospective legal proceedings, or in order to establish, exercise or
            defend our legal rights
          </p>
        </li>
        <li>
          <p>third parties to collect and process data</p>
        </li>
      </ul>

      <h2 className={styles.subtitle}>
        5. International transfers of personal information
      </h2>
      <p>
        The personal information we collect is stored and processed where we or
        our partners, affiliates and third-party providers maintain facilities. By
        providing us with your personal information, you consent to the disclosure
        to these overseas third parties.
      </p>
      <p>
        We will ensure that any transfer of personal information from countries in
        the European Economic Area (EEA) to countries outside the EEA will be
        protected by appropriate safeguards, for example by using standard data
        protection clauses approved by the European Commission, or the use of
        binding corporate rules or other legally accepted means.
      </p>

      <h2 className={styles.subtitle}>
        6. Your rights and controlling your personal information
      </h2>
      <p>
        <b>Choice and consent: </b>
        By providing personal information to us of your own free will, you consent
        to us collecting, holding, using and disclosing your personal information
        in accordance with this privacy policy. If you are under the age of 18,
        you are not permitted to use this website. We do not direct or advertise
        our Services to anyone under the age of 18 and the age of majority. Hence
        we do not knowingly collect personal information from anyone under 18 and
        the age of majority.
      </p>
      <p>
        <b>Information from third parties: </b>
        If we receive personal information about you from a third party, we will
        protect it as set out in this privacy policy. If you are a third party
        providing personal information about somebody else, you represent and
        warrant that you have such person&apos;s consent to provide the personal
        information to us.
      </p>

      <p>
        <b>Restrict: </b>
        You may choose to restrict the collection or use of your personal
        information. If you have previously agreed to us using your personal
        information for direct marketing purposes, you may change your mind at any
        time by contacting us using the details below. If you ask us to restrict
        or limit how we process your personal information, we will let you know
        how the restriction affects your use of our website or products and
        services.
      </p>
      <p>
        <b>Access and data portability: </b>
        You may request details of the personal information that we hold about
        you. You may request a copy of the personal information we hold about you.
        Where possible, we will provide this information in CSV format or other
        easily readable machine format. You may request that we erase the personal
        information we hold about you at any time.
      </p>
      <p>
        <b>Correction: </b>
        If you believe that any information we hold about you is inaccurate, out
        of date, incomplete, irrelevant or misleading, please contact us using the
        details below. We will take reasonable steps to correct any information
        found to be inaccurate, incomplete, misleading or out of date.
      </p>

      <p>
        <b>Notification of data breaches: </b>
        We will comply with laws applicable to us in respect of any data breach.
      </p>

      <p>
        <b>Complaints: </b>
        If you believe that we have breached a relevant data protection law and
        wish to make a complaint, please contact us using the details below and
        provide us with full details of the alleged breach. We will promptly
        investigate your complaint and respond to you, in writing, setting out the
        outcome of our investigation and the steps we will take to deal with your
        complaint. You also have the right to contact a regulatory body or data
        protection authority in relation to your complaint.
      </p>

      <p>
        <b>Unsubscribe: </b>
        To unsubscribe from our e-mail database or opt-out of communications
        (including marketing communications), please contact us using the details
        below or opt-out using the opt-out facilities provided in the
        communication.
      </p>

      <h2 className={`${styles.subtitle} red`}>7. Business transfers</h2>
      <p>
        If we or our assets are acquired, or in the unlikely event that we go out
        of business or enter bankruptcy, we would not include data among the
        assets transferred to any parties who acquire us without consent. You
        acknowledge that such transfers may occur, and that any parties who
        acquire us may continue to use your personal information with your
        expressed consent.
      </p>

      <h2 className={styles.subtitle}>8. Limits of our policy</h2>
      <p>
        Our website may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and policies of those
        sites, and cannot accept responsibility or liability for their respective
        privacy practices.
      </p>

      <h2 className={styles.subtitle}>9. Changes to this policy</h2>
      <p>
        At our discretion, we may change our privacy policy to reflect current
        acceptable practices. We will take reasonable steps to let users know
        about changes via our website. Your continued use of this site after any
        changes to this policy will be regarded as acceptance of our practices
        around privacy and personal information.
      </p>
      <p>
        If we make a significant change to this privacy policy, for example
        changing a lawful basis on which we process your personal information, we
        will ask you to re-consent to the amended privacy policy.
      </p>

      <h2 className={styles.subtitle}>Privacy Contacts</h2>
      <p>
        Our Data Controller Officer and Data Protection Officer can be reached
        through
        {' '}
        <b>{globalEmail}</b>
        .
      </p>
    </div>
  );
};

export { PrivacyPolicy };
