import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { isProductionVisible } from 'config';

import en from 'translate/en.json';
import pl from 'translate/pl.json';
import fr from 'translate/fr.json';
import de from 'translate/de.json';
import es from 'translate/es.json';
import ru from 'translate/ru.json';
import uk from 'translate/uk.json';
import pu from 'translate/pu.json';

const resources = {
  ...(!isProductionVisible && { pu: { translation: pu } }),
  en: { translation: en },
  pl: { translation: pl },
  fr: { translation: fr },
  de: { translation: de },
  es: { translation: es },
  ru: { translation: ru },
  uk: { translation: uk },
};

i18n.use(initReactI18next).init({
  resources,
  language: 'en',
  lng: 'en',
  languages: ['en'],
  fallbackLng: 'en',
  react: {
    wait: true,
  },
  objectNotation: true,
});

export default i18n;
