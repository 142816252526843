import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalWrapper } from 'components/ModalWrapper';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePrivateNavigate } from 'hooks/usePrivateNavigate';

import { setWeeklyOfferPromote } from 'api/requests/lifting';
import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { verificationStatus } from 'constants/verificationStatus';
import { priceForWeeklyOfferAndHotDeal } from 'constants/prices';
import { isProductionVisible } from 'config';

import { TimerFlipClock } from 'components/TimerFlipClock';
import { EditIcon } from 'components/ActiveIcon/EditIcon';
import { QuestionItem } from './components/QuestionItem';

import styles from './styled.module.css';

const WeeklyOfferModal = memo(({ open = false, setOpen }) => {
  const [isSend, setIsSend] = useState(false);
  const privateNavigate = usePrivateNavigate();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();

  const {
    promo: {
      weekly_offer: { can_view_modal, unblock_at, sold_out },
    },
    balance,
    can_edit_profile,
    profile: { gender, orientation, city },
    verification_status,
  } = userInfo;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [timerState, setTimerState] = useState({
    weeklyOffer: new Date().getTime() >= +unblock_at * 1000,
  });

  const isHaveMoney = +balance >= priceForWeeklyOfferAndHotDeal;

  const orientationForLink = (gender === 'male' || gender === 'female') && orientation === 'bi'
    ? 'hetero'
    : orientation;

  const setWeeklyOffer = async () => {
    setLoading(true);

    try {
      const res = await setWeeklyOfferPromote();
      if (res.status === 201) {
        localDispatch({
          type: 'SET_USER_INFO',
          payload: {
            ...userInfo,
            balance: res.data.charge_details.client_balance,
            promo: {
              ...userInfo.promo,
              weekly_offer: {
                ...userInfo.promo.weekly_offer,
                unblock_at: res.data.charge_details.lock,
                sold_out: res.data.charge_details.sold_out,
                can_view_modal: false,
              },
            },
          },
        });
        setTimerState((prev) => ({ ...prev, up: false }));
        setIsSend(true);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  let modalText = {
    title: (
      <div>
        <p className={styles.title}>🌟 Weekly offer</p>
        <p>{timerState.weeklyOffer && t('pages.weeklyOffer.10')}</p>
      </div>
    ),
    firstText: (
      <p>
        {verification_status !== verificationStatus.verification
          ? t('pages.profile.components.liftingBlock.10')
          : t('pages.profile.components.liftingBlock.21')}
      </p>
    ),
    secondText: (
      <p>
        {verification_status !== verificationStatus.verification
          ? t(
            'pages.profile.components.liftingBlock.components.hotDealModal.11',
          )
          : t('pages.profile.components.liftingBlock.22')}
      </p>
    ),
    buttonText: t('pages.profile.components.liftingBlock.13'),
    buttonFunc: async () => {
      await setWeeklyOffer();
    },
  };

  if (verification_status !== verificationStatus.verified) {
    modalText = {
      ...modalText,
      buttonText: t('pages.profile.components.liftingBlock.14'),
      buttonFunc: () => {
        privateNavigate({ paths: paths.verification });
        setOpen(false);
      },
    };
  }

  if (!isHaveMoney && verification_status === verificationStatus.verified) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.7')}
          <br />
          {t('pages.profile.components.liftingBlock.components.hotDealModal.8')}
        </p>
      ),
      firstText: (
        <p>
          {t('pages.profile.components.liftingBlock.components.hotDealModal.9')}
        </p>
      ),
      buttonText: t(
        'pages.profile.components.liftingBlock.components.hotDealModal.10',
      ),
      buttonFunc: () => {
        navigate(paths.profileWallet);
        setOpen(false);
      },
    };
  }

  if (error) {
    modalText = {
      title: (
        <p className={styles.title}>
          {t('common.ohNo')}
          <br />
          {t('pages.weeklyOffer.15')}
        </p>
      ),
      firstText: <p>{t('pages.weeklyOffer.16')}</p>,
    };
  }

  if (isSend) {
    modalText = {
      title: (
        <div>
          <p className={styles.title}>
            {t(
              'pages.profile.components.liftingBlock.components.hotDealModal.21',
            )}
          </p>
        </div>
      ),
      buttonText: 'Show my Weekly offer',
      buttonFunc: () => navigate(
        `/catalog/?page=1&city=${
          city || ''
        }&gender=${gender}&orientation=${orientationForLink}`,
      ),
    };
  }

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      widthModal="820px"
    >
      <div
        className={`${styles.wrapper} ${
          verification_status !== verificationStatus.verified
            ? styles.disabled
            : ''
        }`}
      >
        {modalText.title}

        {!isSend && can_view_modal && (
          <div className={styles.description}>
            {modalText.firstText}
            {modalText.secondText}
          </div>
        )}
        {!isSend
          && verification_status === verificationStatus.verified
          && isHaveMoney && (
            <div className={styles.weeklyOfferInfo}>
              {can_view_modal && (
                <div className={styles.weeklyOfferCount}>
                  <div className={styles.weeklyOfferCity}>
                    <p>
                      {t(
                        'pages.profile.components.liftingBlock.components.hotDealModal.12',
                      )}
                    </p>
                    <p>{city}</p>
                  </div>
                  <div className={styles.weeklyOfferCounter}>
                    <p>
                      {sold_out}
                      /8
                    </p>
                  </div>
                </div>
              )}
              {!isSend
              && !timerState.weeklyOffer
              && verification_status === verificationStatus.verified ? (
                <div className={styles.timerBlock}>
                  <p className={styles.weeklyOfferButtonTitle}>
                    {can_view_modal && !timerState.weeklyOffer
                      ? t(
                        'pages.profile.components.liftingBlock.components.hotDealModal.23',
                      )
                      : t('pages.profile.components.liftingBlock.23')}
                    <br />
                    {t('pages.profile.components.liftingBlock.24')}
                  </p>
                  <TimerFlipClock
                    weeklyOffer
                    setTimerState={setTimerState}
                    time={+unblock_at * 1000}
                  />
                  {can_view_modal && (
                    <p className={styles.weeklyOfferButtonTitle}>
                      {can_edit_profile
                        ? t(
                          'pages.profile.components.liftingBlock.components.hotDealModal.22',
                        )
                        : t('pages.profile.components.liftingBlock.25')}
                    </p>
                  )}
                </div>
                ) : (
                  <p className={styles.weeklyOfferButtonTitle}>
                    {can_edit_profile
                      ? t(
                        'pages.profile.components.liftingBlock.components.hotDealModal.15',
                      )
                      : t('pages.profile.components.liftingBlock.25')}
                  </p>
                )}
              {can_view_modal && (
                <div className={styles.buttonBlock}>
                  <Button
                    className={`${styles.button} ${styles.secondary}`}
                    disabled={!can_edit_profile}
                    onClick={() => navigate(paths.profileCreation)}
                  >
                    <EditIcon className={styles.edit} />
                    {' '}
                    {t(
                      'pages.profile.components.liftingBlock.components.hotDealModal.14',
                    )}
                  </Button>
                </div>
              )}
              <p className={styles.questionsTitle}>
                {t(
                  'pages.profile.components.liftingBlock.components.hotDealModal.16',
                )}
              </p>
              <div className={styles.questions}>
                <QuestionItem
                  title={t(
                    'pages.profile.components.liftingBlock.components.hotDealModal.17',
                  )}
                  text={t(
                    'pages.profile.components.liftingBlock.components.hotDealModal.18',
                  )}
                />
                <QuestionItem
                  title={t(
                    'pages.profile.components.liftingBlock.components.hotDealModal.19',
                  )}
                  text={t(
                    'pages.profile.components.liftingBlock.components.hotDealModal.20',
                  )}
                />
              </div>
            </div>
        )}
        {verification_status !== verificationStatus.verification
          && !error
          && timerState.weeklyOffer && (
            <div className={`${styles.buttonBlock} ${styles.fixedButton}`}>
              <Button
                type="primary"
                className={`${styles.button} ${styles.fixedButton}`}
                loading={loading}
                disabled={
                  (!isSend
                    && !timerState.weeklyOffer
                    && verification_status !== verificationStatus.unverified
                    && verification_status
                      !== verificationStatus.verification_failure)
                  || (isProductionVisible && !isHaveMoney)
                }
                onClick={() => {
                  modalText.buttonFunc();
                }}
              >
                {modalText.buttonText}
              </Button>
            </div>
        )}
      </div>
    </ModalWrapper>
  );
});

WeeklyOfferModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export { WeeklyOfferModal };
