import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { status } from 'constants/status';

import { usePublicVersion } from 'hooks/usePublicVersion';

import logoIcon from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { Button } from 'components/Button';
import { ModalWrapper } from 'components/ModalWrapper';
import { Timer } from 'components/Timer';

import styles from './styled.module.css';

const NotificationModal = ({
  messageSuccess,
  messageError,
  description,
  buttonName,
  buttonDescription,
  onButtonClick,
  onClose,
  logo,
}) => {
  const {
    localState: {
      isShowNotification: { isShow, status: notificationStatus },
    },
    localDispatch,
  } = useLocalContext();

  const { t } = useTranslation();
  const { isPublicVersion } = usePublicVersion();

  const onCloseModal = async () => {
    if (onClose) {
      await onClose();
    }
    localDispatch({
      type: 'SET_IS_SHOW_NOTIFICATION',
      payload: { isShow: false, status: null },
    });
  };

  const onClick = () => {
    onCloseModal();
    if (onButtonClick) onButtonClick();
  };

  return (
    <ModalWrapper open={isShow} setOpen={() => {}} onClose={onCloseModal}>
      <div className={styles.wrapper}>
        {logo && (
          <img
            src={isPublicVersion ? publicLogo : logoIcon}
            alt="logo"
            className={styles.logo}
          />
        )}
        <p className={styles.message}>
          {notificationStatus === status.success
            ? messageSuccess
            : messageError}
        </p>
        {description && description}
        {buttonName ? (
          <div className={styles.buttonBlock}>
            {buttonDescription && <p>{buttonDescription}</p>}
            <Button primary onClick={onClick}>
              {buttonName}
            </Button>
          </div>
        ) : (
          <div className={styles.timerBlock}>
            <p>{t('components.notificationModal.infoMessage')}</p>
            <Timer minutes={0} seconds={7} onTimerEnd={onCloseModal} />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

NotificationModal.propTypes = {
  logo: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  messageSuccess: PropTypes.string.isRequired,
  messageError: PropTypes.string,
};

export { NotificationModal };
