import { Checkbox as AntdCheckbox, Form } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styled.module.css';
import './styles.css';

const Checkbox = ({
  title,
  label,
  noLabel = false,
  options,
  width,
  message,
  form,
}) => {
  const { t } = useTranslation();
  const formatTitle = title.replace(/_/g, ' ');

  const handleChange = (values) => {
    if (values[values.length - 1] === 'none') {
      form.setFieldsValue({ [title]: values.slice(-1) });
    } else {
      form.setFieldsValue({ [title]: values.filter((item) => item !== 'none') });
    }
  };

  return (
    <div className={styles.checkboxBlock}>
      {!noLabel && (
      <p>
        {label || formatTitle}
        :
      </p>
      )}
      <Form.Item
        name={title}
        rules={[
          {
            required: true,
            message: message || t('errors.selectYour', { text: formatTitle }),
          },
        ]}
      >
        <AntdCheckbox.Group
          className={`${styles.checkboxGroup} custom-checkbox-group`}
          onChange={form && handleChange}
        >
          {options.map((option) => (
            <AntdCheckbox
              key={option.value}
              value={option.value}
              className={`${styles.checkboxItem} ${
                (option.image || option.color) && styles.checkboxItemLeft
              }`}
              style={{ width: width && width }}
            >
              <div className={styles.optionBlock}>
                {option.color && (
                <div
                  className={styles.colorItem}
                  style={{
                    background: option.color,
                  }}
                />
                )}
                {option.image && (
                <img
                  src={option.image}
                  alt={option.value}
                  style={{ height: '23px', width: '23px' }}
                />
                )}
                <span>{option.label}</span>
              </div>
            </AntdCheckbox>
          ))}
        </AntdCheckbox.Group>
      </Form.Item>
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  width: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
  }),
};

export { Checkbox };
