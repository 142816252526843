import female from 'assets/female.svg';
import male from 'assets/male.svg';
import trans from 'assets/trans.svg';
import gay from 'assets/gay.svg';
import lesbian from 'assets/lesbian.svg';

export const genders = [
  {
    title: 'Lady',
    gender: 'female',
    image: female,
    orientation: 'hetero',
  },
  {
    title: 'Lesbian',
    gender: 'female',
    image: lesbian,
    orientation: 'homo',
  },
  {
    title: 'Gentleman',
    gender: 'male',
    image: male,
    orientation: 'hetero',
  },
  {
    title: 'Gay',
    gender: 'male',
    image: gay,
    orientation: 'homo',
  },
  {
    title: 'Trans',
    gender: 'trans',
    image: trans,
    orientation: 'bi',
  },
];
