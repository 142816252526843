import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { usePublicVersion } from './usePublicVersion';

import { pageTitle, paths } from 'constants';

const usePageTitle = () => {
  const { pathname } = useLocation();
  const { isPublicVersion } = usePublicVersion();

  const isNotCatalogPage = useMemo(
    () => Object.values(paths).includes(
      pathname.endsWith('/') ? pathname.slice(0, -1) : pathname,
    ),
    [paths, pathname],
  );

  useEffect(() => {
    if (pathname === paths.main) {
      if (isPublicVersion) {
        document.title = 'Flowers';
      } else {
        document.title = pageTitle[pathname];
      }
    } else if (isNotCatalogPage) {
      document.title = `${isPublicVersion ? 'Flowers' : 'ToLips'} - ${
        pageTitle[pathname]
      }`;
    }
  }, [pathname, isPublicVersion]);

  useEffect(() => {
    if (isPublicVersion) {
      const faviconElement = document.querySelector("link[rel='icon']");
      faviconElement.href = '/publicIcon.png';
    }
  }, [pathname, isNotCatalogPage, isPublicVersion]);
};

export { usePageTitle };
