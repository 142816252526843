import {
  performAuthorizedRequest,
  performNonAuthorizedRequest,
} from '../apiConfig';

const catalogsPath = '/catalogs';

export const getMainData = (query) => performNonAuthorizedRequest(`${catalogsPath}/catalog`, query);

export const getMainDataForVisitor = (query) => performAuthorizedRequest(`${catalogsPath}/catalog`, query);

export const getCatalogProfileData = (query) => performNonAuthorizedRequest(`${catalogsPath}/card`, query);

export const getModalRanges = (query) => performNonAuthorizedRequest(`${catalogsPath}/modal_ranges`, query);
