import { useState, useEffect } from 'react';

const useIsMobile = ({ maxWidth = 552 } = {}) => {
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' ? window.innerWidth <= maxWidth : false,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= maxWidth) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxWidth]);

  return isMobile;
};

export { useIsMobile };
