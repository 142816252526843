import { Button } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { usePrivateNavigate } from 'hooks/usePrivateNavigate';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';
import { profileStatus } from 'constants/profileStatus';
import { verificationStatus } from 'constants/verificationStatus';
import { priceForWeeklyOfferAndHotDeal } from 'constants/prices';

import { TimerFlipClock } from 'components/TimerFlipClock';
import { WeeklyOfferModal } from './components/WeeklyOfferModal';
import { UpModal } from './components/UpModal';
import { HotDealModal } from './components/HotDealModal';

import styles from './styles.module.css';

const LiftingBlock = () => {
  const privateNavigate = usePrivateNavigate();
  const { t } = useTranslation();
  const {
    localState: {
      userInfo: {
        status,
        verification_status,
        balance,
        rates,
        can_edit_profile,
        promo: {
          up: { unblock_at: profile_up_unblock_at },
          hot_deal: { unblock_at: hot_deal_unblock_at },
          weekly_offer: { unblock_at: weekly_offer_unblock_at, can_view_modal },
        },
      },
    },
  } = useLocalContext();
  const navigate = useNavigate();
  const isHaveMoney = +balance >= priceForWeeklyOfferAndHotDeal;

  const buttonText = () => {
    if (!rates?.length && verification_status === verificationStatus.verified) {
      return t('pages.profile.components.liftingBlock.1');
    }
    if (verification_status === verificationStatus.verified) {
      return t('pages.profile.components.liftingBlock.2');
    }
    return t('pages.profile.components.liftingBlock.3');
  };

  const [openUpModal, setOpenUpModal] = useState(false);
  const [openWeeklyOfferModal, setOpenWeeklyOfferModal] = useState(false);
  const [openHotDealModal, setOpenHotDealModal] = useState(false);
  const [timerState, setTimerState] = useState({
    up: new Date().getTime() >= +profile_up_unblock_at * 1000,
    weeklyOffer: new Date().getTime() >= +weekly_offer_unblock_at * 1000,
    hotDeal: new Date().getTime() >= +hot_deal_unblock_at * 1000,
  });

  if (status !== profileStatus.published) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.oneClick}>
        <div className={styles.titleBlock}>
          <p className={styles.title}>One click, one UP.</p>
          <TimerFlipClock
            time={+profile_up_unblock_at * 1000}
            setTimerState={setTimerState}
            up
          />
        </div>
        <div className={styles.description}>
          <p>
            {verification_status !== verificationStatus.verified
              && t('pages.profile.components.liftingBlock.4')}
          </p>
          {verification_status !== verificationStatus.verified ? (
            <p>
              {t('pages.profile.components.liftingBlock.6')}
              <br />
              <span>{t('pages.profile.components.liftingBlock.19')}</span>
              {' '}
              {t('pages.profile.components.liftingBlock.20')}
            </p>
          ) : (
            <p>{t('pages.profile.components.liftingBlock.5')}</p>
          )}
          {verification_status === verificationStatus.verified && (
            <p className={styles.lastType}>
              {t('pages.profile.components.liftingBlock.7')}
            </p>
          )}
        </div>
        <div className={styles.buttonBlock}>
          <Button
            type="primary"
            className={styles.button}
            onClick={() => {
              setOpenUpModal(true);
            }}
            disabled={!timerState.up}
          >
            {verification_status === verificationStatus.verified
              ? t('pages.profile.components.liftingBlock.8')
              : t('pages.profile.components.liftingBlock.9')}
          </Button>
        </div>
      </div>
      <div className={styles.offers}>
        <div
          className={
            verification_status !== verificationStatus.verified
              ? styles.offerDisabled
              : ''
          }
        >
          <div>
            <div className={styles.titleBlock}>
              <p className={styles.title}>Weekly offer</p>
              {verification_status === verificationStatus.verified
                && !can_view_modal && (
                  <TimerFlipClock
                    time={+weekly_offer_unblock_at * 1000}
                    setTimerState={setTimerState}
                    weeklyOffer
                  />
              )}
            </div>
            <div className={styles.description}>
              <p>
                {verification_status === verificationStatus.verification
                  ? t('pages.profile.components.liftingBlock.21')
                  : t('pages.profile.components.liftingBlock.10')}
              </p>
              <p>
                {verification_status === verificationStatus.verification
                  ? t('pages.profile.components.liftingBlock.22')
                  : t('pages.profile.components.liftingBlock.11')}
              </p>
            </div>
            {verification_status !== verificationStatus.verification && (
              <p className={styles.lastType}>
                {can_view_modal
                  ? t('pages.profile.components.liftingBlock.12')
                  : t('pages.profile.components.liftingBlock.23')}
              </p>
            )}
          </div>
          {verification_status !== verificationStatus.verification && (
            <div className={styles.buttonBlock}>
              <Button
                type="primary"
                className={styles.button}
                disabled={!can_view_modal}
                onClick={() => {
                  if (verification_status !== verificationStatus.verified) {
                    privateNavigate({ paths: paths.verification });
                  } else {
                    setOpenWeeklyOfferModal(true);
                  }
                }}
              >
                {verification_status === verificationStatus.verified
                  ? t('pages.profile.components.liftingBlock.13')
                  : t('pages.profile.components.liftingBlock.14')}
              </Button>
            </div>
          )}
        </div>
        <div
          className={
            verification_status !== verificationStatus.verified
            || (!rates?.length
              && verification_status === verificationStatus.verified)
              ? styles.offerDisabled
              : ''
          }
        >
          <div>
            <div className={styles.titleBlock}>
              <p className={styles.title}>Hot deal</p>
              {verification_status === verificationStatus.verified
                && !!rates?.length && (
                  <TimerFlipClock
                    time={+hot_deal_unblock_at * 1000}
                    setTimerState={setTimerState}
                    hotDeal
                  />
              )}
            </div>
            <div className={styles.description}>
              {verification_status === verificationStatus.verification && (
                <>
                  <p>{t('pages.profile.components.liftingBlock.21')}</p>
                  <p>{t('pages.profile.components.liftingBlock.22')}</p>
                </>
              )}
              {verification_status === verificationStatus.verified && (
                <>
                  <p>
                    {rates?.length
                      ? t('pages.profile.components.liftingBlock.15')
                      : t('pages.profile.components.liftingBlock.17')}
                  </p>
                  <p>
                    {rates?.length
                      ? t('pages.profile.components.liftingBlock.16')
                      : t('pages.profile.components.liftingBlock.18')}
                  </p>
                </>
              )}
              {verification_status !== verificationStatus.verification
                && verification_status !== verificationStatus.verified && (
                  <>
                    <p>{t('pages.profile.components.liftingBlock.15')}</p>
                    <p>{t('pages.profile.components.liftingBlock.16')}</p>
                  </>
              )}
              {!timerState.hotDeal && (
                <p className={styles.lastType} style={{ paddingTop: ' 20px' }}>
                  {t('pages.profile.components.liftingBlock.23')}
                </p>
              )}
            </div>
          </div>
          {verification_status !== verificationStatus.verification && (
            <div className={styles.buttonBlock}>
              {!rates?.length && !can_edit_profile ? null : (
                <Button
                  type="primary"
                  className={styles.button}
                  disabled={
                    !timerState.hotDeal || (!rates?.length && !can_edit_profile)
                  }
                  onClick={() => {
                    if (
                      !rates?.length
                      && verification_status === verificationStatus.verified
                    ) {
                      navigate(paths.profileCreation, { state: 5 });
                    } else if (
                      verification_status !== verificationStatus.verified
                    ) {
                      privateNavigate({ paths: paths.verification });
                    } else if (isHaveMoney) {
                      privateNavigate({ paths: paths.hotDealPage });
                    } else {
                      setOpenHotDealModal(true);
                    }
                  }}
                >
                  {buttonText()}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <UpModal
        open={openUpModal}
        setOpen={setOpenUpModal}
        setTimerState={setTimerState}
      />
      <WeeklyOfferModal
        open={openWeeklyOfferModal}
        setOpen={setOpenWeeklyOfferModal}
      />
      <HotDealModal open={openHotDealModal} setOpen={setOpenHotDealModal} />
    </section>
  );
};

export { LiftingBlock };
