import { useEffect, useState } from 'react';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLocalContext } from 'LocalContext';
import { latinLettersPattern } from 'constants/patterns';
import { sendReportProvider, sendReportVisitor } from 'api/requests/blackList';
import { cities } from 'constants';
import { userProvider, userVisitor } from 'constants/registrationUserType';
import { reportTags } from 'utils/filters';
import { scrollToErrorField } from 'utils/scrollToErrorField';

import { PhoneCode } from 'components/PhoneCode';
import { Select } from 'components/Select';

import styles from './styled.module.css';
import './styles.css';

const ReportToBlackList = ({ activeKey, isSend, setIsSend }) => {
  const { t } = useTranslation();
  const {
    localState: {
      userInfo: { user_type },
    },
  } = useLocalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [activeKey, isSend]);

  const onClickShowResult = async (values) => {
    const query = {
      ...values,
      email: values.email,
    };

    setLoading(true);
    try {
      if (user_type === userProvider) {
        await sendReportProvider(query);
      }
      if (user_type === userVisitor) {
        await sendReportVisitor(query);
      }
      setError(false);
      setIsSend(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return isSend ? (
    <div className={styles.sendWrapper}>
      <div className={styles.sendTitle}>
        <p className={styles.title}>
          {t('pages.profile.blackList.reportToBlackList.1')}
        </p>
        <p>{t('pages.profile.blackList.reportToBlackList.2')}</p>
        <p>{t('pages.profile.blackList.reportToBlackList.3')}</p>
      </div>
      <Button
        type="primary"
        className={styles.button}
        onClick={() => {
          setIsSend(false);
          form.resetFields();
        }}
      >
        {t('pages.profile.blackList.reportToBlackList.4')}
      </Button>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.reporTitle}>
        <p className={styles.title}>
          {t('pages.profile.blackList.reportToBlackList.5')}
        </p>
        <p>{t('pages.profile.blackList.reportToBlackList.6')}</p>
      </div>
      <div className={styles.depositBlock}>
        <Form
          onFinish={onClickShowResult}
          onFinishFailed={(values) => scrollToErrorField(values)}
          autoComplete="off"
          form={form}
          className={styles.form}
        >
          <div className={styles.formWrapper}>
            <div className={styles.info}>
              <p>{t('pages.profile.blackList.reportToBlackList.7')}</p>
              <Form.Item
                name="visitor_name"
                rules={[
                  {
                    required: true,
                    message: t('errors.enterVisitor'),
                  },
                  {
                    min: 2,
                    message: t('errors.nameAtLeast', { number: 2 }),
                  },
                  {
                    max: 32,
                    message: t('errors.nameLess', { number: 32 }),
                  },
                  {
                    pattern: latinLettersPattern,
                    message: t('errors.onlyLatin'),
                  },
                ]}
              >
                <Input placeholder={`${t('common.visitorName')}*`} />
              </Form.Item>
              <Select
                title="city"
                options={cities.slice(1)}
                form={form}
                noLabel
                placeholder={`${t('common.city')}*`}
              />
              <p>{t('pages.profile.blackList.reportToBlackList.8')}</p>
              <PhoneCode
                form={form}
                dependencies="email"
                name="phone_number"
                withInitialValue
              />
              <Form.Item
                name="email"
                dependencies={['phone_number']}
                rules={[
                  {
                    validator: (_, value) => new Promise((resolve, reject) => {
                      const { getFieldValue } = form;
                      if (!getFieldValue('phone_number') && !value) {
                        reject(new Error(t('errors.visitorNumberOrSocial')));
                      }
                      resolve();
                    }),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    'pages.profile.blackList.reportToBlackList.10',
                  )}
                />
              </Form.Item>
              <Form.Item
                name="text"
                className="report"
                rules={[
                  {
                    required: true,
                    message: t('errors.enterMessage'),
                  },
                ]}
              >
                <TextArea
                  placeholder={t('pages.profile.blackList.reportToBlackList.9')}
                  maxLength={2000}
                  showCount
                  style={{ minWidth: '100%' }}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className={styles.tags}>
              <p>
                {t('common.reportTags')}
                *
              </p>
              <Form.Item
                name="tags"
                className="report"
                rules={[
                  {
                    required: true,
                    message: t('errors.selectTag'),
                  },
                ]}
              >
                <Checkbox.Group className={styles.radioGroup}>
                  {reportTags.map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                      <span className={styles.tagLabel}>{option.label}</span>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
              <Form.Item shouldUpdate className="report buttonBlock">
                {() => {
                  const requiredFields = [
                    'tags',
                    'city',
                    'visitor_name',
                    'text',
                  ];
                  const unrequiredFields = ['email', 'phone_number'];
                  const isRequiredFieldsTouched = requiredFields.every(
                    (field) => form.isFieldTouched(field),
                  );
                  const isUnrequiredFieldsTouched = unrequiredFields.some(
                    (field) => form.isFieldTouched(field),
                  );
                  const hasErrors = form
                    .getFieldsError([...requiredFields, ...unrequiredFields])
                    .some(({ errors }) => errors.length);
                  const isHaveUnrequiredField = Object.values(
                    form.getFieldsValue(unrequiredFields),
                  ).some((item) => !!item);
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className={styles.button}
                      disabled={
                        !isRequiredFieldsTouched
                        || !isHaveUnrequiredField
                        || hasErrors
                        || !isUnrequiredFieldsTouched
                      }
                    >
                      {t('common.send')}
                    </Button>
                  );
                }}
              </Form.Item>
              {error && (
                <span className={styles.error}>
                  {t('errors.somethingWrong')}
                </span>
              )}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

ReportToBlackList.propTypes = {
  isSend: PropTypes.bool.isRequired,
  setIsSend: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
};

export { ReportToBlackList };
