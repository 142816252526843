import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'components/ActiveIcon/CloseIcon';
import { OpenArrow } from 'components/ActiveIcon/OpenArrow';
import { Button } from 'components/Button';
import { SelectWrapper } from './components/SelectWrapper';

import styles from './styled.module.css';

const Select = ({
  options = [],
  placeholder = '',
  defaultValue,
  onSelect = () => {},
  multi = false,
  className = '',
  form,
  title,
  uniquelabel,
  noLabel = false,
  initialValue,
  disabled,
  noSelect = false,
  limit,
  limitMessage,
  optional = false,
  translate,
  dependency = '',
  readOnly = false,
  scrollHeight = 0,
}) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(
    multi ? defaultValue || [] : defaultValue || '',
  );
  const [inputValue, setInputValue] = useState('');
  const [searchOption, setSearchOption] = useState(options);
  const [focused, setFocused] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const { t } = useTranslation();
  const formatTitle = title?.replace(/_/g, ' ') || '';

  const findItem = useMemo(
    () => (el) => options.find((item) => item.value === el),
  );

  const actualPlaceholder = useMemo(() => {
    if (!placeholder) {
      if (multi && !!option.length) {
        return option.length < limit ? `${t('common.search_block')}...` : '';
      }
      return `${formatTitle.slice(0, 1).toUpperCase()}${formatTitle.slice(1)}`;
    }
    if (multi && !!option.length) {
      return `${t('common.search_block')}...`;
    }

    return form ? t('errors.selectYour', { text: formatTitle }) : placeholder;
  }, [placeholder, formatTitle, option.length, form, t, multi]);

  useEffect(() => {
    setSearchOption(options);
  }, [JSON.stringify(options)]);

  useEffect(() => {
    setInputValue('');
    setOption(multi ? defaultValue || [] : defaultValue || '');
  }, [defaultValue, multi]);

  useEffect(() => {
    if (
      dependency
      && dependency !== option
      && !options.some((item) => item.value === option)
    ) {
      if (!multi) {
        setOption('');
      } else {
        setOption([]);
      }
      setInputValue('');
      setSearchOption(options);
    }
  }, [dependency]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event?.target?.id === 'modalCloseIcon') {
        return;
      }
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
        setIsSearch(false);

        if (inputRef.current) {
          inputRef.current.blur();
        }

        if (
          !inputRef.current.value
          || (!!inputRef.current.value
            && !options.some(
              (item) => `${item.value}`.toLowerCase()
                  === inputRef.current.value.toLowerCase()
                || `${item.label}`.toLowerCase()
                  === inputRef.current.value.toLowerCase(),
            ))
        ) {
          if (!multi) {
            setOption(optional ? '' : defaultValue || '');
            setInputValue('');
          } else {
            setInputValue('');
            if (!option.length) {
              setOption(optional ? [] : defaultValue || []);
            }
          }
          setSearchOption(options);
        }
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      if (open) {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      }
    };
  }, [option, open]);

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (isSearch && open) {
        event.preventDefault();
        setIsSearch(false);
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isSearch, open]);

  const onInputBlockClick = () => {
    if (!noSelect && !disabled) {
      if (inputRef.current) {
        setFocused(true);
        inputRef.current.focus();
      }

      const wpapper = wrapperRef?.current?.getBoundingClientRect();
      const modalContainer = wrapperRef?.current?.closest('.ant-modal-body')
        || wrapperRef?.current?.closest('.ant-drawer-body');
      const scrollEl = modalContainer
        ? modalContainer.getBoundingClientRect().bottom
        : window.innerHeight;

      if (scrollEl - wpapper.bottom < 263) {
        (modalContainer || window).scrollBy({
          top: 263 - (scrollEl - wpapper.bottom) + scrollHeight,
          behavior: 'smooth',
        });
      }

      if (!((multi && option.length >= limit) || (open && !isSearch))) {
        setOpen(!open);
      }

      if (open && !isSearch) {
        setIsSearch(true);
      }

      if (open && isSearch) {
        setIsSearch(false);
        setOpen(false);
      }
    }
  };

  return (
    <SelectWrapper
      multi={multi}
      form={form}
      title={title}
      uniquelabel={uniquelabel}
      noLabel={noLabel}
      initialValue={initialValue}
      limitMessage={limitMessage}
      optional={optional}
    >
      <div
        className={`
        ${styles.wrapper}
        ${className}
      `}
        ref={wrapperRef}
      >
        <div
          className={`
          ${styles.inputBlock}
          ${multi ? styles.multiSelect : ''}
          ${focused ? styles.focused : ''}
          ${disabled ? styles.disabled : ''}
        `}
          onClick={onInputBlockClick}
        >
          {multi
            && option.map((item) => (
              <div className={styles.multiSelectItem} key={item}>
                <p>{findItem(item)?.label}</p>
                <CloseIcon
                  className={styles.multiSelectItemCloseIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newOption = option.filter((el) => el !== item);
                    setOption(newOption);
                    if (option.length === 1 && (form && !optional)) {
                      setOpen(true);
                    }
                    if (form) {
                      form.setFieldsValue({ [title]: newOption });
                    }
                    onSelect(newOption);
                  }}
                />
              </div>
            ))}
          {!multi && findItem(option)?.image && option === inputValue && (
            <img
              className={styles.itemImage}
              src={findItem(option)?.image}
              alt={option}
            />
          )}
          {open ? (
            <input
              className={`
            ${styles.input} 
            ${multi && !option.length ? styles.multiSelectInput : ''}
            ${multi && option.length ? styles.shortInput : ''}
            ${disabled ? styles.disabledInput : ''}
            ${findItem(option)?.image ? styles.inputWithImage : ''}
          `}
              value={inputValue}
              placeholder={
                multi && option.length
                  ? `${t('common.search_block')}...`
                  : `${t('common.noSearchInput')}...`
              }
              readOnly={readOnly || option.length >= limit}
              onChange={(e) => {
                if (!open) {
                  setOpen(true);
                }
                setInputValue(e.target.value);
                setSearchOption(
                  options.filter(
                    (item) => item.label
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                      || `${item.value}`
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()),
                  ),
                );
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Backspace'
                  && !inputValue
                  && multi
                  && !!option.length
                ) {
                  setOption([...option.slice(0, -1)]);
                  if (form) {
                    form.setFieldsValue({
                      [title]: [...option.slice(0, -1)],
                    });
                  }
                  onSelect([...option.slice(0, -1)]);
                }
              }}
              ref={inputRef}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              disabled={disabled}
            />
          ) : (
            <Button
              type="link"
              ref={inputRef}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              className={`
              ${styles.input} ${styles.noSearch}
              ${multi && !option.length ? styles.multiSelectInput : ''}
              ${multi && option.length ? styles.shortInput : ''}
              ${multi && option.length >= limit ? styles.hidden : ''}`}
            >
              {options.find((item) => item.value === option)?.label
                || actualPlaceholder}
            </Button>
          )}
        </div>
        <div className={styles.icons}>
          {inputValue && !disabled && !noSelect && (
            <CloseIcon
              className={styles.closeIcon}
              onClick={() => {
                setInputValue('');
                setSearchOption(options);
                if (!optional) {
                  setOpen(true);
                }
                if (!multi) {
                  setOption(optional ? '' : defaultValue || '');
                  if (form) {
                    form.setFieldsValue({
                      [title]: optional ? '' : defaultValue || '',
                    });
                  }
                  onSelect('');
                }
              }}
            />
          )}
          {!inputValue
            && !open
            && !disabled
            && !(multi && option.length >= limit) && (
              <OpenArrow
                className={styles.arrow}
                onClick={() => setOpen(true)}
              />
          )}
        </div>
        <div
          style={{ display: open ? 'flex' : 'none' }}
          className={styles.list}
        >
          {searchOption.length ? (
            searchOption.map((item) => (
              <Button
                type="link"
                className={`
              ${styles.listItem}
              ${
                ((multi
                  ? option.includes(item.value)
                  : option === item.value)
                  || (multi && !option.length && item.value === ''))
                && styles.active
              }
              `}
                label={item.label}
                key={item.value}
                value={item.value}
                onClick={() => {
                  if (multi) {
                    if (!item.value) {
                      setOption([]);
                    } else if (option.includes(item.value)) {
                      setOption([...option.filter((el) => el !== item.value)]);
                      if (form) {
                        form.setFieldsValue({
                          [title]: [
                            ...option.filter((el) => el !== item.value),
                          ],
                        });
                      }
                      onSelect([...option.filter((el) => el !== item.value)]);
                    } else {
                      setOption([...option, item.value]);
                      if (form) {
                        form.setFieldsValue({
                          [title]: [...option, item.value],
                        });
                      }
                      onSelect([...option, item.value]);
                    }
                    setInputValue('');
                    setSearchOption(options);
                  } else if (item.value !== option) {
                    setOption(item.value);
                    setInputValue(item.label);

                    if (form) {
                      form.setFieldsValue({ [title]: item.value });
                    }
                    onSelect(item.value);
                  }
                  setOpen(false);
                  setFocused(false);
                }}
              >
                {item?.image && <img src={item?.image} alt="icon" />}
                {translate ? t(`common.${item.value}`) : item.label}
              </Button>
            ))
          ) : (
            <p className={styles.emptyList}>{t('common.noMatches')}</p>
          )}
        </div>
      </div>
    </SelectWrapper>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onSelect: PropTypes.func,
  multi: PropTypes.bool,
  className: PropTypes.string,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
  }),
  uniquelabel: PropTypes.string,
  title: PropTypes.string,
  noLabel: PropTypes.bool,
  limit: PropTypes.number,
  limitMessage: PropTypes.string,
  disabled: PropTypes.bool,
  noSelect: PropTypes.bool,
  initialValue: PropTypes.string,
  translate: PropTypes.bool,
  optional: PropTypes.bool,
  dependency: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  readOnly: PropTypes.bool,
  scrollHeight: PropTypes.number,
};

export { Select };
