import { useEffect } from 'react';

const useBlockScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      document.getElementById('root').style.overflow = 'visible';
    }
    return () => {
      document.getElementById('root').style.overflow = 'visible';
    };
  }, [open]);
};

export { useBlockScroll };
