import React, { useState } from 'react';
import { Button, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { sendResetPasswordEmail } from 'api/requests';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import { latinLettersPattern } from 'constants/patterns';

import styles from '../../styled.module.css';

const SendMessage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [sentMessage, setSentMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resentMessage, setResentMessage] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    const email = form.getFieldValue('userEmail').toLowerCase();
    const query = {
      email,
    };
    setLoading(true);
    try {
      const res = await sendResetPasswordEmail(query);
      if (res.status === 200) {
        setSentMessage(true);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return sentMessage ? (
    <div className={styles.form}>
      <div className={styles.contentBlockTitle}>
        <h4>{t('pages.forgotPasswordPage.13')}</h4>
        <h4 className={styles.email}>{form.getFieldValue('userEmail')}</h4>
      </div>
      <p className={styles.link}>{t('pages.forgotPasswordPage.5')}</p>
      <div>
        <p>{t('pages.forgotPasswordPage.6')}</p>
        <ul className={styles.list}>
          <li>
            <p>{t('pages.forgotPasswordPage.7')}</p>
          </li>
          <li>
            <p>{t('pages.forgotPasswordPage.8')}</p>
          </li>
          <li>
            <p>{t('pages.forgotPasswordPage.9')}</p>
          </li>
        </ul>
      </div>
      <Button
        className={`${styles.button} ${styles.secondaryButton}`}
        loading={loading}
        onClick={() => {
          setResentMessage(true);
          onSend();
        }}
      >
        {t('pages.forgotPasswordPage.10')}
      </Button>
      {resentMessage && !error && (
        <p className={styles.message}>{t('pages.forgotPasswordPage.11')}</p>
      )}
      {error && <p className={styles.message}>{t('errors.somethingWrong')}</p>}
    </div>
  ) : (
    <Form
      form={form}
      onFinish={onSend}
      autoComplete="off"
      className={styles.form}
      onFinishFailed={(values) => scrollToErrorField(values)}
    >
      <div className={styles.contentBlockTitle}>
        <h4>{t('pages.forgotPasswordPage.12')}</h4>
      </div>
      <Form.Item
        name="userEmail"
        rules={[
          {
            required: true,
            message: t('errors.inputEmail'),
          },
          {
            type: 'email',
            message: t('errors.emailFormat'),
          },
          {
            pattern: latinLettersPattern,
            message: t('errors.onlyLatin'),
          },
        ]}
      >
        <Input placeholder={t('common.email')} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            loading={loading}
            disabled={
              !form.isFieldsTouched(true)
              || !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {t('common.send')}
          </Button>
        )}
      </Form.Item>
      {error && (
        <p className={styles.message}>
          {t('errors.somethingWrongForResetPassword')}
        </p>
      )}
    </Form>
  );
};

export { SendMessage };
