import { Button } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFlipper } from 'hooks/useFlipper';
import { useIsMobile } from 'hooks/useIsMobile';

import { useLocalContext } from 'LocalContext';
import { formatBackImagesToFront } from 'utils/images';

import { PhotosItem } from '../../../../components/PhotosItem';
import { PhotoAlbum } from '../../../../components/PhotoAlbum';
import { PhotosModal } from './components/PhotosModal';

import styles from './styled.module.css';

const Photos = () => {
  const { t } = useTranslation();
  const {
    localState: { userInfo },
  } = useLocalContext();
  const { isFlipperFeatureEnabled: isImageConverterEnabled } = useFlipper('image_converter');

  const isMobile = useIsMobile();
  const avatarRef = useRef(null);
  const photoRef = useRef(null);
  const albumRef = useRef(null);

  const { avatarImages, photoCollectionImages } = useMemo(
    () => formatBackImagesToFront(userInfo.images),
    [userInfo.images],
  );

  const [backDataAlbum, setBackDataAlbum] = useState(
    userInfo?.albums?.[0]?.images || [],
  );

  const [openModal, setOpenModal] = useState(false);
  const [avatar, setAvatar] = useState(avatarImages);
  const [photoCollection, setPhotoCollection] = useState(photoCollectionImages);

  const [photoAlbumId, setPhotoAlbumId] = useState(
    userInfo?.albums?.[0]?.id || null,
  );
  const [photoAlbum, setPhotoAlbum] = useState(
    userInfo?.albums?.[0]?.images || [],
  );

  const onScroll = (ref) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - 140,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {!isMobile && (
        <div className={styles.fixedButton}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!avatar.length}
            className={`${styles.button} ${styles.primary}`}
            onClick={() => setOpenModal(true)}
          >
            {t('common.done')}
          </Button>
        </div>
      )}
      <div className={styles.allButtonsCategory}>
        <Button
          type="primary"
          htmlType="submit"
          className={`${styles.buttonCategory} ${styles.primary}`}
          onClick={() => onScroll(avatarRef)}
        >
          {t('common.avatar')}
        </Button>
        {' '}
        <Button
          type="primary"
          htmlType="submit"
          className={`${styles.buttonCategory} ${styles.primary}`}
          onClick={() => onScroll(photoRef)}
        >
          {t('common.photos')}
        </Button>
        {' '}
        <Button
          type="primary"
          htmlType="submit"
          className={`${styles.buttonCategory} ${styles.primary}`}
          onClick={() => onScroll(albumRef)}
        >
          {t('common.album')}
        </Button>
      </div>
      <div className={styles.photosBlock} ref={avatarRef}>
        <p className={styles.avatar}>
          {t('pages.profileCreation.photos.1')}
          :
          <span className={styles.attention}>
            {t('pages.profileCreation.photos.2')}
          </span>
        </p>
        <PhotosItem
          maxItem={1}
          imagesList={avatar}
          setImagesList={setAvatar}
          className="oneImage"
          isImageConverterEnabled={isImageConverterEnabled}
        />
      </div>
      <div className={styles.photosBlock} ref={photoRef}>
        <p>
          {t('pages.profileCreation.photos.3')}
          :
        </p>
        <PhotosItem
          maxItem={4}
          imagesList={photoCollection}
          setImagesList={setPhotoCollection}
          isImageConverterEnabled={isImageConverterEnabled}
        />
        <PhotoAlbum
          photoAlbumId={photoAlbumId}
          setPhotoAlbumId={setPhotoAlbumId}
          photoAlbum={photoAlbum}
          setPhotoAlbum={setPhotoAlbum}
          setBackDataAlbum={setBackDataAlbum}
          ref={albumRef}
        />
      </div>
      {isMobile && (
        <div className={`${styles.fixedButton} ${styles.fixedMobileButton}`}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!avatar.length}
            onClick={() => setOpenModal(true)}
            className={`${styles.button} ${styles.primary}`}
          >
            {t('common.done')}
          </Button>
        </div>
      )}
      <PhotosModal
        open={openModal}
        setOpen={setOpenModal}
        avatar={avatar}
        photoCollection={photoCollection}
        photoAlbum={photoAlbum}
        photoAlbumId={photoAlbumId}
        backDataAlbum={backDataAlbum}
        isImageConverterEnabled={isImageConverterEnabled}
      />
    </div>
  );
};

export { Photos };
