import {
  string, oneOfType, arrayOf, node,
} from 'prop-types';

import styles from './styled.module.css';

const Box = ({ customClass = '', children }) => (
  <div className={`${styles.box} ${customClass}`}>{children}</div>
);

Box.propTypes = {
  customClass: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export { Box };
