export const getError = (error) => {
  const errors = error?.response?.data?.errors?.map((el) => el.detail)[0];

  if (Array.isArray(errors) && errors?.length) {
    const errorMessages = errors.map((el) => {
      const key = Object.keys(el)[0];
      const message = el[key];
      return `${key.charAt(0).toUpperCase() + key.slice(1)} ${message}`;
    });

    return `${errorMessages.join(', ')}!`;
  }

  if (typeof errors === 'string') {
    return errors;
  }

  return 'Unknown error!';
};

export const getErrorInForm = (errors, providerData) => {
  const errorArr = errors?.response?.data?.errors?.[0]?.detail;
  const valuesArr = Object.keys(providerData);
  const formatErrorObjKeys = [];

  if (!!errorArr.length && !!valuesArr.length) {
    const formatErrorObj = errorArr.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      const newKey = valuesArr.find((el) => el.toLowerCase().includes(key));
      formatErrorObjKeys.push(newKey);
      acc[newKey || key] = curr[key];
      return acc;
    }, {});

    if (formatErrorObjKeys.includes(undefined)) {
      return formatErrorObj;
    }

    const result = {};
    valuesArr.forEach((key) => {
      if (key in formatErrorObj) {
        result[key] = formatErrorObj[key];
      }
    });
    return result;
  }

  return {};
};
