import { Checkbox, Form } from 'antd';
import checkPropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';

import { sendReportVisitor } from 'api/requests';
import { paths } from 'constants';
import { scrollToErrorField } from 'utils/scrollToErrorField';
import { reportTagsVisitor } from 'utils/filters';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';
import './styles.css';

const AddToBlackListModal = memo(({ open = false, setOpen, profile }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const query = {
      profile_id: profile?.id,
      city: profile?.city,
      phone_number: profile?.phone_number,
      tags: values.tags,
      text: values.text,
    };

    try {
      await sendReportVisitor(query);
      setError(false);
      setIsSend(true);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const getIsDisabled = () => {
    const { text, tags } = form.getFieldsValue();
    setIsDisabled(!!text?.length && !!tags?.length);
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      maxHeightModal="70dvh"
      onClick={() => {
        form.resetFields();
      }}
      footer={(
        <Button
          primary
          loading={loading}
          submit
          fullWidth
          onClick={() => {
            if (isSend) {
              navigate(paths.profileBlackList);
            } else {
              onFinish();
            }
          }}
          disabled={!isSend && !isDisabled}
        >
          {!loading
            && (isSend ? t('pages.catalogProfile.5') : t('common.send'))}
        </Button>
      )}
    >
      {isSend ? (
        <div className={styles.sendWrapper}>
          <div className={styles.sendTitle}>
            <p className={styles.title}>
              {t('pages.profile.blackList.reportToBlackList.1')}
            </p>
            <p>{t('pages.profile.blackList.reportToBlackList.2')}</p>
            <p>{t('pages.profile.blackList.reportToBlackList.3')}</p>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.reporTitle}>
            <p className={styles.title}>
              {t('pages.profile.blackList.reportToBlackList.5')}
            </p>
            <p>{t('pages.profile.blackList.reportToBlackList.6')}</p>
          </div>
          <Form
            onFinishFailed={(values) => scrollToErrorField(values)}
            autoComplete="off"
            form={form}
            className={styles.form}
          >
            <Form.Item
              name="text"
              className="addToBlackListModalContent"
              rules={[
                {
                  required: true,
                  message: t('errors.enterMessage'),
                },
              ]}
            >
              <TextArea
                placeholder={t('pages.profile.blackList.reportToBlackList.9')}
                maxLength={2000}
                showCount
                minLength={1}
                onChange={() => getIsDisabled()}
                style={{ minWidth: '100%' }}
                allowClear
              />
            </Form.Item>
            <p className={styles.reportTags}>
              {t('common.reportTags')}
              *
            </p>
            <Form.Item
              name="tags"
              className="addToBlackListModalContent"
              rules={[
                {
                  required: true,
                  message: t('errors.selectTag'),
                },
              ]}
            >
              <Checkbox.Group
                className={styles.radioGroup}
                onChange={() => getIsDisabled()}
              >
                {reportTagsVisitor.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    <span className={styles.tagLabel}>{option.label}</span>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            {error && (
              <span className={styles.error}>{t('errors.somethingWrong')}</span>
            )}
          </Form>
        </div>
      )}
    </ModalWrapper>
  );
});

AddToBlackListModal.propTypes = {
  open: checkPropTypes.bool.isRequired,
  setOpen: checkPropTypes.func.isRequired,
  profile: checkPropTypes.shape({
    id: checkPropTypes.number.isRequired,
    nickname: checkPropTypes.string.isRequired,
    phone_number: checkPropTypes.string.isRequired,
    city: checkPropTypes.string.isRequired,
  }),
};

export { AddToBlackListModal };
