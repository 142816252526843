import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useResetPrivatePage } from 'hooks/useResetPrivatePage';

import { useLocalContext } from 'LocalContext';
import { paths } from 'constants';

import { DividerWithLogo } from 'components/DividerWithLogo';
import { QuestionItem } from './components/QuestionItem';
import { HotDealForm } from './components/HotDealForm';

import styles from './styled.module.css';

const HotDeal = () => {
  useResetPrivatePage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    localState: { isPrivatePage },
  } = useLocalContext();

  const questions = [
    {
      title: t('pages.hotDeal.questions.1'),
      text: (
        <div className={styles.textBlock}>
          <p>
            {t('pages.hotDeal.questions.2')}
          </p>
          <p>
            {t('pages.hotDeal.questions.3')}
            {' '}
            <span
              style={{ background: '#633976', color: '#fff', padding: '0 20px' }}
            >
              {t('pages.hotDeal.questions.4')}
            </span>
          </p>
          <p>
            {t('pages.hotDeal.questions.5')}
            {' '}
            <span
              style={{ background: '#FF1A7A', color: '#fff', padding: '0 20px' }}
            >
              {t('pages.hotDeal.questions.6')}
            </span>
          </p>
        </div>
      ),
    },
    {
      title: t('pages.hotDeal.questions.7'),
      text: (
        <p>
          {t('pages.hotDeal.questions.8')}
        </p>
      ),
    },
  ];

  if (!isPrivatePage) {
    return <Navigate to={paths.myProfile} replace />;
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <div className={styles.title}>
        <div>
          <h1>{t('pages.hotDeal.title')}</h1>
          <p>{t('pages.hotDeal.1')}</p>
        </div>
      </div>
      <div className={styles.content}>
        <h3>
          {t('pages.hotDeal.2')}
          <br />
          {t('pages.hotDeal.3')}
        </h3>
        <p>
          {t('pages.hotDeal.4')}
          <br />
          {t('pages.hotDeal.5')}
        </p>
        <p>
          <b>
            {t('pages.hotDeal.6')}
            :
          </b>
          {' '}
          {t('pages.hotDeal.7')}
          <br />
          {t('pages.hotDeal.8')}
        </p>
        <h3>{t('pages.hotDeal.9')}</h3>
        <p className={styles.borderBlock}>{t('pages.hotDeal.10')}</p>
        <h3>{t('pages.hotDeal.11')}</h3>
        {questions.map((item) => (
          <QuestionItem key={item.title} title={item.title} text={item.text} />
        ))}
      </div>
      <DividerWithLogo className={styles.divider} />
      <div className={styles.title}>
        <div>
          <h1>
            {t('pages.hotDeal.12')}
            <br />
            {t('pages.hotDeal.13')}
          </h1>
        </div>
      </div>
      <HotDealForm />
    </div>
  );
};

export { HotDeal };
