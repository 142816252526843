import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Checkbox, Form, InputNumber, Table, Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'hooks/useIsMobile';

import { onlyNumberInInput } from 'utils/onlyNumberInInput';
import { useLocalContext } from 'LocalContext';
import { getUserData } from 'api/requests';
import { setDiscountInHotDeal } from 'api/requests/lifting';
import {
  formatBackToHotDealFront,
  formatFrontHotDealToBack,
} from 'utils/hotDeal';
import { duration } from 'utils/filters';

import { Spinner } from 'components/Spinner';
import { HotDealModal } from '../HotDealModal';

import styles from './styled.module.css';
import './styles.css';

const HotDealForm = () => {
  const [form] = Form.useForm();
  const {
    localState: { userInfo },
    localDispatch,
  } = useLocalContext();
  const { t } = useTranslation();

  const columns = (type) => [
    {
      title: <p className={styles.label}>{t(`common.${type}`)}</p>,
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: t('pages.hotDeal.hotDealForm.3'),
      dataIndex: 'currentPrice',
      key: 'currentPrice',
    },
    {
      title: t('pages.hotDeal.hotDealForm.4'),
      dataIndex: 'promotion',
      key: 'promotion',
    },
    {
      title: t('pages.hotDeal.hotDealForm.5'),
      dataIndex: 'newPrice',
      key: 'newPrice',
    },
  ];

  const backData = formatBackToHotDealFront(userInfo.rates);
  const [applyOnAll, setApplyOnAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const showTabs = useIsMobile({ maxWidth: 768 });

  const fetchUserInfo = async () => {
    setLoading(true);

    try {
      const res = await getUserData();
      localDispatch({ type: 'SET_USER' });
      const data = res.data.card;
      localDispatch({ type: 'SET_IS_CREATED_PROFILE', payload: true });
      localDispatch({ type: 'SET_USER_INFO', payload: data });
      setLoading(false);
    } catch (e) {
      localDispatch({ type: 'LOGOUT' });
    } finally {
      localDispatch({ type: 'SET_IS_FETCHED_USER', payload: true });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo.rates.some((item) => !item.id)) {
      fetchUserInfo();
    }
  }, []);

  const tableData = useMemo(
    () => (type, arr) => arr.map((item) => ({
      key: item.id || `${item.duration}${item.mount}`,
      duration: duration.find((el) => el.value === item.duration).label,
      currentPrice: item.amount,
      promotion: (
        <Form.Item
          name={`${type}__${item.duration}`}
          initialValue={item.discount_amount}
        >
          <InputNumber
            min={0}
            onKeyDown={(e) => onlyNumberInInput(e)}
            max={100}
            placeholder={t('Set promotion')}
            onClick={() => form.setFieldValue(`${type}__${item.duration}`, '')}
            disabled={applyOnAll}
            style={{ opacity: applyOnAll ? '0' : '1' }}
            formatter={(value) => (value ? `${value}%` : '')}
            parser={(value) => value.replace('%', '')}
          />
        </Form.Item>
      ),
      newPrice: (
        <Form.Item dependencies={[`${type}__${item.duration}`, 'promotion']}>
          {() => {
            const dependentValue = form.getFieldValue(
              applyOnAll ? 'promotion' : `${type}__${item.duration}`,
            );
            return (
              <p className={styles.example}>
                {`${
                  dependentValue
                    ? Math.floor(
                      item.amount * ((100 - +dependentValue) / 100),
                    )
                    : item.amount
                }`.slice(0, 9)}
              </p>
            );
          }}
        </Form.Item>
      ),
    })),
    [duration, applyOnAll, form, userInfo],
  );

  const onFinish = async (values) => {
    const rates_discounts = formatFrontHotDealToBack(
      applyOnAll,
      values,
      userInfo.rates,
    );

    const query = {
      rates_discounts,
    };

    setLoadingButton(true);
    try {
      const res = await setDiscountInHotDeal(query);
      localDispatch({
        type: 'SET_USER_INFO',
        payload: {
          ...userInfo,
          balance: res.data.charge_details.client_balance,
          promo: {
            ...userInfo.promo,
            hot_deal: { unblock_at: res.data.charge_details.lock },
          },
          rates: res.data.profile.json.card.rates,
          can_edit_profile: false,
        },
      });
      setOpenModal(true);
      setError(false);
    } catch (e) {
      setOpenModal(true);
      setError(true);
    } finally {
      setLoadingButton(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form
      className={`${styles.ratesWrapper} hotDeal`}
      form={form}
      onFinish={onFinish}
    >
      <div className={styles.ratesTitle}>
        <div>
          <div className={styles.currency}>
            <p>{t('common.yourCurrency')}</p>
            <p className={styles.whiteBlock}>PLN</p>
          </div>
        </div>
        <div>
          <Checkbox
            className={styles.whiteBlock}
            onChange={(e) => setApplyOnAll(e.target.checked)}
          >
            {t('pages.hotDeal.hotDealForm.2')}
          </Checkbox>
          <div className={styles.currency}>
            <p>{t('common.yourPromotion')}</p>
            <Form.Item name="promotion">
              <InputNumber
                min={0}
                onKeyDown={(e) => onlyNumberInInput(e)}
                max={100}
                disabled={!applyOnAll}
                rootClassName="promotion"
                onClick={() => form.setFieldValue('promotion', '')}
                formatter={(value) => (value ? `${value}%` : '')}
                parser={(value) => value.replace('%', '')}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className={styles.ratesList}>
        {showTabs ? (
          <Tabs
            defaultActiveKey="0"
            items={Object.keys(backData)
              .filter((item) => !!backData[item].length)
              .map((item, index) => ({
                key: `${index}`,
                label: (
                  <p className={`${styles.label} ${styles.colums}`}>{item}</p>
                ),
                children: (
                  <Table
                    columns={columns(item)}
                    dataSource={tableData(item, backData[item])}
                    pagination={false}
                  />
                ),
              }))}
          />
        ) : (
          <>
            {!!backData.outcall.length && (
              <Table
                columns={columns('outcall')}
                dataSource={tableData('outcall', backData.outcall)}
                pagination={false}
              />
            )}
            {!!backData.incall.length && (
              <Table
                columns={columns('incall')}
                dataSource={tableData('incall', backData.incall)}
                pagination={false}
              />
            )}
          </>
        )}
      </div>
      <Form.Item shouldUpdate className={styles.buttonBlock}>
        {() => {
          const { promotion, ...promotionData } = form.getFieldsValue();
          const notForAll = !!(
            !applyOnAll && Object.values(promotionData).some((item) => !!item)
          );
          const forAll = !!(applyOnAll && form.getFieldValue('promotion'));
          return (
            <Button
              type="primary"
              htmlType="submit"
              className={styles.button}
              loading={loadingButton}
              disabled={!forAll && !notForAll}
            >
              {t('common.makeItHot')}
              <br />
              25 GTL
            </Button>
          );
        }}
      </Form.Item>
      <HotDealModal open={openModal} setOpen={setOpenModal} error={error} />
    </Form>
  );
};

export { HotDealForm };
