import axios from 'axios';

import { reactAppPublicApiUrl } from 'config';

const flipperPath = '/api/v1/flipper_data/features';
export const getFlipperFeatures = async (key) => {
  try {
    const response = await axios.get(`${reactAppPublicApiUrl}${flipperPath}`);
    return {
      all_features: response?.data,
      search_feature: key ? response?.data?.[key] : false,
    };
  } catch {
    return false;
  }
};
