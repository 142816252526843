import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styled.module.css';
import './styles.css';

const ProfileDescriptions = ({ descriptions, profile }) => {
  const { t } = useTranslation();
  const [tabPosition, setTabPosition] = useState(
    window.innerWidth >= 765 ? 'left' : 'top',
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 765) {
        setTabPosition('left');
      } else {
        setTabPosition('top');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const catalogProfileDescription = (data) => data.map((item, index) => ({
    key: `${index}`,
    label: (
      <span className={styles.profileDescriptionsLabel}>
        {item.language.toUpperCase()}
      </span>
    ),
    children: <p className={styles.description}>{item.description}</p>,
  }));

  return (
    <div className={styles.profileDescriptions}>
      <p>
        {t('common.about')}
        {' '}
        {profile.nickname}
      </p>
      <Tabs
        className="description"
        defaultActiveKey="0"
        items={catalogProfileDescription(descriptions)}
        tabPosition={tabPosition}
      />
    </div>
  );
};

ProfileDescriptions.propTypes = {
  descriptions: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  profile: PropTypes.shape({
    nickname: PropTypes.string.isRequired,
  }),
};

export { ProfileDescriptions };
