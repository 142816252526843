import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { usePublicVersion } from 'hooks/usePublicVersion';

import { paths } from 'constants';

import logo from 'assets/logo.svg';
import publicLogo from 'assets/publicIcons/publicLogo.svg';

import { OpenArrow } from 'components/ActiveIcon/OpenArrow';

import styles from 'pages/NotCreatedProfile/styled.module.css';

const NotCreatedProfile = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { isPublicVersion } = usePublicVersion();

  const [open, setOpen] = useState(false);

  const isAfterEmailConfirm = state?.afterEmailComfirm;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>
          {isAfterEmailConfirm
            ? t('pages.notCreatedProfile.12')
            : t('pages.notCreatedProfile.1')}
        </h1>
        <p>{t('pages.notCreatedProfile.2')}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.logoBlock}>
          <img
            src={isPublicVersion ? publicLogo : logo}
            alt="logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.contentBlock}>
          <div
            className={`${styles.showHideTitle} ${open ? '' : styles.hide}`}
            onClick={() => setOpen(!open)}
          >
            <h4 className={styles.contentBlockTitle}>
              {t('pages.notCreatedProfile.3')}
            </h4>
            <OpenArrow
              className={`${styles.arrow} ${open ? styles.show : ''}`}
            />
          </div>
          <p className={`${styles.showHideContent} ${open ? styles.show : ''}`}>
            {t('pages.notCreatedProfile.4')}
            <br />
            {t('pages.notCreatedProfile.5')}
          </p>
          <h4 className={styles.contentBlockTitle}>
            {t('pages.notCreatedProfile.6')}
            :
          </h4>
          <p className={styles.note}>{t('pages.notCreatedProfile.7')}</p>
          <p className={styles.description}>
            {t('pages.notCreatedProfile.8')}
            <br />
            <b>
              {t('common.administration')}
              🚀
            </b>
          </p>
        </div>
      </div>
      <Button
        type="primary"
        className={styles.button}
        onClick={() => navigate(paths.profileCreation)}
      >
        {isAfterEmailConfirm
          ? t('pages.notCreatedProfile.11')
          : t('pages.notCreatedProfile.9')}
      </Button>
      <p className={styles.descriptionMobile}>
        {t('pages.notCreatedProfile.10')}
        <br />
        <b>
          {t('common.administration')}
          🚀
        </b>
      </p>
    </div>
  );
};

export { NotCreatedProfile };
