import PropTypes from 'prop-types';

import { Select } from 'components/Select';

const SelectItem = ({
  placeholder, value, setValue, options,
}) => (
  <Select
    multi
    options={options}
    defaultValue={value}
    onSelect={(e) => {
      setValue(e.includes('') ? [] : e);
    }}
    placeholder={placeholder}
  />
);

SelectItem.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export { SelectItem };
