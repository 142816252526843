import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import logo from 'assets/logo.svg';

import { SendMessage } from './components/SendMessage';
import { ResetPassword } from './components/ResetPassword';

import styles from 'pages/ForgotPasswordPage/styled.module.css';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isReset, setIsReset] = useState(false);

  const access_token = searchParams.get('access_token');

  useEffect(() => {
    setIsReset(access_token);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.title} ${isReset && styles.isReset}`}>
        <h1>{t('pages.forgotPasswordPage.1')}</h1>
        {!isReset && <p>{t('pages.forgotPasswordPage.2')}</p>}
      </div>
      <div className={styles.content}>
        <div className={styles.logoBlock}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <div className={styles.contentBlock}>
          {isReset ? (
            <ResetPassword />
          ) : (
            <SendMessage />
          )}
        </div>
      </div>
    </div>
  );
};

export { ForgotPasswordPage };
