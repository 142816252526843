import { useNavigate } from 'react-router-dom';

import styles from './styled.module.css';
import { useTranslation } from 'react-i18next';

const DeletePolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={`${styles.policies} ${styles.wrapper}`}>
      <div
        className={styles.back}
        onClick={() => navigate(-1, { replace: true })}
      >
        {t('common.goBack')}
      </div>
      <h1 className={styles.title}>How do I delete my ToLips account?</h1>
      <p>
        You can delete your ToLips account at any time. Once an account is
        deleted all information is wiped from the site and we can not restore
        it. If you want to come back to ToLips in future you will need to create
        a new profile and re-verify yourself. Deleting your ToLips account is
        permanent and can not be reversed. Once you confirm, the following with
        happen:
      </p>
      <ul className={styles.policyList}>
        <li>
          <p>
            Your account will be immediately closed and can no longer be
            accessed.
          </p>
        </li>
        <li>
          <p>
            Your profile will no longer be visible on the site, however may
            still be found in search engines, like Google results, for a period
            of time.
          </p>
        </li>
        <li>
          <p>
            Your profile will cease to be listed in any accounts who have
            favorited it.
          </p>
        </li>
        <li>
          <p>
            You will forfeit any remaining GTL balance and any time remaining on
            your current plan.
          </p>
        </li>
        <li>
          <p>You will be able to sign up using the same address again.</p>
        </li>
      </ul>
      <h2 className={styles.subtitle}>Steps to close your account</h2>
      <ul className={styles.numberList}>
        <li>
          <p>Log into ToLips and navigate to the dashboard.</p>
        </li>
        <li>
          <p>
            Scroll down to the account section and click the &ldquo;Close my
            account&ldquo; button.
          </p>
        </li>
        <li>
          <p>
            Read the information about what will happen next and when you are
            ready to proceed, click the &ldquo;Close account&ldquo; button. You
            may be prompted to enter your password during this step.
          </p>
        </li>
        <li>
          <p>
            Your profile is now deleted and you will be redirected to the login
            screen. You will no longer be able to log in with those account
            details.
          </p>
        </li>
      </ul>
      <h2 className={styles.subtitle}>
        My profile is still showing in Google, what do I do?
      </h2>
      <p>
        While we will hide your profile or delete your account immediately,
        Google, Bing, DuckDuckGo, and other search engines have their own copies
        of data that we can&apos;t force them to remove. In most cases they will
        clear old data after about 30-90 days, however they provide mechanisms
        for you to report content that should be removed as well.
      </p>
      <p>Unfortunately ToLips can not make these requests on your behalf.</p>
    </div>
  );
};
export { DeletePolicy };
