import React from 'react';
import { Form, Slider as AntdSlider } from 'antd';
import PropTypes from 'prop-types';

import { useLocalContext } from 'LocalContext';

import styles from './styled.module.css';
import './styles.css';

const Slider = ({
  title,
  label = false,
  postfix = '',
  disabled,
  dependencyValue,
  uniquelabel,
  onChange,
  step,
  value,
}) => {
  const {
    localState: { filters },
  } = useLocalContext();

  const formatTitle = title?.split('__')[0].replace(/_/g, ' ');

  return (
    <Form.Item className={styles.sliderBlock}>
      <div
        className={`${styles.title} ${label && styles.label} ${
          disabled && dependencyValue === undefined ? styles.disabled : ''
        }`}
      >
        <p>
          {uniquelabel || formatTitle}
          :
        </p>
        <h4>
          {value[0]}
          {postfix}
          {' '}
          -
          {' '}
          {value[1]}
          {postfix}
        </h4>
      </div>
      <Form.Item className={styles.slider} name={title.toLowerCase()}>
        <AntdSlider
          min={filters[`${title}_min`]}
          max={filters[`${title}_max`]}
          range={{ draggableTrack: true }}
          onChangeComplete={(e) => onChange(e)}
          value={value}
          step={step}
          marks
          tooltip={{
            formatter: null,
            rootClassName: 'filtersModalSliderTooltip',
          }}
          disabled={disabled && dependencyValue === undefined}
        />
      </Form.Item>
    </Form.Item>
  );
};

Slider.propTypes = {
  postfix: PropTypes.string,
  title: PropTypes.string,
  uniquelabel: PropTypes.string,
  label: PropTypes.bool,
  disabled: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.number,
  dependencyValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
};

export { Slider };
