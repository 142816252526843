import React from 'react';
import { Badge } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './styled.module.css';

const StepTitle = ({ link, name, badge }) => {
  const { pathname } = useLocation();
  return (
    <Link to={link}>
      <div
        className={`${styles.title} ${pathname === link ? 'active' : ''} ${
          link === '.' ? styles.disabled : ''
        }`}
      >
        <span>{name}</span>
        {badge && (
          <Badge
            count={badge}
            size="small"
            color={pathname === link ? '#FF1A7A' : '#633976'}
            className={styles.badge}
          />
        )}
      </div>
    </Link>
  );
};

StepTitle.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { StepTitle };
