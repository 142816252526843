import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { ModalWrapper } from 'components/ModalWrapper';
import { Button } from 'components/Button';

import styles from './styled.module.css';

const InfoModal = ({
  open, setOpen, modalStep, setCurrentStep, onClick,
}) => (
  <ModalWrapper open={open} setOpen={setOpen}>
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {t('pages.profileCreation.19')}
      </h2>
      <p>{t('pages.profileCreation.20')}</p>
      <div className={styles.buttonBlock}>
        <Button secondary onClick={() => setOpen(false)}>
          {t('common.cancel')}
        </Button>
        <Button
          primary
          onClick={() => {
            setOpen(false);
            if (setCurrentStep && modalStep !== null) {
              setCurrentStep(modalStep);
            }
            if (onClick) {
              onClick();
            }
          }}
        >
          {t('pages.profileCreation.21')}
        </Button>
      </div>
    </div>
  </ModalWrapper>
);

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func,
  modalStep: PropTypes.number,
  onClick: PropTypes.func,
};

export { InfoModal };
