import React, { memo } from 'react';
import { Drawer, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useIsMobile } from 'hooks/useIsMobile';
import { useBlockScroll } from 'hooks/useBlockScroll';

import { CloseIcon } from 'components/ActiveIcon/CloseIcon';

import styles from './styled.module.css';
import './styles.css';

const ModalWrapper = memo(
  ({
    open = false,
    setOpen,
    onClose = () => {},
    children,
    forceRender = false,
    footer = null,
    maxHeightModal = '',
    widthModal = '',
    onlyDrawer = false,
    noClose = false,
    rootClassName = '',
    minHeightDrawer = 0,
  }) => {
    const isMobile = useIsMobile();
    useBlockScroll(open);

    const onCloseModal = (e) => {
      e.stopPropagation();
      setOpen(false);
      onClose();
    };

    if (onlyDrawer) {
      return (
        <Drawer
          title={null}
          placement="bottom"
          open={open}
          onClose={noClose ? () => {} : onCloseModal}
          forceRender={forceRender}
          destroyOnClose
          footer={footer}
          styles={{
            header: { display: 'none' },
            body: {
              padding: footer ? '20px 20px 0' : '20px',
              overflowY: 'auto',
              minHeight: minHeightDrawer,
              maxHeight: footer ? 'calc(98dvh - 98px)' : '98dvh',
            },
            footer: { padding: '20px', borderTop: 'none', textAlign: 'center' },
          }}
          rootClassName={`modalWrapper ${rootClassName}`}
        >
          {!noClose && (
            <CloseIcon
              className={styles.close}
              onClick={onCloseModal}
              id="modalCloseIcon"
            />
          )}
          {children}
        </Drawer>
      );
    }

    return isMobile ? (
      <Drawer
        title={null}
        placement="bottom"
        open={open}
        onClose={noClose ? () => {} : onCloseModal}
        forceRender={forceRender}
        destroyOnClose
        footer={footer}
        styles={{
          header: { display: 'none' },
          body: {
            padding: footer ? '20px 20px 0' : '20px',
            overflowY: 'auto',
            minHeight: minHeightDrawer,
            maxHeight: footer ? 'calc(98dvh - 98px)' : '98dvh',
          },
          footer: { padding: '20px', borderTop: 'none', textAlign: 'center' },
        }}
        rootClassName={`modalWrapper ${rootClassName}`}
      >
        {!noClose && (
          <CloseIcon
            className={styles.close}
            onClick={onCloseModal}
            id="modalCloseIcon"
          />
        )}
        {children}
      </Drawer>
    ) : (
      <Modal
        open={open}
        onOk={onCloseModal}
        onCancel={noClose ? () => {} : onCloseModal}
        footer={footer}
        centered
        closable={!noClose}
        forceRender={forceRender}
        destroyOnClose
        rootClassName={`modalWrapper ${rootClassName}`}
        width={widthModal || '520px'}
        styles={{
          content: {
            padding: maxHeightModal ? '20px 0 20px 20px' : '20px',
          },
          header: { display: 'none' },
          ...(maxHeightModal && {
            body: {
              overflowY: 'auto',
              padding: '0 20px 4px 2px',
              maxHeight: maxHeightModal,
            },
          }),
          footer: {
            textAlign: 'center',
            ...(maxHeightModal && { paddingRight: '17px' }),
          },
        }}
      >
        {children}
      </Modal>
    );
  },
);

ModalWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  forceRender: PropTypes.bool,
  footer: PropTypes.node,
  maxHeightModal: PropTypes.string,
  onlyDrawer: PropTypes.bool,
  widthModal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  noClose: PropTypes.bool,
  rootClassName: PropTypes.string,
  minHeightDrawer: PropTypes.number,
};

export { ModalWrapper };
